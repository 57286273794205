export enum PeriodOfExperience {
  ZeroToTwo = 0,
  TwoToFour = 1,
  FourToSeven = 2,
  SevenPlus = 3,
}

export enum PeriodOfExperienceDescription {
  ZeroToTwo = '0-2 years',
  TwoToFour = '2-4 years',
  FourToSeven = '4-7 years',
  SevenPlus = '7+ years',
}
