import { AuthenticationStatus } from '../../enums';
import { KeplerState } from '../../models/kepler-state';

export function derivedMayViewSideNav(state: KeplerState): boolean {
  if (state.isInitialising) return false;
  if (state.authenticationStatus !== AuthenticationStatus.Authenticated) return false;
  if (!state.user || !state.user.completedOnboarding) return false;
  if (!!state.skillAssessmentConfig) return false;
  return state.sideNavVisible;
}
