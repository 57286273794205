import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';

export function AdditionalLearningSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="150" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />

        <SkeletonLoaderColumn>
          <SkeletonLoader height="45" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="80" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="80" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="80" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="80" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="80" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="80" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
