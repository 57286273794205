import React from 'react';
import { SkillLayout } from './skill.layout';
import { PagePath } from '../../../navigation/navigation.enums';
import { useParams } from 'react-router';

export function SkillPage(): JSX.Element {
  const { skillSlug } = useParams();

  return <SkillLayout parentPath={PagePath.learningJourneyBase} path={PagePath.learningJourneySkill} slug={skillSlug} />;
}

