import styled from 'styled-components';

export const RatingConfirmationModalCardWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const RatingConfirmationModalCards = styled.div`
  width: 40%;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
`;
