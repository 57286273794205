import styled from 'styled-components';

// account
export const AccountPageButtonContainer = styled.button`
  display: inline-flex;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 0px;
  cursor: pointer;
  padding: 5px;
  margin: 0 10px;
  background: transparent;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const AccountPageContainer = styled.div`
  width: 90%;
  height: auto;
  text-align: center;
  margin: auto;
  max-width: 700px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 580px) {
    height: calc(100vh - 126px);
    padding: 10px 0 30px 0;
    min-height: 100vh;
  }

  .card.glass {
    padding: 40px 20px;
    position: relative;

    &.no-toggler {
      padding: 40px 20px 40px 20px;
    }

    .heading1 {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @media screen and (max-width: 580px) {
      overflow: auto;
      padding-top: 40px;
      padding-bottom: 60px;

      &.no-toggler {
        padding-bottom: 40px;
      }

      .heading1 {
        padding-top: 5px;
        padding-bottom: 10px;
      }
    }
  }
`;

export const AccountPageForm = styled.form`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  margin: auto;
  max-width: 330px;
  padding: 30px 0;
`;

export const AccountPageInstruction = styled.div`
  padding: 30px 0;

  .heading5 {
    font-size: 30px;
    font-family: var(--body);
  }

  @media screen and (max-width: 580px) {
    padding: 0px;

    .heading5 {
      font-size: 16px;
    }
  }
`;

export const AccountPageLineBreak = styled.div`
  text-align: center;
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: var(--text);
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 330px;
  margin: auto;
  margin-bottom: 15px;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--accent-2);
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
`;

export const AccountPageNavigationToggler = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  @media screen and (max-width: 580px) {
    bottom: 20px;
    top: unset;
    margin: auto;
    width: 100%;
    right: 0;
  }

  u {
    color: var(--link-text);
  }

  &:hover u {
    color: var(--primary);
  }

  &.showDesktop {
    @media screen and (max-width: 581px) {
      display: none;
    }
  }

  &.showMobile {
    @media screen and (min-width: 580px) {
      display: none;
    }
  }

  &.bottom {
    bottom: -5px;
  }
`;

// login
export const LoginAccountPageForgotPassword = styled.div`
  width: 100%;
  height: auto;
  text-align: right;
  cursor: pointer;
  line-height: 15px;

  .caption1 {
    color: var(--link-text);
    padding: 0;

    &:hover {
      color: var(--primary);
    }
  }
`;

// register
export const RegisterAccountPageAnchor = styled.a`
  color: var(--link-text);
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
`;

// set-password
export const SetPasswordAccountPageCheckbox = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 30px;

  a {
    color: var(--link-text);
  }
`;

// verify-email
export const VerifyEmailAccountPageImage = styled.object`
  display: block;
  width: 100%;
  height: auto;
`;

export const VerifyEmailAccountPageCopy = styled.div`
  display: block;
  width: 100%;
  max-width: 590px;
  margin: auto;

  .subtitle {
    color: var(--text_1);
    padding: 15px 0;

    strong {
      color: var(--text);
    }

    span {
      color: var(--link-text);
      cursor: pointer;
    }
  }
`;
