import { Context } from '../..';
import { ErrorSignalTypes } from '../../../enums/error-signal-types';
import { getTotalLearnerKeplerPoints } from './kepler-points';
import { handleErrorToErrorPage } from '../functions';
import { QuestionAnswerProps } from '../../../models/overmind/questionnaire';
import { Questionnaire, QuestionnaireName } from '../../../models/questionnaire';
import * as base from '../base';
import { QuestionSetType } from '../../../enums/question-set-type';

export async function getAssessmentQuestionnaires(context: Context): Promise<Questionnaire[] | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`questionnaire`, context.state.skillAssessmentConfig?.assessmentSlug, `get-questionnaires`), authenticated: true };
  const response: base.IResponse<Questionnaire[]> = await base.request(request);
  return response.data;
}

export async function getQuestionSetSkillQuestionnaire(context: Context, requestParams: { questionSetType: QuestionSetType; skillSlug: string }): Promise<Questionnaire | undefined> {
  const request: base.IRequest = { url: base.url(`questionnaire`, `get-question-set-skill-questionnaire`, requestParams.questionSetType.toString(), requestParams.skillSlug), authenticated: true };
  const response: base.IResponse<Questionnaire> = await base.request(request);

  return response.data;
}

export async function answerQuestionSetQuestion(context: Context, requestParams: { questionSetType: QuestionSetType; questionAnswers: QuestionAnswerProps }): Promise<number | undefined> {
  const request: base.IRequest = { url: base.url(`questionnaire`, `answer-question-set-question`, requestParams.questionSetType.toString(), requestParams.questionAnswers.questionnaireSlug, requestParams.questionAnswers.answerId.toString()), authenticated: true, method: 'POST' };
  const response: base.IResponse<number> = await base.request(request);

  if (response.error) throw new Error(`Failed to save the answer to question`);

  return response.data;
}

export async function getQuestionnaires(context: Context, questionnaireSlug: string): Promise<Questionnaire | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`questionnaire`, `get-questionnaire`, context.state.skillAssessmentConfig?.assessmentSlug, questionnaireSlug), authenticated: true };
  const response: base.IResponse<Questionnaire> = await base.request(request);
  if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);

  return response.data;
}

export async function getQuestionnaireNames(context: Context, companySlug: string): Promise<QuestionnaireName[] | undefined> {
  const request: base.IRequest = { url: base.url(`questionnaire`, `get-questionnaires-names?companySlug=${companySlug}`), authenticated: true };
  const response: base.IResponse<QuestionnaireName[]> = await base.request(request);

  return response.data;
}

export async function answerQuestion(context: Context, payload: QuestionAnswerProps): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`questionnaire`, `answer-question`, context.state.skillAssessmentConfig?.assessmentSlug, payload.questionnaireSlug, payload.answerId.toString()), authenticated: true, method: 'POST' };
  const response = await base.request(request);

  if (response.error) throw new Error(`Failed to save the answer to question`);
}

export async function completeQuestionnaireSection(context: Context, payload: QuestionAnswerProps): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`questionnaire`, `complete-questionnaire-skill`, context.state.skillAssessmentConfig?.assessmentSlug, payload.questionnaireSlug, payload.answerId.toString()), authenticated: true, method: 'POST' };
  await base.request(request);
  await getTotalLearnerKeplerPoints(context);
}

export async function completeQuestionnaire(context: Context, questionnaireSlug: string): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`questionnaire`, `complete-questionnaire`, context.state.skillAssessmentConfig?.assessmentSlug, questionnaireSlug), authenticated: true, method: 'POST' };
  await base.request(request);
}
