import { DropdownSelectItem } from '@keplerco/core';
import { AssessmentType } from '../../../enums/assessment-type';
import { CompletionStatus } from '../../../enums';

export function setFilterItems(currentItems: DropdownSelectItem[], value: string): DropdownSelectItem[] {
  const nextItems = currentItems.map(item => ({ ...item }));
  nextItems.forEach(item => {
    item.selected = item.value === value;
  });
  return nextItems;
}

export function clearFilterItems(currentItems: DropdownSelectItem[]): DropdownSelectItem[] {
  const nextItems = currentItems.map(item => ({ ...item }));
  nextItems.forEach(item => (item.selected = false));
  return nextItems;
}

export const assessmentTypeItems: DropdownSelectItem[] = [
  { label: 'Peer Endorsement', value: AssessmentType.PeerEndorsement.toString(), onClick: () => console.log('onClick') },
  { label: 'Questionnaire', value: AssessmentType.Questionnaire.toString(), onClick: () => console.log('onClick') },
  { label: 'Fields Of Practice', value: AssessmentType.FieldsOfPractice.toString(), onClick: () => console.log('onClick') },
];

export function getBorderColorByCompletionStatus(status: CompletionStatus) {
  switch (status) {
    case CompletionStatus.Completed:
      return 'green';
    case CompletionStatus.InProgress:
      return 'orange';
    case CompletionStatus.NotStarted:
      return 'red';
    default:
      return 'gray';
  }
}

export function formatAssessmentTypeLabel(label: string): string {
  return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}
