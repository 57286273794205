import styled from 'styled-components';

export const LoginStreakDays = styled.div`
  display: block;
  width: 90%;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

