import styled from 'styled-components';

export const LearningManagementActions = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  gap: 20px;
`;

export const CourseMappingName = styled.span`
  max-width: 50ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 580px) {
    white-space: normal;
  }
`;

export const TooltipTrigger = styled.span`
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
