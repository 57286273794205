export enum ResponseGroup {
  Overall = 1,
  SkillSubType = 2,
  Skill = 3,
  SkillType = 4,
}

export enum SkillType {
  Behavioural = 1,
  Technical = 2,
  Other = 3,
  Combined = 4,
  Knowledge = 5,
  Competence = 6,
}

export enum UrgencyIndication {
  BelowExpectation = 'Needs urgent improvement',
  MeetsExpectation = 'Needs improvement',
  ExceedsExpectation = 'Needs maintenance',
}
