import { Anchor, GridItemLayout, GridLayout } from '@keplerco/core';
import styled from 'styled-components';

export const AssessmentDetailsWidgetInputLabel = styled.h5`
  color: var(--accent-3);
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-bottom: 5px;

  &.paddingBottom {
    padding-bottom: 10px;
  }
`;

export const AssessmentDetailsRadioButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const AssessmentDetailsWidgetPointsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

export const AssessmentDetailsWidgetFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const AssessmentDetailsWidgetAssessmentNameWrapper = styled.div`
  display: flex;
  width: 60%;
  margin-right: 20px;
  flex-direction: column;
`;

export const AssessmentDetailsWidgetGridLayout = styled(GridLayout).attrs({ as: 'ul' })`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const AssessmentDetailsWidgetGridItemLayout = styled(GridItemLayout).attrs({ as: 'li' })`
  width: 100%;
  padding-bottom: 0px;
`;

export const AssessmentDetailsWidgetSubtitle = styled.h6`
  font-size: 14px;
`;

export const AssessmentDetailsWidgetRadioCardLabel = styled.label`
  text-align: left;
  font-size: 16px;
  color: var(--accent-3);
`;

export const AssessmentDetailsWidgetContentLayout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  align-items: flex-start;
  gap: 0px;
`;

export const AssessmentDetailsSubmitButton = styled(Anchor)`
  align-self: flex-end;
  margin: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const AssessmentDetailsSubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

export const AssessmentDetailsRadioButtonCardWrapper = styled.div`
  &.hasError {
    border-color: var(--r);
  }
`;

