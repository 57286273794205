import React from 'react';

export function EmailIcon(): JSX.Element {

  return (
    <svg width="20" height="16" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.4375 2.3538C0.4375 1.5569 1.08351 0.910889 1.88041 0.910889H13.4237C14.2206 0.910889 14.8666 1.5569 14.8666 2.3538V11.0113C14.8666 11.8082 14.2206 12.4542 13.4237 12.4542H1.88041C1.08351 12.4542 0.4375 11.8082 0.4375 11.0113V2.3538ZM2.97601 2.3538L7.65205 6.44534L12.3281 2.3538H2.97601ZM13.4237 3.31245L8.12713 7.94693C7.85512 8.18494 7.44898 8.18494 7.17697 7.94693L1.88041 3.31245V11.0113H13.4237V3.31245Z" fill="#E5145F" />
    </svg>

  );
}

