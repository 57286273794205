import styled from 'styled-components';

export const AccountPageLoaderLayerChildren = styled.div`
  will-change: height, opacity;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 350ms;

  &.isLoading {
    height: 300px !important;
    opacity: 0;
  }
`;

export const AccountPageLoaderLayerLoader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: none;
  opacity: 0;
  will-change: opacity;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 350ms;

  &.isLoading {
    display: initial;
    opacity: 1;
  }
`;
