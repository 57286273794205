import React from 'react';
import { ManagePersonPermissionsFocusPanelSkeleton } from './manage-person-permissions-focus-panel.skeleton';
import { FocusPanelLoaderLayer } from '../../../../../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { OrganizationLevelType } from '../../../../../../../enums';
import { PermissionType } from '../../../../../../../enums/permission-type';
import { extractPermission, extractHighestOrganizationLevel } from '../../../../../../../lib/permissions.helpers';
import { IManagePersonPermissionsFocusPanelProps, ManagePersonPermissionsCascadingPanelIds } from '../../manage-person-panels.models';
import { ManagePersonHeaderLayout, ManagePersonHeaderLayoutIcon, ManagePersonBodyLayout } from '../../manage-person-panels.styles';
import { PagePath } from '../../../../../../../navigation/navigation.enums';
import { Anchor, AvatarIcon, Chip, ListItemLayout, ListLayout, useMatchScreenWidth } from '@keplerco/core';

export function ManagePersonPermissionsFocusPanel(props: IManagePersonPermissionsFocusPanelProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  function hasAssigned(permissionType: PermissionType): boolean {
    if (!props.permissions) return false;

    const permission = extractPermission(props.permissions, permissionType);
    if (!permission) return false;

    const organizationLevel = extractHighestOrganizationLevel(permission.organizationLevels);
    return !!organizationLevel && organizationLevel.organizationLevel !== OrganizationLevelType.Learner;
  }

  function generateChip(permissionType: PermissionType): JSX.Element {
    const permissionTypes = [PermissionType.Analytics, PermissionType.Administration, PermissionType.LearningManagement, PermissionType.Assessments, PermissionType.RoleManagement];
    if (!permissionTypes.includes(permissionType)) return <Chip label="Not available in the system yet" />;

    if (hasAssigned(permissionType)) return <Chip label="Permissions assigned" backgroundColour="apple" />;

    return <Chip label="No permissions assigned" />;
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationPeople} skeletonLoader={<ManagePersonPermissionsFocusPanelSkeleton />}>
      <div className="panelContent">
        <ManagePersonHeaderLayout className="dialogHeaderLayout">
          <ManagePersonHeaderLayoutIcon>
            <AvatarIcon name={props.person} />
          </ManagePersonHeaderLayoutIcon>

          <div>
            <h3 className="heading3">Permissions</h3>

            {!!props.person && (
              <span className="caption1">
                <strong>Name</strong> {props.person.firstName} {props.person.lastName} | <strong>Department</strong> {props.person.departmentName} | <strong>Team</strong> {props.person.teamName}
              </span>
            )}
          </div>
        </ManagePersonHeaderLayout>

        <ManagePersonBodyLayout className="dialogBodyLayout">
          <ListLayout>
            <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Analytics)}>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    Analytics
                    {!isMobile && generateChip(PermissionType.Analytics)}
                  </div>
                </div>
              </div>
            </ListItemLayout>

            <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Administration)}>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    Administration
                    {!isMobile && generateChip(PermissionType.Administration)}
                  </div>
                </div>
              </div>
            </ListItemLayout>

            <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Roles)}>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    Roles
                    {!isMobile && generateChip(PermissionType.RoleManagement)}
                  </div>
                </div>
              </div>
            </ListItemLayout>

            <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Assessments)}>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    Assessments
                    {!isMobile && generateChip(PermissionType.Assessments)}
                  </div>
                </div>
              </div>
            </ListItemLayout>

            <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.LearningManagement)}>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    Learning Management
                    {!isMobile && generateChip(PermissionType.LearningManagement)}
                  </div>
                </div>
              </div>
            </ListItemLayout>

            <ListItemLayout>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    Skills
                    {!isMobile && generateChip(PermissionType.Skills)}
                  </div>
                </div>
              </div>
            </ListItemLayout>
          </ListLayout>
        </ManagePersonBodyLayout>

        <footer className="panelFooter">
          <Anchor onClick={props.onCancel}>Cancel</Anchor>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}

