import React, { useEffect, useState } from 'react';
import { IPersonDetailsProps } from './person-details.models';
import { AvatarIcon, Button, KeplerPoints, useMatchScreenWidth } from '@keplerco/core';
import styles from './person-details.module.css';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { PDFReport } from '../../../../../components/pdf-report/pdf-report';
import { useAppActions } from '../../../../../overmind';
import { ISettings } from '../../../../../models/settings';
import { SkeletonLoader } from '../../../../../components';
import { EmailIcon } from '../../../../../components/assets/email.icon';
import classNames from 'classnames';

export function PersonDetails({ firstName, lastName, learnerSlug, path, keplerPoints }: IPersonDetailsProps): JSX.Element {
  const actions = useAppActions();
  const keplerNavigate = useKeplerNavigate();

  const [userProfile, setUserProfile] = useState<ISettings>();

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    async function getUserProfile() {
      if (path !== PagePath.dashboard) return;
      const profileResponse = await actions.fetchUserSettingsProfile();
      setUserProfile(profileResponse);
    }

    getUserProfile();
  }, [learnerSlug]);

  return (
    <div className={styles.personDetailsContainer} id="employee-detail-section">
      {!userProfile && path === PagePath.dashboard ? (
        <SkeletonLoader height='100px' />
      ) : (
        <div className={styles.personProfileSection}>
          <div className={styles.personDetails}>
            <div className={styles.personDetailsAvatar}>
              <AvatarIcon name={{ firstName: userProfile?.firstName ?? firstName, lastName: userProfile?.lastName ?? lastName }} />
            </div>

            <div className={styles.personDetailsContent}>
              <h1 className="heading1">{`${userProfile?.firstName ?? firstName} ${userProfile?.lastName ?? lastName}`}</h1>
              {path === PagePath.dashboard && (
                <>
                  <h5 className='caption1' style={{ marginTop: 5 }}>{`Job title: ${userProfile?.jobTitle}`}</h5>
                  <h5 className={classNames('caption1', styles.details)}>
                    <span>{`Department: ${userProfile?.department.length || 'Department'}`}</span>
                    {!isMobile && <span>|</span>}
                    <span style={{ display: 'flex', alignItems: 'center', gap: 3 }}><EmailIcon /> <div>{userProfile?.email}</div></span>
                  </h5>
                </>
              )}
            </div>
          </div>

          {path === PagePath.dashboard && (
            <div className={styles.personActions}>
              <PDFReport path={PagePath.dashboard} />
              <Button type='button' square fullWidthMobile theme='link-text' arrow={false} onClick={() => keplerNavigate(PagePath.profile)}>Edit profile</Button>
            </div>
          )}
        </div>
      )}

      {!!keplerPoints && (
        <div className={classNames('card', 'glass', styles.keplerPoints)}>
          {!isMobile && (
            <div className="heading4" style={{ whiteSpace: 'nowrap' }}>Kepler Points: </div>
          )}

          <h2 className="heading3">
            {isMobile && 'Kepler Points: '}<KeplerPoints trimWhiteSpace points={keplerPoints} />
          </h2>
        </div>
      )}
    </div>
  );
}

