import React from 'react';
import { useParams } from 'react-router';
import { TeamsCMSLayout } from './teams.cms.layout';
import { PagePath } from '../../../navigation/navigation.enums';

export function CompanyTeamsCMSPage(): JSX.Element {
  const params = useParams();

  return <TeamsCMSLayout path={PagePath.administrationTeams} slugs={{ companySlug: params.companySlug! }} type="company" />;
}
