import styled from 'styled-components';

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  position: relative;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export const SkillFrequencyPageHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  justify-items: flex-end;
  align-items: flex-end;
  gap: 30px;

  padding-bottom: 30px;

  margin-bottom: 30px;
  border-bottom: 1px solid var(--borders);
`;
