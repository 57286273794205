import styled from 'styled-components';

export const MultipleChoiceQuestionWrapper = styled.div``;

export const MultipleChoiceQuestionHeader = styled.header`
  font-family: var(--heading);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--text);
  margin: 0;
  margin-bottom: 10px;

  @media screen and (max-width: 580px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const MultipleChoiceQuestionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--text);

  @media screen and (max-width: 580px) {
    padding-top: 5px;
  }
`;

export const MultipleChoiceQuestionListItem = styled.li`
  line-height: 25px;
  padding-left: 25px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 16px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &::before {
    display: block;
    width: 15px;
    height: 15px;
    border: solid 1px var(--link-text);
    left: 0px;
    top: 5px;
  }

  &::after {
    width: 11px;
    height: 11px;
    left: 2px;
    top: 7px;
    background: var(--link-text);
    transform: scale(0);
    opacity: 0;
    will-change: opacity, transform;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  }

  &.active::after {
    transform: scale(1);
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.saving {
    &::before {
      border-width: 2px;
      animation: PulseRing infinite 1400ms linear;
    }
  }

  &.fade {
    opacity: 0.45;
    cursor: not-allowed;
  }

  @media screen and (max-width: 580px) {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 2px;
  }

  @keyframes PulseRing {
    0% {
      transform: scale(1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
`;
