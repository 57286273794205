import { BaseValidator } from '@keplerco/core';
import { ICareerPathFormValues } from './career-path.models';

export class CareerPathValidator extends BaseValidator {
  constructor(public readonly required: string = 'Select a career path', public readonly formValues: ICareerPathFormValues[]) {
    super(required, {
      uniqueCareerPath: (careerPathSlug: string) => {
        return formValues.filter(formValue => formValue.careerPath?.careerPathSlug === careerPathSlug).length < 2 || `Choose a different career path for each level. `;
      },
    });
  }
}
