import styled from 'styled-components';

export const AppLoaderLayerContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: calc(100% - 40px);
  max-width: 490px;
  background: var(--background);
  padding: 15px;
  animation: SpinnerIn cubic-bezier(0.6, -0.28, 0.735, 0.045) 300ms forwards;
  pointer-events: all;
  box-shadow: 0px 4px 20px var(--outer-shadow-colour);
  border-radius: 24px;
  border: solid 4px var(--background);

  display: flex;
  align-items: center;
  gap: 20px;

  @keyframes SpinnerIn {
    from {
      opacity: 0;
      transform: translateX(100%) skew(10deg);
    }

    to {
      opacity: 1;
      transform: translateX(0) skew(0deg);
    }
  }

  @media (max-width: 580px) {
    bottom: 15px;
    right: 15px;
    padding: 10px;
    width: calc(100% - 30px);
    gap: 15px;
  }

  .body1 {
    color: var(--text);
  }
`;

export const AppLoaderLayerOverlay = styled.dialog`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  width: 100%;
  height: 100%;
  padding: 0;
  border: none;

  background: var(--background_1);
  backdrop-filter: blur(3px);
  pointer-events: none;

  opacity: 0;
  will-change: opacity;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

  &.syncing {
    background: none;
    backdrop-filter: none;

    ${AppLoaderLayerContainer} {
      max-width: 390px;
      background: var(--background_1);
      pointer-events: all;
    }
  }

  &[open] {
    pointer-events: all;
    opacity: 1;

    &.syncing {
      pointer-events: none;
    }
  }
`;
