import React from 'react';
import { SkeletonLoader } from '../../loaders/skeleton-loader/skeleton-loader';

export function FocusPanelSkeletonLoader(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <header className="dialogHeaderLayout">
        <SkeletonLoader height="100px" />
      </header>

      <div className="dialogBodyLayout">
        <SkeletonLoader height="calc(100vh - 310px)" />
      </div>

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="50px" width="150px" />
        <SkeletonLoader height="50px" width="150px" />
      </footer>
    </div>
  );
}

