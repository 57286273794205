import styled from 'styled-components';
import { AppPageWrapper } from '../../theme/layout.styles';

export const SettingsCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  button {
    display: block;
    margin: 0;
    appearance: none;
    background: transparent;
    color: var(--link-text);
    border: none;
    font-size: 16px;
    font-family: var(--body);
    text-transform: uppercase;
    height: 40px;
    padding: 0 0px;
    border-radius: 5px;
    will-change: padding, color, background;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
    cursor: pointer;
    font-weight: 400;

    &.editing {
      background: var(--primary);
      color: var(--contrast-text);
      padding: 0 15px;
    }
  }
`;

export const SettingsHeaderProfile = styled.div`
  width: 150px;
  height: 150px;
  border: solid 3px var(--text);
  border-radius: 50%;
  background: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  span {
    font-size: 55px;
  }

  @media screen and (max-width: 580px) {
    width: 60px;
    height: 60px;

    img {
      width: 60px;
      height: 60px;
    }

    span {
      font-size: 35px;
    }
  }
`;

export const SettingsHeaderTitle = styled.div`
  flex: 1;

  .job-title {
    color: var(--accent-2);
  }

  .tag {
    padding-right: 5px;
  }

  .value {
    color: var(--primary);
    font-weight: 700;
  }
`;

export const SettingsHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;

  @media screen and (max-width: 930px) {
    margin: auto;
    max-width: 700px;
    gap: 15px;
  }
`;

export const SettingsPageLayout = styled.article`
  display: grid;
  padding: 50px 0 0 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'about contact' 'security contact';
  gap: 35px;

  .subtitle {
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .about {
    grid-area: about;

    form {
      display: block;
      width: 100%;
    }
  }

  .contact {
    grid-area: contact;

    .card:not(.version) {
      padding: 25px 0 0 0;

      ${SettingsCardHeader} {
        width: calc(100% - 40px);
        margin: auto;
      }
    }

    .card.version {
      margin-top: 35px;
      text-align: center;
    }
  }

  .security {
    grid-area: security;
  }

  @media screen and (max-width: 930px) {
    margin: auto;
    max-width: 700px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'about' 'contact' 'security';
  }
`;

export const SettingsPageWrapper = styled(AppPageWrapper)`
  padding-top: 50px;
`;

export const AddEmailButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  width: 100%;
  background: none;
  border: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 16px;
  color: var(--link-text);
  font-family: var(--body);
  margin-top: -5px;
  text-transform: uppercase;
`;

export const AddEmailField = styled.form`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  .control {
    flex: 1;
  }

  button {
    display: block;
    height: 45px;
    border-radius: 8px;
    padding: 0 20px;
    border: solid 1px var(--primary);
    background: none;
    color: var(--text);
    font-family: var(--heading);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background: var(--primary);
    }
  }
`;

// Email Block
export const SettingsPageEmailBlock = styled.div`
  display: block;
  position: relative;
  padding: 20px;

  &:not(:last-child) {
    border-bottom: solid 1px var(--borders);
  }
`;

export const PrimaryEmailChipWrapper = styled.div`
  display: block;
  padding: 10px 0;
`;

export const PrimaryEmailChip = styled.span`
  display: inline-block;
  padding: 5px 7px;
  border: solid 1px var(--accent-3);
  border-radius: 5px;
  color: var(--text) !important;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: var(--primary);
    border-color: var(--primary);
  }

  &.active {
    background: var(--apple);
    border-color: var(--apple);
    cursor: default;
  }

  &.unverified {
    cursor: not-allowed;
    background: var(--background);
    border-color: var(--borders);
    color: var(--borders) !important;
  }
`;

export const EmailVerificationStatusIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 5px 0;

  span,
  button {
    line-height: 20px;
    font-size: 16px;
    font-family: var(--body);
  }

  span {
    display: inline-block;
    padding-right: 30px;
    position: relative;

    .indicator {
      display: block;
      position: absolute;
      right: 7px;
      top: 50%;
      width: 16px;
      height: 16px;
      margin-top: -8px;
    }
  }

  button {
    background: transparent;
    border: none;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const EmailBlockDeleteButtonContainer = styled.span`
  position: absolute;
  top: calc(50% - 50px);
  right: 15px;
  transform: translateY(calc(-50% + 50px));
`;
// End Email Block

export const SettingsPageDetailsItem = styled.div`
  display: block;
  padding: 5px 0;

  span {
    color: var(--accent-3);
    font-size: 14px;
  }

  a span {
    color: var(--link-text) !important;
    font-size: 16px !important;
  }

  .body1 {
    line-height: 30px;
  }
`;

export const ChangePasswordWrapper = styled.div`
  text-align: right;
`;
