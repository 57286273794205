import React from 'react';
import { SkeletonLoader } from '../../../components';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

export function ManageTeamFocusPanelSkeleton(props: { hasTeamSlug: boolean }): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>{props.hasTeamSlug && <SkeletonLoader height="45px" width="315px" />}</SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      {props.hasTeamSlug && (
        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      )}

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="25px" width="60px" />
        <SkeletonLoader height="50px" width="145px" />
      </footer>
    </div>
  );
}

