import { Context } from '../..';
import * as base from '../base';
import { ArchiveTagParams, Assignee, GetAssigneesParams, GetAssigneesTagsParams, TagAssigneeRequest, TagAssigneeResponse, TagList, TagSearchResponse } from '../../../models/tag';
import { BaseSearchRequest } from '../../../models/overmind/search-request';

export async function getAssignees(context: Context, payload: GetAssigneesParams): Promise<TagAssigneeResponse[] | undefined> {
  const request: base.IRequest = {
    url: base.url(`tag`, `get-assignees/${payload.companySlug}/${payload.tagName}`),
    authenticated: true,
  };

  const response: base.IResponse<TagAssigneeResponse[]> = await base.request(request);

  return response.data;
}

export async function searchTags(context: Context, payload: BaseSearchRequest): Promise<TagSearchResponse | undefined> {
  const request: base.IRequest = {
    url: base.url(`tag`, `search-tags/${payload.companySlug}?search=${payload.search}&sortField=${payload.sortField}&sortAscending=${payload.sortAscending}&pageSize=${payload.pageSize}&page=${payload.page}`),
    authenticated: true,
  };

  const response: base.IResponse<any> = await base.request(request);

  return response.data;
}

export async function getAssigneeTags(context: Context, payload: GetAssigneesTagsParams): Promise<TagList[] | undefined> {
  const request: base.IRequest = {
    url: base.url(`tag`, `get-assignee-tags/${payload.companySlug}?EntitySlug=${payload.entitySlug}&TagType=${payload.tagType}`),
    authenticated: true,
  };

  const response: base.IResponse<TagList[]> = await base.request(request);

  return response.data;
}

export async function addAssignees(context: Context, payload: { tagName: string; assignees: Assignee[]; companySlug: string }): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`tag`, `add-assignees/${payload.companySlug}/${payload.tagName}`),
    authenticated: true,
    method: 'POST',
    body: JSON.stringify(payload.assignees),
  };

  await base.request(request);
}

export async function archiveTag(context: Context, payload: ArchiveTagParams): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`tag`, `archive-tag/${payload.companySlug}/${payload.tagName}`),
    authenticated: true,
    method: 'PUT',
  };
  base.request(request);
}

export async function removeAssignees(context: Context, payload: { tagName: string; assignees: TagAssigneeRequest[]; companySlug: string }): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`tag`, `remove-assignees/${payload.companySlug}/${payload.tagName}`),
    authenticated: true,
    method: 'DELETE',
    body: JSON.stringify(payload.assignees),
  };
  base.request(request);
}
