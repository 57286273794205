import React from 'react';
import { themedAssetUrl } from '../../lib/theme-asset-url';
import { IEmptyStateProps } from './empty-state.models';
import { EmptyStateBadge, EmptyStateButtonContainer, EmptyStateContainer, TitleContainer } from './empty-state.styles';
import { Button, colourString } from '@keplerco/core';


export function EmptyState(props: IEmptyStateProps): JSX.Element {
  const badgeIconWidth = props.badgeIconWidth === 'full-width' ? '100%' : `${props.badgeIconWidth ?? 500}px`;

  return (
    <EmptyStateContainer>
      {!props.noBadgeIcon && (

        <EmptyStateBadge maxWidth={badgeIconWidth} centerText={props.centerText}>
          <object data={props.badgeUrl ?? themedAssetUrl('graphics/empty-state-add.graphic.svg')} type="image/svg+xml">
            <img src={props.badgeUrl ?? themedAssetUrl('graphics/empty-state-add.graphic.svg')} alt="" />
          </object>
          <TitleContainer centerText={props.centerText}>
            {(!!props.title || (!props.title && !props.subtitle)) && <h4 className="heading4" style={{ color: colourString(props.color ?? 'text') }}>{props.title ?? 'No results'}</h4>}
            {!!props.subtitle && <p className="body2">{props.subtitle}</p>}
          </TitleContainer>
        </EmptyStateBadge>

      )}

      {!!props.buttons && props.buttons.length !== 0 && (
        <EmptyStateButtonContainer>
          {props.buttons?.map(button => (
            <Button key={Date.now() + Math.random()} type="button" arrow={false} filled onClick={button.onClick}>
              {button.text}
            </Button>
          ))}
        </EmptyStateButtonContainer>
      )}
    </EmptyStateContainer>
  );
}
