import { ReflectionListItem } from '../../../models';
import { CompletionStatus } from '../../../enums';
import { ReducerAction } from '@keplerco/core';

interface ReflectionsPageState {
  searchTerm: string;
  reflections?: ReflectionListItem[];
  pendingReflections?: ReflectionListItem[];
  completeReflections?: ReflectionListItem[];
  selectedReflectionId?: number;
}

export const initialState: ReflectionsPageState = {
  searchTerm: '',
};

export enum ReflectionsPageActionTypes {
  SetSearchTerm = 0,
  SetReflections = 1,
  SetSelectedReflectionId = 2,
}

type ReflectionsPageActions =
  | ReducerAction<ReflectionsPageActionTypes.SetSearchTerm, { payload: string }>
  | ReducerAction<ReflectionsPageActionTypes.SetReflections, { payload: ReflectionListItem[] | undefined }>
  | ReducerAction<ReflectionsPageActionTypes.SetSelectedReflectionId, { payload: number | undefined }>;

export function reducer(state: ReflectionsPageState, action: ReflectionsPageActions): ReflectionsPageState {
  if (action.type === ReflectionsPageActionTypes.SetSearchTerm) {
    return {
      ...state,
      searchTerm: action.payload,
    };
  }

  if (action.type === ReflectionsPageActionTypes.SetReflections) {
    if (!action.payload) {
      return {
        ...state,
        reflections: undefined,
        pendingReflections: undefined,
        completeReflections: undefined,
      };
    }

    const pendingReflections = [];
    const completeReflections = [];

    for (const reflection of action.payload) {
      reflection.reflectionCompletionStatus !== CompletionStatus.Completed ? pendingReflections.push(reflection) : completeReflections.push(reflection);
    }

    return {
      ...state,
      reflections: action.payload,
      pendingReflections,
      completeReflections,
    };
  }

  if (action.type === ReflectionsPageActionTypes.SetSelectedReflectionId) {
    return {
      ...state,
      selectedReflectionId: action.payload,
    };
  }

  return state;
}

