import styled from 'styled-components';

export const SyncContentCardWrapperCompact = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  &.reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @media screen and (max-width: 580px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    &.reverse {
      flex-direction: column-reverse;
    }
  }
`;

export const SyncContentCardWrapperCompactLabel = styled.span`
  display: block;
  color: var(--text);

  &.isMapMode {
    color: var(--white);
  }
`;

export const SyncContentCardWrapperCompactButton = styled.div``;

export const SyncContentCardWrapperLarge = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas: 'content button' 'content label';
  align-items: center;
  border-top: solid 1px var(--borders);
  border-bottom: solid 1px var(--borders);
  padding: 20px 0;
  gap: 5px 15px;

  @media screen and (max-width: 650px) {
    display: block;
  }
`;

export const SyncContentCardWrapperLargeLabel = styled.div`
  grid-area: label;
`;

export const SyncContentCardWrapperLargeButton = styled.div`
  grid-area: button;
  text-align: right;

  @media screen and (max-width: 650px) {
    text-align: left;
    padding-top: 10px;
  }
`;

export const SyncContentCardWrapperLargeContent = styled.div`
  grid-area: content;
  display: flex;
  align-items: center;
  gap: 10px;

  &.hideContent {
    visibility: hidden;
  }
`;

export const SyncContentCardWrapperLargeContentIcon = styled.div`
  width: 90px;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const SyncContentCardWrapperLargeContentText = styled.div``;

export const SyncContentCardLayoutWrapper = styled.div`
  padding: 15px 0;
  width: 100%;
  display: block;
`;

