import React from 'react';
import { SkeletonLoader } from '../../components';
import { SkeletonLoaderContainer, SkeletonLoaderRow, SkeletonLoaderColumn } from '../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

export function LearningManagementSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="150px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="50px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="50px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={5} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="460px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={1} />
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="25px" />
        </SkeletonLoaderColumn>
        <SkeletonLoaderColumn flex={1} />
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

