import { ReducerAction } from '@keplerco/core';
import { CompanyEntityAssigneeListResponse } from '../../../../../models/overmind/company-entity';
import { CompanyRoleAssigneesSearchParams } from '../../../../../models/overmind/search-params';

// state
interface ViewRolePeopleState {
  request: CompanyRoleAssigneesSearchParams | undefined;
  data: CompanyEntityAssigneeListResponse | undefined;
}

export const initialState: ViewRolePeopleState = {
  request: undefined,
  data: undefined,
};

// actions
export enum ViewRolePeopleActionTypes {
  SetData,
  SetRequest,
}

type ViewRolePeopleActions = ReducerAction<ViewRolePeopleActionTypes.SetRequest, { payload: CompanyRoleAssigneesSearchParams }> | ReducerAction<ViewRolePeopleActionTypes.SetData, { payload: CompanyEntityAssigneeListResponse | undefined }>;

// reducer
export function reducer(state: ViewRolePeopleState, action: ViewRolePeopleActions): ViewRolePeopleState {
  if (action.type === ViewRolePeopleActionTypes.SetData) {
    return { ...state, data: action.payload };
  }

  if (action.type === ViewRolePeopleActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  return state;
}
