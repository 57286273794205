import { IconToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function StartIcon(props: IconToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 74 73" fill="none">
      <g clipPath="url(#clip0_129_393)">
        <circle cx="36.9998" cy="36.5001" r="35.4167" stroke={tone} strokeWidth="2.08333" />
        <path d="M27.6514 25.7921C27.6514 24.2126 29.3404 23.2076 30.7286 23.9612L50.4552 34.6692C51.9078 35.4577 51.9078 37.5426 50.4552 38.3311L30.7286 49.0392C29.3404 49.7927 27.6514 48.7877 27.6514 47.2082V25.7921Z" fill={tone} />
      </g>
    </svg>
  );
}

