import React from 'react';
import { CounterWrapper, CounterInput, ButtonMinus, ButtonPlus } from './counter.styles';
import { ICounter } from './counter-model';
import classNames from 'classnames';

export class Counter extends React.Component<ICounter, { count: number; max?: number }> {
  constructor(props: ICounter) {
    super(props);

    this.state = {
      count: props.count ?? 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.reset = this.reset.bind(this);
  }

  public increment(): void {
    const count = typeof this.props.max === 'number' ? Math.min(this.state.count + 1, this.props.max) : this.state.count + 1;
    this.setState({ count });
    this.fireChange('increment', count);
  }

  public decrement(): void {
    const count = this.state.count > 0 ? this.state.count - 1 : this.state.count;
    this.setState({ count });
    this.fireChange('decrement', count);
  }

  public fireChange(type: 'increment' | 'decrement', value: number): void {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, type);
    }
  }

  public reset(): void {
    this.setState({
      count: 0,
    });
  }

  public handleChange(event: { target: { value: any } }): void {
    const count = parseInt(event.target.value);
    this.setState({ count });
    this.fireChange('increment', count);
  }

  public render(): JSX.Element {
    return (
      <CounterWrapper className={classNames({ center: this.props.center })}>
        <ButtonMinus onClick={this.decrement}>
          <svg viewBox="0 0 24 25" fill="none">
            <path d="M16 12.75H8" stroke="var(--secondary)" strokeWidth="2" strokeLinecap="round" />
            <path d="M12 22.75C17.5228 22.75 22 18.2728 22 12.75C22 7.22715 17.5228 2.75 12 2.75C6.47715 2.75 2 7.22715 2 12.75C2 18.2728 6.47715 22.75 12 22.75Z" stroke="var(--secondary)" strokeWidth="2" />
          </svg>
        </ButtonMinus>

        <CounterInput type="number" value={this.state.count} onChange={this.handleChange} min="0" />

        <ButtonPlus onClick={this.increment}>
          <svg viewBox="0 0 24 25" fill="none">
            <path d="M12 12.75H8M12 8.75V12.75V8.75ZM12 12.75V16.75V12.75ZM12 12.75H16H12Z" stroke="var(--secondary)" strokeWidth="2" strokeLinecap="round" />
            <path d="M12 22.75C17.5228 22.75 22 18.2728 22 12.75C22 7.22715 17.5228 2.75 12 2.75C6.47715 2.75 2 7.22715 2 12.75C2 18.2728 6.47715 22.75 12 22.75Z" stroke="var(--secondary)" strokeWidth="2" />
          </svg>
        </ButtonPlus>
      </CounterWrapper>
    );
  }
}

