import React from 'react';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { getSubdomain } from '../../../../lib/get-subdomain';
import { Button, KeplerPoints, UnorderedList, UnorderedListItem } from '@keplerco/core';

export function KeplerExplainerFocusPanelLayout(props: { onClick?: () => void }): JSX.Element {
  return (
    <React.Fragment>
      {getSubdomain() === 'app' && (
        <div className="dialogBackgroundLayout" style={{ zIndex: 2 }}>
          <object data={themedAssetUrl('backgrounds/stars.background.svg')} type="image/svg+xml">
            <img src={themedAssetUrl('backgrounds/stars.background.svg')} alt="" />
          </object>
        </div>
      )}

      <div className="dialogContentLayout focusPanelContentLayout" style={{ zIndex: 3, position: 'relative' }}>
        <header className="dialogHeaderLayout">
          <div className="dialogHeaderIconLayout">
            <object data="/badges/badge_KP.svg" type="image/svg+xml">
              <img src="/badges/badge_KP.svg" alt="KP" />
            </object>
          </div>

          <h2 className="heading2">Kepler points explained</h2>
        </header>

        <div className="dialogBodyLayout">
          <h6 className="subtitle">
            Kepler Points(
            <KeplerPoints forceFontWeight="bold" />
            )are used to give an indication of your progress on the Kepler platform.
          </h6>

          <UnorderedList>
            <UnorderedListItem>The first set of KP that you achieve are for completing your self-assessment, and these KP are based on your current skill-set and skill rating.</UnorderedListItem>

            <UnorderedListItem>Then, after every activity on the Kepler platform that you complete, you are awarded more KP. We'll also give you KP for completing daily and weekly goals, and completing challenges and study streaks.</UnorderedListItem>
          </UnorderedList>
        </div>

        <footer className="dialogFooterLayout focusPanelFooterLayout">
          <Button type="button" filled arrow={false} onClick={props.onClick}>
            Great, thanks!
          </Button>
        </footer>
      </div>
    </React.Fragment>
  );
}

