import { Anchor } from '@keplerco/core';
import styled from 'styled-components';

export const EntityAssignmentWidgetRadioCardLabel = styled.label`
  text-align: left;
  font-size: 16px;
  color: var(--accent-3);
`;

export const EntityAssignmentWidgetContentLayout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const EntityAssignmentWidgetRow = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 10px;
`;

export const EntityAssignmentWidgetCardWrapper = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const EntityAssignmentWidgetErrorMessageWrapper = styled.div`
  padding-bottom: 10px;
`;

export const EntityAssignLabel = styled.h5`
  color: var(--accent-3);
`;
export const EntityAssignmentWidgetListIconAvatar = styled.div`
  width: 25px;
  margin-right: 5px;
  height: 25px;
  border: solid 1px var(--accent-2);
  border-radius: 30px;
  overflow: hidden;
`;

export const EntityAssignmentWidgetSelectedDropdownCard = styled.div`
  background-color: var(--cards_2);
`;

export const EntityAssignmentWidgetCardListItem = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
  align-items: center;
  justify-content: flex-start;
`;

export const EntityAssignmentWidgetCardListItemBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const EntityAssignmentWidgetListItemLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const EntityAssignmentSelectionWidgetSelectedDropdownItem = styled.div`
  margin-left: 10px;
`;

export const EntityAssignmentWidgetSpacer = styled.div`
  flex: 1;
`;

export const EntityAssignmentNextButton = styled(Anchor)`
  align-self: flex-end;
  margin: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const EntityAssignmentButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomStyledDropdownList = styled.div`
  border-radius: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  max-height: 200px;
`;

export const CustomStyledDropdownListItem = styled.div<{ isSelected: boolean }>`
  padding: 10px;
  background-color: ${props => (props.isSelected ? 'var(--background)' : 'var(--background)')};
  color: var(--text);
  cursor: pointer;

  &:hover {
    background-color: var(--cards);
  }
`;

export const CustomStyledDropdownListItemCheck = styled.div``;

export const CustomStyledDropdownListItemLabel = styled.div`
  font-size: 16px;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
`;

export const DeselectAllButton = styled(Anchor)`
  padding-bottom: 0px;
`;

export const EntityAssignmentWidgetFormWrapper = styled.div``;

export const EntityAssignmentAutocompleteWrapper = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const EntityAssignmentSpacer = styled.div`
  padding: 20px;
`;
