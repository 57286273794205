import React, { useEffect, useState } from 'react';
import { ISideNavRoute } from '../../side-nav.models';
import classNames from 'classnames';
import { keplerActive } from '../../../navigation.helpers';
import { Tooltip, kebabCase } from '@keplerco/core';
import navStyles from '../../../navigation.module.css';
import sideNavStyles from '../../side-nav.module.css';
import sideNavPanelRouteListStyles from './side-nav-panel-route-list.module.css';
import KeplerNavlink from '../../../guards/kepler-navlink';
import { useAppState } from '../../../../overmind';

export function SideNavPanelRouteList(props: { sideNavParentRoutes: ISideNavRoute[]; sideNavCollapsed: boolean; pathname: string }): JSX.Element {
  const { mode } = useAppState();

  const { sideNavParentRoutes, sideNavCollapsed, pathname } = props;
  const [showSideNavChildRouteList, setShowSideNavChildRouteList] = useState<boolean>(true);

  useEffect(() => {
    sideNavCollapsed ? setShowSideNavChildRouteList(false) : setTimeout(() => setShowSideNavChildRouteList(true), 400);
  }, [sideNavCollapsed]);

  return (
    <ul className={navStyles.navList}>
      {sideNavParentRoutes.map(sideNavParentRoute => (
        <li key={sideNavParentRoute.path} id={`${mode}_${kebabCase(sideNavParentRoute.title)}`}>
          {sideNavCollapsed ? (
            <div
              className={classNames(
                sideNavPanelRouteListStyles.sideNavPanelParentRouteContainer,
                sideNavPanelRouteListStyles.collapsed,
                { [sideNavPanelRouteListStyles.keplerActive]: keplerActive(!!sideNavParentRoute.exactMatch, sideNavParentRoute.path, pathname) },
              )}
            >
              <Tooltip
                content={sideNavParentRoute.title}
                backgroundColour="primary"
                borderColour="primary"
                fontWeight="700"
                textTransform="uppercase"
              >
                <KeplerNavlink className={sideNavPanelRouteListStyles.sideNavPanelParentRouteNavLink} to={sideNavParentRoute.path}>
                  <img
                    className={classNames(navStyles.navIcon, sideNavPanelRouteListStyles.sideNavPanelParentRouteIcon, sideNavPanelRouteListStyles.collapsed)}
                    src={sideNavParentRoute.icon}
                    alt=""
                  />
                </KeplerNavlink>
              </Tooltip>
            </div>
          ) : (
            <div
              className={classNames(
                sideNavPanelRouteListStyles.sideNavPanelParentRouteContainer,
                sideNavPanelRouteListStyles.expanded,
                { [sideNavPanelRouteListStyles.keplerActive]: keplerActive(!!sideNavParentRoute.exactMatch, sideNavParentRoute.path, pathname) },
              )}
            >
              <KeplerNavlink className={sideNavPanelRouteListStyles.sideNavPanelParentRouteNavLink} to={sideNavParentRoute.path}>
                <img
                  className={classNames(navStyles.navIcon, sideNavPanelRouteListStyles.sideNavPanelParentRouteIcon, sideNavPanelRouteListStyles.expanded)}
                  src={sideNavParentRoute.icon}
                  alt=""
                />

                <div
                  className={classNames(
                    sideNavStyles.sideNavParentRouteTitle,
                    sideNavPanelRouteListStyles.sideNavPanelParentRouteTitle,
                    sideNavPanelRouteListStyles.expanded,
                    { [sideNavPanelRouteListStyles.keplerActive]: keplerActive(!!sideNavParentRoute.exactMatch, sideNavParentRoute.path, pathname) },
                  )}
                >
                  {sideNavParentRoute.title}
                </div>
              </KeplerNavlink>
            </div>
          )}

          {!!sideNavParentRoute.children && pathname.includes(sideNavParentRoute.path) && showSideNavChildRouteList && (
            <ul className={classNames(navStyles.navList, sideNavPanelRouteListStyles.sideNavPanelChildRouteList)}>
              {sideNavParentRoute.children.map(sideNavChildRoute => (
                <li key={sideNavChildRoute.path} id={`${mode}_${kebabCase(sideNavChildRoute.title)}`} className={sideNavStyles.sideNavChildRouteListItem}>
                  <KeplerNavlink
                    to={sideNavChildRoute.path}
                    className={classNames(
                      sideNavStyles.sideNavChildRoute,
                      { [sideNavStyles.keplerActive]: keplerActive(!!sideNavChildRoute.exactMatch, sideNavChildRoute.path, pathname) }
                    )}
                  >
                    {sideNavChildRoute.title}
                  </KeplerNavlink>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}
