import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';

const REDIRECT_TO = 'redirectTo';

export function useKeplerNavigate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function keplerNavigate(to: string, options?: NavigateOptions) {
    const redirectTo = searchParams.get(REDIRECT_TO);
    navigate(!!redirectTo ? `${to}?${REDIRECT_TO}=${redirectTo}` : to, options);
  }

  return keplerNavigate;
}
