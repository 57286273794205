import { SkillScoreComparison } from '../enums';

export function skillScoreComparisonText(skillScoreComparison: SkillScoreComparison): string {
  if (skillScoreComparison === SkillScoreComparison.FoPExpectedProficiency || skillScoreComparison === SkillScoreComparison.RoleToSkillExpectedProficiency) {
    return 'Expected proficiency';
  }

  return `${skillScoreComparison} average`;
}

