import styled from 'styled-components';
import { Textfield } from '@keplerco/core';

export const InputButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
  border-radius: 5px;
`;

export const StyledInput = styled(Textfield)`
  flex-grow: 1;

  color: var(--link-text);
  border-top-right-radius: 0;
  height: 40px;
  border-bottom-right-radius: 0;

  &:focus {
    outline: none;
  }
`;
export const LabelAndTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--background);
  width: 100%;
  gap: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledCardFlat = styled.div`
  background: var(--background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Label = styled.p`
  font-size: 11px;
  color: black;
`;

export const StyledButton = styled.button`
  font-size: 14px;
  font-family: var(--body);
  background-color: transparent;
  white-space: nowrap;
  color: var(--link-text);
  width: 150px;
  height: 40px;
  border: 1px solid var(--borders);
  border-radius: 10px;
  padding: 7px 15px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
  &:hover {
    background-color: var(--link-text);
    color: black;
  }
`;

export const StyledLabel = styled.h3`
  position: relative;
  font-size: 11px;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;

  ${StyledInput}:focus ~ & {
    transform: translateY(-50%);
  }
`;

export const SelectedTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
export const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SelectedTagsHeader = styled.h3`
  margin-bottom: 10px;
  font-size: 11px;
  text-align: left;
`;
