import React from 'react';
import { InputButtonContainer, StyledInput, StyledButton } from './tag-input.styles';

interface InputWithButtonProps {
  inputValue: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick: () => void;
  buttonText: string;
  inputLabel: string;
}

function InputWithButton({ inputValue, onInputChange, onButtonClick, buttonText, inputLabel }: InputWithButtonProps) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    onButtonClick();
  }
  return (
    <InputButtonContainer>
      <StyledInput className='responsive' label={inputLabel} name='text' type='text' value={inputValue} onChange={onInputChange} />
      <StyledButton onClick={handleClick}>{buttonText}</StyledButton>
    </InputButtonContainer>
  );
}

export default InputWithButton;
