import { IconToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function AlertIcon(props: IconToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3384 13.5526C11.8933 13.5526 11.5325 13.1918 11.5325 12.7467V10.3289C11.5325 9.88377 11.8933 9.52294 12.3384 9.52295C12.7835 9.52295 13.1443 9.88377 13.1443 10.3289V12.7467C13.1443 13.1918 12.7835 13.5526 12.3384 13.5526Z" fill={tone} />
      <path d="M11.1295 15.5674C11.1295 14.8997 11.6708 14.3585 12.3384 14.3585C13.0061 14.3585 13.5473 14.8997 13.5473 15.5674C13.5473 16.2351 13.0061 16.7763 12.3384 16.7763C11.6708 16.7763 11.1295 16.2351 11.1295 15.5674Z" fill={tone} />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9121 4.86114C11.5164 3.71296 13.1605 3.71295 13.7648 4.86114L20.4891 17.6374C21.0541 18.7108 20.2757 20 19.0628 20H5.61405C4.40111 20 3.62277 18.7108 4.18769 17.6374L10.9121 4.86114ZM19.0628 18.3881L12.3384 5.61186L5.61405 18.3881L19.0628 18.3881Z" fill={tone} />
    </svg>
  );
}

