export enum KeplerPointType {
  CategoryCompleted = 0,
  SkillsGapReport = 1,
  JourneyCompleted = 2,
  LoginStreak = 3,
  LevelAchieved = 4,
  ActivityCompleted = 5,
  SkillScorePostAssessmentUpdated = 6,
  CareerPathAssessment = 7,
  VariationQuestionReady = 8,
  OffPlatformLearning = 9,
  SkillScorePreAssessmentUpdated = 10,
}
