import styled from 'styled-components';

export const PageLoaderLayerChildren = styled.div`
  position: relative;
`;

export const PageLoaderLayerLoader = styled.div<{ zIndex: number; background: string }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${props => props.zIndex};
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 92px);
  overflow: hidden;
  background: ${props => props.background};
  pointer-events: all;
  opacity: 1;
  will-change: opacity;

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transition: opacity 500ms;
  }
`;

export const PageLoaderLayerContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 90px);
`;
