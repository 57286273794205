import { TagType } from '../../../enums/tag';

export function tagTypeToString(tagType: TagType) {
  switch (tagType) {
    case TagType.Department:
      return 'Department';
    case TagType.User:
      return 'User';
    case TagType.Team:
      return 'Team';
    case TagType.Assessment:
      return 'Assessment';
    default:
      return '';
  }
}
