import styled from 'styled-components';

export const HoverCardFront = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 20px;

  &.isHovered {
    visibility: hidden;
  }
`;

export const HoverCardInner = styled.div`
  visibility: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: 'title  title action' 'description description description';
  align-items: center;
  row-gap: 15px;

  &.front {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'title' 'description' 'action';

    &.front {
      display: block;
    }
  }

  &.isMobileAction {
    @media screen and (max-width: 580px) {
      display: none;
    }
  }
`;

export const HoverCardWrapper = styled.div<{ chip: boolean }>`
  cursor: pointer;
  border-radius: 21.25px;
  background: var(--borders);
  height: 250px;
  position: relative;
  box-shadow: 0px 4px 20px var(--outer-shadow-colour_1);
  background: var(--cards);

  &.isHovered {
    background: var(--borders);

    ${HoverCardFront} {
      visibility: hidden;
    }

    ${HoverCardInner} {
      visibility: visible;
    }
  }

  border: ${({ chip }) => (chip ? 'solid 1px var(--borders)' : 'none')};

  @media screen and (max-width: 580px) {
    height: auto;
    padding: 45px 15px;
    border-radius: 8px;
    border: solid 1px var(--borders);
  }
`;

export const HoverCardTitle = styled.div`
  grid-area: title;
  text-align: left;

  .heading4 {
    color: var(--accent-3);
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .body2 {
    display: block;
    color: var(--primary);
    font-weight: 700;
  }
`;

export const HoverCardDescription = styled.div`
  grid-area: description;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
`;

export const HoverCardAction = styled.div`
  grid-area: action;
  text-align: right;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  margin-top: 0;

  @media screen and (max-width: 580px) {
    margin-top: 10px;
  }
`;

export const HoverCardActionIcon = styled.div`
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  min-width: 25px;
  min-height: 25px;
`;

export const HoverCardMenu = styled(HoverCardActionIcon)`
  background: transparent;
  padding: 0%;
  border: none;
`;

export const HoverCardChip = styled.h6`
  color: var(--black);
  font-weight: 500;
  position: absolute;
  line-height: 26px;
  border: solid 1px var(--borders);
  background: var(--apple);
  border-radius: 10px;
  padding: 5px 15px;
  top: -19px;
  left: 20px;
`;

export const EllipsisTitle = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; // Adjust as needed based on your layout
  text-align: center;
`;

