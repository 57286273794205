import { IconToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function InfoIcon(props: IconToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM4.38462 12C4.38462 16.2059 7.79414 19.6154 12 19.6154C16.2059 19.6154 19.6154 16.2059 19.6154 12C19.6154 7.79414 16.2059 4.38462 12 4.38462C7.79414 4.38462 4.38462 7.79414 4.38462 12Z" fill={tone} />
      <path d="M12 10.4C12.4419 10.4 12.8 10.7582 12.8 11.2V16C12.8 16.4418 12.4419 16.8 12 16.8C11.5582 16.8 11.2 16.4418 11.2 16V11.2C11.2 10.7582 11.5582 10.4 12 10.4Z" fill={tone} />
      <path d="M13.2 8.40001C13.2 9.06275 12.6628 9.60001 12 9.60001C11.3373 9.60001 10.8 9.06275 10.8 8.40001C10.8 7.73727 11.3373 7.20001 12 7.20001C12.6628 7.20001 13.2 7.73727 13.2 8.40001Z" fill={tone} />
    </svg>
  );
}

