import { LeaderboardResponse } from '../../../models/overmind/analytics';
import { LeaderboardSearchParams } from '../../../models/overmind/search-params';

export enum LeaderboardType {
  SkillScore = 'Skill Score',
  KeplerPoints = 'Kepler Points',
}

interface PeopleLeaderboardState {
  loading: boolean;
  leaderboardType: LeaderboardType;
  request: LeaderboardSearchParams | undefined;
  data: LeaderboardResponse | undefined;
}

export const initialState: PeopleLeaderboardState = {
  loading: true,
  leaderboardType: LeaderboardType.SkillScore,
  request: undefined,
  data: undefined,
};

export enum PeopleLeaderboardActionTypes {
  SetLoading,
  SetLeaderboardType,
  SetRequest,
  SetData,
}

type PeopleLeaderboardActions =
  | { type: PeopleLeaderboardActionTypes.SetLoading; payload: boolean }
  | { type: PeopleLeaderboardActionTypes.SetLeaderboardType; payload: LeaderboardType }
  | { type: PeopleLeaderboardActionTypes.SetRequest; payload: LeaderboardSearchParams }
  | { type: PeopleLeaderboardActionTypes.SetData; payload: LeaderboardResponse | undefined };

export function reducer(state: PeopleLeaderboardState, action: PeopleLeaderboardActions): PeopleLeaderboardState {
  switch (action.type) {
    case PeopleLeaderboardActionTypes.SetLoading: {
      return { ...state, loading: action.payload };
    }

    case PeopleLeaderboardActionTypes.SetLeaderboardType: {
      const request = !!state.request ? { ...state.request, page: 1 } : undefined;
      return { ...state, leaderboardType: action.payload, request };
    }

    case PeopleLeaderboardActionTypes.SetRequest: {
      return { ...state, request: action.payload };
    }

    case PeopleLeaderboardActionTypes.SetData: {
      return { ...state, data: action.payload };
    }

    default: {
      return state;
    }
  }
}
