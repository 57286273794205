export enum LearningPlatformType {
  Kepler = 0,
  Disprz = 1,
  Other = 2,
  Learnworlds = 3,
  Go1Learning = 4,
  SucceedAcademy = 5,
  Moodle = 6,
  Mix = 7,
  // SucceedAcademy = 8, // needs to be removed from BE
  CornerStoneSFTP = 9,
}
