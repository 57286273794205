import styled from 'styled-components';

export const CounterWrapper = styled.div`
  display: flex;
  height: auto;
  align-items: center;

  &.center {
    justify-content: center;
  }
`;

export const CounterInput = styled.input`
  width: 60px;
  height: 31px;
  background: var(--cards);
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  border: none;
  color: var(--text);
  font-weight: bold;
  font-size: 11px;
  -webkit-appearance: none;
  font-family: Prompt;
  border: solid 1px var(--borders);
`;

const ClickerButton = styled.button`
  background: transparent;
  color: var(--secondary);
  border: none;
  text-align: center;
  text-align: center;
  align-items: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: auto;

  svg {
    pointer-events: none;
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const ButtonPlus = styled(ClickerButton)``;

export const ButtonMinus = styled(ClickerButton)``;
