import React, { useState } from 'react';
import { ExplainerFocusPanelLayout } from '../../layouts';
import { SkeletonLoader } from '../../../components';
import { IOverviewWidgetProps } from './overview.models';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { Donut, DonutSlice, FocusPanel, HelpIcon, IconButton, SkillLevel, SkillPercentage, ragColourType } from '@keplerco/core';
import styles from './overview.module.css';
import classNames from 'classnames';
import { skillLevelRAGColour } from '../../../lib/skill-level-rag-colour.helper';
import { useAppState } from '../../../overmind';
import { SkillLevelsExplainerFocusPanelLayout } from '../../layouts/focus-panel-layouts/skill-levels-explainer-focus-panel/skill-levels-explainer-focus-panel.layout';

export function OverviewWidget({ loading, title, donutChartData, firstNumberDisplayChartData, secondNumberDisplayChartData, thirdNumberDisplayChartData }: IOverviewWidgetProps): JSX.Element {
  const { companyVariables } = useAppState();

  const [open, setOpen] = useState<{ title: string; description: string }>();

  const [explainerOpen, setExplainerOpen] = useState(false);

  if (loading) return <SkeletonLoader height="260px" />;

  return (
    <div className="card glass">
      {!donutChartData && !firstNumberDisplayChartData && !secondNumberDisplayChartData && !thirdNumberDisplayChartData ? (
        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={205} title="No overview found" />
      ) : (
        <React.Fragment>
          <React.Fragment>
            <h3 className="heading3">{title}</h3>

            <div className={styles.grid}>
              {!!donutChartData && (
                <div className={styles.donutGridItem}>
                  {!!donutChartData.description ? (
                    <Donut
                      donutLabel={donutChartData.donutLabel}
                      donutMaxWidth="200px"
                      legendLabel={donutChartData.label}
                      showTooltips
                      showTotal
                      totalCount={donutChartData.value}
                      onClickInformationButton={
                        donutChartData.label === 'Skill levels' ?
                          () => setExplainerOpen(true) :
                          () => setOpen({
                            title: donutChartData.label,
                            description: donutChartData.description!,
                          })}
                    >
                      {donutChartData.slices.map(slice => (
                        <DonutSlice key={slice.label} label={slice.label} value={slice.value} color={slice.colour} />
                      ))}
                    </Donut>
                  ) : (
                    <Donut donutLabel={donutChartData.donutLabel} legendLabel={donutChartData.label} totalCount={donutChartData.value} showTooltips showTotal>
                      {donutChartData.slices.map(slice => (
                        <DonutSlice key={slice.label} label={slice.label} value={slice.value} color={slice.colour} />
                      ))}
                    </Donut>
                  )}
                </div>
              )}

              {!!firstNumberDisplayChartData && (
                <div className={styles.gridItem}>
                  {firstNumberDisplayChartData.chartType === 'skillLevel' && (
                    <div className={styles.skillLevelItem}>
                      <div className="heading5">
                        {firstNumberDisplayChartData.label}
                        <IconButton
                          iconType="fill"
                          padding={5}
                          onClick={() =>
                            setOpen({
                              title: 'Average skill score',
                              description: firstNumberDisplayChartData.description!,
                            })
                          }
                        >
                          <HelpIcon />
                        </IconButton>
                      </div>

                      <SkillLevel
                        level={firstNumberDisplayChartData.value ?? companyVariables.minLevel}
                        minLevel={companyVariables.minLevel}
                        maxLevel={companyVariables.maxLevel}
                        notAssessed={!firstNumberDisplayChartData.value}
                        dotColour={skillLevelRAGColour(firstNumberDisplayChartData.value ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                      />
                    </div>
                  )}

                  {firstNumberDisplayChartData.chartType === 'skillPercentage' && (
                    <div className={styles.skillLevelItem}>
                      <div className="heading5">
                        {firstNumberDisplayChartData.label}
                        <IconButton
                          iconType="fill"
                          padding={5}
                          onClick={() =>
                            setOpen({
                              title: 'Average skill score',
                              description: firstNumberDisplayChartData.description!,
                            })
                          }
                        >
                          <HelpIcon />
                        </IconButton>
                      </div>

                      <SkillPercentage
                        percentage={firstNumberDisplayChartData.value ?? 0}
                        notAssessed={!firstNumberDisplayChartData.value}
                        barColour={ragColourType(firstNumberDisplayChartData.value ?? 0)}
                      />
                    </div>
                  )}

                  {firstNumberDisplayChartData.chartType === 'numberDisplay' && (
                    <React.Fragment>
                      {!!firstNumberDisplayChartData.description ? (
                        <React.Fragment>
                          <span className="heading5">
                            {firstNumberDisplayChartData.label}

                            <IconButton
                              iconType="fill"
                              onClick={() => setOpen({ title: firstNumberDisplayChartData.label, description: firstNumberDisplayChartData.description! })}
                            >
                              <HelpIcon />
                            </IconButton>
                          </span>
                          <span className="heading2">{firstNumberDisplayChartData.value ?? 0}{firstNumberDisplayChartData.valueType === 'percentage' && '%'}</span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span className="heading5">{firstNumberDisplayChartData.label}</span>
                          <span className="heading2">{firstNumberDisplayChartData.value ?? 0}{firstNumberDisplayChartData.valueType === 'percentage' && '%'}</span>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>
              )}

              {!!secondNumberDisplayChartData && (
                <div className={classNames([styles.gridItem], [styles.secondGridItem])}>
                  {!!secondNumberDisplayChartData.description ? (
                    <React.Fragment>
                      <span className="heading5">
                        {secondNumberDisplayChartData.label}

                        <IconButton
                          iconType="fill"
                          onClick={() => setOpen({ title: secondNumberDisplayChartData.label, description: secondNumberDisplayChartData.description! })}
                        >
                          <HelpIcon />
                        </IconButton>
                      </span>
                      <span className="heading2">{secondNumberDisplayChartData.value ?? 0}{secondNumberDisplayChartData.valueType === 'percentage' && '%'}</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="heading5">{secondNumberDisplayChartData.label}</span>
                      <span className="heading2">{secondNumberDisplayChartData.value ?? 0}{secondNumberDisplayChartData.valueType === 'percentage' && '%'}</span>
                    </React.Fragment>
                  )}
                </div>
              )}

              {!!thirdNumberDisplayChartData && (
                <div className={classNames([styles.gridItem], [styles.thirdGridItem])}>
                  {!!thirdNumberDisplayChartData.description ? (
                    <React.Fragment>
                      <span className="heading5">
                        {thirdNumberDisplayChartData.label}

                        <IconButton
                          iconType="fill"
                          onClick={() => setOpen({ title: thirdNumberDisplayChartData.label, description: thirdNumberDisplayChartData.description! })}
                        >
                          <HelpIcon />
                        </IconButton>
                      </span>
                      <span className="heading2">{thirdNumberDisplayChartData.value ?? 0}{thirdNumberDisplayChartData.valueType === 'percentage' && '%'}</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="heading5">{thirdNumberDisplayChartData.label}</span>
                      <span className="heading2">{thirdNumberDisplayChartData.value ?? 0}{thirdNumberDisplayChartData.valueType === 'percentage' && '%'}</span>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </React.Fragment>

          <FocusPanel open={!!open} onClose={() => setOpen(undefined)}>
            {!!open && <ExplainerFocusPanelLayout title={open.title} description={open.description} onClose={() => setOpen(undefined)} />}
          </FocusPanel>

          {companyVariables.useLevels && (
            <FocusPanel open={explainerOpen} onClose={() => setExplainerOpen(false)}>
              <SkillLevelsExplainerFocusPanelLayout onClose={() => setExplainerOpen(false)} />
            </FocusPanel>
          )}
        </React.Fragment>
      )
      }
    </div >
  );
}

