if (typeof (global ?? window).requestIdleCallback !== 'function') {
  // eslint-disable-next-line
  // @ts-ignore
  (global ?? window).requestIdleCallback = callback => {
    const start = Date.now();
    return setTimeout(function () {
      callback({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };
}

if (typeof (global ?? window).cancelIdleCallback !== 'function') {
  (global ?? window).cancelIdleCallback = id => clearTimeout(id);
}

export {};
