import { KeplerPointType } from '../../../enums';
import { getSubdomain } from '../../../lib/get-subdomain';
import { QueueItem } from '../notification-hub.models';

/**
 * Returns whether a modal should be suppressed based on the type and current subdomain.
 * @param {QueueItem} item - The item to check suppression conditions for.
 * @returns {boolean} True if the modal should be suppressed, otherwise false.
 */
export function suppressModal(item: QueueItem) {
  const subdomain = getSubdomain();
  const isLPI = subdomain.includes('lpi');
  const isRoche = subdomain.includes('roche');
  if (isLPI) {
    return true;
  }
  if (isRoche) {
    return (
      item.keplerPointType !== KeplerPointType.SkillScorePostAssessmentUpdated &&
      item.keplerPointType !== KeplerPointType.SkillScorePreAssessmentUpdated &&
      item.keplerPointType !== KeplerPointType.VariationQuestionReady
    );
  }
}
