import { safeCallback } from '@keplerco/core';
import { useEffect } from 'react';

export function useRefocusHook(focusCallback: () => void, blurCallback?: () => void) {
  function handleBlur() {
    safeCallback(blurCallback);

    window.addEventListener(
      'focus',
      () => {
        safeCallback(focusCallback);
      },
      { once: true }
    );
  }

  useEffect(() => {
    window.addEventListener('blur', handleBlur);
    return () => window.removeEventListener('blur', handleBlur);
  });
}

