import styled from 'styled-components';

export const IFramePlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (pointer: coarse) {
    flex-direction: column;
  }
`;

export const IFrameLayoutContainer = styled.div`
  height: 100%;
  width: calc(100% - 335px);

  // animation
  will-change: width;
  transition: width cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;

  &.playlistCollapsed {
    width: calc(100% - 15px);
  }

  @media screen and (pointer: coarse) {
    width: 100%;

    &.playlistCollapsed {
      width: 100%;
    }
  }
`;

export const IFramePlayerThumbnailArrowIconContainer = styled.figure`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
`;

export const IFramePlayerThumbnailTextContainer = styled.div`
  position: absolute;
  top: calc(50% + 96px);
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  padding: 16px;
  background-color: var(--background_1);
  color: var(--text);
  font-weight: 700;
  text-transform: uppercase;
`;

export const IFramePlayerThumbnail = styled.figure`
  margin: 0px;
  height: 100%;
  width: 100%;

  img,
  svg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @supports (aspect-ratio: 16 / 9) {
    padding-top: 0;
    aspect-ratio: 16 / 9;
  }
`;

export const IFramePlayerThumbnailContainer = styled.button`
  position: relative;
  border: none;
  height: 50%;
  width: 100%;
  background-color: var(--primary);
  cursor: pointer;
`;

export const IFramePlayerPlaylistToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 15px;
  height: 100%;
  border: solid 1px var(--borders);
  border-right: none;

  background: var(--borders);
  overflow: hidden;
  cursor: pointer;

  // animation
  will-change: top;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;

  svg {
    transform-origin: center center;
    transform: rotate(180deg);

    // animation
    will-change: transform, transform-origin;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  }

  &.playlistCollapsed {
    svg {
      transform: rotate(0deg);
    }
  }
`;

export const IFramePlayerPlaylistItem = styled.div`
  cursor: pointer;
`;

export const IFramePlayerPlaylist = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 30px;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;

  // animation
  will-change: right;
  transition: right cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;

  &.playlistCollapsed {
    right: -320px;
  }

  @media screen and (pointer: coarse) {
    width: 100%;
    height: 50%;
    border: none;
    border-top: 1px solid var(--borders);
    top: 50%;

    &.playlistCollapsed {
      right: 0px;
    }
  }
`;

export const IFramePlayerPlaylistCardHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const IFramePlayerPlaylistCardName = styled.h5`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
  margin: 12px 0px;
`;

export const IFramePlayerPlaylistCardDescription = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
`;

export const IFramePlayerPlaylistCardFooter = styled.footer`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const IFramePlayerPlaylistCardFooterBody = styled.p`
  text-transform: uppercase;
  color: var(--default);
`;

