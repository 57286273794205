if (typeof (global ?? window).structuredClone !== 'function') {
  (global ?? window).structuredClone = (data: any, ...extraParamsPresent: any) => {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch (error) {
      console.log(`Couldn't parse or clone data`, { error, data, rest: extraParamsPresent });
    }
  };
}

export {};

