import { Context } from '../..';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { Permissions, Permission } from '../../../models/overmind/permissions';
import * as base from '../base';
import { addNotification } from './notifications';

export async function getUserPermissions(context: Context): Promise<void> {
  const request: base.IRequest = { url: base.url(`permission`, `get-permissions`), authenticated: true, method: 'GET' };
  const response: base.IResponse<Permissions> = await base.request(request);
  context.state.permissions = response.data;
}

export async function getPermissions(context: Context, personId: string): Promise<Permissions | undefined> {
  const request: base.IRequest = { url: base.url(`permission`, `get-permissions`, personId), authenticated: true, method: 'GET' };
  const response: base.IResponse<Permissions> = await base.request(request);
  return response.data;
}

export async function assignPermission(context: Context, params: { companySlug: string; personSlug: string | undefined; permission: Permission }): Promise<Permission | undefined> {
  if (!params.personSlug) {
    addNotification(context, {
      id: Date.now().toString(),
      title: 'Something went wrong',
      message: `This person doesn't have an id`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      active: true,
    });
    return;
  }

  const request: base.IRequest = { url: base.url(`permission`, `assign`, params.companySlug, params.personSlug), authenticated: true, method: 'POST', body: JSON.stringify(params.permission) };
  const response: base.IResponse<Permission> = await base.request(request);

  if (response.error) {
    addNotification(context, {
      id: Date.now().toString(),
      title: 'Something went wrong',
      message: response.error.type,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      active: true,
    });
    return;
  }

  addNotification(context, {
    id: Date.now().toString(),
    title: 'User permissions updated',
    message: 'Please note, the user will need to sign out and then sign in again for the changes to reflect',
    priority: QueueItemPriority.Toast,
    type: QueueItemType.Success,
    active: true,
  });
  return response.data;
}
