// learner
export const LEARNER_COMPLETION_RATES = `The status of the courses assigned to you is a useful metric to have. This information shows your overall progress regarding the courses, and gives an indication of the effort that you have put in.`;

export const LEARNER_COURSES_IN_PROGRESS = `Knowing how many courses you are currently engaged in allows you to gauge how much effort you are putting in. If you see a big drop in course numbers without a good reason, it's a sign to spend more time on learning.`;

export const LEARNER_COURSES_COMPLETE = `Tracking the courses you finish helps you see how much effort you're putting into learning, and motivates you to spend more time on your skills development, which is important not only for you, but for the company. It's also important to note when you are spending too much time on Kepler, so be alert of higher stress levels.`;

// non-learner
export const NON_LEARNER_COMPLETION_RATES = `The status of the courses assigned to employees in your company is a useful metric to have. This information shows overall company progress regarding the courses, and gives an indication of effort within your company.`;

export const NON_LEARNER_SKILL_PERCENTAGE = `The skill score gives an indication of the level of understanding that users have regarding each skill, and helps to show how adept your team members are at each skill. Knowing which teams or learners are better at certain skills might help to guide where their efforts and time are more useful, but it also shows where people need to work harder in order to help the company progress. Learners who actively work on their skills gaps will see an increase in their skill score, and you'll be able to see their progress as well - on the dashboard, but also in their productivity.`;

export const NON_LEARNER_COURSES_IN_PROGRESS = `Knowing how many courses learners are currently engaged in, especially given a benchmark against previous periods, allows you to gauge the effort of your employees to some extent, and you are able to see which skills these courses map to. Knowing the statistics compared to previous periods allows you to encourage learners to work a bit more on the courses if the stats have dropped dramatically, with no relation to workload changes in the workspace.`;

export const NON_LEARNER_COURSES_COMPLETE = `Tracking the number of courses that are completed is a great tool in determining effort within the company, and correlates to closures in skills gaps. Being able to compare to previous time periods allows you to motivate learners to spend more time on their skills development, which is important not only for the company, but for the individuals themselves, but also to note when learners are spending too much time on Kepler and possibly alerting you to higher stress levels.`;

// both
export const SKILL_LEVELS = `Knowing the breakdown of the skill scores in your company helps identify where more training and development is required. Skills with a higher score of a lower score skill could require a higher resource allocation. It also helps with project planning and assigning users to specific roles in mixed teams, and creating possible mentorships. It is also useful in establishing a benchmark against other companies in your industry.`;

export const AVERAGE_SKILL_LEVEL = `The average skill score of a skill in your company gives a broad, yet useful, overview of the capability available in the company. Knowing what skill score you need in order to reach the goals you want to achieve, and the current skill score, helps to define training strategies, and where training is required the most.`;

export const KP_AWARDED = `This provides a bird's eye view of the engagement of your employees. The higher the KP, the more your employees are engaging with the system. A lower KP can mean many things including low morale, limited capacity, or that there are not enough resources that users can learn from. All of these provide great opportunities to learn about your company. Encourage your employees to learn and grow, and the KP will increase.`;
