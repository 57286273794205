import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Button } from '@keplerco/core';
import { useNavigate } from 'react-router-dom';

export function VariationQuestionReadyModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="modalContent">
      <div className="dialogBodyLayout">
        <div className="badgeIconContainer">
          <object data={'/badges/badge_Planet.svg'} type="image/svg+xml">
            <img src={'/badges/badge_Planet.svg'} alt="KP" />
          </object>
        </div>

        <h2 className="heading2" style={{ paddingBottom: 30 }}>
          It's time to test your skills!
        </h2>

        <h6 className="subtitle">
          You've recently completed your <strong style={{ color: 'var(--text)' }}>{props.title}</strong> learning journey. Let's see what you've learned.
        </h6>
      </div>

      <footer className="dialogFooterLayout modalFooterLayout">
        <Button
          chubby
          type="button"
          filled
          arrow={false}
          onClick={async () => {
            await props.onAction();
            navigate(`${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(':skillSlug', props.slug!)}`);
          }}
        >
          Take the Quiz
        </Button>

      </footer>
    </div>
  );
}
