import React from 'react';
import { useAppState } from '../../../../overmind';
import { useNavigate } from 'react-router-dom';
import { Anchor, Button, KeplerPoints, ProgressBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import { PagePath } from '../../../../navigation/navigation.enums';
import { ICoreSkillsMapPlanetProps } from '../core-skills-map.models';
import { SkillScoreComparison } from '../../../../enums';
import styles from './core-skills-map-planet.module.css';
import classNames from 'classnames';
import { UrgencyIndicationChip } from '../../../your-skills/urgency-indication.chip';
import { getSubdomain } from '../../../../lib/get-subdomain';
import { skillScoreComparisonText } from '../../../../lib/skill-score-comparison-text';

export function CoreSkillsMapPlanet({ planet }: ICoreSkillsMapPlanetProps): JSX.Element {
  const { companyVariables } = useAppState();

  const subdomain = getSubdomain();

  const navigate = useNavigate();
  const to = `${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(':skillSlug', planet.slug)}`;

  return (
    <div className={styles.row}>
      <div className={styles.innerRow} style={{ transform: `translateX(${planet.offset}%)` }}>
        <div className={styles.cardRow}>
          <div className={styles.cardRowInner}>
            <div className={classNames(styles.cardInnerText, 'heading5')}>{planet.name}</div>

            {!!planet.urgencyIndication ? (
              <div className={styles.cardChip}>
                <UrgencyIndicationChip urgencyIndication={planet.urgencyIndication} />
              </div>
            ) : (
              <React.Fragment>
                <div className={styles.skillScoreContainer}>
                  {companyVariables.useLevels ? (
                    <React.Fragment>
                      <div className={styles.skillLevelIndicator}>
                        <SkillLevel
                          level={planet.learnerScore?.level ?? companyVariables.minLevel}
                          minLevel={companyVariables.minLevel}
                          maxLevel={companyVariables.maxLevel}
                          notAssessed={!planet.learnerScore}
                          title="Your proficiency"
                          titleColour="black"
                          labelColour="black"
                        />
                      </div>

                      {companyVariables.skillScoreComparison !== SkillScoreComparison.None && (
                        <div className={styles.skillLevelIndicator}>
                          <SkillLevel
                            level={planet.comparisonScore?.level ?? companyVariables.minLevel}
                            minLevel={companyVariables.minLevel}
                            maxLevel={companyVariables.maxLevel}
                            notAssessed={!planet.comparisonScore}
                            title={skillScoreComparisonText(companyVariables.skillScoreComparison)}
                            titleColour="black"
                            labelColour="black"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <>
                      <SkillPercentage
                        percentage={planet.learnerScore?.percentage ?? 0}
                        notAssessed={!planet.learnerScore}
                        title="Skill percentage"
                        titleColour="black"
                        labelColour="black" />

                      {companyVariables.skillScoreComparison !== SkillScoreComparison.None && (
                        <SkillPercentage
                          percentage={planet.comparisonScore?.percentage ?? 0}
                          notAssessed={!planet.comparisonScore}
                          title={skillScoreComparisonText(companyVariables.skillScoreComparison)}
                          titleColour="black"
                          labelColour="black" />
                      )}
                    </>
                  )}
                </div>

                {!!planet.totalActivityCount && (
                  <div className={styles.progressBar}>
                    <ProgressBar value={planet.completedActivityCount} max={planet.totalActivityCount} theme="secondary" round />

                    <div className={styles.captions}>
                      <div className="caption2" style={{ color: 'black' }}>
                        {planet.completedActivityCount} of {planet.totalActivityCount} activities completed
                      </div>

                      <div className="caption2" style={{ marginLeft: !planet.totalActivityCount ? 'auto' : 'unset', color: 'black' }}>
                        <KeplerPoints trimWhiteSpace forceFontWeight="bold" points={planet.completedActivityCount === planet.totalActivityCount ? planet.keplerPointsEarned : planet.keplerPointsAvailable} />
                        {!!planet.totalActivityCount && planet.completedActivityCount === planet.totalActivityCount ? ' Earned' : ' Available'}
                      </div>
                    </div>
                  </div>
                )}

              </React.Fragment>
            )}

            <div className={styles.cardActions}>
              {planet.completedActivityCount < planet.totalActivityCount ? (
                <Button type="button" arrow onClick={() => navigate(to)}>
                  {!!planet.completedActivityCount ? 'Continue' : 'Start'}
                </Button>
              ) : (
                <Anchor style={{ padding: '8px 0' }} onClick={() => navigate(to)}>
                  {subdomain.includes('roche-deep') ? 'Completed' : 'View Skill'}
                </Anchor>
              )}
            </div>

          </div>
        </div>

        <img draggable={false} width={planet.width} src={planet.src} loading="lazy" alt="" onClick={() => navigate(to)} />
      </div>
    </div >
  );
}

