import { Context } from '../..';
import { FetchStatus, FetchType } from '../../../enums';
import { FetchStateItem } from '../../../models/fetch-state';
import { PagePath } from '../../../navigation/navigation.enums';
import { generateInitialFetchState } from '../../state';

export function startLoader(context: Context, payload: { path: PagePath; type: FetchType }): void {
  const fetchState: { [path in PagePath]: FetchStateItem } = { ...context.state.fetchState };
  fetchState[payload.path] = { status: FetchStatus.Active, type: payload.type };
  context.state.fetchState = fetchState;
}

export function stopLoader(context: Context, payload: PagePath): void {
  const fetchState: { [path in PagePath]: FetchStateItem } = { ...context.state.fetchState };
  fetchState[payload] = { status: FetchStatus.Inactive };
  context.state.fetchState = fetchState;
}

export function resetAllLoaders(context: Context) {
  context.state.fetchState = generateInitialFetchState();
}
