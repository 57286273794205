import classNames from 'classnames';
import React, { useState } from 'react';
import { QuestionnaireAnswer } from '../../../models/questionnaire';
import { IMultipleChoiceQuestionProps } from './multiple-choice-question.models';
import { MultipleChoiceQuestionHeader, MultipleChoiceQuestionList, MultipleChoiceQuestionListItem, MultipleChoiceQuestionWrapper } from './multiple-choice-question.styles';
import { safeCallback } from '@keplerco/core';

export function MultipleChoiceQuestion(props: IMultipleChoiceQuestionProps): JSX.Element {
  const { isSaving = false } = props;
  const [currentlySelected, setCurrentlySelected] = useState<QuestionnaireAnswer | undefined>(props.answers.find(d => d.selected));

  return (
    <React.Fragment>
      <MultipleChoiceQuestionWrapper>
        <MultipleChoiceQuestionHeader>{props.question}</MultipleChoiceQuestionHeader>

        <MultipleChoiceQuestionList>
          {props.answers.map(a => {
            const active = currentlySelected?.id === a.id;

            return (
              <MultipleChoiceQuestionListItem
                key={a.id}
                className={classNames({ active, saving: isSaving && active, fade: isSaving && !active })}
                onClick={() => {
                  if (isSaving) return;
                  setCurrentlySelected(a);
                  if (a.id !== currentlySelected?.id) {
                    safeCallback(props.onSelection, a, () => {
                      setCurrentlySelected(void 0);
                    });
                  }
                }}
              >
                {a.description}
              </MultipleChoiceQuestionListItem>
            );
          })}
        </MultipleChoiceQuestionList>
      </MultipleChoiceQuestionWrapper>
    </React.Fragment>
  );
}

