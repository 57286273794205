import { Context } from '../..';
import { AssignCompanyEntityRequest, CompanyEntityResponse, CompanyEntityListResponse, CreateCompanyEntityRequest, UpdateCompanyEntityRequest, ImportCompanyEntityRequest } from '../../../models/overmind/company-entity';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import * as base from '../base';

const CONTROLLER = `companyskill`;

/**
 * Search for Skills as a User within the User's Company.
 */
export async function getUserCompanySkills(context: Context, payload: CompanyEntitySearchParams): Promise<CompanyEntityListResponse | undefined> {
  const url: string = base.url(CONTROLLER, `get-skills${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Skills as a User with Permissions or a User with System Admin within any Company.
 */
export async function getCompanySkills(context: Context, payload: CompanyEntitySearchParams): Promise<CompanyEntityListResponse | undefined> {
  const url: string = base.url(CONTROLLER, `get-skills`, `${payload.companySlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityListResponse> = await base.request(request);
  return response.data;
}

export async function getCompanySkill(context: Context, payload: { companySlug: string; skillSlug: string }): Promise<CompanyEntityResponse | undefined> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `get-skill`, payload.companySlug, payload.skillSlug), authenticated: true };
  const response: base.IResponse<CompanyEntityResponse> = await base.request(request);
  return response.data;
}

export async function createCompanySkill(context: Context, payload: CreateCompanyEntityRequest): Promise<string | undefined> {
  const url: string = base.url(CONTROLLER, `create-skill`, payload.companySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);
  return response.data;
}

export async function updateCompanySkill(context: Context, payload: UpdateCompanyEntityRequest): Promise<string | undefined> {
  const url: string = base.url(CONTROLLER, `update-skill`, payload.companySlug, payload.entitySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);
  return response.data;
}

export async function importCompanySkill(context: Context, payload: ImportCompanyEntityRequest): Promise<base.IResponse<string>> {
  const url: string = base.url(CONTROLLER, `import`, payload.companySlug, payload.entitySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST' };
  const response: base.IResponse<string> = await base.request(request);
  return response;
}

export async function assignCompanySkill(context: Context, payload: AssignCompanyEntityRequest): Promise<void> {
  const url: string = base.url(CONTROLLER, `assign-skill`, context.state.companyVariables.slug!);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}
