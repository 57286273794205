import { IconRotatableProps, colourString } from '@keplerco/core';
import React from 'react';

export function ExpandIcon(props: IconRotatableProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg fill="none" width={size} height={size} viewBox="0 0 23 23">
      <path fillRule="evenodd" clipRule="evenodd" d="m2.75 19.432 8.598.098-.014-1.252-7.345-.084-.084-7.345-1.253-.014.099 8.597Z" fill={tone} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.743 20.084a.652.652 0 0 1-.645-.645L2 10.842a.652.652 0 0 1 .66-.66l1.252.015c.354.004.64.29.645.645l.076 6.707 6.708.077c.354.004.64.29.645.645L12 19.523a.652.652 0 0 1-.66.66l-8.597-.099Zm1.246-1.89-.084-7.345-1.253-.014.099 8.597 8.597.098-.014-1.252-7.345-.084ZM19.314 2.933l.099 8.597-1.253-.014-.084-7.345-7.345-.084-.014-1.252 8.597.098Z"
        fill={tone}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.322 2.281c.354.004.64.29.645.645l.098 8.597a.652.652 0 0 1-.66.66l-1.252-.015a.652.652 0 0 1-.645-.644l-.076-6.708-6.708-.077a.652.652 0 0 1-.645-.644l-.014-1.253a.652.652 0 0 1 .66-.66l8.597.099Zm-1.246 1.89.084 7.345 1.253.014-.099-8.597-8.597-.098.014 1.252 7.345.084Z"
        fill={tone}
      />
    </svg>
  );
}

