import { useRef, useState } from 'react';
import { Day, getDateRangeForMonth, MonthRange } from './datepicker.helpers';

type UpdateFunction = (by?: number) => void;

interface DateRangeOutput {
  range: MonthRange;
  previous: UpdateFunction;
  next: UpdateFunction;
  selected: Day;
  selectDay(day: Day): void;
  dirty: boolean;
}

function dateRangeForDate(date: Date = new Date()): MonthRange {
  return Day.from(date).toMonthRange();
}

export function useDateRange(defaultDate: Date = new Date(), selectedDate?: Date): DateRangeOutput {
  const [range, setRange] = useState<MonthRange>(dateRangeForDate(defaultDate));
  const [selected, setSelected] = useState<Day>(Day.from(selectedDate ?? defaultDate));
  const isDirty = useRef<boolean>(false);

  return {
    range,
    previous(by: number = 1) {
      setRange(getDateRangeForMonth(range.currentMonth - by, range.year));
    },
    next(by: number = 1) {
      setRange(getDateRangeForMonth(range.currentMonth + by, range.year));
    },
    selected,
    selectDay(day: Day) {
      isDirty.current = true;
      setSelected(day);
      setRange(getDateRangeForMonth(day.month, day.year));
    },
    dirty: isDirty.current,
  };
}
