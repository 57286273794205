import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const PersonCardText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PersonCardName = styled.p`
  ${PersonCardText}
`;

export const PersonCardRole = styled.p`
  ${PersonCardText}
  color: var(--accent-2);
`;

export const PersonCardDepartment = styled.p`
  ${PersonCardText}
  color: var(--secondary);
  text-transform: uppercase;
`;

export const PersonCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;

  &.List {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: start;
    gap: 15px;
  }
`;

export const PersonCardAvatarIcon = styled.figure`
  margin: 0px;
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  overflow: hidden;
`;

export const PersonCardContainer = styled.div`
  display: grid;
  grid-template-columns: 70px minmax(0, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const PersonCardNavlink = styled(NavLink)`
  text-decoration: none;
`;
