import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Rater } from '../../../components';
import { CompletionStatus, FetchType } from '../../../enums';
import { RatingFocusPanelContentLayout, RatingPageHeader, RatingPageLayout, RatingPageMobileContent, RatingPageScaleFooter, RatingPageScaleInputs, RatingPageScaleKey, RatingPageScaleKeyInner, RatingPageScaleLayout, RatingScaleMarker } from './rating.styles';
import { IRatingsProps } from './rating.models';
import { RatingScale } from '../../../models/rating-scale';
import { useAppActions, useAppState } from '../../../overmind';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import * as st from './rating.state';
import { ReviewResource } from '../../../models/review-resource';
import { ExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/explainer-focus-panel/explainer-focus-panel.layout';
import { filteredColours } from '../../../lib/filtered-colours';
import { RatingSkeleton } from './rating.skeleton';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Button, FocusPanel, PageHeader, UnorderedList, UnorderedListItem, capitalCase } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { getSubdomain } from '../../../lib/get-subdomain';
import { AssessmentType } from '../../../enums/assessment-type';

function RatingKeyPartial(state: { ratingScale: RatingScale }): JSX.Element {
  return (
    <RatingPageScaleKeyInner>
      <h6 className="subtitle">Rating key</h6>

      <UnorderedList>
        {state.ratingScale.map(rating => {
          return (
            <UnorderedListItem key={rating.label}>
              <span className="caption1">{rating.label}</span>
              <span className="caption2">{rating.description}</span>
            </UnorderedListItem>
          );
        })}
      </UnorderedList>
    </RatingPageScaleKeyInner>
  );
}

export function RatingLayout(props: IRatingsProps): JSX.Element {
  const ratingKey = useRef<HTMLDivElement>(null);
  const scrollMarker = useRef<HTMLDivElement>(null);

  const actions = useAppActions();
  const { skillAssessmentConfig } = useAppState();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(st.reducer, st.initialState);
  const { ratingSlug } = useParams<any>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<ReviewResource[]>();

  const [queriedSkill, setQueriedSkill] = useState<{ name: string; description: string }>();

  const subdomain = getSubdomain();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: props.path, type: FetchType.PageFetching });

      if (props.type === 'peer') {
        const [ratingScale, teamMembers] = await Promise.all([actions.getCompanyRatingScales(), actions.getTeamReviews()]);
        const teamMember = teamMembers?.find(tempTeamMember => tempTeamMember.userSlug === ratingSlug);
        dispatch({ type: 'populate-peer', payload: { ratingScale, teamMember, title: props.title, includeManagerSkillAdd: false } });
      } else if (props.type === 'self') {
        const [ratingScale, learnerSkills] = await Promise.all([actions.getCompanyRatingScales(), actions.getSelfReviews()]);
        dispatch({ type: 'populate-self', payload: { ratingScale, learnerSkills, title: props.title, includeLearnerSkillFrequency: true } });
      } else {
        const [ratingScale, reviews] = await Promise.all([actions.getCompanyRatingScales(), actions.getLearnerCareerPathSkillSet(ratingSlug!)]);
        dispatch({ type: 'populate-career-paths', payload: { ratingScale, careerPathSkills: reviews, title: props.title, subdomain } });
      }

      actions.stopLoader(props.path);
    }

    getData();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const marker = scrollMarker.current;
      const key = ratingKey.current;

      if (!!key && marker) {
        const { top, left } = marker.getBoundingClientRect();

        if (top <= 90) {
          key.style.position = 'fixed';
          key.style.top = `90px`;
          key.style.left = `${left}px`;
        } else {
          key.style.position = '';
          key.style.top = '';
          key.style.left = '';
        }
      }
    });
  }, []);

  function onRatingPeerHandler(rating: number, skill: ReviewResource, index: number, parent: ReviewResource[]) {
    dispatch({ type: 'patch-team-member-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);

    if (rating === undefined) {
      return;
    }

    const nextSibling = parent[index + 1];
    if (nextSibling) {
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);
      window.scrollBy({ behavior: 'smooth', top: currentElement?.clientHeight });
    }
  }

  function onRatingCareerPathHandler(rating: number, skill: ReviewResource, index: number, parent: ReviewResource[]) {
    dispatch({ type: 'patch-career-path-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);

    const nextSibling = parent[index + 1];
    if (nextSibling) {
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);
      window.scrollBy({ behavior: 'smooth', top: currentElement?.clientHeight });
    }
  }

  function onRatingSelfHandler(rating: number, skill: ReviewResource) {
    dispatch({ type: 'patch-learner-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);
  }

  return (
    <PageLoaderLayer path={props.path} skeletonLoader={<RatingSkeleton />}>
      <RatingPageLayout className="wrapper">
        <RatingPageHeader>
          <PageHeader breadcrumbs={state.crumbs} title={state.title ?? props.title} subtitle={state.subtitle ?? props.subtitle} />

          <RatingPageMobileContent className="rating-key-anchor">
            <Anchor onClick={() => setIsModalOpen(true)}>view rating key</Anchor>
          </RatingPageMobileContent>
        </RatingPageHeader>

        <RatingPageScaleLayout>
          <RatingPageScaleInputs>
            {state.type === 'peer' && (
              <React.Fragment>
                {skillAssessmentConfig?.assessmentType !== AssessmentType.PeerEndorsement && <Navigate to={PagePath.analysisBase} />}
                {!!state.teamMember && (
                  <React.Fragment>
                    {state.teamMember.reviews.map((skill, index, parent) => {
                      const colour = filteredColours[index % filteredColours.length];

                      return (
                        <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                          {!!state.ratingScale && (
                            <Rater
                              scale={state.ratingScale}
                              data={{
                                id: skill.companySkillId,
                                label: skill.skillName,
                                description: skill.skillDescription,
                                rating: skill.skillLevel,
                              }}
                              colour={colour}
                              fadeOnComplete
                              extraRating={{ label: `Don't know` }}
                              onChange={rating => onRatingPeerHandler(rating, skill, index, parent)}
                            />
                          )}

                          {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                            <div style={{ padding: '0 0' }}>
                              <div className="formErrorMessage">Rating cannot be blank</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}

                <RatingPageScaleFooter>
                  <Anchor arrow reverse block onClick={() => navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`)}>
                    Back
                  </Anchor>

                  <Button
                    type={'button'}
                    onClick={async () => {
                      const emptyReviews = state.teamMember?.reviews.filter(r => typeof r.skillLevel === 'undefined' || r.skillLevel === null) ?? [];

                      if (emptyReviews?.length > 0) {
                        const first = emptyReviews[0];
                        const element = document.getElementById(`rater-${first.companySkillId}`);
                        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        setErrorFields(emptyReviews);
                      } else {
                        actions.startLoader({ path: props.path, type: FetchType.Sending });

                        if (!!state.teamMember) {
                          await actions.submitTeamMemberReview({ ...state.teamMember, hasCompletedReview: true });
                        }

                        if (skillAssessmentConfig?.assessmentSlug) actions.getSkillAssessmentConfiguration(skillAssessmentConfig?.assessmentSlug);

                        actions.resetAllLoaders();

                        navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`);
                      }
                    }}
                  >
                    Submit Rating
                  </Button>
                </RatingPageScaleFooter>
              </React.Fragment>
            )}

            {state.type === 'career-path' && (
              <React.Fragment>
                {skillAssessmentConfig?.assessmentType !== AssessmentType.FieldsOfPractice && <Navigate to={PagePath.analysisBase} />}
                {!!state.careerPathSkills && (
                  <React.Fragment>
                    {state.careerPathSkills.reviews
                      .sort((a, b) => a.skillName.localeCompare(b.skillName))
                      .map((skill, index, parent) => {
                        const colour = filteredColours[index % filteredColours.length];

                        return (
                          <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                            {!!state.ratingScale && (
                              <Rater
                                scale={state.ratingScale}
                                data={{
                                  id: skill.companySkillId,
                                  label: skill.skillName,
                                  description: skill.skillDescription,
                                  rating: skill.skillLevel,
                                }}
                                colour={colour}
                                fadeOnComplete
                                onChange={rating => onRatingCareerPathHandler(rating, skill, index, parent)}
                              />
                            )}

                            {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                              <div style={{ padding: '0 0' }}>
                                <div className="formErrorMessage">Rating cannot be blank</div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </React.Fragment>
                )}

                <RatingPageScaleFooter>
                  <Anchor arrow reverse block onClick={() => navigate(`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`)}>
                    Back
                  </Anchor>

                  <Button
                    type={'button'}
                    onClick={async () => {
                      if (!!state.careerPathSkills && !!skillAssessmentConfig) {
                        const emptySkills = state.careerPathSkills.reviews.filter(skill => typeof skill.skillLevel === 'undefined' || skill.skillLevel === null) ?? [];

                        if (emptySkills.length > 0) {
                          const first = emptySkills[0];
                          const element = document.getElementById(`rater-${first.companySkillId}`);
                          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          setErrorFields(emptySkills);
                        } else {
                          actions.startLoader({ path: props.path, type: FetchType.Sending });
                          await actions.saveLearnerCareerPathSkillSets(state.careerPathSkills);

                          actions.resetAllLoaders();

                          const result = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig?.assessmentSlug);
                          if (result?.assessmentResultReady) {
                            actions.clearSkillAssessmentConfig();
                          }
                          navigate(`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`);
                        }
                      }
                    }}
                  >
                    Submit Ratings
                  </Button>
                </RatingPageScaleFooter>
              </React.Fragment>
            )}

            {state.type === 'self' && (
              <React.Fragment>
                {skillAssessmentConfig?.assessmentType !== AssessmentType.PeerEndorsement && <Navigate to={PagePath.analysisBase} />}
                {!!state.learnerSkills && (
                  <React.Fragment>
                    {state.learnerSkills.map((skill, index) => {
                      const colour = filteredColours[index % filteredColours.length];

                      return (
                        <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                          {!!state.ratingScale && (
                            <Rater
                              scale={state.ratingScale}
                              data={{
                                id: skill.companySkillId,
                                label: skill.skillName,
                                description: skill.skillDescription,
                                rating: skill.skillLevel,
                              }}
                              colour={colour}
                              extraRating={{
                                count: skill.yearsSpent,
                                label: 'Number of years using this skill',
                                maxValue: 99,
                                onChange: years => {
                                  dispatch({ type: 'patch-learner-skill', payload: { skillId: skill.companySkillId, skillUpdates: { yearsSpent: years } } });
                                },
                              }}
                              onChange={(rating: any) => onRatingSelfHandler(rating, skill)}
                            />
                          )}

                          {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                            <div style={{ padding: '0 0' }}>
                              <div className="formErrorMessage">Rating cannot be blank</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}

                <RatingPageScaleFooter>
                  <Anchor arrow reverse block onClick={() => navigate(`${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`)}>
                    Back
                  </Anchor>

                  <Button
                    type="button"
                    onClick={async () => {
                      if (!!state.learnerSkills && !!skillAssessmentConfig) {
                        const emptySkills = state.learnerSkills.filter(skill => {
                          return typeof skill.skillLevel === 'undefined' || skill.skillLevel === null || typeof skill.yearsSpent === 'undefined';
                        });

                        if (emptySkills.length > 0) {
                          const first = emptySkills[0];
                          const element = document.getElementById(`rater-${first.companySkillId}`);
                          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          setErrorFields(emptySkills);
                        } else {
                          actions.startLoader({ path: props.path, type: FetchType.Sending });
                          await actions.submitSelfReviews(state.learnerSkills);
                          await actions.completeSelfReview();
                          await actions.getSkillAssessmentConfiguration(skillAssessmentConfig.assessmentSlug);

                          const skillAssessmentConfiguration = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug);
                          if (skillAssessmentConfiguration?.selfReviewCompletionStatus === CompletionStatus.Completed && skillAssessmentConfig?.selfPeerReviewCompletionStatus === CompletionStatus.NoActionRequired) {
                            actions.resetSkillConfiguration();
                          }
                          navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`);

                          actions.stopLoader(props.path);
                        }
                      }
                    }}
                  >
                    Submit
                  </Button>
                </RatingPageScaleFooter>
              </React.Fragment>
            )}
          </RatingPageScaleInputs>

          <RatingScaleMarker ref={scrollMarker} />

          <RatingPageScaleKey ref={ratingKey}>{!!state.ratingScale && <RatingKeyPartial ratingScale={state.ratingScale} />}</RatingPageScaleKey>
        </RatingPageScaleLayout>
      </RatingPageLayout>

      <FocusPanel open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <RatingFocusPanelContentLayout className="dialogContentLayout focusPanelContentLayout">
          <div className="dialogBodyLayout">{!!state.ratingScale && <RatingKeyPartial ratingScale={state.ratingScale} />}</div>

          <Button filled type={'button'} onClick={() => setIsModalOpen(false)}>
            Got it, Thanks!
          </Button>
        </RatingFocusPanelContentLayout>
      </FocusPanel>

      <FocusPanel open={!!queriedSkill} onClose={() => setQueriedSkill(void 0)}>
        {!!queriedSkill && <ExplainerFocusPanelLayout title={capitalCase(queriedSkill.name)} description={queriedSkill.description} onClose={() => setQueriedSkill(void 0)} />}
      </FocusPanel>
    </PageLoaderLayer>
  );
}
