import styled from 'styled-components';

export const AdditionalLearningFuzzySearchWrapper = styled.div``;

export const AdditionalLearningForm = styled.form`
  display: block;

  @media screen and (max-width: 850px) {
    padding-bottom: 220px;
  }
`;

export const AdditionalLearningHeader = styled.div`
  justify-content: flex-start;
`;

export const AdditionalLearningFocusPanelFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse nowrap;
`;

export const AdditionalLearningDatepickerLayout = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const AdditionalLearningDatepickerLayoutColumn = styled.div``;

export const AdditionalLearningGridCardLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  align-items: stretch;

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
  }
`;

export const AdditionalLearningGridCard = styled.div`
  .card {
    padding: 10px !important;
  }

  .toggleCardLabel {
    font-size: 12px;
    min-height: 40px;
    display: block;
    width: 100%;
    text-align: center;
  }

  .toggleCardIcon {
    overflow: hidden;
    margin: -5px auto -15px auto;
  }
`;

