import { IconRotatableProps, colourString } from '@keplerco/core';
import React from 'react';

export function CollapseIcon(props: IconRotatableProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg fill="none" width={size} height={size} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="m10.25 13.75-8.598-.098.015 1.253 7.344.084.084 7.345 1.253.014-.099-8.597Z" fill={tone} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.257 13.098c.354.004.64.29.645.645L11 22.34a.653.653 0 0 1-.66.66l-1.252-.014a.652.652 0 0 1-.645-.645l-.076-6.708-6.708-.076a.652.652 0 0 1-.645-.645L1 13.66a.652.652 0 0 1 .66-.66l8.597.098Zm-1.246 1.89.084 7.346 1.253.014-.099-8.597-8.597-.099.015 1.253 7.344.084ZM13.75 10.25l-.098-8.598 1.253.015.084 7.344 7.345.084.014 1.253-8.597-.099Z"
        fill={tone}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.743 10.902a.652.652 0 0 1-.645-.645L13 1.66a.652.652 0 0 1 .66-.66l1.252.014c.354.004.64.29.645.645l.076 6.708 6.708.076c.354.004.64.29.645.645L23 10.34a.653.653 0 0 1-.66.66l-8.597-.098Zm1.246-1.89-.085-7.346-1.252-.014.099 8.598 8.597.098-.014-1.253-7.345-.084Z"
        fill={tone}
      />
    </svg>
  );
}

