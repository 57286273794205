import styled from 'styled-components';

export const ManagePersonHeaderLayout = styled.header`
  display: grid;
  align-items: center;
  grid-template-columns: 90px 1fr;
  gap: 15px;
  padding-bottom: 25px;
  border-bottom: solid 1px var(--borders);
`;

export const ManagePersonHeaderLayoutIcon = styled.div`
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  border: solid 2px var(--text);
`;

export const ManagePersonBodyLayout = styled.div`
  padding-top: 25px;
`;

