import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Anchor, Button, KeplerPoints, ListItemLayout, ProgressBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import classNames from 'classnames';
import styles from './skill-list-item.module.css';
import { useAppState } from '../../../../overmind';
import { UrgencyIndicationChip } from '../../../your-skills/urgency-indication.chip';
import { ISkillListItemProps } from './skill-list-item.models';
import { SkillScoreComparison } from '../../../../enums';
import { getSubdomain } from '../../../../lib/get-subdomain';
import { skillScoreComparisonText } from '../../../../lib/skill-score-comparison-text';

export function SkillListItem(props: ISkillListItemProps): JSX.Element {
  const subdomain = getSubdomain();

  const { companyVariables } = useAppState();

  const navigate = useNavigate();

  const isMobile = window.innerWidth < 768;

  return (
    <ListItemLayout>
      <div className={classNames('card', [styles.content])} style={{ gridTemplateColumns: (!!props.urgencyIndication || !!props.totalActivityCount) ? (isMobile ? '1fr' : 'minmax(0px, 1fr) minmax(0px, 1fr) 180px') : (isMobile ? '1fr' : 'minmax(0px, 1fr) 180px') }}>
        <div className={styles.info}>
          <div className={classNames('heading5', [styles.heading])}>{props.name}</div>

          {!props.urgencyIndication && props.urgencyIndication !== 0 && (
            <React.Fragment>
              {companyVariables.useLevels ? (
                <div className={styles.scores}>
                  <SkillLevel
                    level={props.learnerScore?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    notAssessed={!props.learnerScore}
                    title="Your proficiency"
                  />

                  {companyVariables.skillScoreComparison !== SkillScoreComparison.None && (
                    <SkillLevel
                      level={props.comparisonScore?.level ?? companyVariables.minLevel}
                      minLevel={companyVariables.minLevel}
                      maxLevel={companyVariables.maxLevel}
                      notAssessed={!props.comparisonScore}
                      title={skillScoreComparisonText(companyVariables.skillScoreComparison)}
                    />
                  )}
                </div>
              ) : (
                <div className={styles.scores}>
                  <SkillPercentage
                    percentage={props.learnerScore?.percentage ?? 0}
                    notAssessed={!props.learnerScore}
                    title="Your proficiency"
                  />

                  {companyVariables.skillScoreComparison !== SkillScoreComparison.None &&
                    <SkillPercentage
                      percentage={props.comparisonScore?.percentage ?? 0}
                      notAssessed={!props.comparisonScore}
                      title={skillScoreComparisonText(companyVariables.skillScoreComparison)}
                    />
                  }
                </div>
              )}
            </React.Fragment>
          )}
        </div>

        {!props.urgencyIndication && props.urgencyIndication !== 0 ? (
          <div className={styles.progressIndication} style={{ display: !!props.totalActivityCount ? 'flex' : 'none' }}>
            {!!props.totalActivityCount &&
              <ProgressBar
                value={props.completedActivityCount}
                max={props.totalActivityCount}
                theme="secondary" round
              />}

            <div className={styles.captions}>
              {!!props.totalActivityCount && (
                <div className="caption2">
                  {props.completedActivityCount} of {props.totalActivityCount} activities completed
                </div>
              )}

              {!!props.keplerPointsAvailable && (
                <div className="caption2" style={{ marginLeft: !props.totalActivityCount ? 'auto' : 'unset' }}>
                  <KeplerPoints
                    trimWhiteSpace
                    forceFontWeight="bold"
                    points={props.completedActivityCount === props.totalActivityCount ? props.keplerPointsEarned : props.keplerPointsAvailable}
                  />
                  {!!props.totalActivityCount && props.completedActivityCount === props.totalActivityCount ? ' Earned' : ' Available'}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="chipWrapper">
            <UrgencyIndicationChip urgencyIndication={props.urgencyIndication} />
          </div>
        )}

        <div className={styles.actions}>
          {!!props.totalActivityCount && props.completedActivityCount < props.totalActivityCount ? (
            <Button type="button" arrow onClick={() => navigate(props.path)}>
              {!!props.completedActivityCount ? 'Continue' : 'Start'}
            </Button>
          ) : (
            <Anchor style={{ padding: '8px 0' }} onClick={() => navigate(props.path)}>
              {subdomain.includes('roche-deep') ? 'Completed' : 'View Skill'}
            </Anchor>
          )}
        </div>
      </div>
    </ListItemLayout>
  );
}
