import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';

export function RatingSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ alignItems: 'flex-end', paddingBottom: 20 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="115" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={4}>
          <SkeletonLoaderRow style={{ margin: 0, paddingTop: 35, paddingBottom: 35 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="50" />
                </SkeletonLoaderColumn>

                <SkeletonLoaderColumn />
              </SkeletonLoaderRow>

              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="70" />
                </SkeletonLoaderColumn>
              </SkeletonLoaderRow>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ margin: 0, paddingTop: 35, paddingBottom: 35 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="50" />
                </SkeletonLoaderColumn>

                <SkeletonLoaderColumn />
              </SkeletonLoaderRow>

              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="70" />
                </SkeletonLoaderColumn>
              </SkeletonLoaderRow>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ margin: 0, paddingTop: 35, paddingBottom: 35 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="50" />
                </SkeletonLoaderColumn>

                <SkeletonLoaderColumn />
              </SkeletonLoaderRow>

              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="70" />
                </SkeletonLoaderColumn>
              </SkeletonLoaderRow>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ margin: 0, paddingTop: 35, paddingBottom: 35 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="50" />
                </SkeletonLoaderColumn>

                <SkeletonLoaderColumn />
              </SkeletonLoaderRow>

              <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
                <SkeletonLoaderColumn>
                  <SkeletonLoader height="70" />
                </SkeletonLoaderColumn>
              </SkeletonLoaderRow>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="400" width="375" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}