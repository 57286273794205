import { DropdownItem } from '@keplerco/core';
import { CourseMappingStatus, LearningPlatformType, MappingType } from '../../enums';

export const courseMappingStatuses: DropdownItem[] = [
  { label: 'Not mapped', value: CourseMappingStatus.NotMapped.toString(), onClick: () => console.log('onClick') },
  { label: 'Mapped', value: CourseMappingStatus.Mapped.toString(), onClick: () => console.log('onClick') },
];

export const learningPlatformTypes: DropdownItem[] = [
  { label: 'Corner Stone SFTP', value: LearningPlatformType.CornerStoneSFTP.toString(), onClick: () => console.log('onClick') },
  { label: 'Disprz', value: LearningPlatformType.Disprz.toString(), onClick: () => console.log('onClick') },
  { label: 'Go1 Learning', value: LearningPlatformType.Go1Learning.toString(), onClick: () => console.log('onClick') },
  { label: 'Kepler', value: LearningPlatformType.Kepler.toString(), onClick: () => console.log('onClick') },
  { label: 'Learnworlds', value: LearningPlatformType.Learnworlds.toString(), onClick: () => console.log('onClick') },
  { label: 'Mix', value: LearningPlatformType.Mix.toString(), onClick: () => console.log('onClick') },
  { label: 'Moodle', value: LearningPlatformType.Moodle.toString(), onClick: () => console.log('onClick') },
  { label: 'Succeed Academy', value: LearningPlatformType.SucceedAcademy.toString(), onClick: () => console.log('onClick') },
  { label: 'Other', value: LearningPlatformType.Other.toString(), onClick: () => console.log('onClick') },
];

export const mappingTypes: DropdownItem[] = [
  { label: 'Skill', value: MappingType.Skill.toString(), onClick: () => console.log('onClick') },
  { label: 'Department', value: MappingType.Department.toString(), onClick: () => console.log('onClick') },
  { label: 'Team', value: MappingType.Team.toString(), onClick: () => console.log('onClick') },
  { label: 'Person', value: MappingType.Person.toString(), onClick: () => console.log('onClick') },
  { label: 'Role', value: MappingType.Role.toString(), onClick: () => console.log('onClick') },
  { label: 'Language', value: MappingType.Language.toString(), onClick: () => console.log('onClick') },
];

export function setFilterItems(currentItems: DropdownItem[], value: string): DropdownItem[] {
  const nextItems = currentItems.map(item => ({ ...item }));
  nextItems.forEach(item => {
    item.selected = item.value === value;
  });
  return nextItems;
}

export function clearFilterItems(currentItems: DropdownItem[]): DropdownItem[] {
  const nextItems = currentItems.map(item => ({ ...item }));
  nextItems.forEach(item => (item.selected = false));
  return nextItems;
}
