import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAppActions } from '../../overmind';
import { useDataSyncReaction } from '../../lib/data-sync.hooks';
import { PagePath } from '../navigation.enums';
import { SkillsPage } from '../../pages/learning-journey/skills/skills.page';
import { AdditionalLearningPage } from '../../pages/learning-journey/additional-learning/additional-learning.page';
import { ReflectionsPage } from '../../pages/learning-journey/reflections/reflections.page';
import { CertificatesPage } from '../../pages/learning-journey/certificates/certificates.page';
import { AssignedSkillPage } from '../../pages/learning-journey/skill/assigned-skill.page';
import { YearSkillPage } from '../../pages/learning-journey/skill/year-skill.page';
import { SkillPage } from '../../pages/learning-journey/skill/skill.page';
import { ErrorRedirect } from './routers.helpers';
import { CoreSkillsMapPage } from '../../pages/learning-journey/core-skills-map/core-skills-map.page';
import { PageGuard } from '../guards/page.guard';

export function LearningJourneyRouter(): JSX.Element {
  const location = useLocation();

  const actions = useAppActions();

  async function getData() {
    actions.getTotalLearnerKeplerPoints();
  }

  useDataSyncReaction(async () => {
    getData();
  });

  useEffect(() => {
    getData();
  }, [location.pathname]);

  return (
    <Routes>
      <Route path={PagePath.root} element={<SkillsPage />} />
      <Route path={PagePath.learningJourneySkill} element={<SkillPage />} />
      <Route path={PagePath.learningJourneyYearSkill} element={<YearSkillPage />} />
      <Route path={PagePath.learningJourneyAssignedSkill} element={<AssignedSkillPage />} />
      <Route path={PagePath.learningJourneyAdditionalLearning} element={
        <PageGuard additionalLearningRequired>
          <AdditionalLearningPage />
        </PageGuard>
      }
      />
      <Route path={PagePath.learningJourneyCourseReflections} element={<ReflectionsPage />} />
      <Route path={PagePath.learningJourneyCertificates} element={<CertificatesPage />} />
      <Route
        path={PagePath.learningJourneyCoreSkillsMap}
        element={
          <PageGuard desktopRequired>
            <CoreSkillsMapPage />
          </PageGuard>
        }
      />
      {/* <Route path={PagePath.learningCompletedAssessments} element={<CertificatesPage />} /> */}

      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}