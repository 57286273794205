import { SkillScoresSkillSubTypeResponse } from '../../../models/overmind/analytics';

export interface SkillsReportState {
  skillSubTypes: SkillScoresSkillSubTypeResponse[];
  activeSkillSubType: SkillScoresSkillSubTypeResponse | undefined;
  includeAwaitingAssessment: boolean;
}

export const initialState: SkillsReportState = {
  skillSubTypes: [],
  activeSkillSubType: undefined,
  includeAwaitingAssessment: false,
};

export enum SkillsReportActionTypes {
  SetSkillTypes,
  SetActiveSkillType,
  SetIncludeAwaitingAssessment,
}

type SkillsReportActions =
  | { type: SkillsReportActionTypes.SetSkillTypes; payload: SkillScoresSkillSubTypeResponse[] }
  | { type: SkillsReportActionTypes.SetActiveSkillType; payload: SkillScoresSkillSubTypeResponse | undefined }
  | { type: SkillsReportActionTypes.SetIncludeAwaitingAssessment; payload: boolean };

export function reducer(state: SkillsReportState, action: SkillsReportActions): SkillsReportState {
  if (action.type === SkillsReportActionTypes.SetSkillTypes) {
    return { ...state, skillSubTypes: action.payload };
  }

  if (action.type === SkillsReportActionTypes.SetIncludeAwaitingAssessment) {
    return { ...state, includeAwaitingAssessment: action.payload };
  }

  if (action.type === SkillsReportActionTypes.SetActiveSkillType) {
    if (!action.payload) return { ...state, activeSkillSubType: undefined };
    return { ...state, activeSkillSubType: action.payload };
  }

  return state;
}

