import styled from 'styled-components';

export const TilesNotificationsList = styled.ul`
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  gap: 20px;
  margin: 0;
  list-style: none;
  width: 550px;
  height: 100%;
  pointer-events: none;
  z-index: 10;

  padding-bottom: 0;
  padding-right: 20px;
  padding-top: 20px;
  padding-left: 20px;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TileNotificationsItem = styled.li`
  display: block;
  width: 100%;
  background: var(--cards);
  padding: 15px;
  animation: NotifyIn cubic-bezier(0.6, -0.28, 0.735, 0.045) 300ms forwards;
  pointer-events: all;
  box-shadow: 0px 4px 20px var(--outer-shadow-colour);
  border-radius: 20px;
  border: solid 2px var(--primary);

  p {
    font-family: 'Prompt';
    margin: 0;
    color: var(--text);
  }

  &.Activity {
  }

  &.LoginStreak {
  }

  &.Message {
  }

  &.Urgent {
    border-color: var(--honey);
  }

  @keyframes NotifyIn {
    from {
      opacity: 0;
      transform: translateX(100%) skew(10deg);
    }

    to {
      opacity: 1;
      transform: translateX(0) skew(0deg);
    }
  }
`;

export const TileNotificationsItemBody = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 65px 1fr 100px;
  grid-template-areas: 'icon content action';
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 580px) {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'content' 'action';
  }
`;

export const TileNotificationsItemBodyIcon = styled.div`
  display: block;
  grid-area: icon;
  width: 65px;
  height: 65px;

  object {
    display: block;
    width: 100%;
    height: auto;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const TileNotificationsItemBodyContent = styled.div`
  display: block;
  grid-area: content;
  flex: 1;

  p {
    font-size: 14px;
  }

  h5 {
    padding-top: 3px;

    img {
      display: inline-block;
      height: 35px;
      vertical-align: middle;
      margin-top: -7px;
    }
  }

  ul {
    margin: 0;
    padding: 5px 0 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    li {
      width: auto;
      height: auto;
      display: grid;
      place-content: center;
      color: var(--text);
      border-radius: 50%;
      font-size: 14px;

      span {
        display: block;
        text-align: center;
        padding-bottom: 3px;
        font-size: 12px;
        font-weight: 500;
      }

      div {
        border: 1px solid var(--secondary);
        border-radius: 50%;
        display: block;
        width: 25px;
        height: 25px;
        position: relative;
      }

      &.active div {
        background: var(--secondary);

        &::after {
          content: '';
          display: block;
          width: 14px;
          height: 8px;
          border: solid 2px var(--background);
          border-top: none;
          border-right: none;
          top: 50%;
          left: 50%;
          position: absolute;
          margin: -6px -7px;
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

export const TileNotificationsItemBodyClose = styled.div`
  width: 30px;
  height: 100%;
  padding: 0;
  position: absolute;
  top: -5px;
  right: -5px;

  button {
    width: 30px;
    height: 30px;
    position: relative;
    border: none;
    background: none;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background: var(--text);
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -1px -10px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
`;
