import React, { useEffect, useState } from 'react';
import * as theme from '../../../theme';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { AccountPageInstruction, AccountPageContainer, AccountPageForm, AccountPageNavigationToggler } from '../account.styles';
import { SetPasswordStatus } from '../../../enums/set-password-status';
import { LoginInput } from '../../../models/view/login-input';
import { AccountPageLoaderLayer } from '../../../components/loading-handling/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, EmailValidator, FormControl, Textfield } from '@keplerco/core';

export function ActivateAccountPage(): JSX.Element {
  const actions = useAppActions();
  const { setPasswordStatus } = useAppState<KeplerState>();
  const navigate = useNavigate();
  const [sentForgotPassword, setState] = useState(false);

  useEffect(() => {
    if (setPasswordStatus === SetPasswordStatus.Succeeded) {
      setState(true);
    }
  }, [setPasswordStatus]);

  const { control, handleSubmit } = useForm<any>({ reValidateMode: 'onChange' });

  return (
    <theme.ShapesBackgroundWrapper>
      <AccountPageContainer>
        <div className="card glass">
          <AccountPageLoaderLayer path={PagePath.accountActivate}>
            <AccountPageNavigationToggler className="showDesktop">
              <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                Already have an account? <u>Sign in</u>
              </span>
            </AccountPageNavigationToggler>

            <h1 className="heading1">Activate your account</h1>

            {!!sentForgotPassword && (
              <AccountPageInstruction>
                <h6 className="subtitle">Thank you, please check your email for instructions to activate your account</h6>
              </AccountPageInstruction>
            )}

            {!sentForgotPassword && (
              <React.Fragment>
                <h6 className="subtitle">Enter your company email address and we'll send you instructions to activate your account.</h6>

                <AccountPageForm
                  id="activateAccount"
                  autoComplete="off"
                  onSubmit={handleSubmit(async (value: LoginInput) => {
                    actions.startLoader({ path: PagePath.accountActivate, type: FetchType.PageFetching });
                    await actions.requestAccountActivation(value);
                    actions.stopLoader(PagePath.accountActivate);
                  })}
                >
                  <FormControl paddingBottom={0} control={control} name="email" rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

                  <Button type="button" fullWidthMobile>
                    Send Instructions
                  </Button>

                  <AccountPageNavigationToggler className="showMobile bottom">
                    <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                      Already have an account? <u>Sign in</u>
                    </span>
                  </AccountPageNavigationToggler>
                </AccountPageForm>
              </React.Fragment>
            )}
          </AccountPageLoaderLayer>
        </div>
      </AccountPageContainer>
    </theme.ShapesBackgroundWrapper>
  );
}

