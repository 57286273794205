import { AddTwoToneIcon, DropdownItem, FocusPanel } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { CompanyEntityListItemResponse } from '../../../../models/overmind/company-entity';
import { createLevelsArray } from '../../../../lib/create-levels-array';
import { useAppActions, useAppState } from '../../../../overmind';
import { IManageSkillsWidgetProps } from './manage-role.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { extractHighestOrganizationLevel } from '../../../../lib/permissions.helpers';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { EntityType, OrganizationLevelType, SortField } from '../../../../enums';
import { ExplainerFocusPanelLayout } from '../../../../widgets/layouts';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components';
import { useParams } from 'react-router-dom';
import { EntitySelectionDualList } from '../../../../components/entity-selection/entity-selection-dual-list/entity-selection-dual-list';

export function ManageSkillsWidget({
  path,
  loadingSkills,
  setLoadingSkills,
  setSkillsToAssign,
  setSkillsToUnassign,
  onClickImportSkill,
  refetchDropdownItems,
}: IManageSkillsWidgetProps): JSX.Element {
  const { roleSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 99999, // ridiculously high pageSize to fetch ALL skills
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  };

  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [skills, setSkills] = useState<CompanyEntityListItemResponse[]>([]);
  const [skillToView, setSkillToView] = useState<CompanyEntityListItemResponse>();

  function onClickDropdownItem(skill: CompanyEntityListItemResponse) {
    setDropdownItems(currentState => {
      const nextState = currentState.map(item => ({ ...item }));
      const item = nextState.find(temp => temp.value === skill.slug);
      if (!!item) {
        item.selected ? removeSkill(skill) : addSkill(skill);
        item.selected = !item.selected;
      }
      return nextState;
    });
  }

  async function getDropdownItems(skills: CompanyEntityListItemResponse[]) {
    const response = await actions.getCompanySkills(defaultRequest);

    const items: DropdownItem[] = response?.entities.map(entity => ({
      value: entity.slug,
      label: entity.name,
      onClick: () => onClickDropdownItem(entity),
      selected: skills.some(skill => skill.slug === entity.slug),
    })) ?? [];

    skills.forEach(skill => {
      if (!items.some(item => item.value === skill.slug)) {
        items.push({
          value: skill.slug,
          label: skill.name,
          onClick: () => onClickDropdownItem(skill),
          selected: true,
        });
      }
    });

    setDropdownItems(items);
  }

  useEffect(() => {
    async function getSkills(): Promise<CompanyEntityListItemResponse[]> {
      const request: CompanyEntitySearchParams = {
        ...defaultRequest,
        relatedSlug: roleSlug,
        relatedType: EntityType.Role,
        searchGlobally: path === PagePath.roleManagementImportRole,
      };
      const skills = await actions.getCompanySkills(request);
      setSkills(skills?.entities ?? []);
      if (path === PagePath.roleManagementImportRole && !!skills?.entities.length) setSkillsToAssign(skills?.entities);
      return skills?.entities ?? [];
    }

    async function getData() {
      setLoadingSkills(true);

      if (!roleSlug) {
        await getDropdownItems([]);
        setLoadingSkills(false);
        return;
      }

      const response = await getSkills();
      await getDropdownItems(response);
      setLoadingSkills(false);
    }

    getData();
  }, [roleSlug]);

  useEffect(() => {
    async function getData() {
      setLoadingSkills(true);
      await getDropdownItems(skills);
      setLoadingSkills(false);
    }

    if (!!refetchDropdownItems) getData();
  }, [refetchDropdownItems]);

  async function addSkill(skill: CompanyEntityListItemResponse) {
    setSkills(currentState => !currentState.some(temp => temp.slug === skill.slug) ? [...currentState, skill] : currentState);
    setSkillsToAssign(currentState => [...currentState, skill]);
    setSkillsToUnassign(currentState => currentState.filter(temp => temp.slug !== skill.slug));
  }

  async function removeSkill(skill: CompanyEntityListItemResponse): Promise<CompanyEntityListItemResponse[]> {
    let nextSkills: CompanyEntityListItemResponse[] = [];
    setSkills(currentState => {
      nextSkills = currentState.filter(temp => temp.slug !== skill.slug);
      return nextSkills;
    });
    setSkillsToAssign(currentState => currentState.filter(temp => temp.slug !== skill.slug));
    if (path !== PagePath.roleManagementImportRole) setSkillsToUnassign(currentState => [...currentState, skill]);
    return nextSkills;
  }

  if (loadingSkills) {
    return (
      <div>
        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="43px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="400px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      </div>
    );
  }

  return (
    <React.Fragment>
      <EntitySelectionDualList
        loading={loadingSkills}
        label="Search skills"
        dropdownItems={dropdownItems}
        entities={skills.map(skill => ({
          slug: skill.slug,
          title: skill.name,
          subtitle: skill.description ?? 'No skill description provided',
          menuItems: [
            {
              label: 'Remove skill',
              onClick: async () => {
                const response = await removeSkill(skill);
                setDropdownItems(currentState => currentState.map(item => ({ ...item, selected: response.some(skill => skill.slug === item.value) })));
              },
            },
            {
              label: 'View skill',
              onClick: async () => setSkillToView(skill),
            }
          ],
          levelItems: createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => ({
            value: `Level ${level}`,
            onClick: async () => {
              setSkillsToAssign(currentState => {
                const nextState: CompanyEntityListItemResponse[] = structuredClone(currentState);
                const nextSkill = nextState.find(temp => temp.slug === skill.slug);
                !!nextSkill ? nextSkill.level = level : nextState.push(structuredClone({ ...skill, level }));
                return nextState;
              });

              setSkills(currentState => {
                const nextState: CompanyEntityListItemResponse[] = currentState.map(temp => ({ ...temp }));
                const nextItem = nextState.find(temp => temp.slug === skill.slug);
                if (!!nextItem) nextItem.level = level;
                return nextState;
              });
            },
            selected: skill.level === level,
          }))
        }))}
        button={!!onClickImportSkill ? { icon: <AddTwoToneIcon />, text: 'Import skill', onClick: onClickImportSkill } : undefined}
      />

      <FocusPanel open={!!skillToView} backgroundStyle="clean" onClose={() => setSkillToView(undefined)}>
        {!!skillToView && (
          <ExplainerFocusPanelLayout
            supertitle="Role Management"
            title={skillToView.name}
            description={skillToView.description ?? ''}
            onClose={() => setSkillToView(undefined)}
          />
        )}
      </FocusPanel>
    </React.Fragment>
  );
}