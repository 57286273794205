import { LessonStatusCountsResponse, SkillLevelsResponse } from '../../../models/overmind/analytics';
import { DonutSlices, OverviewWidgetChartData, OverviewWidgetDonutChartData } from './overview.models';

export const defaultLessonStatusCounts: LessonStatusCountsResponse = {
  notStarted: 0,
  inProgress: 0,
  complete: 0,
};

export const defaultSkillLevels: SkillLevelsResponse = {
  peopleCount: 0,
  percentages: [],
};

export function generateDonutChartData(label: string, donutLabel: string, value: number, slices: DonutSlices, description?: string): OverviewWidgetDonutChartData {
  const temp: OverviewWidgetDonutChartData = {
    label,
    value,
    description,
    donutLabel,
    slices,
  };

  return temp;
}

export function generateNumberChartData(label: string, value: number | undefined, valueType: 'number' | 'percentage', description?: string, chartType: 'skillLevel' | 'skillPercentage' | 'numberDisplay' = 'numberDisplay'): OverviewWidgetChartData {
  const temp: OverviewWidgetChartData = {
    label,
    value,
    valueType,
    chartType,
    description,
  };

  return temp;
}
