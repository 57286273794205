import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';

export function CreateSkillAssessmentSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ marginTop: 20, marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="100" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="500" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="600" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="600" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="600" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="200" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
      <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="100" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

