import styled from 'styled-components';

// loader
export const IFrameLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--white);
`;

export const IFrameLoaderContent = styled.div`
  max-width: 550px;
  margin: auto;
`;

// iframe
export const IFrame = styled.iframe`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--white);
`;
