import React, { useEffect, useState } from 'react';
import { FetchType } from '../../enums';
import { PersonalDetails } from '../../models/view/personal-details';
import { useAppActions, useAppState } from '../../overmind';
import * as theme from '../../theme';
import { Buttons, PersonalDetailsFormOutlet, PersonalDetailsFormOutletControl, PersonalDetailsFormSection, PersonalDetailsFormSectionDescription, PersonalDetailsFormWrapper, PersonalDetailsNonTranslationBreakingSpacer } from './onboarding.styles';
import { PageLoaderLayer } from '../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../navigation/navigation.enums';
import { UniqueEntity } from '../../models';
import { ErrorFieldState, IFormValues, Country, defaultFormValues, initialErrorFieldState } from './onboarding.models';
import { Anchor, Button, DropdownItem, DropdownSearch, IPillButton, PillButtonGroup, TextField, colourString, useMatchScreenWidth } from '@keplerco/core';
import classNames from 'classnames';
import { PeriodOfExperience, PeriodOfExperienceDescription } from '../../enums/period-of-experience';

export function OnboardingPage(): JSX.Element {
  const actions = useAppActions();
  const { user, settings } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const [view, setView] = useState<'personal' | 'professional'>('personal');
  const [errorField, setErrorField] = useState<ErrorFieldState>(initialErrorFieldState);
  const [formValues, setFormValues] = useState<IFormValues>(defaultFormValues);

  const [countryItems, setCountryItems] = useState<DropdownItem[]>([]);

  const [pillButtons] = useState<IPillButton[]>([
    { id: PeriodOfExperience.ZeroToTwo, label: PeriodOfExperienceDescription.ZeroToTwo },
    { id: PeriodOfExperience.TwoToFour, label: PeriodOfExperienceDescription.TwoToFour },
    { id: PeriodOfExperience.FourToSeven, label: PeriodOfExperienceDescription.FourToSeven },
    { id: PeriodOfExperience.SevenPlus, label: PeriodOfExperienceDescription.SevenPlus },
  ]);

  useEffect(() => {
    async function getData() {
      if (!user) return actions.startLoader({ path: PagePath.onboarding, type: FetchType.PageFetching });

      setView('personal');

      const countries: Country[] | undefined = await actions.getCountries();

      let filteredCountries: DropdownItem[] = [];
      if (!!countries) {
        filteredCountries = countries
          .filter(country => country.iso !== null)
          .map(country => ({
            value: country.name,
            onClick: () => onSelectCountry(country),
          }));
      }

      setCountryItems(filteredCountries);

      setFormValues(() => {
        const firstName = (user.firstName ?? '').trim() === '' ? '' : user.firstName;

        return {
          ...defaultFormValues,
          firstName: firstName,
          lastName: (user.lastName ?? '').trim() === '' ? '' : user.lastName,
          preferredName: (user.preferredName ?? '').trim() === '' ? firstName : user.preferredName,
          jobTitle: (user.jobTitle ?? '').trim() === '' ? '' : user.jobTitle,
          countryId: settings?.countryId ?? undefined,
        } as IFormValues;
      });

      if (!!settings?.countryId) {
        const defaultCountry = countries?.find(country => country.id === settings.countryId);
        setCountryItems(currentState => {
          const nextState = currentState?.map(item => ({ ...item }));
          nextState?.forEach(item => (item.selected = item.value === defaultCountry?.name));
          return nextState;
        });
      }

      actions.stopLoader(PagePath.onboarding);
    }

    getData();
  }, [user]);

  function onSelectCountry(country: UniqueEntity) {
    setErrorField(currentState => {
      const nextState = structuredClone(currentState);
      nextState.countryId = !country;
      return nextState;
    });

    setFormValues(currentState => {
      const nextState = structuredClone(currentState);
      nextState.countryId = country.id;
      return nextState;
    });

    setCountryItems(currentState => {
      const nextState = currentState?.map(item => ({ ...item }));
      nextState?.forEach(item => (item.selected = item.value === country.name));
      return nextState;
    });
  }

  async function submitForm() {
    setErrorField(initialErrorFieldState);

    actions.startLoader({ path: PagePath.onboarding, type: FetchType.Sending });

    const personalDetails = {
      ...formValues,
    } as PersonalDetails;

    await actions.submitPersonalDetails(personalDetails);
    await actions.getProfile();

    actions.stopLoader(PagePath.onboarding);
  }

  function onClickNextHandler() {
    switch (view) {
      case 'personal': {
        if (!!formValues.firstName && !!formValues.lastName && !!formValues.preferredName && formValues.countryId !== undefined) {
          setErrorField(initialErrorFieldState);
          user?.displayJobInformationForm ? setView('professional') : submitForm();
          break;
        }

        setErrorField(currentState => {
          const nextState = structuredClone(currentState);
          nextState.firstName = !formValues.firstName;
          nextState.lastName = !formValues.lastName;
          nextState.preferredName = !formValues.preferredName;
          nextState.countryId = !formValues.countryId;
          return nextState;
        });
        break;
      }

      case 'professional': {
        if (!!formValues.jobTitle && formValues.periodOfExperience !== undefined) {
          setErrorField(initialErrorFieldState);
          submitForm();
          break;
        }

        setErrorField(currentState => {
          const nextState = structuredClone(currentState);
          nextState.jobTitle = !formValues.jobTitle;
          nextState.periodOfExperience = !formValues.periodOfExperience;
          return nextState;
        });

        break;
      }

      default: {
        break;
      }
    }
  }

  return (
    <PageLoaderLayer path={PagePath.onboarding}>
      <PersonalDetailsFormWrapper>
        {view === 'personal' && (
          <PersonalDetailsFormSection autoComplete="off">
            <PersonalDetailsFormSectionDescription>
              <h1 className="heading1">{user?.displayJobInformationForm ? `Let's c` : `C`}reate your profile</h1>
              <h6 className="subtitle">{user?.displayJobInformationForm ? `First we'll` : `Let's`} confirm your details, then we'll dive right into your skills analysis.</h6>
            </PersonalDetailsFormSectionDescription>

            <PersonalDetailsFormOutlet>
              <PersonalDetailsFormOutletControl>
                <TextField
                  type="text"
                  label="What is your first name?"
                  large
                  responsive
                  validation={{ dirty: errorField.firstName, invalid: errorField.firstName, message: 'Please enter your first name' }}
                  defaultValue={formValues.firstName}
                  onChange={event => {
                    const value = event.currentTarget.value;
                    setFormValues(currentState => ({ ...currentState, firstName: value }));
                    setErrorField(currentState => ({ ...currentState, firstName: !value }));
                  }}
                />
              </PersonalDetailsFormOutletControl>

              <PersonalDetailsFormOutletControl>
                <TextField
                  type="text"
                  label="What is your last name?"
                  large
                  responsive
                  validation={{ dirty: errorField.lastName, invalid: errorField.lastName, message: 'Please enter your last name' }}
                  defaultValue={formValues.lastName}
                  onChange={event => {
                    const value = event.currentTarget.value;
                    setFormValues(currentState => ({ ...currentState, lastName: value }));
                    setErrorField(currentState => ({ ...currentState, lastName: !value }));
                  }}
                />
              </PersonalDetailsFormOutletControl>

              <PersonalDetailsFormOutletControl>
                <TextField
                  type="text"
                  label="What would you like us to call you?"
                  large
                  responsive
                  validation={{ dirty: errorField.preferredName, invalid: errorField.preferredName, message: 'Please enter your nickname' }}
                  defaultValue={formValues.preferredName}
                  onChange={event => {
                    const value = event.currentTarget.value;
                    setFormValues(currentState => ({ ...currentState, preferredName: value }));
                    setErrorField(currentState => ({ ...currentState, preferredName: !value }));
                  }}
                />
              </PersonalDetailsFormOutletControl>

              <PersonalDetailsFormOutletControl>
                <DropdownSearch
                  label="Where are you based?"
                  items={countryItems}
                  large
                  responsive
                  validation={{ invalid: errorField.countryId, dirty: errorField.countryId, message: 'Please select your country' }}
                />
              </PersonalDetailsFormOutletControl>
            </PersonalDetailsFormOutlet>
          </PersonalDetailsFormSection>
        )}

        {view === 'professional' && (
          <PersonalDetailsFormSection>
            <PersonalDetailsFormSectionDescription>
              <h1 className="heading1">Hello, {formValues.preferredName ?? user?.firstName}</h1>
              <PersonalDetailsNonTranslationBreakingSpacer />
            </PersonalDetailsFormSectionDescription>

            <PersonalDetailsFormOutlet>
              <PersonalDetailsFormOutletControl>
                <TextField
                  type="text"
                  label="What is your job title?"
                  large
                  responsive
                  validation={{ dirty: errorField.jobTitle, invalid: errorField.jobTitle, message: 'Please enter your job title' }}
                  defaultValue={formValues.jobTitle}
                  onChange={event => {
                    const value = event.currentTarget.value;
                    setFormValues(currentState => ({ ...currentState, jobTitle: value }));
                    setErrorField(currentState => ({ ...currentState, jobTitle: !value }));
                  }}
                />
              </PersonalDetailsFormOutletControl>

              <PersonalDetailsFormOutletControl>
                <div className="caption2" style={{ color: colourString('accent-3'), marginBottom: 10 }}>
                  How long have you been employed in your current role?
                </div>

                <PillButtonGroup
                  buttons={pillButtons}
                  layout={isMobile ? 'fill' : 'fit'}
                  backgroundColour="link-text"
                  activeTextColour="background"
                  onClick={buttons => {
                    setFormValues(currentState => ({ ...currentState, periodOfExperience: buttons[0].id }));
                    setErrorField(currentState => ({ ...currentState, periodOfExperience: false }));
                  }}
                />

                <div className={classNames('fieldValidation fieldErrorMessage', { hidden: !errorField.periodOfExperience })}>Please enter your experience</div>
              </PersonalDetailsFormOutletControl>
            </PersonalDetailsFormOutlet>
          </PersonalDetailsFormSection>
        )}
      </PersonalDetailsFormWrapper>

      <theme.BottomButtonWrapper>
        <theme.ButtonWrapperInner>
          <Buttons>
            {view === 'professional' ? (
              <Anchor arrow reverse block onClick={() => setView('personal')}>
                Back
              </Anchor>
            ) : (
              <div />
            )}

            <Button type="button" onClick={onClickNextHandler}>
              Next
            </Button>
          </Buttons>
        </theme.ButtonWrapperInner>
      </theme.BottomButtonWrapper>
    </PageLoaderLayer>
  );
}

