import { Context } from '../..';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { JobsResponse } from '../../../models';
import { JobDescription, JobsSearchRequest } from '../../../models/jobs';
import * as base from '../base';

export async function getJobTitles(_: Context, requestBuilder: JobsSearchRequest) {
  const jobTitleParams = requestBuilder.jobProvider.map(value => `jobProvider=${value}`).join('&');
  const params = new URLSearchParams(Object.entries(requestBuilder));
  params.delete('jobProvider');

  const request: base.IRequest = { url: base.url(`jobs?${params.toString()}&${jobTitleParams}`), authenticated: true };
  const response: base.IResponse<JobsResponse> = await base.request(request);

  return response.data;
}

export async function getJobTitle(context: Context, slug: string) {
  const request: base.IRequest = { url: base.url(`jobs`, slug), authenticated: true };
  const response: base.IResponse<JobDescription> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      type: QueueItemType.Error,
      priority: QueueItemPriority.Toast,
      title: 'Something went wrong',
      message: `Unfortunately we couldn't load the information for that role. Please try again later`,
      active: true,
      id: slug,
    });
  }

  return response.data;
}

