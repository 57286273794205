import React from 'react';
import { RatingScalePoint } from '../../../models/rating-scale';
import { RaterRadioButtonElement, RaterRadioButtonListItem } from './rater-radio-button.styles';
import { GraphColours, colourString } from '@keplerco/core';

export function RaterRadioButton(props: { colour: GraphColours | 'primary' | 'link-text'; details: { id: number; label: string; rating?: number }; point: RatingScalePoint; onChange: () => void; bgColor?: string }): JSX.Element {
  return (
    <RaterRadioButtonListItem colour={props.colour}>
      <input type="radio" id={props.details.id.toString()} name={props.details.label} value={props.point.rating} checked={props.details.rating !== undefined && props.details.rating === props.point.rating} onChange={props.onChange} />

      <RaterRadioButtonElement>
        <span>
          <svg fill="none" viewBox="0 0 23 17">
            <path
              fill={props.bgColor ?? colourString(props.colour === 'primary' || props.colour === 'grc_14' ? 'contrast-text' : 'black')}
              fillRule="evenodd"
              d="M18.32.831859 9.13194 10.2705 4.92845 5.92478c-.84151-.87039-2.40372-.87039-3.36199 0-.22797.22374-.40955.49331-.53364.79225-.124092.29895-.188093.62099-.188093.94644 0 .32546.064001.6475.188093.94644.12409.29895.30567.56852.53364.79226l5.88247 6.08643c.43468.4376 1.01672.6824 1.62261.6824s1.18796-.2448 1.62266-.6824l10.871-11.24198c.228-.22373.4096-.4933.5337-.79225.1241-.29894.1881-.62098.1881-.94644 0-.32545-.064-.64749-.1881-.94644-.1241-.29894-.3057-.568518-.5337-.792249-.8998-.807771-2.4017-.807772-3.2412.062618"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </RaterRadioButtonElement>

      <label htmlFor={props.details.id.toString()}>{props.point.label}</label>
    </RaterRadioButtonListItem>
  );
}
