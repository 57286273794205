import styled from 'styled-components';

export const AnalyticsReportTypeName = styled.h6`
  min-width: 30%;
  max-width: 30%;

  @media screen and (max-width: 930px) {
    width: 100%;
    max-width: 100%;
    min-width: unset;
  }
`;

export const AnalyticsReportTemplateHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 25px 0;
`;

export const AnalyticsReportTemplateHeaderButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const AnalyticsReportTemplateFooter = styled.footer`
  display: block;
  padding: 15px 0;
`;

export const FadedDataCell = styled.span`
  opacity: 0.75;
`;
