import { IconTwoToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function UploadTwoToneIcon(props: IconTwoToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;

  const primaryTone = colourString(props.primaryTone ?? 'primary');
  const secondaryTone = colourString(props.secondaryTone ?? 'secondary');

  return (
    <svg fill="none" viewBox="0 0 18 17" width={size} height={size}>
      <path
        fill={primaryTone}
        d="M17.4375 11.0515c-.1492 0-.2923.0592-.3977.1647-.1055.1055-.1648.2486-.1648.3978v2.8125c0 .2983-.1185.5845-.3295.7954-.211.211-.4971.3296-.7955.3296H2.25c-.29837 0-.58452-.1186-.79549-.3296-.21098-.2109-.32951-.4971-.32951-.7954V11.614c0-.1492-.05926-.2923-.164753-.3978-.105488-.1055-.248562-.1647-.397747-.1647s-.292259.0592-.397747.1647C.059263 11.3217 0 11.4648 0 11.614v2.8125c0 .5967.237053 1.169.65901 1.5909.42196.422.99425.6591 1.59099.6591h13.5c.5967 0 1.169-.2371 1.591-.6591.4219-.4219.659-.9942.659-1.5909V11.614c0-.1492-.0593-.2923-.1648-.3978-.1054-.1055-.2485-.1647-.3977-.1647Z"
      />
      <path
        fill={secondaryTone}
        stroke={secondaryTone}
        strokeWidth=".5"
        d="M9.39754 1.20328a.562555.562555 0 0 0-.18266-.1223.562465.562465 0 0 0-.21559-.04296.562465.562465 0 0 0-.21559.04296.562555.562555 0 0 0-.18266.1223l-3.375 3.375a.56322.56322 0 0 0-.16496.39825.56322.56322 0 0 0 .16496.39825.56322.56322 0 0 0 .39825.16496.56322.56322 0 0 0 .39825-.16496l2.41425-2.41537v9.89209c0 .1492.05926.2923.16475.3978.10549.1055.24856.1647.39775.1647.14918 0 .29226-.0592.39775-.1647.10549-.1055.16475-.2486.16475-.3978V2.95941L11.976 5.37478c.0523.0523.1144.09379.1828.12209.0683.0283.1415.04287.2155.04287.074 0 .1472-.01457.2155-.04287.0684-.0283.1304-.06979.1827-.12209a.563199.563199 0 0 0 .1221-.18272c.0283-.06833.0429-.14157.0429-.21553s-.0146-.1472-.0429-.21553a.563199.563199 0 0 0-.1221-.18272l-3.37496-3.375Z"
      />
    </svg>
  );
}

