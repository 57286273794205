import styled from 'styled-components';

export const CreateSkillAssessmentPageHeader = styled.div`
  padding-bottom: 40px;
`;

export const CreateSkillAssessmentPageHeaderWrapper = styled.div`
  padding-bottom: 20px;
`;

export const CreateSkillAssessmentFooter = styled.footer`
  text-align: right;
  margin-top: 25px;

  .card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
`;

