import styled from 'styled-components';

export const AnalyticsPageHeaderContainer = styled.div`
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid var(--borders);
`;

// TODO: update to row and column utility classes
export const AnalyticsGridSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;

  .start-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .middle-1 {
    grid-column-start: 2;
    grid-column-end: 3;
    background: red;
  }

  .end-1 {
    grid-column-start: 3;
    grid-column-end: 4;
    background: red;
  }

  .start-2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .end-2 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .full-span {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  .stretch {
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-flow: column nowrap;
    gap: 10px;
  }

  @media screen and (max-width: 580px) {
    display: flex;
    flex-flow: column nowrap;
  }
`;
