import React from 'react';
import {
  CaptureAdditionalLearningCardInner,
  CaptureAdditionalLearningCardImage,
  CaptureAdditionalLearningCardContent,
  CaptureAdditionalLearningCardStrip,
  CaptureAdditionalLearningCardAnchor,
  CaptureAdditionalLearningCardFooter,
  CaptureAdditionalLearningCardOuter,
} from './capture-additional-learning-card.styles';
import { AddTwoToneIcon } from '@keplerco/core';

export function CaptureAdditionalLearningCard(props: { onCancel?: () => void; onClick: () => void }): JSX.Element {
  return (
    <CaptureAdditionalLearningCardOuter onClick={props.onClick}>
      <CaptureAdditionalLearningCardInner>
        <CaptureAdditionalLearningCardStrip>
          <span className="caption1">Additional Learning</span>
        </CaptureAdditionalLearningCardStrip>

        <CaptureAdditionalLearningCardContent>
          <p className="body2">Have you completed any in-person sessions, webinars, talks or learning from places outside of this platform?</p>

          <CaptureAdditionalLearningCardImage>
            <AddTwoToneIcon primaryTone="link-text_2" secondaryTone="link-text_2" />
          </CaptureAdditionalLearningCardImage>

          <CaptureAdditionalLearningCardFooter>
            <CaptureAdditionalLearningCardAnchor>Capture Additional Learning</CaptureAdditionalLearningCardAnchor>
          </CaptureAdditionalLearningCardFooter>
        </CaptureAdditionalLearningCardContent>
      </CaptureAdditionalLearningCardInner>
    </CaptureAdditionalLearningCardOuter>
  );
}

