import { OrganizationLevelType } from '../enums';
import { PermissionType } from '../enums/permission-type';
import { Permissions, Permission, OrganizationLevel } from '../models/overmind/permissions';

export function extractPermission(permissions: Permissions | undefined, permissionType: PermissionType): Permission | undefined {
  if (!permissions) return undefined;

  switch (permissionType) {
    case PermissionType.Administration: {
      return permissions.administration;
    }

    case PermissionType.Analytics: {
      return permissions.analytics;
    }

    case PermissionType.Assessments: {
      return permissions.assessments;
    }

    case PermissionType.LearningManagement: {
      return permissions.learningManagement;
    }

    case PermissionType.Skills: {
      return permissions.skills;
    }

    case PermissionType.RoleManagement: {
      return permissions.roleManagement;
    }

    default: {
      return undefined;
    }
  }
}

export function extractOrganizationLevel(organizationLevels: OrganizationLevel[] | undefined, organizationLevelType: OrganizationLevelType): OrganizationLevel | undefined {
  if (!organizationLevels) return undefined;

  return organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === organizationLevelType);
}

export function extractHighestOrganizationLevel(organizationLevels: OrganizationLevel[] | undefined): OrganizationLevel | undefined {
  if (!organizationLevels) return undefined;

  let organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Company);
  if (!!organizationLevel) return organizationLevel;

  organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Department);
  if (!!organizationLevel) return organizationLevel;

  organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Team);
  if (!!organizationLevel) return organizationLevel;

  organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Learner);
  if (!!organizationLevel) return organizationLevel;

  return undefined;
}

