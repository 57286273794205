import React, { useEffect, useState } from 'react';
import { ViewRoleLayout } from './view-role.layout';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../overmind';
import { useParams } from 'react-router-dom';
import { CompanyEntityResponse } from '../../../../models/overmind/company-entity';
import { FetchType } from '../../../../enums';

export function CompanyRolePage(): JSX.Element {
  const { roleSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [role, setRole] = useState<CompanyEntityResponse>();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.roleManagementCompanyRole, type: FetchType.PageFetching });
      const response = await actions.getCompanyRole({ companySlug: companyVariables.slug!, roleSlug: roleSlug! });
      setRole(response);
      actions.stopLoader(PagePath.roleManagementCompanyRole);
    }

    getData();
  }, [roleSlug]);

  return (
    <ViewRoleLayout path={PagePath.roleManagementCompanyRole} role={role} />
  );
}