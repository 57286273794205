import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppActions } from '../../../../overmind';
import { FocusPanelLoaderLayer } from '../../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { TagHeaderColumn, TagManagementScrollContainer } from './update-tag-focus.panel.styles';
import { FetchType } from '../../../../enums';
import { TagAssigneeRequest, TagAssigneeResponse } from '../../../../models/tag';
import { UpdateTagFocusPanelSkeleton } from './update-tag-focus-panel.skeleton';
import { IUpdateTagFocusPanelProps } from './update-tag-focus-panel.models';
import { EmptyState } from '../../../../components/empty-state/empty-state';
import { tagTypeToString } from '../tag-management.cms.helpers';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Button, FocusPanelHeader, KebabMenu, ListItemLayout, ListLayout, Searchfield, colourString } from '@keplerco/core';

export function UpdateTagFocusPanelLayout(props: IUpdateTagFocusPanelProps): JSX.Element {
  const params = useParams();

  const actions = useAppActions();

  const [searchInput, setSearchInput] = useState('');
  const [assignees, setAssignees] = useState(props.assignees);
  const [filteredAssignees, setFilteredAssignees] = useState(assignees);

  useEffect(() => {
    setFilteredAssignees(assignees.filter(assignee => assignee.name?.toLowerCase().includes(searchInput.toLowerCase()) || tagTypeToString(assignee.tagType).toLowerCase().includes(searchInput.toLowerCase())));
  }, [searchInput, assignees]);

  async function onRemove(assignee: TagAssigneeResponse | undefined) {
    if (!assignee) return;

    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.Sending });

    const request: TagAssigneeRequest[] = [
      {
        entitySlug: assignee.entitySlug ?? '',
        tagType: assignee.tagType,
      },
    ];
    await actions.removeAssignees({ assignees: request, companySlug: params.companySlug!, tagName: props.tagName });
    setAssignees(currentState => {
      let nextState: TagAssigneeResponse[] = structuredClone(currentState);
      nextState = nextState.filter(temp => temp.entitySlug !== assignee.entitySlug);
      return nextState;
    });

    actions.stopLoader(PagePath.administrationTags);
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationTags} skeletonLoader={<UpdateTagFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout" style={{ borderBottom: '1px solid var(--borders)', paddingBottom: 30, marginBottom: 30 }}>
          <FocusPanelHeader
            supertitle={props.tagName}
            title="Assigned To"
            type="actions"
          >
            <div className="headerActions">
              <Searchfield onInput={setSearchInput} />
            </div>
          </FocusPanelHeader>
        </header>

        <div className="dialogBodyLayout">
          {filteredAssignees.length > 0 && (
            <React.Fragment>
              <div className="card" style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <TagHeaderColumn>
                  <span className="caption1" style={{ color: colourString('text_1') }}>
                    [Name]
                  </span>
                </TagHeaderColumn>

                <TagHeaderColumn>
                  <span className="caption1" style={{ color: colourString('text_1') }}>
                    [Type]
                  </span>
                </TagHeaderColumn>
              </div>

              <TagManagementScrollContainer>
                <ListLayout>
                  {filteredAssignees.map(assignee => (
                    <ListItemLayout key={assignee.entitySlug}>
                      <div className="card">
                        <div className="toggleCardHorizontalContentLayout" style={{ gridAutoColumns: 'auto 1fr 1fr' }}>
                          <div className="toggleCardIcon" />

                          <span className="caption1" style={{ color: colourString('text_1') }}>
                            {assignee.name}
                          </span>

                          <span className="caption1" style={{ color: colourString('text_1') }}>
                            {tagTypeToString(assignee.tagType)}
                          </span>

                          <KebabMenu items={[{
                            label: 'Remove',
                            onClick: () => onRemove(assignee),
                          }]} />
                        </div>
                      </div>
                    </ListItemLayout>
                  ))}
                </ListLayout>
              </TagManagementScrollContainer>
            </React.Fragment>
          )}

          {filteredAssignees.length === 0 && <EmptyState title="No assignees found" />}
        </div>

        <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
          <Anchor onClick={props.onBack}>Back</Anchor>

          <Button type="button" filled arrow={false} onClick={props.onDone}>
            Done
          </Button>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}

