import React from 'react';
import { IRoutingMapProps } from '../../../models/routing-map';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppState } from '../../../overmind';
import { AssessmentType } from '../../../enums/assessment-type';
import { Navigate } from 'react-router-dom';

export function QuestionnairesLandingRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfig } = useAppState();

  if (skillAssessmentConfig?.assessmentType !== AssessmentType.Questionnaire) return <Navigate to={PagePath.analysisBase} />;

  return <React.Fragment>{props.children({ next: PagePath.analysisBase, previous: PagePath.analysisBase })}</React.Fragment>;
}

export function QuestionnairesRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfig } = useAppState();

  if (skillAssessmentConfig?.assessmentType !== AssessmentType.Questionnaire) return <Navigate to={PagePath.analysisBase} />;

  const next = PagePath.yourSkillsBase;
  const previous = `${PagePath.analysisBase}${PagePath.analysisBehavioural}`;

  return <React.Fragment>{props.children({ next, previous })}</React.Fragment>;
}
