import React from 'react';
import { ISideNavRoute } from '../../side-nav.models';
import classNames from 'classnames';
import { keplerActive } from '../../../navigation.helpers';
import navStyles from '../../../navigation.module.css';
import sideNavStyles from '../../side-nav.module.css';
import sideNavDialogStyles from '../side-nav-dialog.module.css';
import sideNavDialogRouteListStyles from './side-nav-dialog-route-list.module.css';
import KeplerNavlink from '../../../guards/kepler-navlink';
import { useAppState } from '../../../../overmind';
import { kebabCase } from '@keplerco/core';

export function SideNavDialogRouteList(props: { sideNavParentRoutes: ISideNavRoute[], pathname: string, onClick: () => void }): JSX.Element {
  const { mode } = useAppState();

  const { sideNavParentRoutes, pathname, onClick } = props;

  return (
    <ul className={navStyles.navList}>
      {sideNavParentRoutes.map(sideNavParentRoute => (
        <li key={sideNavParentRoute.path} id={`${mode}_${kebabCase(sideNavParentRoute.title)}`}>
          <KeplerNavlink
            to={sideNavParentRoute.path}
            className={classNames(
              sideNavDialogStyles.sideNavDialogParentRoute,
              { [sideNavDialogStyles.keplerActive]: keplerActive(!!sideNavParentRoute.exactMatch, sideNavParentRoute.path, pathname) },
            )}
            onClick={onClick}
          >
            <img className={navStyles.navIcon} src={sideNavParentRoute.icon} />
            <div className={sideNavStyles.sideNavParentRouteTitle}>{sideNavParentRoute.title}</div>
          </KeplerNavlink>

          {!!sideNavParentRoute.children && pathname.includes(sideNavParentRoute.path) && (
            <ul
              className={classNames(
                navStyles.navList,
                sideNavDialogRouteListStyles.sideNavDialogChildRouteList,
                { active: pathname.includes(sideNavParentRoute.path) }
              )}
            >
              {sideNavParentRoute.children.map(sideNavChildRoute => (
                <li key={sideNavChildRoute.path} id={`${mode}_${kebabCase(sideNavChildRoute.title)}`} className={sideNavStyles.sideNavChildRouteListItem}>
                  <KeplerNavlink
                    to={sideNavChildRoute.path}
                    className={classNames(
                      sideNavStyles.sideNavChildRoute,
                      { [sideNavStyles.keplerActive]: keplerActive(!!sideNavChildRoute.exactMatch, sideNavChildRoute.path, pathname) }
                    )}
                    onClick={onClick}
                  >
                    {sideNavChildRoute.title}
                  </KeplerNavlink>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}