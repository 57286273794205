import React, { useState } from 'react';
import { AssignedEntitiesContainer } from './assign-entities.styles';
import { UniqueEntity } from '../../../../../models';
import classNames from 'classnames';
import { IAssignEntitiesProps } from './assign-entities.models';
import { Autocomplete, Chip, ChipWrapper, colourString } from '@keplerco/core';

export function AssignEntities(props: IAssignEntitiesProps): JSX.Element {
  const [availableEntities, setAvailableEntities] = useState<UniqueEntity[]>();

  return (
    <React.Fragment>
      <p className="body1" style={{ paddingBottom: 10 }}>
        Assign to {props.type}:{' '}
      </p>

      <Autocomplete
        name={props.type}
        label={`Search for ${props.type}`}
        zIndex={100}
        responsive
        onSearch={query => {
          if (!query) return void setAvailableEntities(undefined);

          const lowercaseQuery = query.toLowerCase();
          setAvailableEntities(props.entities?.filter(entity => entity.name.toLowerCase().includes(lowercaseQuery) || entity.slug?.toLowerCase().includes(lowercaseQuery)) ?? []);
        }}
      >
        {!!availableEntities &&
          (() => {
            if (availableEntities.length === 0)
              return (
                <h4 className="heading4" style={{ padding: '0 15px', color: colourString('default') }}>
                  no results
                </h4>
              );

            return (
              <div className="autocompleteDropdownList">
                {availableEntities.map(entity => {
                  const index = props.selectedEntities.findIndex(selectedEntity => selectedEntity.slug === entity.slug);

                  return (
                    <div
                      key={entity.slug}
                      className={classNames('autocompleteDropdownListItem', { selected: index !== -1 })}
                      onClick={() => {
                        const tempEntities = structuredClone(props.selectedEntities);
                        index !== -1 ? tempEntities.splice(index, 1) : tempEntities.push(entity);
                        props.onSelect(tempEntities);
                      }}
                    >
                      <div className="autocompleteDropdownListItemCheck">
                        <span />
                      </div>

                      <div className="autocompleteDropdownListItemLabel">{entity.name}</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </Autocomplete>

      {!!props.selectedEntities && props.selectedEntities.length > 0 && (
        <AssignedEntitiesContainer>
          <ChipWrapper>
            {props.selectedEntities.map(entity => (
              <Chip key={entity.slug} label={entity.name} backgroundColour="transparent" borderColour="grc_3" colour="text" />
            ))}
          </ChipWrapper>
        </AssignedEntitiesContainer>
      )}
    </React.Fragment>
  );
}

