import React from 'react';
import { Questionnaire } from '../../../models/questionnaire';
import { QuestionnaireCardWrapper, QuestionnaireCardDescription, QuestionnaireCardDescriptionInner, QuestionnaireCardActions, QuestionnaireCardActionsInner, QuestionnaireCardProgressBarWrapper, QuestionnaireCardProgressBar } from './behavioral.styles';
import { Chip, ChipWrapper, colourString, kebabCase } from '@keplerco/core';

export function QuestionnaireCard(props: Questionnaire & { index: number }): JSX.Element {
  const isComplete = props.totalAnsweredQuestions >= props.totalQuestions;
  const hasEarned = props.keplerPointsEarned > 0;

  return (
    <QuestionnaireCardWrapper className="card">
      <QuestionnaireCardDescription>
        <QuestionnaireCardDescriptionInner>
          <ChipWrapper gap={5}>
            <Chip label={`${props.totalQuestions} Skills`} variant="tiny" colour="text" />

            {!isComplete && hasEarned && (
              <Chip label="In Progress" variant="tiny" backgroundColour="light" colour="contrast-text" />
            )}

            {isComplete && (
              <Chip label="Completed" variant="tiny" backgroundColour={'apple'} />
            )}
          </ChipWrapper>

          <h3 className="heading3" id={`${kebabCase(props.skillSubTypeName)}-questionnaire`} style={{ paddingTop: 5 }}>
            {props.skillSubTypeName ?? `Questionnaire #${props.index + 1}`}
          </h3>
          <span className="caption1" style={{ color: colourString('text') }}>
            {props.skillSubTypeDescription}
          </span>
        </QuestionnaireCardDescriptionInner>
      </QuestionnaireCardDescription>

      <QuestionnaireCardActions>
        <QuestionnaireCardActionsInner>
          {isComplete ? (
            <svg fill="none" viewBox="0 0 23 24">
              <path
                fill="var(--secondary)"
                fillRule="evenodd"
                d="M.40332 12.3647C.40332 6.12087 5.29419.996582 11.4033.996582c6.1092 0 11 5.124288 11 11.368118 0 6.2437-4.8908 11.368-11 11.368-6.10911 0-10.99998-5.1243-10.99998-11.368Zm8.98071 1.3152 6.85607-7.04299h.003c.6264-.64948 1.7471-.64948 2.4185-.04673.1702.16695.3056.3681.3982.59117.0926.22307.1404.46338.1404.70623s-.0478.48315-.1404.70622c-.0926.22307-.228.42423-.3982.59117l-8.1119 8.38863c-.3243.3266-.75863.5092-1.21075.5092-.45209 0-.88641-.1826-1.21076-.5092L3.73875 13.032c-.17011-.167-.3056-.3681-.3982-.5912-.0926-.2231-.14035-.4634-.14035-.7062 0-.2429.04775-.4832.14035-.7062.0926-.2231.22809-.4243.3982-.5912.71505-.64948 1.88075-.64948 2.50868 0l3.1366 3.2427Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <span />
          )}
        </QuestionnaireCardActionsInner>
      </QuestionnaireCardActions>

      <QuestionnaireCardProgressBarWrapper>
        <QuestionnaireCardProgressBar value={props.totalAnsweredQuestions} max={props.totalQuestions} />
      </QuestionnaireCardProgressBarWrapper>
    </QuestionnaireCardWrapper>
  );
}

