import React, { useEffect, useReducer, useState } from 'react';
import { SortField } from '../../../enums/sort-field';
import { FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { CourseMappingStatus } from '../../../enums/course-mapping-status';
import * as store from './course-mapping.store';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { PagerNavigationConnector } from '../../../components/pager-connector/pager-connector';
import { SkeletonLoader } from '../../../components';
import { CourseMappingName, LearningManagementActions } from '../learning-management.styles';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { CourseMappingsListItem, CourseMappingsSearchParams } from '../../../models/overmind/learning-management';
import { setFilterItems, clearFilterItems, courseMappingStatuses, learningPlatformTypes } from '../learning-management.helpers';
import { LearningManagementSkeleton } from '../learning-management.skeleton';
import { Chip, MenuItem, DropdownSelectItem, PageHeader, Searchfield, Filters, DropdownSelect, Table, Pager, TableRow, KebabMenu, TableColumn } from '@keplerco/core';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { CMSTableLink } from '../../../theme/layout.styles';

export function CourseMappingPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const keplerNavigate = useKeplerNavigate();

  async function fetchData(searchParams: CourseMappingsSearchParams) {
    dispatch({ type: store.CourseMappingActionTypes.SetLoading, payload: true });

    const data = await actions.getCourseMappings(searchParams);
    dispatch({ type: store.CourseMappingActionTypes.SetData, payload: data });

    dispatch({ type: store.CourseMappingActionTypes.SetLoading, payload: false });
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.learningManagementCourseMapping, type: FetchType.PageFetching });

      if (!permissions) return;

      const company = await actions.getCompany(companyVariables.slug!);
      dispatch({ type: store.CourseMappingActionTypes.SetHeader, payload: { entityName: company?.companyName, companySlug: companyVariables.slug! } });

      const organizationLevel = extractHighestOrganizationLevel(permissions?.learningManagement.organizationLevels);
      const request: CourseMappingsSearchParams = { ...state.searchParams, organizationLevel: organizationLevel?.organizationLevel, companySlug: companyVariables.slug };
      dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: request });

      await fetchData(request);

      actions.stopLoader(PagePath.learningManagementCourseMapping);
    }

    if (!!permissions) initPage();
  }, [permissions]);

  useEffect(() => {
    if (!state.data) return;
    fetchData(state.searchParams);
  }, [state.searchParams]);

  async function onInputHandler(value: string) {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, search: value, page: 1 } });
  }

  async function onSortHandler(sortField: SortField, sortAscending: boolean) {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, sortField, sortAscending } });
  }

  function generateStatusChip(mapping: CourseMappingsListItem): JSX.Element {
    if (mapping.learningObjectMappingStatus === CourseMappingStatus.Mapped) return <Chip label="Mapped" backgroundColour="g" variant="tiny" />;

    return <Chip label="Not mapped" variant="tiny" />;
  }

  function onPageChangeHandler(page: number) {
    if (page === state.searchParams?.page) return;
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, page } });
  }

  function generateKebabMenuItems(learningObject: CourseMappingsListItem): MenuItem[] {
    return [{
      label: learningObject.learningObjectMappingStatus === CourseMappingStatus.Mapped ? 'Edit mapping' : 'Create mapping',
      onClick: () => keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMappingCourse.replace(':companySlug', companyVariables.slug!).replace(':courseSlug', learningObject.slug)}`),
    }];
  }

  // filters
  function applyCourseMappingStatusItem(value: string) {
    setStatusItems(currentState => setFilterItems(currentState, value));
  }
  function applyLearningPlatformTypeItem(value: string) {
    setLearningPlatformItems(currentState => setFilterItems(currentState, value));
  }

  const [courseMappingStatusItems, setStatusItems] = useState<DropdownSelectItem[]>(courseMappingStatuses?.map(item => ({ ...item, onClick: () => applyCourseMappingStatusItem(item.value) })) ?? []);
  const [learningPlatformItems, setLearningPlatformItems] = useState<DropdownSelectItem[]>(learningPlatformTypes?.map(item => ({ ...item, onClick: () => applyLearningPlatformTypeItem(item.value) })) ?? []);

  function applyFilters() {
    const courseMappingStatus = courseMappingStatusItems.find(item => item.selected)?.value;
    const learningPlatformType = learningPlatformItems.find(item => item.selected)?.value;
    dispatch({
      type: store.CourseMappingActionTypes.SetRequest,
      payload: {
        ...state.searchParams,
        page: 1,
        courseMappingStatus: !courseMappingStatus ? undefined : parseInt(courseMappingStatus),
        learningPlatformType: !learningPlatformType ? undefined : parseInt(learningPlatformType),
      },
    });
  }

  function clearFilters() {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, page: 1, courseMappingStatus: undefined, learningPlatformType: undefined } });
    setStatusItems(currentState => clearFilterItems(currentState));
    setLearningPlatformItems(currentState => clearFilterItems(currentState));
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningManagementCourseMapping} skeletonLoader={<LearningManagementSkeleton />}>
        <div className="wrapper stack">
          <PageHeader
            breadcrumbs={state.crumbs}
            title={state.pageTitle}
            subtitle="Control which courses are mapped to each skill"
            divider
          />

          <LearningManagementActions>
            <Searchfield loading={!!state.searchParams.search && state.loading} onInput={onInputHandler} />

            {/* TODO */}
            {/* <SyncContentCard
              isSyncing={state.syncing}
              variant="compact"
              lastSyncDate={lastSynced}
              onClick={async () => {
                dispatch({ type: store.EntityMappingActionTypes.SetSyncing, payload: true });
                await actions.sync();
                dispatch({ type: store.EntityMappingActionTypes.SetRequest, payload: { ...state.searchParams } });
                dispatch({ type: store.EntityMappingActionTypes.SetSyncing, payload: false });
              }}
            /> */}

            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              <DropdownSelect id="status" name="Status" label="Status" items={courseMappingStatusItems} />

              <DropdownSelect id="learningPlatform" name="Learning Platform" label="Learning Platform" items={learningPlatformItems} />
            </Filters>
          </LearningManagementActions>

          {!!state.data && state.loading && <SkeletonLoader height="460px" />}

          {!state.loading && (
            <Table
              onSort={() => onSortHandler(SortField.Name, !state.searchParams.sortAscending)}
              currentSortDirection={state.searchParams.sortAscending ? 'Ascending' : 'Descending'}
              currentSortBy="Name"
              standAloneConfigColumn
              emptyState={<EmptyState title="No course mappings found" />}
              footerContent={
                <PagerNavigationConnector onPageChange={onPageChangeHandler} defaultPageNumber={state.searchParams?.page} pageCount={state.data?.totalPages ?? 0}>
                  {connector => {
                    return <Pager {...connector} />;
                  }}
                </PagerNavigationConnector>
              }
            >
              {state.data?.learningObjectList.map(course => (
                <TableRow key={course.slug} id={course.slug} configCell={() => <KebabMenu items={generateKebabMenuItems(course)} />}>
                  <TableColumn sortable id="Name" label="Name">
                    <CMSTableLink onClick={event => event.stopPropagation()} to={`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMappingCourse.replace(':companySlug', companyVariables.slug!).replace(':courseSlug', course.slug)}`}>
                      <CourseMappingName>{course.name}</CourseMappingName>
                    </CMSTableLink>
                  </TableColumn>

                  <TableColumn id="contains" label="Contains">
                    {course.activityCount} activities
                  </TableColumn>

                  <TableColumn id="status" label="Status">
                    {generateStatusChip(course)}
                  </TableColumn>
                </TableRow>
              ))}
            </Table>
          )}
        </div>
      </PageLoaderLayer>
    </React.Fragment>
  );
}
