import styled from 'styled-components';

export const SkillGapReportBreakdownList = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding-top: 30px;
  margin: 0;

  li .card {
    border-radius: 0;
    border-left: none;
    color: var(--text);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  li:first-child .card {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-left: 1px solid var(--borders);
  }

  li:last-child .card {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const SkillGapReportBreakdownListItem = styled.li`
  font-size: 14px;
  font-family: var(--body);
  padding-bottom: 10px;
  color: var(--text_1);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

