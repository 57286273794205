import React from 'react';
import { LoaderBackground } from './loader.styles';
import { LoaderIcon } from '@keplerco/core';

export function Loader(): JSX.Element {
  return (
    <LoaderBackground>
      <LoaderIcon />
    </LoaderBackground>
  );
}

