import { IconRotatableProps, colourString } from '@keplerco/core';
import React from 'react';

export function ChevronIcon(props: IconRotatableProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');
  const rotation = props.rotation ?? 1;
  const rotationDegrees = (rotation - 1) * 90;

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 10 14" style={{ transform: `rotate(${rotationDegrees}deg)` }}>
      <path stroke={tone} strokeLinecap="round" strokeWidth="3" d="m2.35156 1.78198 5.00963 5L2.35156 11.782" />
    </svg>
  );
}

export function DoubleChevronIcon(props: IconRotatableProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');
  const rotation = props.rotation ?? 1;
  const rotationDegrees = (rotation - 1) * 90;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 15 15" fill="none" style={{ transform: `rotate(${rotationDegrees}deg)` }}>
      <path
        d="M12.7707 11.664C13.0763 11.9696 13.0763 12.4652 12.7707 12.7708C12.4651 13.0764 11.9695 13.0764 11.6639 12.7708L6.44651 7.5534C6.14088 7.2478 6.14088 6.7522 6.44651 6.4466L11.6639 1.2292C11.9695 0.9236 12.4651 0.9236 12.7707 1.2292C13.0763 1.5348 13.0763 2.0304 12.7707 2.336L8.1067 7L12.7707 11.664Z"
        fill={tone}
      />
      <path
        d="M7.7707 11.664C8.0763 11.9696 8.0763 12.4652 7.7707 12.7708C7.4651 13.0764 6.96953 13.0764 6.6639 12.7708L1.44651 7.5534C1.14088 7.2478 1.14088 6.7522 1.44651 6.4466L6.6639 1.2292C6.96953 0.9236 7.4651 0.9236 7.7707 1.2292C8.0763 1.5348 8.0763 2.0304 7.7707 2.336L3.10668 7L7.7707 11.664Z"
        fill={tone}
      />
    </svg>
  );
}

