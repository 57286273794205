import { Context } from '../..';

export function handleErrorToErrorPage(context: Context, path: string, returnTo?: string): void {
  const isOnErrorPage = window.location.pathname === path;

  if (!isOnErrorPage) {
    let to = path;
    if (!!returnTo) to += `?returnTo=${returnTo}`;
    window.location.href = to;
  }
}