import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownSearch, ListItemLayout, ListLayout, capitalCase } from '@keplerco/core';
import { MappingType, OrganizationLevelType } from '../../../../../enums';
import { UniqueEntity } from '../../../../../models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ICourseMappingCourseConditionProps } from '../course-mapping-course.models';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../lib/permissions.helpers';
import { EntitySelectionListItem } from '../../../../../components/entity-selection/entity-selection-list-item/entity-selection-list-item';
import styles from './course-mapping-course-conditions.module.css';
import { EntitiesCard } from '../../../../../components/entities-card.widget';
import { MAJOR_LANGUAGES } from '../../../../../lib/languages';

function getMappingTypeString(type: MappingType): string {
  switch (type) {
    case MappingType.Language: {
      return 'language';
    }

    case MappingType.Team: {
      return 'team';
    }

    case MappingType.Department: {
      return 'department';
    }

    case MappingType.Role: {
      return 'role';
    }

    default: {
      return 'entity';
    }
  }
}

export function CourseMappingCourseCondition({ type, entities, onClickAddEntity, onClickRemoveEntity }: ICourseMappingCourseConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  function onClickEntityItem(selected: boolean | undefined, entity: UniqueEntity) {
    selected ? onClickRemoveEntity(type, entity.slug!) : onClickAddEntity(type, entity);
  }

  const [entityItems, setEntityItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function fetchEntityItems() {
      switch (type) {
        case MappingType.Role: {
          const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

          const rolesRequest: CompanyEntitySearchParams = {
            organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
            companySlug: companyVariables.slug,
            departmentSlug: undefined,
            teamSlug: undefined,
            learnerSlug: undefined,
            searchGlobally: false,
            page: 1,
            pageSize: 99999, // ridiculously large pageSize to fetch ALL roles
            search: undefined,
            sortAscending: undefined,
          };

          const roles = await actions.getCompanyRoles(rolesRequest);
          setEntityItems(
            roles?.entities.map(role => {
              const selected = entities?.some(entity => entity.slug === role.slug);
              return {
                value: role.slug,
                label: role.name,
                onClick: () => onClickEntityItem(selected, { slug: role.slug, name: role.name }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }
        case MappingType.Department: {
          const departments = await actions.getLearningManagementDepartments(companyVariables.slug!);
          setEntityItems(
            departments?.map(department => {
              const selected = entities?.some(entity => entity.slug === department.slug);
              return {
                value: department.slug,
                label: department.name,
                onClick: () => onClickEntityItem(selected, { slug: department.slug, name: department.name }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }

        case MappingType.Team: {
          const teams = await actions.getLearningManagementTeams(companyVariables.slug!);
          setEntityItems(
            teams?.map(team => {
              const selected = entities?.some(entity => entity.slug === team.slug);
              return {
                value: team.slug,
                label: team.teamName,
                onClick: () => onClickEntityItem(selected, { slug: team.slug, name: team.teamName }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }

        case MappingType.Language: {
          setEntityItems(
            MAJOR_LANGUAGES.map(language => {
              const selected = entities?.some(entity => entity.slug === language);
              return { value: language, onClick: () => onClickEntityItem(selected, { slug: language, name: language }), selected } as DropdownItem;
            })
          );
          break;
        }

        default: {
          break;
        }
      }
    }

    fetchEntityItems();
  }, [type, entities]);

  return (
    <EntitiesCard title={`${capitalCase(getMappingTypeString(type))}s`}>
      <DropdownSearch
        label={`Assign ${getMappingTypeString(type)}s`}
        items={entityItems}
        responsive
        multiple
        dialogContained
      />

      {!!entities?.length && (
        <div className={styles.scrollContainer}>
          <ListLayout>
            {entities?.map(entity => (
              <ListItemLayout key={entity.slug}>
                <EntitySelectionListItem
                  title={entity.name}
                  menuItems={[{
                    label: 'Remove',
                    onClick: () => onClickRemoveEntity(type, entity.slug!),
                  }]}
                />
              </ListItemLayout>
            ))}
          </ListLayout>
        </div>
      )}
    </EntitiesCard>
  );
}
