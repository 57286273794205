import { Context } from '../..';
import { ReportType } from '../../../models/overmind/report-type';
import * as base from '../base';
import { UserStatusReport } from '../../../models/user';
import { QueueItemPriority, QueueItemType } from '../../../components/notification-hub/notification-hub.models';
import { KeyValue } from '../../../models';

export async function downloadReport(context: Context, reportTypeId: number): Promise<string | undefined> {
  const request: base.IRequest = { url: base.url(`report`, `download-report`, reportTypeId.toString()), authenticated: true };
  const response: base.IResponse<string> = await base.request(request);

  return response.data;
}

export async function getAdministrationReportTemplates(context: Context, companySlug: string): Promise<ReportType[] | undefined> {
  const url = base.url(`reporttemplate`, `report-templates`, companySlug);

  const request: base.IRequest = { url: url, authenticated: true };
  const response: base.IResponse<ReportType[]> = await base.request(request);

  return response.data;
}

export async function getAnalyticsReportTemplates(context: Context, companySlug: string): Promise<ReportType[] | undefined> {
  const url = base.url(`report`, `report-templates`, companySlug);

  const request: base.IRequest = { url: url, authenticated: true };
  const response: base.IResponse<ReportType[]> = await base.request(request);

  return response.data;
}

export async function addReportTemplate(context: Context, payload: { companySlug: string; reportType: string }): Promise<base.IResponse<ReportType> | undefined> {
  const url = base.url(`reporttemplate`, `company-report-template`, payload.companySlug, payload.reportType);
  const request: base.IRequest = { url: url, method: 'POST', authenticated: true };
  const response: base.IResponse<ReportType> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      id: payload.reportType,
      type: QueueItemType.Error,
      priority: QueueItemPriority.Toast,
      title: '',
      message: `${response.error.type}. Please contact support`,
      active: true,
    });
  }

  return response;
}

export async function removeReportTemplate(context: Context, payload: { companySlug: string; reportType: string }): Promise<base.IResponse<ReportType> | undefined> {
  const url = base.url(`reporttemplate`, `company-report-template`, payload.companySlug, payload.reportType);
  const request: base.IRequest = { url: url, method: 'DELETE', authenticated: true };
  const response: base.IResponse<ReportType> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      id: payload.reportType,
      type: QueueItemType.Error,
      priority: QueueItemPriority.Toast,
      title: '',
      message: `${response.error.type}. Please contact support`,
      active: true,
    });
  }

  return response;
}

export async function editReportTemplate(context: Context, payload: { companySlug: string; reportType: string; body: { name: string; description: string } }) {
  const url = base.url(`reporttemplate`, `company-report-template`, payload.companySlug, payload.reportType);
  const request: base.IRequest = { url: url, method: 'PUT', body: JSON.stringify(payload.body), authenticated: true };
  const response: base.IResponse<ReportType> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      id: payload.reportType,
      type: QueueItemType.Error,
      priority: QueueItemPriority.Toast,
      title: '',
      message: `${response.error.type}. Please contact support`,
      active: true,
    });
  } else {
    context.actions.addNotification({
      id: Date.now().toString(),
      message: 'Report details updated successfully',
      title: '',
      type: QueueItemType.Success,
      priority: QueueItemPriority.Toast,
      active: true,
    });
  }
}

export async function userStatusReport(_: Context, companySlug: string): Promise<UserStatusReport[] | undefined> {
  const request: base.IRequest = { url: base.url(`report`, `view-report`, `user-status?companySlug=${companySlug}`), authenticated: true };
  const response: base.IResponse<UserStatusReport[]> = await base.request(request);

  return response.data;
}

export async function getPDFReports(): Promise<KeyValue[] | undefined> {
  const request: base.IRequest = { url: base.url('pdfreport', 'pdf-list'), authenticated: true };
  const response: base.IResponse<KeyValue[]> = await base.request(request);

  return response.data;
}
