import React from 'react';
import classNames from 'classnames';
import { PersonCardContainer, PersonCardContent, PersonCardAvatarIcon, PersonCardNavlink, PersonCardDepartment, PersonCardRole, PersonCardName } from './person-card.styles';
import { PagePath } from '../../../../navigation/navigation.enums';
import { IPersonCardProps } from './person-card.models';
import { AvatarIcon } from '@keplerco/core';

export function PersonCard(props: IPersonCardProps): JSX.Element {
  return (
    <PersonCardNavlink to={`${PagePath.analyticsBase}${PagePath.analyticsPerson.replace(':companySlug', props.companySlug).replace(':personSlug', props.slug)}`}>
      <PersonCardContainer className="card">
        <PersonCardAvatarIcon>
          <AvatarIcon name={{ firstName: props.name.split(' ')[0], lastName: props.name.split(' ')[1] }} />
        </PersonCardAvatarIcon>

        <PersonCardContent className={classNames(props.viewType)}>
          <PersonCardName className="body1">{props.name}</PersonCardName>

          <PersonCardRole className="body2">{props.role}</PersonCardRole>

          <PersonCardDepartment className="body2">{props.department}</PersonCardDepartment>
        </PersonCardContent>
      </PersonCardContainer>
    </PersonCardNavlink>
  );
}

