import styled from 'styled-components';

export const AdditionalLearningCardHeader = styled.figure`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  height: 130px;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid var(--borders);
  padding: 15px;

  svg,
  img {
    width: auto;
    height: 100%;
  }
`;

export const AdditionalLearningCardBody = styled.div`
  height: 100%;
  padding: 15px;

  display: flex;
  flex-direction: column;

  .caption1 {
    margin-top: auto;
  }
`;

export const AdditionalLearningCardFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 50px;
  width: 100%;

  border-top: 1px solid var(--borders);
`;

export const AdditionalLearningCardWrapper = styled.span`
  position: relative;

  display: block;

  border: solid 1px var(--borders);
  border-radius: 8px;
  padding-top: 130px;
  padding-bottom: 50px;

  background: var(--cards);
  box-shadow: 0px 4px 8px var(--outer-shadow-colour);

  overflow: hidden;
`;
