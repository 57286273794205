import { Context } from '../..';
import * as base from '../base';
import { CareerPath } from '../../../models/view/career-path';
import { ErrorSignalTypes } from '../../../enums';
import { handleErrorToErrorPage } from '../functions';

export async function getCareerPaths(context: Context): Promise<CareerPath[] | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`careerpath?assessmentSlug=${context.state.skillAssessmentConfig?.assessmentSlug}`, ), authenticated: true };
  const response: base.IResponse<CareerPath[]> = await base.request(request);

  return response.data;
}

export async function getCompanyCareerPaths(context: Context, companySlug: string): Promise<CareerPath[] | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `platform?companySlug=${companySlug}`, ), authenticated: true };
  const response: base.IResponse<CareerPath[]> = await base.request(request);

  return response.data;
}
