import React from 'react';
import { useAppState } from '../../../../overmind';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { LandingPageContent, LandingPageContentCard, LandingPageContentCardsWrapper, LandingPageImage, LandingPageWrapper } from '../landing.page.styles';
import classNames from 'classnames';
import { CompletionStatus } from '../../../../enums/completion-status';
import { TechnicalSkillsChips, technicalSkillStatus } from './analysis-landing.page.helpers';
import { PagePath } from '../../../../navigation/navigation.enums';
import { AssessmentType } from '../../../../enums/assessment-type';
import { getSubdomain } from '../../../../lib/get-subdomain';
import { Chip, ChipWrapper } from '@keplerco/core';

export function AnalysisLandingPage(): JSX.Element {
  const subdomain = getSubdomain();
  const { skillAssessmentConfig } = useAppState();

  const selfReviewStatus = skillAssessmentConfig?.selfReviewCompletionStatus;
  const behavioralStatus = skillAssessmentConfig?.behavioralCompletionStatus;
  const peerReviewStatus = skillAssessmentConfig?.selfPeerReviewCompletionStatus;
  const careerPathSelectionStatus = skillAssessmentConfig?.careerPathSelectionCompletionStatus;
  const careerPathsStatus = skillAssessmentConfig?.careerPathCompletionStatus;

  function PeerEndorsementCard() {
    const linkTo =
      skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired
        ? `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`
        : selfReviewStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed
          ? `${PagePath.analysisBase}`
          : `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`;

    return (
      <LandingPageContentCard to={linkTo} borderColour="blue" className={classNames({ done: selfReviewStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed })}>
        <ChipWrapper>
          <TechnicalSkillsChips hasPeerReview={true} peerReviewStatus={skillAssessmentConfig?.selfPeerReviewCompletionStatus} technicalStatus={selfReviewStatus} />
        </ChipWrapper>
        <h3 className="heading3">Role-based skills analysis</h3>
        <span className="caption2">{technicalSkillStatus(selfReviewStatus, true, peerReviewStatus)}</span>
      </LandingPageContentCard>
    );
  }

  function QuestionnaireCard() {
    return (
      <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisBehavioural}`} borderColour="grape" className={classNames({ done: behavioralStatus === CompletionStatus.Completed })}>
        <ChipWrapper>
          {behavioralStatus === CompletionStatus.NotStarted && <Chip label="Not Started" variant="tiny" backgroundColour="secondary" colour="background" />}
          {behavioralStatus === CompletionStatus.InProgress && <Chip label="In Progress" variant="tiny" backgroundColour="borders" colour="text" />}
          {behavioralStatus === CompletionStatus.Completed && <Chip label="Complete" variant="tiny" backgroundColour="apple" colour="contrast-text" />}
        </ChipWrapper>
        <h3 className="heading3">Behavioural skills analysis</h3>
        <span className="caption2">These skills determine how well you interact with others.</span>
      </LandingPageContentCard>
    );
  }

  function FieldsOfPracticeCard() {
    return (
      <React.Fragment>
        <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisCareerPaths}`} borderColour="lime" className={classNames({ done: careerPathSelectionStatus === CompletionStatus.Completed })}>
          <ChipWrapper>
            <Chip
              label={careerPathSelectionStatus === CompletionStatus.Completed ? 'Completed' : 'Not Started'}
              variant="tiny"
              backgroundColour={careerPathSelectionStatus === CompletionStatus.Completed ? 'g' : 'default'}
              colour={careerPathSelectionStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
            />
          </ChipWrapper>
          <h3 className="heading3">Choose {subdomain.includes('lpi') ? 'your role' : 'a career pathway'}</h3>
          <span className="caption2">Let us know where you are heading with your career</span>
        </LandingPageContentCard>

        {careerPathSelectionStatus === CompletionStatus.Completed && (
          <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`} borderColour="yellow" className={classNames({ done: careerPathSelectionStatus === CompletionStatus.Completed && careerPathsStatus === CompletionStatus.Completed })}>
            <ChipWrapper>
              <Chip
                label={careerPathsStatus === CompletionStatus.Completed ? 'Completed' : careerPathsStatus === CompletionStatus.InProgress ? 'In Progress' : 'Not Started'}
                variant="tiny"
                backgroundColour={careerPathsStatus === CompletionStatus.Completed ? 'g' : careerPathsStatus === CompletionStatus.InProgress ? 'borders' : 'default'}
                colour={careerPathsStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
              />
            </ChipWrapper>
            <h3 className="heading3">{skillAssessmentConfig?.assessmentName ?? 'Skills assessment'}</h3>
            <span className="caption2">Rate yourself based on your career skills.</span>
          </LandingPageContentCard>
        )}
      </React.Fragment>
    );
  }

  return (
    <LandingPageWrapper>
      <LandingPageImage>
        <object data={themedAssetUrl('graphics/pie-graph.graphic.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('graphics/pie-graph.graphic.svg')} alt="graph" />
        </object>
      </LandingPageImage>

      <LandingPageContent>
        <ChipWrapper>
          <Chip label={subdomain.includes('lpi') ? 'Capability map' : 'Skills analysis'} backgroundColour="primary" colour="contrast-text" />
        </ChipWrapper>

        <h1 className="heading1">{subdomain.includes('lpi') ? 'Rate your skills' : 'Identify your strengths'}</h1>

        <h6 className="subtitle">Your analysis will give us the insights we need to build your personalised development plan.</h6>

        <LandingPageContentCardsWrapper>
          {skillAssessmentConfig?.assessmentType === AssessmentType.PeerEndorsement && <PeerEndorsementCard />}

          {skillAssessmentConfig?.assessmentType === AssessmentType.Questionnaire && <QuestionnaireCard />}

          {skillAssessmentConfig?.assessmentType === AssessmentType.FieldsOfPractice && <FieldsOfPracticeCard />}
        </LandingPageContentCardsWrapper>
      </LandingPageContent>
    </LandingPageWrapper>
  );
}
