import { AddTwoToneIcon, Anchor, Button, Checkbox, FocusPanelHeader } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { EntitySelectionList } from '../../../../components/entity-selection/entity-selection-list/entity-selection-list';
import { CompanyEntityListResponse } from '../../../../models/overmind/company-entity';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { OrganizationLevelType, SortField } from '../../../../enums';
import { useAppActions, useAppState } from '../../../../overmind';
import { extractHighestOrganizationLevel } from '../../../../lib/permissions.helpers';
import { IEditSkillsLayoutProps, IPerson } from './people-and-skills.models';

const pageSize = 5;

export function EditPersonLayout({ personToEdit, sync, allowManagerReview, allowPeerReview, onImport, onBack, onDone }: IEditSkillsLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);

  const [selectedPerson, setSelectedPerson] = useState<IPerson>(personToEdit);
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  });
  const [skills, setSkills] = useState<CompanyEntityListResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await actions.getCompanySkills(request);
      setSkills(response);
      setLoading(false);
    }

    getData();
  }, [request, sync]);

  return (
    <div className="panelContent">
      <FocusPanelHeader
        supertitle="Assessments"
        title={`${selectedPerson.firstName} ${selectedPerson.lastName}`}
        subtitle="Edit the skills you would like to be assessed"
        divider
      />

      <div className="dialogBodyLayout" style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 30 }}>
        {(allowManagerReview || allowPeerReview) && (
          <Checkbox
            id="manager"
            checked={selectedPerson.manager}
            onChange={() => setSelectedPerson(currentState => {
              const nextState = structuredClone(currentState);
              nextState.manager = !nextState.manager;
              return nextState;
            })}
          >
            Manager for the assessment
          </Checkbox>
        )}

        {(!allowManagerReview || allowPeerReview || !selectedPerson.manager) && (
          <div className="card">
            <EntitySelectionList
              loading={loading}
              label="Search company skills"
              entities={skills?.entities.map(entity => {
                const selected = selectedPerson.skills.some(skill => skill.companyEntityId === entity.companyEntityId);
                return ({
                  slug: entity.slug,
                  title: entity.name,
                  subtitle: entity.description ?? 'No skill description provided',
                  selected,
                  menuItems: [{
                    label: selected ? 'Remove skill' : 'Add skill',
                    onClick: () => setSelectedPerson(currentState => {
                      const nextState: IPerson = structuredClone(currentState);
                      selected ? nextState.skills = nextState.skills.filter(skill => skill.companyEntityId !== entity.companyEntityId) : nextState.skills.push(entity);
                      return nextState;
                    }),
                  }],
                });
              }) ?? []}
              page={request.page ?? 1}
              totalPages={skills?.totalPages ?? 0}
              onInput={search => setRequest(currentState => ({ ...currentState, search, page: 1 }))}
              onPageChange={page => setRequest(currentState => ({ ...currentState, page }))}
              button={{
                icon: <AddTwoToneIcon />,
                text: 'Import skill',
                onClick: onImport,
              }}
            />
          </div>
        )}
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button
          type="button"
          onClick={() => onDone(selectedPerson)}
        >
          Done
        </Button>
      </footer>
    </div>
  );
}