import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Day } from './datepicker.helpers';
import { IDatepickerProps } from './datepicker.models';
import * as datepickerStyles from './datepicker.styles';
import { useDateRange } from './use-date-range';
import { safeCallback } from '@keplerco/core';

export function Datepicker(props: IDatepickerProps): JSX.Element {
  const { range, previous, next, selected, selectDay, dirty } = useDateRange(props.defaultDate);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const datepickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function clickWatcher(event: MouseEvent) {
      if (datepickerRef.current?.contains(event.target as any)) return;
      setIsOpen(false);
    }

    window.addEventListener('click', clickWatcher);

    return () => {
      window.removeEventListener('click', clickWatcher);
    };
  }, []);

  function isLessThanMax(day: Day): boolean {
    let isLessThanMax = true;
    if (!!props.maxDate) isLessThanMax = new Date(props.maxDate) > day.toDate();
    return isLessThanMax;
  }

  function isGreaterThanMin(day: Day): boolean {
    let isGreaterThanMin = true;
    if (!!props.minDate) isGreaterThanMin = new Date(props.minDate) < day.toDate();
    return isGreaterThanMin;
  }

  function isValidDay(day: Day): boolean {
    return isLessThanMax(day) && isGreaterThanMin(day);
  }

  function open(target: EventTarget) {
    setIsOpen(true);
    if (!props.fixed) (target as HTMLElement).scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const selectedDate = selected.toDate();

    if (!!props.maxDate && selectedDate > new Date(props.maxDate)) {
      selectDay(Day.from(props.maxDate));
    }

    if (!!props.minDate && selectedDate < new Date(props.minDate)) {
      selectDay(Day.from(props.minDate));
    }
  }, [props.maxDate, props.minDate]);

  return (
    <React.Fragment>
      {isOpen && !props.fixed && <datepickerStyles.DatepickerMobileBackground />}

      <datepickerStyles.DatepickerInputWrapper ref={datepickerRef} className={classNames({ populated: dirty, tiny: true, hasError: props.haserror })}>
        <datepickerStyles.DatepickerInner onClick={({ target }) => open(target)}>
          <datepickerStyles.DatepickerInputLabel>{props.label ?? 'Date'}</datepickerStyles.DatepickerInputLabel>
          <datepickerStyles.DatepickerInputValue>{selected.printDate}</datepickerStyles.DatepickerInputValue>
        </datepickerStyles.DatepickerInner>

        <datepickerStyles.DatepickerInputIcon onClick={({ target }) => open(target)}>
          <svg fill="none" viewBox="0 0 19 21">
            <path fill="var(--secondary)" d="M4.176 10.021h2v2h-2v-2ZM4.176 14.021h2v2h-2v-2ZM10.176 10.021h-2v2h2v-2ZM8.176 14.021h2v2h-2v-2ZM14.176 10.021h-2v2h2v-2ZM12.176 14.021h2v2h-2v-2Z" />
            <path fill="var(--secondary)" fillRule="evenodd" d="M16.176 20.021h-14c-1.103 0-2-.896-2-2v-14c0-1.102.897-2 2-2h2v-2h2v2h6v-2h2v2h2c1.103 0 2 .898 2 2v14c0 1.104-.897 2-2 2Zm0-14v-2h-14v2h14Zm0 2v10h-14v-10h14Z" clipRule="evenodd" />
          </svg>
        </datepickerStyles.DatepickerInputIcon>

        {isOpen && (
          <datepickerStyles.DatepickerDropdown className={classNames({ openAbove: props.openAbove, fixed: props.fixed, autoHeight: props.autoHeight })}>
            <datepickerStyles.DatepickerDropdownHeader>
              <span onClick={() => isValidDay(range.firstDayOfRange) && previous()} className={classNames('control', { disabled: !isGreaterThanMin(range.firstDayOfRange) })}>
                <svg fill="none" viewBox="0 0 13 20">
                  <path stroke="var(--text_1)" strokeWidth="4" d="m11.5 18-8-8 8-8" />
                </svg>
              </span>

              <div className="label">
                <label>{range.currentMonthName}</label> <label>{range.year}</label>
              </div>

              <span onClick={() => isValidDay(range.lastDayOfRange) && next()} className={classNames('control', { disabled: !isLessThanMax(range.lastDayOfRange) })}>
                <svg fill="none" viewBox="0 0 13 20">
                  <path stroke="var(--text_1)" strokeWidth="4" d="m11.5 18-8-8 8-8" />
                </svg>
              </span>
            </datepickerStyles.DatepickerDropdownHeader>

            <datepickerStyles.DatepickerDropdownLabels>
              <li>S</li>
              <li>M</li>
              <li>T</li>
              <li>W</li>
              <li>T</li>
              <li>F</li>
              <li>S</li>
            </datepickerStyles.DatepickerDropdownLabels>

            <datepickerStyles.DatepickerDropdownBody>
              {range.dateRows.map((row, rowIndex) => {
                return (
                  <datepickerStyles.DropdownDateRow key={rowIndex}>
                    {row.map((day, dayIndex) => {
                      const isValid = isValidDay(day);

                      return (
                        <datepickerStyles.DropdownDateDay
                          className={classNames({ overflow: day.month !== range.currentMonth, today: day.isCurrentDay && isValid, selected: day.matches(selected), disabled: !isValid })}
                          key={`${rowIndex}-${dayIndex}`}
                          onClick={() => {
                            if (isValidDay(day)) {
                              selectDay(day);
                              setIsOpen(false);
                              safeCallback(props.onDateSelected, day.toDate());
                            }
                          }}
                        >
                          {day.day}
                        </datepickerStyles.DropdownDateDay>
                      );
                    })}
                  </datepickerStyles.DropdownDateRow>
                );
              })}
            </datepickerStyles.DatepickerDropdownBody>

            <datepickerStyles.DatepickerDropdownFooter>
              <button onClick={() => setIsOpen(false)}>cancel</button>
            </datepickerStyles.DatepickerDropdownFooter>
          </datepickerStyles.DatepickerDropdown>
        )}
      </datepickerStyles.DatepickerInputWrapper>
    </React.Fragment>
  );
}

