import { CompanySearchResponse, BaseSearchRequest } from '../../models';
import { ReducerAction } from '../../models/reducer-state';
import { SortField } from '../../enums';

// state
interface CompaniesState {
  request: BaseSearchRequest;
  data: CompanySearchResponse | undefined;

  openCreateCompanyFocusPanel: boolean;
}

export const initialState: CompaniesState = {
  request: {
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 6,
  },
  data: undefined,

  openCreateCompanyFocusPanel: false,
};

// actions
export enum CompaniesActionTypes {
  SetRequest,
  SetData,
  SetOpenCreateCompanyFocusPanel,
}

type CompaniesActions = ReducerAction<CompaniesActionTypes.SetRequest, { payload: BaseSearchRequest }> | ReducerAction<CompaniesActionTypes.SetData, { payload: CompanySearchResponse | undefined }> | ReducerAction<CompaniesActionTypes.SetOpenCreateCompanyFocusPanel, { payload: boolean }>;

// reducer
export function reducer(state: CompaniesState, action: CompaniesActions): CompaniesState {
  switch (action.type) {
    case CompaniesActionTypes.SetRequest: {
      return { ...state, request: action.payload };
    }

    case CompaniesActionTypes.SetData: {
      return { ...state, data: action.payload };
    }

    case CompaniesActionTypes.SetOpenCreateCompanyFocusPanel: {
      return { ...state, openCreateCompanyFocusPanel: action.payload };
    }

    default: {
      return state;
    }
  }
}

