import { ReducerAction } from '@keplerco/core';
import { Employee } from '../../../../../models';

interface ManagePersonPermissionsState {
  person: Employee | undefined;
}

export const initialState: ManagePersonPermissionsState = {
  person: undefined,
};

export enum ManagePersonPermissionsActionTypes {
  SetPerson = 0,
}

type ManagePersonPermissionsActions = ReducerAction<ManagePersonPermissionsActionTypes.SetPerson, { payload: Employee | undefined }>;

export function reducer(state: ManagePersonPermissionsState, action: ManagePersonPermissionsActions): ManagePersonPermissionsState {
  if (action.type === ManagePersonPermissionsActionTypes.SetPerson) {
    return { ...state, person: action.payload };
  }

  return state;
}

