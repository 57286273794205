import styled from 'styled-components';

export const ErrorPageBackgroundWrapper = styled.section`
  margin: 0 auto;
  padding: 0;
  height: auto;
  width: 100%;
  background-color: #0c1326;
  background-image: url('/backgrounds/space-background.png');
  background-size: cover;
`;

export const ErrorPageImageWrapper = styled.figure`
  display: block;
  padding: 0;
  margin: 0;

  object,
  img {
    display: block;
    width: 100%;
    max-width: 550px;
    margin-left: auto;
  }

  &.wide {
    object,
    img {
      max-width: 700px;
    }
  }

  @media screen and (max-width: 950px) {
    object,
    img {
      margin: auto;
      width: 90% !important;
      max-width: 100% !important;
    }
  }
`;

export const ErrorPageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 92px);
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 50px;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin: auto;

  @media screen and (max-width: 950px) {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-end;
    gap: 5px;
  }
`;

export const ErrorPageContent = styled.div`
  .heading1,
  .body2 {
    color: var(--white);
  }

  .subtitle {
    padding-top: 10px;
    padding-bottom: 25px;
    color: var(--grc_1);
  }

  .body2 {
    padding-bottom: 15px;
  }

  @media screen and (max-width: 950px) {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
`;
