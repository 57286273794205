import React, { PropsWithChildren } from 'react';
import { useAppState } from '../../overmind';
import { AuthenticationStatus, OrganizationLevelType } from '../../enums';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Mode, PagePath } from '../navigation.enums';
import { PermissionType } from '../../enums/permission-type';
import { extractHighestOrganizationLevel, extractPermission } from '../../lib/permissions.helpers';
import { IRouteGuardProps } from './guards.models';

const REDIRECT_TO = 'redirectTo';

export function RouterGuard(props: PropsWithChildren<IRouteGuardProps>): JSX.Element {
  const { children, authStatusRequired, onboardingStatusRequired, skillAssessmentStatusRequired, modeRequired, permissionTypeRequired: permissionTypeRequired } = props;

  const { isInitialising, authenticationStatus, user, skillAssessmentConfig, mode, permissions } = useAppState();

  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get(REDIRECT_TO);

  // auth
  if (!!authStatusRequired) {
    if (authStatusRequired === 'Unauthenticated') {
      if (!isInitialising && authenticationStatus === AuthenticationStatus.Authenticated && !!user) {
        return <Navigate to={!!redirectTo ? redirectTo : PagePath.dashboard} />;
      }
    }

    if (authStatusRequired === 'Authenticated') {
      if (authenticationStatus !== AuthenticationStatus.Authenticated) {
        return <Navigate to={`${PagePath.accountBase}${PagePath.accountLogin}?${REDIRECT_TO}=${location.pathname}`} />;
      }
    }
  }

  // onboarding
  if (!!onboardingStatusRequired) {
    if (!user) {
      return <Navigate to={PagePath.error.replace(':type', 'system').replace(':code', 'api')} />;
    }

    if (onboardingStatusRequired === 'Incomplete') {
      if (user.completedOnboarding) {
        return <Navigate to={!!redirectTo ? redirectTo : PagePath.dashboard} />;
      }
    }

    if (onboardingStatusRequired === 'Complete') {
      if (!user.completedOnboarding) {
        return <Navigate to={`${PagePath.onboarding}?${REDIRECT_TO}=${location.pathname}`} />;
      }
    }
  }

  // skill assessment
  if (!!skillAssessmentStatusRequired) {
    if (skillAssessmentStatusRequired === 'Incomplete') {
      if (!skillAssessmentConfig) {
        return <Navigate to={!!redirectTo ? redirectTo : PagePath.yourSkillsBase} />;
      }
    }

    if (skillAssessmentStatusRequired === 'Complete') {
      if (!!skillAssessmentConfig) {
        return <Navigate to={`${PagePath.analysisBase}?${REDIRECT_TO}=${location.pathname}`} />;
      }
    }
  }

  // mode
  if (!!modeRequired) {
    if (modeRequired === Mode.LearningDashboard) {
      if (mode === Mode.PlatformManagement) {
        return <Navigate to={PagePath.companies} />;
      }
    }

    if (modeRequired === Mode.PlatformManagement) {
      if (!user) {
        return <Navigate to={PagePath.error.replace(':type', 'system').replace(':code', 'api')} />;
      }

      if (!user.isSystemAdmin) {
        return <Navigate to={PagePath.dashboard} />;
      }

      if (mode === Mode.LearningDashboard) {
        return <Navigate to={PagePath.dashboard} />;
      }
    }
  }

  // system admin OR permission type
  if (permissionTypeRequired !== undefined) {
    if (!user) {
      return <Navigate to={PagePath.error.replace(':type', 'system').replace(':code', 'api')} />;
    }

    if (!user.isSystemAdmin) {
      // TODO: update as Skills APIs updated for Permissions
      const allowedPermissionTypes = [PermissionType.Assessments, PermissionType.Administration, PermissionType.Analytics, PermissionType.LearningManagement, PermissionType.RoleManagement];
      if (!allowedPermissionTypes.includes(permissionTypeRequired)) {
        return <Navigate to={PagePath.dashboard} />;
      }

      const permission = extractPermission(permissions, permissionTypeRequired);
      if (!permission) {
        return <Navigate to={PagePath.dashboard} />;
      }

      const highestOrganizationLevel = extractHighestOrganizationLevel(permission.organizationLevels);
      if (!highestOrganizationLevel || highestOrganizationLevel.organizationLevel === OrganizationLevelType.Learner) {
        return <Navigate to={PagePath.dashboard} />;
      }
    }

    if (user.isSystemAdmin && mode === Mode.LearningDashboard) {
      return <Navigate to={PagePath.dashboard} />;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}
