import React from 'react';
import { SkeletonLoader } from '../../../components';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

export function ReportTemplateSkeleton(props: { pageSize?: number }): JSX.Element {
  const { pageSize = 10 } = props;

  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="60" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      {Array.from(Array(pageSize)).map((_, index) => {
        return (
          <SkeletonLoaderRow key={index}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="45" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        );
      })}

      <br />
    </SkeletonLoaderContainer>
  );
}