import styled from 'styled-components';

export const SkillAssessmentScheduleWrapper = styled.form`
  display: block;
`;

export const SkillAssessmentScheduleRadioCardWrapper = styled.div`
  padding: 20px 0;

  .subtitle {
    color: var(--accent-3);
    font-size: 18px;
    font-weight: bold;
    display: block;
  }

  .body2 {
    font-size: 12px;
  }
`;

export const ScheduleAssessmentLabel = styled.h5`
  color: var(--accent-3);
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-bottom: 15px;
`;

export const ScheduleAssessmentSpacer = styled.div`
  height: 25px;
`;

export const ScheduleAssessmentFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 25px;

  button {
    display: flex;
    align-items: center;
    line-height: 1.22rem;
    font-size: 0.89rem;
    font-weight: 500;
    font-family: var(--body);
    text-decoration: none;
    text-transform: uppercase;
    color: var(--link-text);
    appearance: none;
    background: none;
    border: none;
    will-change: opacity, border-color;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
    cursor: pointer;

    &:hover {
      border-bottom-color: var(--primary);
    }
  }

  button svg {
    width: 10px;
    margin-left: 5px;
  }
`;

