import { ReducerAction } from '../../../models/reducer-state';
import { SortField } from '../../../enums';
import { BaseSearchRequest } from '../../../models';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { PagePath } from '../../../navigation/navigation.enums';
import { TagAssigneeResponse, TagSearchResponse } from '../../../models/tag';
import { Breadcrumb } from '@keplerco/core';

interface TagCMSState {
  pageTitle: string;
  crumbs: Breadcrumb[];
  request: BaseSearchRequest;
  columnConfiguration: ColumnConfiguration[];
  data: TagSearchResponse | undefined;
  managingColumns: boolean;
  openCreateTagPanel: boolean;
  tagToUpdateName: string | undefined;
  tagToArchiveName: string | undefined;
  assignees: TagAssigneeResponse[] | undefined;
}

export const initialState: TagCMSState = {
  pageTitle: 'Tag Management',
  crumbs: [],
  request: { sortAscending: true, sortField: SortField.Name, pageSize: 15, page: 1, search: '' },
  columnConfiguration: [
    { label: 'Tag Name', key: 'name', required: true },
    { label: 'Status', key: 'Status', required: false, selected: true },
    { label: 'Date Created', key: 'dateCreated', required: false, selected: true },
  ],
  data: undefined,
  managingColumns: false,
  openCreateTagPanel: false,
  tagToUpdateName: undefined,
  tagToArchiveName: undefined,
  assignees: undefined,
};

export enum TagCMSActionTypes {
  SetHeader = 'SetHeader',
  SetRequest = 'SetRequest',
  SetColumnConfiguration = 'SetColumnConfiguration',

  SetData = 'SetData',
  SetManageColumns = 'SetManageColumns',
  SetOpenCreateTagPanel = 'SetOpenCreateTagPanel',
  SetTagToUpdate = 'SetTagToUpdate',
  SetTagToArchive = 'SetTagToArchive',
}

type TagCMSActions =
  | ReducerAction<TagCMSActionTypes.SetHeader, { payload: { entityName: string | undefined; entitySlug: string } }>
  | ReducerAction<TagCMSActionTypes.SetRequest, { payload: BaseSearchRequest }>
  | ReducerAction<TagCMSActionTypes.SetColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<TagCMSActionTypes.SetData, { payload: TagSearchResponse | undefined }>
  | ReducerAction<TagCMSActionTypes.SetManageColumns, { payload: { open: boolean; reset?: boolean } }>
  | ReducerAction<TagCMSActionTypes.SetOpenCreateTagPanel, { payload: boolean }>
  | ReducerAction<TagCMSActionTypes.SetTagToUpdate, { payload: { tagName: string | undefined; assignees: TagAssigneeResponse[] | undefined } }>
  | ReducerAction<TagCMSActionTypes.SetTagToArchive, { payload: { tagName: string | undefined; assignees: TagAssigneeResponse[] | undefined } }>;

export function reducer(state: TagCMSState, action: TagCMSActions): TagCMSState {
  switch (action.type) {
    case TagCMSActionTypes.SetHeader:
      return {
        ...state,
        pageTitle: action.payload.entityName ? `Tags in ${action.payload.entityName}` : 'Tags',
        crumbs: [
          { name: 'Administration', url: PagePath.administrationBase },
          { name: 'Tags', url: window.location.pathname },
        ],
      };

    case TagCMSActionTypes.SetRequest:
      return { ...state, request: action.payload };

    case TagCMSActionTypes.SetColumnConfiguration:
      return { ...state, columnConfiguration: action.payload };

    case TagCMSActionTypes.SetData:
      return { ...state, data: action.payload };

    case TagCMSActionTypes.SetManageColumns:
      if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
      else return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };

    case TagCMSActionTypes.SetOpenCreateTagPanel:
      return { ...state, openCreateTagPanel: action.payload };

    case TagCMSActionTypes.SetTagToUpdate:
      return { ...state, tagToUpdateName: action.payload.tagName, assignees: action.payload.assignees };

    case TagCMSActionTypes.SetTagToArchive:
      return { ...state, tagToArchiveName: action.payload.tagName, assignees: action.payload.assignees };

    default:
      return state;
  }
}
