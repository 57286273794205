import React from 'react';

export const ItalianFlag = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_44_7278)">
      <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F0F0F0" />
      <path d="M28 13.9998C28 7.98031 24.2007 2.84871 18.8695 0.870605V27.1291C24.2007 25.1509 28 20.0193 28 13.9998Z" fill="#D80027" />
      <path d="M0 13.9998C0 20.0193 3.79925 25.1509 9.13046 27.129V0.870605C3.79925 2.84871 0 7.98031 0 13.9998Z" fill="#6DA544" />
    </g>
    <defs>
      <clipPath id="clip0_44_7278">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const EnglishFlag = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_44_7268)">
      <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F0F0F0" />
      <path d="M2.89407 5.47632C1.79436 6.90711 0.965136 8.55621 0.4823 10.3477H7.76547L2.89407 5.47632Z" fill="#0052B4" />
      <path d="M27.5177 10.3479C27.0348 8.55645 26.2056 6.90735 25.1059 5.47656L20.2346 10.3479H27.5177Z" fill="#0052B4" />
      <path d="M0.4823 17.6521C0.965191 19.4436 1.79442 21.0927 2.89407 22.5234L7.76531 17.6521H0.4823Z" fill="#0052B4" />
      <path d="M22.5234 2.89401C21.0927 1.7943 19.4436 0.965068 17.6521 0.482178V7.7653L22.5234 2.89401Z" fill="#0052B4" />
      <path d="M5.47656 25.1059C6.90735 26.2056 8.55645 27.0348 10.3479 27.5177V20.2346L5.47656 25.1059Z" fill="#0052B4" />
      <path d="M10.3479 0.482178C8.5564 0.965068 6.9073 1.79429 5.47656 2.89395L10.3479 7.76524V0.482178Z" fill="#0052B4" />
      <path d="M17.6522 27.5177C19.4437 27.0348 21.0928 26.2056 22.5235 25.1059L17.6522 20.2346V27.5177Z" fill="#0052B4" />
      <path d="M20.2346 17.6521L25.1059 22.5234C26.2056 21.0927 27.0348 19.4436 27.5177 17.6521H20.2346Z" fill="#0052B4" />
      <path
        d="M27.8815 12.1739H15.8262H15.8261V0.118508C15.2283 0.0406875 14.6189 0 14 0C13.381 0 12.7717 0.0406875 12.1739 0.118508V12.1738V12.1739H0.118508C0.0406875 12.7717 0 13.3811 0 14C0 14.619 0.0406875 15.2283 0.118508 15.8261H12.1738H12.1739V27.8815C12.7717 27.9593 13.381 28 14 28C14.6189 28 15.2283 27.9594 15.8261 27.8815V15.8262V15.8261H27.8815C27.9593 15.2283 28 14.619 28 14C28 13.3811 27.9593 12.7717 27.8815 12.1739Z"
        fill="#D80027"
      />
      <path d="M17.6522 17.6522L23.8995 23.8994C24.1868 23.6122 24.4609 23.3119 24.7224 23.0006L19.3739 17.6521H17.6522V17.6522Z" fill="#D80027" />
      <path d="M10.3478 17.6521H10.3477L4.10046 23.8993C4.38768 24.1867 4.68797 24.4607 4.99925 24.7223L10.3478 19.3736V17.6521Z" fill="#D80027" />
      <path d="M10.3478 10.3478V10.3477L4.10053 4.10034C3.8132 4.38756 3.5391 4.68785 3.27759 4.99913L8.62619 10.3477H10.3478V10.3478Z" fill="#D80027" />
      <path d="M17.6522 10.3479L23.8996 4.10047C23.6123 3.81314 23.312 3.53905 23.0008 3.27759L17.6522 8.62619V10.3479Z" fill="#D80027" />
    </g>
    <defs>
      <clipPath id="clip0_44_7268">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const SpanishFlag = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_44_7272)">
      <path d="M0 14C0 15.7125 0.308055 17.3529 0.870789 18.8696L14 20.087L27.1292 18.8696C27.6919 17.3529 28 15.7125 28 14C28 12.2875 27.6919 10.6471 27.1292 9.13048L14 7.91309L0.870789 9.13048C0.308055 10.6471 0 12.2875 0 14H0Z" fill="#FFDA44" />
      <path d="M27.1293 9.13046C25.1512 3.7993 20.0196 0 14.0001 0C7.98055 0 2.84895 3.7993 0.87085 9.13046H27.1293Z" fill="#D80027" />
      <path d="M0.87085 18.8696C2.84895 24.2008 7.98055 28.0001 14.0001 28.0001C20.0196 28.0001 25.1512 24.2008 27.1293 18.8696H0.87085Z" fill="#D80027" />
    </g>
    <defs>
      <clipPath id="clip0_44_7272">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const FrenchFlag = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_44_7275)">
      <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F0F0F0" />
      <path d="M28 13.9998C28 7.98031 24.2007 2.84871 18.8695 0.870605V27.1291C24.2007 25.1509 28 20.0193 28 13.9998Z" fill="#D80027" />
      <path d="M0 13.9998C0 20.0193 3.7993 25.1509 9.13046 27.129V0.870605C3.7993 2.84871 0 7.98031 0 13.9998Z" fill="#0052B4" />
    </g>
    <defs>
      <clipPath id="clip0_44_7275">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ChevronDownIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.37903 1.69995L4.91236 4.67627L1.44569 1.69995" stroke="#141E3A" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ChevronUpIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.621 4.3L5.08767 1.32368L8.55434 4.3" stroke="#141E3A" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CheckIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.525 14.55L15.6 7.475L14.45 6.35L8.525 12.275L5.525 9.275L4.4 10.4L8.525 14.55ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18.5C12.3667 18.5 14.375 17.6708 16.025 16.0125C17.675 14.3542 18.5 12.35 18.5 10C18.5 7.63333 17.675 5.625 16.025 3.975C14.375 2.325 12.3667 1.5 10 1.5C7.65 1.5 5.64583 2.325 3.9875 3.975C2.32917 5.625 1.5 7.63333 1.5 10C1.5 12.35 2.32917 14.3542 3.9875 16.0125C5.64583 17.6708 7.65 18.5 10 18.5Z"
      fill="#0B41CD"
    />
  </svg>
);

export const VietnameseFlag = () => (
  <svg width="28" height="28" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <circle cx="256" cy="256" fill="#d80027" r="256" />
    <path d="m256 133.565 27.628 85.029h89.405l-72.331 52.55 27.628 85.03-72.33-52.551-72.33 52.551 27.628-85.03-72.33-52.55h89.404z" fill="#ffda44" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const PortugalFlag = () => (
  <svg width="28" height="28" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 256c0 110.07 69.472 203.905 166.955 240.076l22.262-240.077-22.262-240.076c-97.483 36.172-166.955 130.006-166.955 240.077z" fill="#6da544" />
    <path d="m512 256c0-141.384-114.616-256-256-256-31.314 0-61.311 5.633-89.045 15.923v480.154c27.734 10.291 57.731 15.923 89.045 15.923 141.384 0 256-114.616 256-256z" fill="#d80027" />
    <circle cx="166.957" cy="256" fill="#ffda44" r="89.043" />
    <path d="m116.87 211.478v55.652c0 27.662 22.424 50.087 50.087 50.087s50.087-22.424 50.087-50.087v-55.652z" fill="#d80027" />
    <path d="m166.957 283.826c-9.206 0-16.696-7.49-16.696-16.696v-22.26h33.391v22.261c0 9.205-7.49 16.695-16.695 16.695z" fill="#f0f0f0" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const GermanFlag = () => (
  <svg width="28" height="28" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957l-240.077-22.26z" fill="#ffda44" />
    <path d="m256 0c-110.071 0-203.906 69.472-240.077 166.957l240.077 22.26 240.077-22.261c-36.171-97.484-130.006-166.956-240.077-166.956z" />
    <path d="m15.923 166.957c-10.29 27.733-15.923 57.729-15.923 89.043s5.633 61.31 15.923 89.043h480.155c10.29-27.733 15.922-57.729 15.922-89.043s-5.632-61.31-15.923-89.043z" fill="#d80027" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const ChineseFlag = () => (
  <svg width="28" height="28" enableBackground="new -49 141 512 512" viewBox="-49 141 512 512" xmlns="http://www.w3.org/2000/svg">
    <circle cx="207" cy="397" fill="#d80027" r="256" />
    <g fill="#ffda44">
      <path d="m91.1 296.8 22.1 68h71.5l-57.8 42.1 22.1 68-57.9-42-57.9 42 22.2-68-57.9-42.1h71.5z" />
      <path d="m254.5 537.5-16.9-20.8-25 9.7 14.5-22.5-16.9-20.9 25.9 6.9 14.6-22.5 1.4 26.8 26 6.9-25.1 9.6z" />
      <path d="m288.1 476.5 8-25.6-21.9-15.5 26.8-.4 7.9-25.6 8.7 25.4 26.8-.3-21.5 16 8.6 25.4-21.9-15.5z" />
      <path d="m333.4 328.9-11.8 24.1 19.2 18.7-26.5-3.8-11.8 24-4.6-26.4-26.6-3.8 23.8-12.5-4.6-26.5 19.2 18.7z" />
      <path d="m255.2 255.9-2 26.7 24.9 10.1-26.1 6.4-1.9 26.8-14.1-22.8-26.1 6.4 17.3-20.5-14.2-22.7 24.9 10.1z" />
    </g>
  </svg>
);

export const SouthKoreanFlag = () => (
  <svg width="28" height="28" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
    <path d="m345.043 256c0 22.261-39.866 77.913-89.043 77.913s-89.043-55.652-89.043-77.913c0-49.178 39.866-89.043 89.043-89.043s89.043 39.865 89.043 89.043z" fill="#d80027" />
    <path d="m345.043 256c0 49.178-39.866 89.043-89.043 89.043s-89.043-39.865-89.043-89.043" fill="#0052b4" />
    <path d="m353.427 319.639h33.391v22.261h-33.391z" transform="matrix(.707 -.707 .707 .707 -125.483 358.596)" />
    <path d="m314.07 358.994h33.391v22.261h-33.391z" transform="matrix(.707 -.707 .707 .707 -164.839 342.294)" />
    <path d="m400.656 366.851h33.391v22.261h-33.391z" transform="matrix(.707 -.707 .707 .707 -145.034 405.821)" />
    <path d="m361.299 406.213h33.391v22.261h-33.391z" transform="matrix(.707 -.707 .707 .707 -184.394 389.52)" />
    <path d="m377.04 343.247h33.391v22.261h-33.391z" transform="matrix(.707 -.707 .707 .707 -135.26 382.208)" />
    <path d="m337.694 382.602h33.391v22.261h-33.391z" transform="matrix(.707 -.707 .707 .707 -174.613 365.913)" />
    <path d="m329.544 126.805h89.043v22.261h-89.043z" transform="matrix(-.707 -.707 .707 -.707 541.034 499.974)" />
    <path d="m314.081 130.749h33.391v22.26h-33.391z" transform="matrix(-.707 -.707 .707 -.707 464.348 476.098)" />
    <path d="m353.427 170.098h33.391v22.26h-33.391z" transform="matrix(-.707 -.707 .707 -.707 503.692 571.091)" />
    <path d="m361.282 83.526h33.391v22.261h-33.391z" transform="matrix(-.707 -.707 .707 -.707 578.316 428.859)" />
    <path d="m400.651 122.879h33.391v22.261h-33.391z" transform="matrix(-.707 -.707 .707 -.707 617.695 523.876)" />
    <path d="m69.81 386.542h89.043v22.261h-89.043z" transform="matrix(.707 .707 -.707 .707 314.684 35.631)" />
    <path d="m140.919 382.6h33.391v22.26h-33.391z" transform="matrix(.707 .707 -.707 .707 324.573 3.87)" />
    <path d="m101.569 343.252h33.391v22.261h-33.391z" transform="matrix(.707 .707 -.707 .707 285.225 20.171)" />
    <path d="m117.031 339.316h89.043v22.261h-89.043z" transform="matrix(.707 .707 -.707 .707 295.121 -11.592)" />
    <path d="m69.81 103.201h89.043v22.261h-89.043z" transform="matrix(-.707 .707 -.707 -.707 276.02 114.331)" />
    <path d="m93.412 126.806h89.043v22.261h-89.043z" transform="matrix(-.707 .707 -.707 -.707 333.003 137.938)" />
    <path d="m117.038 150.428h89.043v22.26h-89.043z" transform="matrix(-.707 .707 -.707 -.707 390.037 161.557)" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const NetherlandsFlag = () => (
  <svg width="28" height="28" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
    <path d="m256 0c-110.071 0-203.906 69.472-240.077 166.957h480.155c-36.172-97.485-130.007-166.957-240.078-166.957z" fill="#a2001d" />
    <path d="m256 512c110.071 0 203.906-69.472 240.077-166.957h-480.154c36.171 97.485 130.006 166.957 240.077 166.957z" fill="#0052b4" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const CzechFlag = () => (
  <svg width="28" height="28" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
    <path d="m233.739 256s-158.609 181.055-158.759 181.019c46.326 46.327 110.327 74.981 181.02 74.981 141.384 0 256-114.616 256-256z" fill="#d80027" />
    <path d="m74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04 41.313-41.313 81.046-81.046 181.02-181.02z" fill="#0052b4" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const JapaneseFlag = () => (
  <svg width="28" height="28" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256" /><circle cx="256" cy="256" fill="#d80027" r="111.304" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
);