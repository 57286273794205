import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { LoginPage } from '../../pages/account/login/login.page';
import { RegisterPage } from '../../pages/account/register/register.page';
import { SetPasswordPage } from '../../pages/account/set-password/set-password.page';
import { ForgotPasswordPage } from '../../pages/account/forgot-password/forgot-password.page';
import { ResetPasswordPage } from '../../pages/account/reset-password/reset-password.page';
import { VerifyEmailPage } from '../../pages/account/verify-email/verify-email';
import { ActivateAccountPage } from '../../pages/account/activate-account/activate-account.page';
import { ErrorRedirect, ModeRedirect } from './routers.helpers';

export function AccountRouter(): JSX.Element {
  return (
    <Routes>
      <Route path={PagePath.accountLogin} element={<LoginPage />} />
      <Route path={PagePath.accountRegister} element={<RegisterPage />} />
      <Route path={PagePath.accountSetPassword} element={<SetPasswordPage />} />
      <Route path={PagePath.accountForgotPassword} element={<ForgotPasswordPage />} />
      <Route path={PagePath.accountResetPassword} element={<ResetPasswordPage />} />
      <Route path={PagePath.accountVerifyEmail} element={<VerifyEmailPage />} />
      <Route path={PagePath.accountActivate} element={<ActivateAccountPage />} />

      <Route path={PagePath.root} element={<ModeRedirect />} />
      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}