import styled from 'styled-components';

export const SkillAssessmentTagsWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const SkillAssessmentTagsFooter = styled.footer`
  display: block;
  width: 100%;
  padding-top: 15px;
  text-align: right;
`;

