import React, { useEffect } from 'react';
import { ErrorPageContent, ErrorPageWrapper, ErrorPageImageWrapper, ErrorPageBackgroundWrapper } from './error.styles';
import { useParams, useSearchParams } from 'react-router-dom';
import { ErrorSignalTypes } from '../../enums/error-signal-types';
import { useAppActions } from '../../overmind';
import { PagePath } from '../../navigation/navigation.enums';
import { Button } from '@keplerco/core';

export function ErrorPage(): JSX.Element {
  const { type = ErrorSignalTypes.Unknown, code } = useParams<{ type: ErrorSignalTypes; code: string }>();
  const [searchParams] = useSearchParams();

  const actions = useAppActions();

  const returnTo = searchParams.get('returnTo');

  useEffect(() => {
    actions.setSideNavVisible(false);

    return () => actions.setSideNavVisible(true);
  }, []);

  if (type === ErrorSignalTypes.Routing) {
    return (
      <ErrorPageBackgroundWrapper style={{ margin: 'auto' }}>
        <ErrorPageWrapper>
          <ErrorPageContent>
            <h1 className="heading1">Oops!</h1>
            <h6 className="subtitle">
              We're sorry, something has
              <br /> gone wrong.
            </h6>

            <Button type="a" href={returnTo ?? PagePath.root} filled arrow={false}>
              Take me home
            </Button>
          </ErrorPageContent>

          <ErrorPageImageWrapper>
            <object data="/404.svg" type="image/svg+xml">
              <img src="/404.svg" alt="Cow" />
            </object>
          </ErrorPageImageWrapper>
        </ErrorPageWrapper>
      </ErrorPageBackgroundWrapper>
    );
  } else {
    return (
      <ErrorPageBackgroundWrapper style={{ margin: 'auto' }}>
        <ErrorPageWrapper>
          <ErrorPageContent>
            <h1 className="heading1">Well this is awkward...</h1>
            {code === 'api' ? <h6 className="subtitle">Something seems to have gone wrong with our servers.</h6> : <h6 className="subtitle">We're sorry, we can't seem to find the page you're looking for.</h6>}
            <p className="body2">Please try again, or if this keeps happening, let us know and we'll look into it.</p>

            <Button type="a" href={returnTo ?? PagePath.root} filled arrow={false}>
              Take me home
            </Button>
          </ErrorPageContent>

          <ErrorPageImageWrapper className="wide">
            <object data="/404_UFO.svg" type="image/svg+xml">
              <img src="/404_UFO.svg" alt="Cow" />
            </object>
          </ErrorPageImageWrapper>
        </ErrorPageWrapper>
      </ErrorPageBackgroundWrapper>
    );
  }
}

