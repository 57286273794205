import styled from 'styled-components';
import { CMSColumn, CMSRow } from '../../theme/layout.styles';

export const ManageSkillAssessmentFocusPanelLoader = styled.div`
  width: 100%;
  height: 300px;
  display: grid;
  place-items: center;
`;

export const SkillAssessmentCreateButtonInner = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const CMSButtonRow = styled(CMSRow)`
  justify-content: space-between;
  gap: 25px;
  align-items: flex-end;

  @media screen and (max-width: 750px) {
    display: block;

    ${CMSColumn} {
      width: 100%;
    }
  }
`;

export const CMSDropdownColumn = styled(CMSColumn)`
  margin-left: auto;
`;

