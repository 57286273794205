import React, { useEffect, useState } from 'react';
import { Anchor, Button, FocusPanelHeader, Modal } from '@keplerco/core';
import { IImportSkillLayoutProps } from './manage-role.models';
import { CompanyEntityListItemResponse, CompanyEntityListResponse } from '../../../../models/overmind/company-entity';
import { useAppActions, useAppState } from '../../../../overmind';
import { OrganizationLevelType, SortField } from '../../../../enums';
import { ConfirmationModalLayout } from '../../../../widgets/layouts';
import { QueueItemPriority, QueueItemType } from '../../../../components';
import { extractHighestOrganizationLevel } from '../../../../lib/permissions.helpers';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { EntitySelectionList } from '../../../../components/entity-selection/entity-selection-list/entity-selection-list';

const pageSize = 5;

export function ImportSkillLayout({ supertitle, onClose }: IImportSkillLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);

  const [loading, setLoading] = useState<boolean>(true);
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: true,
  });
  const [data, setData] = useState<CompanyEntityListResponse>();
  const [skillToImport, setSkillToImport] = useState<CompanyEntityListItemResponse>();
  const [importedSkills, setImportedSkills] = useState<CompanyEntityListItemResponse[]>([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await actions.getCompanySkills(request);
      setData(response);
      setLoading(false);
    }

    getData();
  }, [request]);

  return (
    <React.Fragment>
      <div className="panelContent">
        <FocusPanelHeader
          supertitle={supertitle}
          title="Import skills"
          subtitle="Search for global skills and add them to your company skills"
          divider
        />

        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 15 }}>
          <EntitySelectionList
            loading={loading}
            label="Search global skills"
            entities={data?.entities.map(entity => {
              const selected = importedSkills.some(importedSkill => importedSkill.slug === entity.slug);
              return ({
                slug: entity.slug,
                title: entity.name,
                subtitle: entity.description ?? 'No skill description provided',
                selected,
                menuItems: [{
                  label: 'Import skill',
                  onClick: async () => setSkillToImport(entity),
                  disabled: selected,
                }]
              });
            }) ?? []}
            page={request.page ?? 1}
            totalPages={data?.totalPages ?? 0}
            onInput={search => setRequest(currentState => ({ ...currentState, search, page: 1 }))}
            onPageChange={page => setRequest(currentState => ({ ...currentState, page }))}
          />
        </div>

        <footer className="panelFooter">
          <Anchor onClick={onClose}>Back</Anchor>

          <Button
            type="button"
            disabled={!importedSkills.length}
            onClick={onClose}
          >
            Done
          </Button>
        </footer>
      </div>

      <Modal open={!!skillToImport} onClose={() => setSkillToImport(undefined)}>
        {!!skillToImport && (
          <ConfirmationModalLayout
            title="Confirm import"
            subtitle={`Are you sure you want to add ${skillToImport.name} to your company skills?`}
            submitButtonText="Import"
            onClickSubmit={async () => {
              setLoading(true);
              setSkillToImport(undefined);

              const response = await actions.importCompanySkill({
                companySlug: companyVariables.slug!,
                entitySlug: skillToImport.slug,
              });

              if (!!response.error) {
                // TODO: use updated IError model
                actions.addNotification({
                  active: true,
                  id: crypto.randomUUID(),
                  type: QueueItemType.Error,
                  title: 'Failed import!',
                  message: `There was an error adding ${skillToImport.name} to your company skills...`,
                  priority: QueueItemPriority.Toast,
                });
                setLoading(false);
                return;
              }

              actions.addNotification({
                active: true,
                id: crypto.randomUUID(),
                type: QueueItemType.Success,
                title: 'Successful import!',
                message: `You have successfully added ${skillToImport.name} to your company skills!`,
                priority: QueueItemPriority.Toast,
              });
              setImportedSkills(currentState => ([...currentState, skillToImport]));
              setLoading(false);
            }}
            cancelButtonText="Cancel"
            onClickCancel={() => setSkillToImport(undefined)}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}