import React from 'react';
import styles from './entity-skill-scores-card.module.css';
import { SkillLevel, SkillPercentage, ragColourType } from '@keplerco/core';
import { IEntitySkillScoresCardProps } from './entity-skill-scores-card.model';
import classNames from 'classnames';
import { skillLevelRAGColour } from '../../lib/skill-level-rag-colour.helper';
import { UrgencyIndicationChip } from '../../pages/your-skills/urgency-indication.chip';
import { useAppState } from '../../overmind';
import { PagePath } from '../../navigation/navigation.enums';

export function EntitySkillScoresCard({ overallScore, path, technicalScore, behaviouralScore, comparisonScore, name, urgencyIndication }: IEntitySkillScoresCardProps) {
  const { companyVariables } = useAppState();

  return (
    <div className={classNames(styles.cardWrapper, 'kplr_entitySkillLevelCard')}>
      <h5 className={classNames(styles.cardTitle, 'heading5')}>{name}</h5>
      <div className={styles.cardContent}>
        {!!urgencyIndication ? (
          <UrgencyIndicationChip urgencyIndication={urgencyIndication} />
        ) : (
          <>
            <div className={styles.skillScoresContainer}>
              <div className={styles.skillScore}>
                {companyVariables.useLevels ? (
                  <SkillLevel
                    level={overallScore?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    notAssessed={!overallScore}
                    dotColour={path !== PagePath.yourSkillsBase ? skillLevelRAGColour(overallScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                    title={path === PagePath.yourSkillsBase ? 'Your proficiency' : 'Overall skill average'}
                  />
                ) : (
                  <SkillPercentage
                    percentage={overallScore?.percentage ?? 0}
                    notAssessed={!overallScore}
                    barColour={path !== PagePath.yourSkillsBase ? ragColourType(overallScore?.percentage ?? 0) : 'baby-blue'}
                    title={path === PagePath.yourSkillsBase ? 'Your proficiency' : 'Overall skill average'}
                  />
                )}
              </div>

              {!!comparisonScore && (
                <div className={styles.skillScore}>
                  {companyVariables.useLevels ? (
                    <SkillLevel
                      level={comparisonScore?.level ?? companyVariables.minLevel}
                      minLevel={companyVariables.minLevel}
                      maxLevel={companyVariables.maxLevel}
                      notAssessed={!comparisonScore}
                      dotColour={path !== PagePath.yourSkillsBase ? skillLevelRAGColour(overallScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                      title={`${companyVariables.skillScoreComparison} average`}
                    />
                  ) : (
                    <SkillPercentage
                      percentage={comparisonScore?.percentage ?? 0}
                      notAssessed={!comparisonScore}
                      barColour={path !== PagePath.yourSkillsBase ? ragColourType(overallScore?.percentage ?? 0) : 'baby-blue'}
                      title={`${companyVariables.skillScoreComparison} average`}
                    />
                  )}
                </div>
              )}
            </div>

            {path !== PagePath.yourSkillsBase && (
              <div className={styles.skillScoresContainer}>
                {companyVariables.useLevels ? (
                  <SkillLevel
                    level={technicalScore?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    notAssessed={!technicalScore}
                    dotColour={skillLevelRAGColour(technicalScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                    title="Role-based skill average"
                  />
                ) : (
                  <SkillPercentage
                    percentage={technicalScore?.percentage ?? 0}
                    notAssessed={!technicalScore}
                    barColour={ragColourType(technicalScore?.percentage ?? 0)}
                    title="Role-based skill average"
                  />
                )}

                {companyVariables.useLevels ? (
                  <SkillLevel
                    level={behaviouralScore?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    notAssessed={!behaviouralScore}
                    title="Behavioural skill average"
                  />
                ) : (
                  <SkillPercentage
                    percentage={behaviouralScore?.percentage ?? 0}
                    notAssessed={!behaviouralScore}
                    title="Behavioural skill average"
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

