import { Questionnaire } from '../../../models/questionnaire';
import { ReducerAction } from '../../../models/reducer-state';
import { ReflectionList } from '../../../models/view/reflection-list';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { Course, LearnerSkillWithCourses } from '../../../models/overmind/learner-skill-with-courses';
import { AdditionalLearningListViewModel } from '../additional-learning/additional-learning.models';
import { QuestionSetType } from '../../../enums/question-set-type';
import { MappedPlanCourse, MappedPlanCourseLesson } from '../../../models/view/courses';
import { PagePath } from '../../../navigation/navigation.enums';
import { Breadcrumb } from '@keplerco/core';

export interface SkillLayoutState {
  hasInitialised: boolean;
  pageTitle: string;
  pageDescription?: string;
  breadcrumbs: Breadcrumb[];

  // timeline
  courseLessons?: MappedPlanCourse[];

  // variation
  courseWithVariation?: LearnerSkillWithCourses;
  variationQuestionnaire?: { questionnaire: Questionnaire; type: QuestionSetType };
  showVariationAssessmentFocusPanel: boolean;
  showVariationNotification: boolean;

  // assessment
  preAssessment?: Questionnaire;
  postAssessment?: Questionnaire;

  // additional learning
  additionalLearningList: AdditionalLearningListViewModel[] | undefined;
  selectedAdditionalLearning?: UniqueEntity;
  showConfirmationModal: boolean;

  // reflection
  reflectionList?: ReflectionList;
  selectedReflectionId?: number;
}

export const initialState: SkillLayoutState = {
  hasInitialised: false,
  pageTitle: 'Skill',
  breadcrumbs: [],

  // variation
  showVariationAssessmentFocusPanel: false,
  showVariationNotification: false,

  // additional learning
  additionalLearningList: [],
  showConfirmationModal: false,
};

export enum SkillLayoutActionTypes {
  SetData,
  SetQuestionnaire,
  CompleteQuestionnaire,
  SetShowVariationAssessmentFocusPanel,
  SetShowVariationNotification,
  ClearPreAssessment,
  ClearPostAssessment,
  SetAdditionalLearningList,
  SetAdditionalLearning,
  SetShowConfirmationModal,
  SetReflectionList,
  SetReflectionId,
}

type SkillLayoutActions =
  | ReducerAction<
    SkillLayoutActionTypes.SetData,
    {
      payload: {
        parentPath: PagePath.learningJourneyBase | PagePath.learningJourneyCoreSkillsMap;
        path: PagePath.learningJourneySkill | PagePath.learningJourneyYearSkill | PagePath.learningJourneyAssignedSkill;
        journey: LearnerSkillWithCourses | undefined;
        reflectionList: ReflectionList | undefined;
        preAssessment: Questionnaire | undefined;
        postAssessment: Questionnaire | undefined;
      };
    }
  >
  | ReducerAction<SkillLayoutActionTypes.SetQuestionnaire, { payload: { questionnaire: Questionnaire; type: QuestionSetType } | undefined }>
  | ReducerAction<SkillLayoutActionTypes.CompleteQuestionnaire, {}>
  | ReducerAction<SkillLayoutActionTypes.SetShowVariationAssessmentFocusPanel, { payload: boolean }>
  | ReducerAction<SkillLayoutActionTypes.SetShowVariationNotification, { payload: boolean }>
  | ReducerAction<SkillLayoutActionTypes.ClearPreAssessment, {}>
  | ReducerAction<SkillLayoutActionTypes.ClearPostAssessment, {}>
  | ReducerAction<SkillLayoutActionTypes.SetAdditionalLearningList, { payload: AdditionalLearningListViewModel[] | undefined }>
  | ReducerAction<SkillLayoutActionTypes.SetAdditionalLearning, { payload: UniqueEntity | undefined }>
  | ReducerAction<SkillLayoutActionTypes.SetShowConfirmationModal, { payload: boolean }>
  | ReducerAction<SkillLayoutActionTypes.SetReflectionList, { payload: ReflectionList | undefined }>
  | ReducerAction<SkillLayoutActionTypes.SetReflectionId, { payload: number | undefined }>;

export function reducer(state: SkillLayoutState, action: SkillLayoutActions): SkillLayoutState {
  if (action.type === SkillLayoutActionTypes.SetData) {
    const journey = action.payload.journey;
    const reflectionList = action.payload.reflectionList;

    const hasCoursesWithVariations = !!journey?.courses?.find(course => course.variationQuestionsReady);

    let timelinePoints: MappedPlanCourse[] = [];
    if (!!journey?.courses) timelinePoints = mapCourseLessons(journey.courses);

    const breadcrumbs =
      action.payload.parentPath === PagePath.learningJourneyCoreSkillsMap
        ? [
          {
            name: 'Learning Journey',
            url: `${PagePath.learningJourneyBase}`,
          },
          {
            name: 'Core Skills Map',
            url: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}`,
          },
          {
            name: journey?.name ?? 'Skill',
            url: window.location.pathname,
          },
        ]
        : [
          {
            name: 'Learning Journey',
            url: `${PagePath.learningJourneyBase}`,
          },
          {
            name: 'Learning',
            url: `${PagePath.learningJourneyBase}`,
          },
          {
            name: journey?.name ?? 'Skill',
            url: window.location.pathname,
          },
        ];

    return {
      ...state,
      hasInitialised: true,
      pageTitle: journey?.name ?? 'Skill',
      pageDescription: journey?.description ?? '',
      breadcrumbs,
      courseLessons: timelinePoints,
      reflectionList,
      courseWithVariation: hasCoursesWithVariations ? journey : void 0,
      preAssessment: action.payload.preAssessment,
      postAssessment: action.payload.postAssessment,
    };
  }

  // variation
  if (action.type === SkillLayoutActionTypes.SetQuestionnaire) {
    return { ...state, variationQuestionnaire: action.payload };
  }

  if (action.type === SkillLayoutActionTypes.CompleteQuestionnaire) {
    return { ...state, variationQuestionnaire: undefined, courseWithVariation: undefined };
  }

  if (action.type === SkillLayoutActionTypes.SetShowVariationAssessmentFocusPanel) {
    return { ...state, showVariationAssessmentFocusPanel: action.payload };
  }

  if (action.type === SkillLayoutActionTypes.SetShowVariationNotification) {
    return { ...state, showVariationNotification: action.payload };
  }

  // assessment
  if (action.type === SkillLayoutActionTypes.ClearPreAssessment) {
    return { ...state, preAssessment: undefined, variationQuestionnaire: undefined };
  }

  if (action.type === SkillLayoutActionTypes.ClearPostAssessment) {
    return { ...state, postAssessment: undefined, variationQuestionnaire: undefined };
  }

  // additional learning
  if (action.type === SkillLayoutActionTypes.SetAdditionalLearningList) {
    return { ...state, additionalLearningList: action.payload };
  }

  if (action.type === SkillLayoutActionTypes.SetAdditionalLearning) {
    return { ...state, selectedAdditionalLearning: action.payload };
  }

  if (action.type === SkillLayoutActionTypes.SetShowConfirmationModal) {
    return { ...state, showConfirmationModal: action.payload };
  }

  // reflection
  if (action.type === SkillLayoutActionTypes.SetReflectionList) {
    return { ...state, reflectionList: action.payload };
  }

  if (action.type === SkillLayoutActionTypes.SetReflectionId) {
    return { ...state, selectedReflectionId: action.payload };
  }

  return state;
}

function mapCourseLessons(courses: Course[]): MappedPlanCourse[] {
  return courses.map(c => {
    const courseLesson: MappedPlanCourse = {
      name: c.name,
      description: c.description,
      adjustedFinalRating: 0,
      keplerPoints: c.keplerPoints,
      keplerPointsAvailable: c.keplerPointsAvailable,
      lessonOrderLocked: c.lessonOrderLocked,
      lessons: c.lessons.map(b => {
        const timelineLesson: MappedPlanCourseLesson = {
          name: b.name,
          image: b.image,
          url: b.url,
          description: b.description,
          keplerPoints: b.keplerPoints,
          keplerPointsAvailable: b.keplerPointsAvailable,
          completedOn: b.completedOn,
          learningPlatformType: b.learningPlatformType,
          externalLessonId: b.externalLessonId,
          externalCourseId: b.externalCourseId,
          playerType: b.playerType,
          type: b.type,
          completionStatus: b.completionStatus,
          courseSlug: b.courseSlug,
        };
        return timelineLesson;
      }),
    };

    return courseLesson;
  });
}

