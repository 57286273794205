import React, { Fragment, useState } from 'react';
import { Anchor, Button, Upload } from '@keplerco/core';
import { useAppActions } from '../../overmind';
import { useParams } from 'react-router-dom';
import { IBulkUploadProps } from './bulk-upload.models';
import { useKeplerNavigate } from '../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../navigation/navigation.enums';

export function BulkUpload({ bulkUploadType, setData, onValidate }: IBulkUploadProps): JSX.Element {
  const params = useParams();
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <Fragment>
      <Upload
        description="Drop your file here or <strong>Browse</strong"
        accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']}
        error={errorMessage}
        onChange={files => {
          setSelectedFile(files[0]);
          setErrorMessage('');
          setData(undefined);
        }}
      />

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between' }}>
        <Anchor onClick={() => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationPeople}`.replace(':companySlug', params.companySlug!))}>Cancel</Anchor>

        <Button
          type="button"
          disabled={!selectedFile}
          filled
          onClick={async () => {
            if (!selectedFile) return;

            if (selectedFile.size === 0) {
              setErrorMessage('The file you selected is empty. Please select a valid file.');
              return;
            }

            const validationResult = await actions.bulkUploadValidate({
              file: selectedFile,
              companySlug: params.companySlug!,
              bulkUploadType: bulkUploadType,
            });

            if (validationResult.error) {
              setErrorMessage(validationResult.error.detail);
              return;
            }

            if (validationResult.data?.rows) {
              setData({ rows: validationResult.data.rows });
              onValidate();
            } else {
              setErrorMessage('No valid rows found, please add some data.');
            }
          }}
        >
          Validate
        </Button>
      </div>
    </Fragment>
  );
}
