import styled from 'styled-components';

export const QuestionnaireSidenav = styled.nav`
  --sidenav-width: 375px;

  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: var(--sidenav-width);
  max-width: var(--sidenav-width);
  min-width: var(--sidenav-width);
  background: linear-gradient(162.05deg, var(--background) 4.1%, transparent 42.5%);
  border-right: 1px solid var(--borders);
  padding-bottom: 10px;

  @media screen and (max-width: 1045px) {
    --sidenav-width: 210px;
  }

  @media screen and (max-width: 850px) {
    --sidenav-width: 100%;
    border: none;
    background: none;
  }
`;

export const QuestionnaireSidenavList = styled.ul`
  display: block;
  padding: 10px 0 10px 0;
  margin: 0;
  list-style: none;
  margin-bottom: auto;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

export const QuestionnaireSidenavListItem = styled.li`
  display: block;
  padding: 3px 40px 3px 0;
  margin: 0 0 10px 0;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--link-text);
  position: relative;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  will-change: font-weight;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;

  svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: auto;
    z-index: 10;
    display: none;
  }

  &::after {
    content: '';
    display: block;
    width: 14px;
    height: 1px;
    background: var(--borders);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    will-change: width, background;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  }

  &:not(.done):hover::after {
    background: var(--accent-3);
    width: 18px;
  }

  &.activeSection {
    font-weight: 700;
    color: var(--text);

    &::after {
      background: var(--primary);
      width: 25px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.done {
    cursor: not-allowed;

    &::after {
      display: none;
    }

    svg {
      display: block;
    }
  }

  @media screen and (max-width: 1045px) {
    font-size: 12px;
  }
`;

export const QuestionnaireSidenavProgressWrapper = styled.div`
  display: block;
  padding: 10px 30px;

  .caption1 {
    color: var(--secondary);
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
  }

  @media screen and (max-width: 850px) {
    padding: 10px 0;
  }
`;

export const QuestionnaireContent = styled.div`
  flex: 1;
  padding: 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  @media screen and (max-width: 850px) {
    padding: 20px 0;
  }

  @media screen and (max-width: 580px) {
    padding: 0;
  }
`;

export const QuestionnaireQuestionListWrapper = styled.ul`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 10px;
  position: relative;

  @media screen and (max-width: 850px) {
    padding-left: 0;
  }
`;

export const QuestionnaireQuestionListIcon = styled.div`
  width: 92px;
  height: 92px;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  border: 1px solid var(--borders);
  border-radius: 50%;

  object {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: contain;
  }

  @media screen and (max-width: 850px) {
    width: 72px;
    height: 72px;
  }

  @media screen and (max-width: 580px) {
    width: 62px;
    height: 62px;
  }
`;

export const QuestionnaireQuestionListTitle = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;

  .heading3 {
    color: var(--headings);
  }

  @media screen and (max-width: 850px) {
    height: 62px;
  }
`;

export const QuestionnaireQuestionListItem = styled.li`
  opacity: 0;
  display: none;
  transform-origin: top left;
  pointer-events: none;

  &.active {
    display: block;
    animation: FadeIn ease-in-out 400ms forwards;
    pointer-events: all;
  }

  @keyframes FadeIn {
    from {
      opacity: 0;
      transform: translateX(-15px);
    }

    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
`;

export const QuestionnaireFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse nowrap;
  padding-top: 45px;

  span.previous {
    display: block;
    color: var(--link-text);
    line-height: 40px;
    text-transform: uppercase;
    position: relative;
    padding-left: 18px;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border: solid 2px var(--primary);
      border-bottom: none;
      border-right: none;
      position: absolute;
      left: 5px;
      top: 50%;
      margin-top: -5px;
      transform: rotate(-45deg);
    }

    &:hover {
      color: var(--link-text_1);
    }

    @media (max-width: 580px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 850px) {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 25px 25px 25px;
    background: var(--background);
    width: 100%;
    border-top: solid 1px var(--borders);
    box-shadow: inset 0px 0px 25px var(--inner-shadow-colour);
  }
`;

export const QuestionnaireWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid var(--borders);
  position: relative;
  z-index: 1;

  @media screen and (max-width: 850px) {
    flex-flow: column-reverse nowrap;
    border: none;
  }

  @media screen and (max-width: 580px) {
    padding-bottom: 80px;
  }

  &.questionnaire {
    border: none;
    min-height: 100%;
    background: var(--background);

    ${QuestionnaireSidenav}, ${QuestionnaireContent} {
      padding-top: 130px;
    }

    ${QuestionnaireSidenav} {
      --sidenav-width: 300px;
      background: var(--cards);
    }

    ${QuestionnaireFooter} {
      padding-bottom: 50px;
    }

    @media screen and (max-width: 850px) {
      background: none;

      ${QuestionnaireSidenav}, ${QuestionnaireContent} {
        background: none;
      }
    }
  }
`;