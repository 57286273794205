import { UniqueEntity } from '../../../models/view/unique-entity';

export enum AdditionalLearningTypeEnum {
  Book = 0,
  Diploma = 1,
  InHouse = 2,
  Online = 3,
  ShortCourse = 4,
  Video = 5,
}

export const additionalLearningTypeIcons: { type: AdditionalLearningTypeEnum; name: string; iconUrl: string; listUrl: string; panelUrl: string; description: string }[] = [
  {
    type: AdditionalLearningTypeEnum.Book,
    name: 'Book',
    iconUrl: `/icons/off-platform-learning/ico_book.svg`,
    listUrl: `/additional-learning/img_book_label.svg`,
    panelUrl: `/additional-learning/img_book_panel.svg`,
    description: 'A book or article',
  },
  {
    type: AdditionalLearningTypeEnum.Diploma,
    name: 'Diploma',
    iconUrl: `/icons/off-platform-learning/ico_diploma.svg`,
    listUrl: `/additional-learning/img_degree_label.svg`,
    panelUrl: `/additional-learning/img_degree_panel.svg`,
    description: 'A degree or diploma',
  },
  {
    type: AdditionalLearningTypeEnum.InHouse,
    name: 'In-house',
    iconUrl: `/icons/off-platform-learning/ico_in_house.svg`,
    listUrl: `/additional-learning/img_in_house_label.svg`,
    panelUrl: `/additional-learning/img_in_house_panel.svg`,
    description: 'In-house training',
  },
  {
    type: AdditionalLearningTypeEnum.Online,
    name: 'Online',
    iconUrl: `/icons/off-platform-learning/ico_online.svg`,
    listUrl: `/additional-learning/img_online_label.svg`,
    panelUrl: `/additional-learning/img_online_panel.svg`,
    description: 'An online short course',
  },
  {
    type: AdditionalLearningTypeEnum.ShortCourse,
    name: 'Short Course',
    iconUrl: `/icons/off-platform-learning/ico_short_course.svg`,
    listUrl: `/additional-learning/img_short_course_label.svg`,
    panelUrl: `/additional-learning/img_short_course_panel.svg`,
    description: 'A short course',
  },
  {
    type: AdditionalLearningTypeEnum.Video,
    name: 'Video',
    iconUrl: `/icons/off-platform-learning/ico_video.svg`,
    listUrl: `/additional-learning/img_video_label.svg`,
    panelUrl: `/additional-learning/img_video_panel.svg`,
    description: 'A video or documentary',
  },
];

export interface AdditionalLearningListViewModel {
  slug: string;
  type: AdditionalLearningTypeEnum;
  keplerPoints: number;
  name: string;
  completedOn: Date;
}

export interface AdditionalLearningViewModel {
  slug: string;
  type: AdditionalLearningTypeEnum;
  name: string;
  rating: number;
  skills: UniqueEntity[];
  dateStarted: Date;
  dateCompleted: Date;
  webAddress?: string;
  reflectionId?: number;
}

export interface AdditionalLearningSearchRequest {
  searchTerm?: string;
  skillSlug?: string;
}

