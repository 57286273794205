import styled from 'styled-components';

export const ProgressChipWrapper = styled.div<{ themeColor: string }>`
  display: block;
  width: 100%;
  height: auto;

  label {
    display: block;
    font-size: 45px;
    line-height: 50px;
    margin-top: -5px;
    font-weight: bold;
    color: ${props => props.themeColor};
  }

  span {
    display: block;
    font-size: 12px;
    color: var(--accent-2);
    line-height: 20px;
  }
`;
