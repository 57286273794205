import React, { useEffect, useState } from 'react';
import { Anchor, Button, FieldController, PageHeader, Panel, RequiredValidator, TextArea, TextField } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../../overmind';
import { useParams } from 'react-router-dom';
import { EntityType, FetchType, OrganizationLevelType } from '../../../../enums';
import { ManagePeopleWidget } from './manage-people.widget';
import { useKeplerNavigate } from '../../../../navigation/guards/use-kepler-navigate';
import { CompanyEntityAssigneeListItemResponse, CompanyEntityListItemResponse, CompanyEntityResponse } from '../../../../models/overmind/company-entity';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { useForm } from 'react-hook-form';
import { EntitiesCard } from '../../../../components/entities-card.widget';
import { ManageRoleSkeleton } from './manage-role.skeleton';
import { IManageRoleLayoutPageHeaderProps, ManageRolePagePath } from './manage-role.models';
import classNames from 'classnames';
import styles from './manage-role.module.css';
import { ManageSkillsWidget } from './manage-skills.widget';
import { ImportSkillLayout } from './import-skill.layout';
import { SkeletonLoader } from '../../../../components';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

function ManageRoleLayoutPageHeader({ path, companySlug, loading, role }: IManageRoleLayoutPageHeaderProps): JSX.Element {
  if (loading) {
    return (
      <SkeletonLoader height="130px" />
    );
  }

  if (!role) {
    return (
      <PageHeader
        breadcrumbs={[
          { name: 'Role Management', url: `${PagePath.roleManagementBase}${PagePath.roleManagementRoles.replace(':companySlug', companySlug)}` },
          { name: 'Create role', url: window.location.pathname },
        ]}
        title="Create role"
        subtitle="Create role and confirm details"
        divider
      />
    );
  }

  return (
    <PageHeader
      breadcrumbs={[
        { name: 'Role Management', url: `${PagePath.roleManagementBase}${PagePath.roleManagementRoles.replace(':companySlug', companySlug)}` },
        { name: `${role?.name}`, url: `${PagePath.roleManagementBase}${(path === PagePath.roleManagementImportRole ? PagePath.roleManagementGlobalRole : PagePath.roleManagementCompanyRole).replace(':companySlug', companySlug).replace(':roleSlug', role.slug)}` },
        { name: path === PagePath.roleManagementImportRole ? 'Import role' : 'Edit role', url: window.location.pathname },
      ]}
      title={path === PagePath.roleManagementImportRole ? 'Import role' : 'Edit role'}
      subtitle={path === PagePath.roleManagementImportRole ? 'Edit role, confirm details and import role into own database' : 'Edit role and confirm details'}
      divider
    />
  );
}

export function ManageRoleLayout({ path }: { path: ManageRolePagePath }): JSX.Element {
  const { roleSlug } = useParams();
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const { control, handleSubmit, setValue } = useForm<any>({ mode: 'onBlur' });

  const [loadingRole, setLoadingRole] = useState<boolean>(false);
  const [role, setRole] = useState<CompanyEntityResponse>();

  const [loadingPeople, setLoadingPeople] = useState<boolean>(true);
  const [peopleToAssign, setPeopleToAssign] = useState<CompanyEntityAssigneeListItemResponse[]>([]);
  const [peopleToUnassign, setPeopleToUnassign] = useState<CompanyEntityAssigneeListItemResponse[]>([]);

  const [loadingSkills, setLoadingSkills] = useState<boolean>(true);
  const [skillsToAssign, setSkillsToAssign] = useState<CompanyEntityListItemResponse[]>([]);
  const [skillsToUnassign, setSkillsToUnassign] = useState<CompanyEntityListItemResponse[]>([]);

  const [showImportSkill, setShowImportSkill] = useState<boolean>(false);
  const [refetchDropdownItems, setRefetchDropdownItems] = useState<string>();

  useEffect(() => {
    async function getData() {
      if (!roleSlug) return;
      setLoadingRole(true);
      const response = await actions.getCompanyRole({ companySlug: companyVariables.slug!, roleSlug: roleSlug });
      if (!!response) {
        setRole(response);
        setValue('name', response.name);
        setValue('description', response.description);
      }
      setLoadingRole(false);
    }

    getData();
  }, [roleSlug]);

  async function assignPeople(roleSlug: string | undefined) {
    if (!roleSlug || !peopleToAssign.length) return;
    await actions.assignCompanyRole({
      entitySlug: roleSlug,
      assignees: peopleToAssign.map(person => ({
        organizationLevel: OrganizationLevelType.Learner,
        entitySlug: person.slug!,
        name: person.name,
      })),
    });
  }

  async function unassignPeople(roleSlug: string | undefined) {
    if (!roleSlug || !peopleToUnassign.length) return;
    await actions.unassignCompanyRole({
      entitySlug: roleSlug,
      assignees: peopleToUnassign.map(person => ({
        organizationLevel: OrganizationLevelType.Learner,
        entitySlug: person.slug!,
        name: person.name,
      })),
    });
  }

  async function assignSkills(roleSlug: string | undefined) {
    if (!roleSlug || !skillsToAssign.length) return;
    await Promise.all(skillsToAssign.map(async skill => {
      await actions.relateCompanyRole({
        companySlug: companyVariables.slug!,
        slug: roleSlug,
        targetSlug: skill.slug!,
        targetEntityType: EntityType.Skill,
      });

      await actions.relateCompanyRoleSkillExpectedLevel({
        jobSlug: roleSlug,
        skillSlug: skill.slug!,
        expectedLevel: skill.level,
      });
    }));
  }

  async function unassignSkills(roleSlug: string | undefined) {
    if (!roleSlug || !skillsToUnassign.length) return;
    await Promise.all(skillsToUnassign.map(async skill => {
      await actions.unrelateCompanyRole({
        companySlug: companyVariables.slug!,
        slug: roleSlug,
        targetSlug: skill.slug!,
        targetEntityType: EntityType.Skill,
      });
    }));
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={path} skeletonLoader={<ManageRoleSkeleton />}>
        <div className="pageWrapper wrapper">
          <ManageRoleLayoutPageHeader path={path} companySlug={companyVariables.slug!} loading={loadingRole} role={role} />

          <div className={styles.container}>
            <form id="manageRole">
              <EntitiesCard title="Role Details" description="Add a role name and description">
                {loadingRole ? (
                  <React.Fragment>
                    <SkeletonLoaderRow>
                      <SkeletonLoaderColumn>
                        <SkeletonLoader height="43px" width="300px" />
                      </SkeletonLoaderColumn>
                    </SkeletonLoaderRow>

                    <SkeletonLoaderRow style={{ marginBottom: 25 }}>
                      <SkeletonLoaderColumn>
                        <SkeletonLoader height="76px" />
                      </SkeletonLoaderColumn>
                    </SkeletonLoaderRow>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FieldController
                      name="name"
                      control={control}
                      rules={new RequiredValidator()}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          label="Role name"
                          validation={{
                            dirty: fieldState.isDirty || !!fieldState.error,
                            invalid: !!fieldState.error,
                            message: 'Please enter a role name',
                          }}
                        />
                      )}
                    />

                    <FieldController
                      name="description"
                      control={control}
                      rules={new RequiredValidator()}
                      render={({ field, fieldState }) => (
                        <TextArea
                          {...field}
                          label="Role description"
                          validation={{
                            dirty: fieldState.isDirty || !!fieldState.error,
                            invalid: !!fieldState.error,
                            message: 'Please enter a role description',
                          }}
                          responsive
                        />
                      )}
                    />
                  </React.Fragment>
                )}
              </EntitiesCard>
            </form>

            <EntitiesCard title="People" description="Assign and confirm people for the role">
              <ManagePeopleWidget
                loadingPeople={loadingPeople}
                setLoadingPeople={setLoadingPeople}
                setPeopleToAssign={setPeopleToAssign}
                setPeopleToUnassign={setPeopleToUnassign}
              />
            </EntitiesCard>

            <EntitiesCard title="Skills" description="Assign and confirm skills for the role">
              <ManageSkillsWidget
                path={path}
                loadingSkills={loadingSkills}
                setLoadingSkills={setLoadingSkills}
                setSkillsToAssign={setSkillsToAssign}
                setSkillsToUnassign={setSkillsToUnassign}
                onClickImportSkill={() => setShowImportSkill(true)}
                refetchDropdownItems={refetchDropdownItems}
              />
            </EntitiesCard>

            <div className={classNames('card', styles.actions)}>
              <Anchor
                onClick={() => {
                  if (path === PagePath.roleManagementImportRole) return keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementGlobalRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role!.slug)}`);
                  if (path === PagePath.roleManagementEditRole) return keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementCompanyRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role!.slug)}`);
                  keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementRoles.replace(':companySlug', companyVariables.slug!)}`);
                }}>
                Cancel
              </Anchor>

              <Button
                type="button"
                arrow={false}
                filled
                disabled={loadingRole || loadingPeople || loadingSkills}
                onClick={handleSubmit(async values => {
                  actions.startLoader({ path, type: FetchType.Sending });

                  const response = !role
                    ? await actions.createCompanyRole({ companySlug: companyVariables.slug!, ...values })
                    : await actions.updateCompanyRole({ companySlug: companyVariables.slug!, entitySlug: role.slug, ...values });

                  const roleSlug: string | undefined = typeof response === 'string' ? response : role?.slug;

                  await assignPeople(roleSlug);
                  await unassignPeople(roleSlug);
                  await assignSkills(roleSlug);
                  await unassignSkills(roleSlug);

                  keplerNavigate(`${PagePath.roleManagementBase}${!!roleSlug ? PagePath.roleManagementCompanyRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', roleSlug) : PagePath.roleManagementRoles.replace(':companySlug', companyVariables.slug!)}`);

                  actions.stopLoader(path);
                })}
              >
                Save Role
              </Button>
            </div>
          </div>
        </div>
      </PageLoaderLayer>

      <Panel
        open={showImportSkill}
        onClose={() => {
          setShowImportSkill(false);
          setRefetchDropdownItems(crypto.randomUUID());
          actions.stopLoader(path);
        }}
      >
        {!!showImportSkill && (
          <ImportSkillLayout
            supertitle="Role Management"
            onClose={() => {
              setShowImportSkill(false);
              setRefetchDropdownItems(crypto.randomUUID());
              actions.stopLoader(path);
            }}
          />
        )}
      </Panel>
    </React.Fragment>
  );
}
