import React from 'react';
import { QueueItem } from '../notification-hub.models';
import classNames from 'classnames';
import { useAppActions } from '../../../overmind';
import { TileLayoutContainer } from './tile-layout-container';
import { TileNotificationsItem, TilesNotificationsList } from './tile-hub.styles';

export function TileHub(props: { tiles: QueueItem[] }): JSX.Element {
  const actions = useAppActions();

  return (
    <TilesNotificationsList>
      {props.tiles.map(tile => {
        return (
          <TileNotificationsItem className={classNames(tile.type)} key={tile.id}>
            <TileLayoutContainer {...tile} onAction={() => actions.dismissNotification(tile.id)} />
          </TileNotificationsItem>
        );
      })}
    </TilesNotificationsList>
  );
}