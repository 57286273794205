import React, { useEffect, useState } from 'react';
import { ViewRoleLayout } from './view-role.layout';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useParams } from 'react-router-dom';
import { useAppActions } from '../../../../overmind';
import { CompanyEntityResponse } from '../../../../models/overmind/company-entity';
import { FetchType } from '../../../../enums';

export function MyCareerPage(): JSX.Element {
  const { roleSlug } = useParams();

  const actions = useAppActions();

  const [role, setRole] = useState<CompanyEntityResponse>();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.myCareerRole, type: FetchType.PageFetching });
      const response = await actions.getUserCompanyRole({ roleSlug: roleSlug! });
      setRole(response);
      actions.stopLoader(PagePath.myCareerRole);
    }

    getData();
  }, [roleSlug]);

  return (
    <ViewRoleLayout path={PagePath.myCareerRole} role={role} />
  );
}