import styled from 'styled-components';

export const AnalysisLandingLayoutInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 100px;
  gap: 30px;

  @media screen and (max-width: 900px) {
    height: auto;
    min-height: 0px;
    padding: 20px 20px 0px 20px;
  }

  @media screen and (max-width: 400px) {
    padding-top: 20px;
  }
`;

export const AnalysisLandingLayoutHero = styled.figure`
  max-width: 640px;
  width: 640px;
  flex-basis: 640px;
  padding: 0;
  margin: 0;
  display: block;
  padding-left: 60px;

  @media screen and (max-width: 1060px) {
    max-width: 440px;
    width: 440px;
    flex-basis: 440px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const AnalysisLandingLayoutHeroImage = styled.div`
  object {
    display: block;
    width: 100%;
    height: auto;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const AnalysisLandingLayoutContent = styled.section`
  flex: 1;
  max-width: calc(100% - 640px);
  width: calc(100% - 640px);
  flex-basis: calc(100% - 640px);

  .heading1 {
    line-height: 60px;

    @media screen and (max-width: 580px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .subtitle {
    padding: 15px 0 25px 0;
    line-height: 30px;
  }

  @media screen and (max-width: 1060px) {
    max-width: calc(100% - 440px);
    width: calc(100% - 440px);
    flex-basis: calc(100% - 440px);
  }

  @media screen and (max-width: 900px) {
    max-width: 700px;
    width: 90%;
    flex-basis: 90%;
    margin: auto;
    padding: 70px 0;
  }

  @media screen and (max-width: 800px) {
    max-width: unset;
    width: 90%;
    margin: auto;
    padding: 0;
  }
`;
