import React from 'react';
import { useAppState } from '../../../../overmind';
import { IRoutingMapProps } from '../../../../models/routing-map';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Navigate } from 'react-router-dom';

export function InterimReportsDownloadPageRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { user } = useAppState();

  if (!user?.downloadLearningProgressPdf) return <Navigate to={PagePath.analysisBase} />;

  return <React.Fragment>{props.children({ next: PagePath.analysisBase, previous: PagePath.analysisBase })}</React.Fragment>;
}



