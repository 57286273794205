import styled from 'styled-components';

export const TechnicalPeerEndorsementInterstitialPageWrapper = styled.div`
  @media screen and (max-width: 930px) {
    width: 90%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const TechnicalPeerEndorsementInterstitialContent = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 580px) {
    padding-top: 10px;
  }
`;

export const TechnicalPeerEndorsementInterstitialHeroImageContainer = styled.figure`
  max-width: 640px;
  width: 640px;
  flex-basis: 640px;
  padding: 0;
  margin: 0;
  display: block;

  @media screen and (max-width: 930px) {
    display: none;
  }
`;

export const TechnicalPeerEndorsementInterstitialHeroImage = styled.div`
  object {
    display: block;
    width: 100%;
    height: auto;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const TechnicalPeerEndorsementInterstitialBody = styled.section`
  flex: 1;
  max-width: calc(100% - 640px);
  width: calc(100% - 640px);
  flex-basis: calc(100% - 640px);

  .heading1 {
    @media screen and (max-width: 580px) {
      line-height: 35px;
    }
  }

  .subtitle {
    padding: 15px 0 25px 0;
    line-height: 30px;

    @media screen and (max-width: 580px) {
      line-height: 20px;
    }
  }

  @media screen and (max-width: 930px) {
    max-width: 100%;
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const TechnicalPeerEndorsementInterstitialListContainer = styled.div`
  margin: 20px 0;
`;
