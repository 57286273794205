import React, { useEffect, useReducer } from 'react';
import { FetchStatus, FetchType } from '../../../enums';
import * as store from './additional-learning.store';
import { useAppActions, useAppState } from '../../../overmind';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { AdditionalLearningFocusPanelLayout } from './additional-learning-focus-panel/additional-learning-focus-panel.layout';
import { AdditionalLearningTypeEnum } from './additional-learning.models';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { ConfirmationModalLayout } from '../../../widgets/layouts/modal-layouts/confirmation-modal/confirmation-modal.layout';
import { ReflectionFocusPanelLayout } from '../reflections/reflection-focus-panel.layout';
import { PageFilterHeader, PageFilterHeaderColumn, AppPageHeader } from '../../../theme/layout.styles';
import { AdditionalLearningSkeleton } from './additional-learning.skeleton';
import { useDataSyncReaction } from '../../../lib/data-sync.hooks';
import * as additionalLearningStyles from './additional-learning.styles';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { Button, CascadingPanel, CascadingPanels, DeleteIcon, EditIcon, IconButton, KeplerPoints, Modal, PageHeader, Searchfield, useCascadingPanelsConnector } from '@keplerco/core';

function mapLearningTypeEnum(value: AdditionalLearningTypeEnum) {
  if (value === AdditionalLearningTypeEnum.InHouse) {
    return 'In-house training';
  } else if (value === AdditionalLearningTypeEnum.ShortCourse) {
    return 'Short course';
  } else if (value === AdditionalLearningTypeEnum.Online) {
    return 'Online course';
  }

  return AdditionalLearningTypeEnum[value];
}

enum CascadingPanelIds {
  AdditionalLearning = 'AdditionalLearning',
  Reflection = 'Reflection',
}

export function AdditionalLearningPage(): JSX.Element {
  const actions = useAppActions();
  const { fetchState } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const { openPanelIds, next, close, closeAll } = useCascadingPanelsConnector();

  useDataSyncReaction(async () => {
    getData();
  });

  async function getData(type?: FetchType): Promise<void> {
    if (type !== undefined) actions.startLoader({ path: PagePath.learningJourneyAdditionalLearning, type: type });

    const data = await actions.getAdditionalLearningList(state.request);
    dispatch({ type: store.AdditionalLearningActionTypes.SetData, payload: data });

    if (type !== undefined) actions.stopLoader(PagePath.learningJourneyAdditionalLearning);
  }

  useEffect(() => {
    getData(!state.data ? FetchType.PageFetching : FetchType.Custom);
  }, [state.request?.searchTerm]);

  function closeReflections() {
    closeAll();
    getData(FetchType.PageFetching);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningJourneyAdditionalLearning} skeletonLoader={<AdditionalLearningSkeleton />}>
        <additionalLearningStyles.AdditionalLearningPageLayout className="wrapper">
          <AppPageHeader>
            <PageHeader
              breadcrumbs={[
                {
                  name: 'Learning Journey',
                  url: PagePath.learningJourneyBase,
                },
                {
                  name: 'Additional Learning',
                  url: window.location.pathname,
                },
              ]}
              title="Additional Learning"
              subtitle="Learning can take place anywhere, so if you've completed any in-person sessions, webinars, talks or learning from places outside of this platform, you can track it here. It's also important to reflect on your learning to validate your existing knowledge and skills."
            />
          </AppPageHeader>

          <additionalLearningStyles.AdditionalLearningPageContent>
            <PageFilterHeader>
              <PageFilterHeaderColumn>
                <Searchfield
                  loading={fetchState[PagePath.learningJourneyAdditionalLearning].type === FetchType.Custom && fetchState[PagePath.learningJourneyAdditionalLearning].status === FetchStatus.Active}
                  onInput={searchTerm => dispatch({ type: store.AdditionalLearningActionTypes.SetRequest, payload: !!searchTerm ? { searchTerm } : {} })}
                />
              </PageFilterHeaderColumn>

              <PageFilterHeaderColumn>
                <Button
                  filled
                  type="button"
                  arrow={false}
                  onClick={() => {
                    dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: true } });
                    next(CascadingPanelIds.AdditionalLearning);
                  }}
                >
                  Track Additional Learning
                </Button>
              </PageFilterHeaderColumn>
            </PageFilterHeader>

            {typeof state.data !== 'undefined' && state.data.length ? (
              <additionalLearningStyles.AdditionalLearningPageCardsList>
                {state.data.map(item => {
                  return (
                    <additionalLearningStyles.AdditionalLearningPageCardsListItem key={item.slug}>
                      <div className="card">
                        <additionalLearningStyles.AdditionalLearningCardDeleteButton>
                          <IconButton iconType="fill" hoverTone="light" onClick={async () => dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: item })}>
                            <DeleteIcon tone="default" />
                          </IconButton>
                        </additionalLearningStyles.AdditionalLearningCardDeleteButton>

                        <additionalLearningStyles.AdditionalLearningCardContent>
                          <additionalLearningStyles.AdditionalLearningCardContentTitle>{item.name}</additionalLearningStyles.AdditionalLearningCardContentTitle>
                          <additionalLearningStyles.AdditionalLearningCardContentType>{mapLearningTypeEnum(item.type)}</additionalLearningStyles.AdditionalLearningCardContentType>
                          <additionalLearningStyles.AdditionalLearningCardContentDate>Added: {new Date(item.completedOn).toLocaleString('en-UK', { dateStyle: 'long' })}</additionalLearningStyles.AdditionalLearningCardContentDate>
                          <additionalLearningStyles.AdditionalLearningCardContentKeplerPoints>
                            <span className="caption1">
                              <KeplerPoints trimWhiteSpace points={item.keplerPoints} /> Earned
                            </span>
                          </additionalLearningStyles.AdditionalLearningCardContentKeplerPoints>
                        </additionalLearningStyles.AdditionalLearningCardContent>

                        <additionalLearningStyles.AdditionalLearningCardEditButton>
                          <IconButton
                            iconType="stroke"
                            hoverTone="light"
                            onClick={async () => {
                              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: true, data: item.slug } });
                              next(CascadingPanelIds.AdditionalLearning);
                            }}
                          >
                            <EditIcon tone="default" size={30} />
                          </IconButton>
                        </additionalLearningStyles.AdditionalLearningCardEditButton>
                      </div>
                    </additionalLearningStyles.AdditionalLearningPageCardsListItem>
                  );
                })}
              </additionalLearningStyles.AdditionalLearningPageCardsList>
            ) : (
              <additionalLearningStyles.AdditionalLearningEmptyState>
                <EmptyState
                  badgeIconWidth={450}
                  badgeUrl={themedAssetUrl('graphics/empty-state-add.graphic.svg')}
                  title="No learning activities have been assigned yet"
                  subtitle="Have you completed any in-person sessions, webinars, talks or learning from places outside of this platform?"
                  buttons={[
                    {
                      text: 'Track Additional Learning',
                      onClick: () => {
                        dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: true } });
                        next(CascadingPanelIds.AdditionalLearning);
                      },
                    },
                  ]}
                />
              </additionalLearningStyles.AdditionalLearningEmptyState>
            )}
          </additionalLearningStyles.AdditionalLearningPageContent>
        </additionalLearningStyles.AdditionalLearningPageLayout>
      </PageLoaderLayer>

      {/* TODO: unify additional learning and reflection panel combinations into a component */}
      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={(id: string) => {
          switch (id as CascadingPanelIds) {
            case CascadingPanelIds.AdditionalLearning: {
              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });
              break;
            }

            case CascadingPanelIds.Reflection: {
              closeReflections();
              break;
            }
          }

          close(id);
        }}
      >
        <CascadingPanel id={CascadingPanelIds.AdditionalLearning}>
          <AdditionalLearningFocusPanelLayout
            slug={state.additionalLearningToEdit.data}
            path={PagePath.learningJourneyAdditionalLearning}
            onCancel={() => {
              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });
              closeAll();
            }}
            onSubmit={async (model, doReflection) => {
              actions.startLoader({ path: PagePath.learningJourneyAdditionalLearning, type: FetchType.Sending });

              const result = await actions.saveAdditionalLearning(model);

              if (!doReflection || !result?.reflectionId) {
                getData(FetchType.PageFetching);
                closeAll();
                dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });
                return;
              }

              dispatch({ type: store.AdditionalLearningActionTypes.SetReflectionId, payload: result.reflectionId });
              next(CascadingPanelIds.Reflection);
              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });

              actions.stopLoader(PagePath.learningJourneyAdditionalLearning);

              console.log('onSubmit');
            }}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingPanelIds.Reflection}>
          <ReflectionFocusPanelLayout
            reflectionId={state.reflectionId}
            path={PagePath.learningJourneyAdditionalLearning}
            onCancel={closeReflections}
            onSubmit={closeReflections}
          />
        </CascadingPanel>
      </CascadingPanels>

      <Modal open={!!state.additionalLearningToDelete} onClose={() => dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: undefined })}>
        {!!state.additionalLearningToDelete && (
          <ConfirmationModalLayout
            title="Remove additional learning"
            subtitle={`"${mapLearningTypeEnum(state.additionalLearningToDelete.type)}: ${state.additionalLearningToDelete.name}"?`}
            cancelButtonText="No, cancel"
            onClickCancel={() => dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: undefined })}
            submitButtonText="Yes, remove it"
            textAlign="left"
            onClickSubmit={async () => {
              if (!!state.additionalLearningToDelete) {
                actions.startLoader({ path: PagePath.learningJourneyAdditionalLearning, type: FetchType.Sending });
                await actions.deleteAdditionalLearning(state.additionalLearningToDelete.slug);
                getData(FetchType.PageFetching);
              }

              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: undefined });
            }}
          >
            <h5 className="heading4" style={{ fontWeight: 400 }}>
              Are you sure you want to remove{' '}
              <strong>
                {mapLearningTypeEnum(state.additionalLearningToDelete.type)}: {state.additionalLearningToDelete.name}
              </strong>
              ?
            </h5>
          </ConfirmationModalLayout>
        )}
      </Modal>
    </React.Fragment>
  );
}

