import styled from 'styled-components';

export const RaterCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  background-color: transparent;

  &.fade {
    opacity: 0.45;
  }
`;

export const RaterCardHeader = styled.header`
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
  }
`;

export const RaterCardModuleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;

  @media screen and (max-width: 580px) {
    flex-flow: column nowrap;
    align-items: stretch;
  }
`;
