import { ReducerAction } from '@keplerco/core';
import { CompanyEntityListResponse } from '../../../../../models/overmind/company-entity';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';

// state
interface ViewRoleSkillsState {
  request: CompanyEntitySearchParams | undefined;
  data: CompanyEntityListResponse | undefined;
}

export const initialState: ViewRoleSkillsState = {
  request: undefined,
  data: undefined,
};

// actions
export enum ViewRoleSkillsActionTypes {
  SetRequest,
  SetData,
}

type ViewRoleSkillsActions = ReducerAction<ViewRoleSkillsActionTypes.SetRequest, { payload: CompanyEntitySearchParams }> | ReducerAction<ViewRoleSkillsActionTypes.SetData, { payload: CompanyEntityListResponse | undefined }>;

// reducer
export function reducer(state: ViewRoleSkillsState, action: ViewRoleSkillsActions): ViewRoleSkillsState {
  if (action.type === ViewRoleSkillsActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  if (action.type === ViewRoleSkillsActionTypes.SetData) {
    return { ...state, data: action.payload };
  }

  return state;
}
