import { CompletionStatus } from '../../../enums';
import { Team, UniqueEntity } from '../../../models';

export const StatusFilters: UniqueEntity[] = [
  { name: 'All', slug: 'all' },
  { name: 'Not Started', slug: CompletionStatus[0] },
  { name: 'In Progress', slug: CompletionStatus[1] },
  { name: 'Complete', slug: CompletionStatus[2] },
];

export function mapTeamToUniqueEntity(teams?: Team[]): UniqueEntity[] {
  return teams?.map(team => {
    return {
      name: team.teamName,
      id: team.id,
      slug: team.slug,
    };
  }) as UniqueEntity[];
}
