import { safeCallback } from '@keplerco/core';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useWatchSearchParams(callback: (searchParams: string) => void) {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    safeCallback(callback, searchParams);
  }, [searchParams]);
}
