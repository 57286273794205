import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../components';
import { ListItemLayout, ListLayout } from '@keplerco/core';

export function CompaniesSkeleton(props: { isMobile: boolean; isTablet: boolean }): JSX.Element {
  return (
    <React.Fragment>
      {props.isMobile ? (
        <SkeletonLoaderContainer>
          <SkeletonLoaderRow style={{ marginTop: 30, marginBottom: 5 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="60px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ borderBottom: '1px solid var(--borders)', paddingBottom: 30 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="65px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ alignItems: 'center', marginBottom: 15 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="30px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn>
              <SkeletonLoader height="50px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="45px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 20 }}>
            <SkeletonLoaderColumn>
              <ListLayout>
                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>
              </ListLayout>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn flex={1} />

            <SkeletonLoaderColumn flex={8}>
              <SkeletonLoader height="25px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn flex={1} />
          </SkeletonLoaderRow>
        </SkeletonLoaderContainer>
      ) : props.isTablet ? (
        <SkeletonLoaderContainer>
          <SkeletonLoaderRow style={{ marginTop: 30, marginBottom: 5 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="100px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ borderBottom: '1px solid var(--borders)', paddingBottom: 30 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="55px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn flex={1}>
              <SkeletonLoader height="35px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn flex={2}>
              <SkeletonLoader height="50px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn flex={1}>
              <SkeletonLoader height="50px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 20 }}>
            <SkeletonLoaderColumn>
              <ListLayout>
                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>
              </ListLayout>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn />

            <SkeletonLoaderColumn>
              <SkeletonLoader height="25px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn />
          </SkeletonLoaderRow>
        </SkeletonLoaderContainer>
      ) : (
        <SkeletonLoaderContainer>
          <SkeletonLoaderRow style={{ marginTop: 30, marginBottom: 5 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="50px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ borderBottom: '1px solid var(--borders)', paddingBottom: 30 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="25px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn flex={2} />

            <SkeletonLoaderColumn flex={1}>
              <SkeletonLoader height="50px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn flex={1}>
              <SkeletonLoader height="50px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <ListLayout>
                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>

                <ListItemLayout>
                  <SkeletonLoader height="60px" />
                </ListItemLayout>
              </ListLayout>
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn />

            <SkeletonLoaderColumn>
              <SkeletonLoader height="25px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn />
          </SkeletonLoaderRow>
        </SkeletonLoaderContainer>
      )}
    </React.Fragment>
  );
}

