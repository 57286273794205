import styled from 'styled-components';

export const AnalysisPageWrapper = styled.div`
  display: block;

  &.full {
    padding: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 5%;
  }

  &.padBottom {
    margin-bottom: 120px;

    @media (max-width: 580px) {
      margin-bottom: 80px;
    }
  }
`;

export const AnalysisTeamAvatarIcon = styled.div`
  width: 25px;
  height: 25px;
  border: solid 1px var(--accent-2);
  border-radius: 25px;
  overflow: hidden;
`;

export const AnalysisTeamMemberNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--cards);
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: auto;
  box-shadow: 0px 4px 20px var(--outer-shadow-colour_1);
  border-radius: 50px;
  padding: 10px;
`;

export const AnalysisTeamMemberListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  overflow: auto;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  padding: 20px 0 20px 10px;
  scrollbar-color: var(--primary) var(--primary);
  scrollbar-width: thin;

  @media (max-width: 580px) {
    &.scrollbar::-webkit-scrollbar {
      display: none;
    }
    max-height: 50vh;
    scrollbar: none;
    scrollbar-color: transparent transparent;
  }
`;

export const AnalysisButtonContainer = styled.div`
  margin-top: 20px;
`;

export const AnalysisPageHeader = styled.div`
  display: block;
  width: 100%;
`;

export const AnalysisAddSkillsContent = styled.div`
  width: 100%;
  height: auto;
`;
