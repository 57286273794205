import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { AnalysisLandingPage } from '../../pages/analysis/landing/analysis-landing/analysis-landing.page';
import { CheckTeamPage } from '../../pages/analysis/peer-endorsements/check-team/check-team.page';
import { SkillFrequencyPage } from '../../pages/analysis/peer-endorsements/skill-frequency/skill-frequency.page';
import { RatingTechnicalSelfAssessmentPage } from '../../pages/analysis/rating/rating-technical/rating-technical-self-assessment.page';
import { CompletedPage } from '../../pages/analysis/landing/completed/completed.page';
import { QuestionnairesPage } from '../../pages/analysis/questionnaires/questionnaires.page';
import { QuestionnairePage } from '../../pages/analysis/questionnaires/questionnaire.page';
import { TechnicalLandingPage } from '../../pages/analysis/landing/technical-landing/technical-landing.page';
import { CareerPathPage } from '../../pages/analysis/career-path/career-path.page';
import { RatingTechnicalPeerEndorsementInterstitialPage } from '../../pages/analysis/rating/rating-technical/rating-technical-peer-endorsement-interstitial/rating-technical-peer-endorsement-interstitial.page';
import { RatingTechnicalPeerEndorsementLandingPage } from '../../pages/analysis/rating/rating-technical/rating-technical-peer-endorsement-landing/rating-technical-peer-endorsement-landing.page';
import { RatingTechnicalPeerEndorsementPage } from '../../pages/analysis/rating/rating-technical/rating-technical-peer-endorsement.page';
import { RatingCareerPathsLandingPage } from '../../pages/analysis/rating/rating-career-paths/rating-career-paths-landing.page';
import { RatingCareerPathsPage } from '../../pages/analysis/rating/rating-career-paths/rating-career-paths.page';
import { InterimReportDownloadsPage } from '../../pages/analysis/landing/interim-reports-download/interim-reports-download.page';
import { ErrorRedirect } from './routers.helpers';

export function AnalysisRouter(): JSX.Element {
  return (
    <Routes>
      <Route path={PagePath.root} element={<AnalysisLandingPage />} />
      <Route path={PagePath.analysisInterimDownload} element={<InterimReportDownloadsPage />} /> {/* Manager */}
      <Route path={PagePath.analysisTechnical} element={<TechnicalLandingPage />} /> {/* Manager */}
      <Route path={PagePath.analysisTechnicalCheckTeam} element={<CheckTeamPage />} /> {/* Manager */}
      <Route path={PagePath.analysisTechnicalSkillFrequency} element={<SkillFrequencyPage />} /> {/* Learner */}
      <Route path={PagePath.analysisCareerPaths} element={<CareerPathPage />} />
      <Route path={PagePath.analysisRatingTechnical} element={<RatingTechnicalPeerEndorsementInterstitialPage />} />
      <Route path={PagePath.analysisRatingTechnicalSelfAssessment} element={<RatingTechnicalSelfAssessmentPage />} /> {/* Learner */}
      <Route path={PagePath.analysisRatingTechnicalPeerEndorsements} element={<RatingTechnicalPeerEndorsementLandingPage />} />
      <Route path={PagePath.analysisRatingTechnicalPeerEndorsement} element={<RatingTechnicalPeerEndorsementPage />} />
      <Route path={PagePath.analysisRatingTechnicalCompleted} element={<CompletedPage />} /> {/* Champion */}
      <Route path={PagePath.analysisRatingCareerPaths} element={<RatingCareerPathsLandingPage />} />
      <Route path={PagePath.analysisRatingCareerPath} element={<RatingCareerPathsPage />} />
      <Route path={PagePath.analysisBehavioural} element={<QuestionnairesPage />} />
      <Route path={PagePath.analysisBehaviouralQuestionnaire} element={<QuestionnairePage />} />
      
      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}


