import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components';
import { ListItemLayout, ListLayout } from '@keplerco/core';

export function SkillFrequencySkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ alignItems: 'flex-end', paddingBottom: 40 }}>
        <SkeletonLoaderColumn flex={6}>
          <SkeletonLoader height="115" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <ListLayout>
          <ListItemLayout>
            <SkeletonLoader height="120" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="120" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="120" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="120" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="120" />
          </ListItemLayout>
        </ListLayout>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
