import { IconToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function ExportFileIcon(props: IconToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 19 21">
      <path
        fill={tone}
        d="M18.6934 13.9525a1.147413 1.147413 0 0 0-.21-.33l-3-3c-.1883-.1883-.4437-.2941-.71-.2941s-.5217.1058-.71.2941c-.1883.1883-.2941.4437-.2941.71s.1058.5217.2941.71l1.3 1.29H9.77344c-.26522 0-.51957.1054-.70711.2929-.18754.1875-.29289.4419-.29289.7071 0 .2652.10535.5196.29289.7071.18754.1876.44189.2929.70711.2929h5.58996l-1.3 1.29c-.0937.093-.1681.2036-.2189.3254-.0507.1219-.0769.2526-.0769.3846s.0262.2627.0769.3846c.0508.1219.1252.2325.2189.3254.093.0937.2036.1681.3255.2189.1218.0508.2525.0769.3845.0769s.2628-.0261.3846-.0769c.1219-.0508.2325-.1252.3254-.2189l3-3c.0926-.0939.1642-.2063.21-.33.1001-.2434.1001-.5165 0-.76Zm-6.92 4.38H3.77344c-.26522 0-.51957-.1053-.70711-.2929-.18754-.1875-.29289-.4419-.29289-.7071V3.33252c0-.26522.10535-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289h5v3c0 .79565.31607 1.55871.87868 2.12132.56258.56261 1.32568.87868 2.12128.87868h4c.1975-.00099.3902-.06039.5539-.17072a.9999.9999 0 0 0 .3661-.44928c.0766-.18211.0975-.38282.0602-.57681-.0374-.19399-.1314-.37257-.2702-.51319l-6-6.000001a1.067657 1.067657 0 0 0-.28-.19h-.09L9.83344.33252h-6.06c-.79565 0-1.55871.31607-2.12132.87868-.56261.56261-.878682 1.32567-.878682 2.12132V17.3325c0 .7957.316072 1.5587.878682 2.1213.56261.5626 1.32567.8787 2.12132.8787h7.99996c.2653 0 .5196-.1053.7071-.2929.1876-.1875.2929-.4419.2929-.7071 0-.2652-.1053-.5196-.2929-.7071-.1875-.1875-.4418-.2929-.7071-.2929Zm-1-14.58998 2.59 2.59h-1.59c-.2652 0-.5195-.10536-.7071-.29289-.1875-.18754-.2929-.44189-.2929-.70711v-1.59Z"
      />
    </svg>
  );
}

