import React from 'react';
import { QueueItem } from '../../../components';
import { TileNotificationsItemBody, TileNotificationsItemBodyClose, TileNotificationsItemBodyContent, TileNotificationsItemBodyIcon } from '../../../components/notification-hub/tile-hub/tile-hub.styles';
import { KeplerPoints } from '@keplerco/core';

export function ActivityTileLayout(props: QueueItem & { onAction: () => void }): JSX.Element {
  return (
    <TileNotificationsItemBody>
      <TileNotificationsItemBodyIcon>
        <object data="/badges/badge_KP.svg" type="image/svg+xml">
          <img src="/badges/badge_KP.svg" alt="" />
        </object>{' '}
      </TileNotificationsItemBodyIcon>

      <TileNotificationsItemBodyContent>
        <h6 className="subtitle">
          You've earned
          <KeplerPoints forceFontWeight="bold" points={Math.round(props.amount ?? 0)} />
          for completing:
        </h6>

        <p className="body1">{props.title}</p>
      </TileNotificationsItemBodyContent>

      <TileNotificationsItemBodyClose>
        <button onClick={props.onAction} />
      </TileNotificationsItemBodyClose>
    </TileNotificationsItemBody>
  );
}

