import { ReportType } from '../../models';
interface Paging {
  pageData: ReportType[];
  currentPage: number;
  pageSize: number;
}

interface ReportManagementCMSState {
  reports: ReportType[] | undefined;
  paging: Paging;
  report: ReportType | undefined;
}

export const initialState: ReportManagementCMSState = {
  reports: undefined,
  paging: { currentPage: 1, pageSize: 0, pageData: [] },
  report: undefined,
};

export enum ReportManagementCMSActionTypes {
  SetReports,
  SetPaging,
  SetReport,
}

type ReportManagementCMSActions = { type: ReportManagementCMSActionTypes.SetReports; payload: ReportType[] | undefined } | { type: ReportManagementCMSActionTypes.SetPaging; payload: Paging } | { type: ReportManagementCMSActionTypes.SetReport; payload: ReportType | undefined };

export function reducer(state: ReportManagementCMSState, action: ReportManagementCMSActions): ReportManagementCMSState {
  switch (action.type) {
    case ReportManagementCMSActionTypes.SetReports:
      return { ...state, reports: action.payload };
    case ReportManagementCMSActionTypes.SetReport:
      return { ...state, report: action.payload };
    case ReportManagementCMSActionTypes.SetPaging:
      return {
        ...state,
        paging: {
          ...state.paging,
          pageData: action.payload.pageData,
          currentPage: action.payload.currentPage,
          pageSize: action.payload.pageSize,
        },
      };
    default:
      return state;
  }
}

