export enum PermissionType {
  Analytics = 0,
  Assessments = 1,
  LearningManagement = 2,
  Administration = 4,
  Courses = 8,
  Skills = 16,
  SkillLevelManagement = 32,
  RoleManagement = 64,
}
