import { ThemeColours, colourString } from '@keplerco/core';
import styled from 'styled-components';

export const YourSkillsModalCardSubtitle = styled.h6`
  padding-top: 0px;
  font-weight: 700;
  font-family: var(--headings);
  font-size: 18px;
  width: 100%;
  line-height: 22px;
  height: 44px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
  margin-bottom: 5px;
`;

export const YourSkillsModalCard = styled.div<{ themeColor: ThemeColours }>`
  padding-top: 18px;
  padding-bottom: 18px;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-left: 30px;
  border: solid 1px var(--borders);
  background: var(--cards);

  &::after {
    content: '';
    width: 10px;
    height: 100%;
    background: ${props => colourString(props.themeColor)};
    position: absolute;
    left: 0;
    top: 0;
  }

  .yourSkillsChipWrapper {
    padding-top: 4px;
  }
`;

export const YourSkillsModalHeader = styled.header`
  justify-content: flex-start;
  padding-bottom: 10px;
`;

export const YourSkillsModalBodyDescription = styled.h6`
  padding-bottom: 30px;
`;

export const YourSkillsModalContent = styled.div`
  text-align: left;
  pointer-events: none;
`;

