import { Context } from '../..';
import { AuthenticationStatus, ErrorSignalTypes } from '../../../enums';
import { ConfirmNewEmailModel } from '../../../models/confirm-new-email-model';
import { ISettings } from '../../../models/settings';
import { User, UserEmail } from '../../../models/user';
import { Mode, PagePath } from '../../../navigation/navigation.enums';
import * as base from '../base';
import { handleErrorToErrorPage } from '../functions';

export async function getProfile(context: Context, logout: boolean = false): Promise<void> {
  const request: base.IRequest = { url: base.url('user', 'profile'), authenticated: true };
  const response: base.IResponse<User> = await base.request(request);

  if (response.error) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);
    return;
  }

  if (!!response.data) {
    context.state.user = response.data;
    context.state.authenticationStatus = AuthenticationStatus.Authenticated;

    const modeString = window.localStorage.getItem(`${response.data.learnerSlug}-mode`);
    if (!!modeString) {
      context.state.mode = modeString as Mode;
      if (modeString === Mode.PlatformManagement && window.location.pathname.includes(PagePath.companies)) context.state.sideNavVisible = false;
    }

    context.actions.getActiveCompanySlug(response.data);

    return;
  }

  if ((response.data as any)?.message === 'You are not logged in.') {
    context.state.authenticationStatus = logout ? AuthenticationStatus.UnAuthenticated : AuthenticationStatus.AuthenticationFailed;
    return;
  }

  context.state.authenticationStatus = AuthenticationStatus.UnAuthenticated;
}

export async function fetchUserSettingsProfile(context: Context): Promise<ISettings | undefined> {
  const request: base.IRequest = { url: base.url(`user`, `settings-profile`), authenticated: true };
  const response: base.IResponse<ISettings> = await base.request(request);

  if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);

  if (response.data) context.state.settings = response.data;

  return response.data;
}

export async function saveUserProfile(context: Context, settings: ISettings): Promise<void> {
  if (context.state.settings) settings.department = context.state.settings.department;

  const request: base.IRequest = { url: base.url('user', 'settings-profile'), authenticated: true, method: 'POST', body: JSON.stringify(settings) };
  await base.request(request);

  context.state.settings = settings;

  await getProfile(context);
  await fetchUserSettingsProfile(context);
  context.actions.initialiseAuthenticatedState();
}

export async function addEmail(context: Context, email: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `add-email`), authenticated: true, method: 'POST', body: JSON.stringify({ email: email.trim() }) };
  const response: base.IResponse<User> = await base.request(request);

  // if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.statusCode ?? 500}`, window.location.pathname);
  console.log(response.error);

  await getProfile(context);
  await fetchUserSettingsProfile(context);
}

export async function removeEmail(context: Context, userEmail: UserEmail): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `remove-email`), authenticated: true, method: 'POST', body: JSON.stringify(userEmail) };
  const response: base.IResponse<User> = await base.request(request);

  if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);

  await getProfile(context);
  await fetchUserSettingsProfile(context);
}

export async function changePrimaryEmail(context: Context, email: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `change-primary-email`), authenticated: true, method: 'POST', body: JSON.stringify(email) };
  const response: base.IResponse<User> = await base.request(request);

  if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);

  await getProfile(context);
  await fetchUserSettingsProfile(context);
}

export async function confirmNewEmail(context: Context, confirmNewEmailModel: ConfirmNewEmailModel): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `confirm-new-email?userId=${confirmNewEmailModel.userId}&code=${confirmNewEmailModel.code}&emailAddress=${confirmNewEmailModel.emailAddress}`), authenticated: true };
  const response: base.IResponse<User> = await base.request(request);

  if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);

  await getProfile(context);
  await fetchUserSettingsProfile(context);
}

export async function resendVerification(context: Context, email: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `resend-verification`), authenticated: true, method: 'POST', body: JSON.stringify(email) };
  const response: base.IResponse<User> = await base.request(request);

  if (response.error) handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);

  await getProfile(context);
  await fetchUserSettingsProfile(context);
}
