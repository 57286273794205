import { DropdownItem } from '@keplerco/core';
import { UniqueEntity } from '../models';

export function updateItems(currentItems: DropdownItem[], value: string) {
  const nextItems = currentItems.map(item => ({ ...item }));

  nextItems.forEach(item => {
    item.selected = item.value === value;
  });

  return nextItems;
}

export function clearItems(currentItems: DropdownItem[]): DropdownItem[] {
  const nextItems = currentItems.map(item => ({ ...item }));
  nextItems.forEach(item => (item.selected = false));
  return nextItems;
}

export function generateItems(entities: UniqueEntity[] | undefined, onClick: (value: string) => void): DropdownItem[] {
  const items: DropdownItem[] = [];

  entities?.forEach(entity => {
    if (!items.some(option => option.value === entity.slug)) items.push({ label: entity.name, value: entity.slug!, onClick: () => onClick(entity.slug!) });
  });

  return items;
}

