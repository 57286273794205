import React, { useImperativeHandle, useRef } from 'react';
import { NavLink, NavLinkProps, useSearchParams } from 'react-router-dom';

const REDIRECT_TO = 'redirectTo';

const KeplerNavlink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, outerRef) => {
  const { children, to } = props;
  const cleansedProps = {
    ...props,
    children: undefined,
  };

  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get(REDIRECT_TO);

  const innerRef = useRef<HTMLAnchorElement>(null);
  useImperativeHandle(outerRef, () => innerRef.current!, []);

  return (
    <NavLink {...cleansedProps} to={!!redirectTo ? `${to}?${REDIRECT_TO}=${redirectTo}` : to}>
      {children}
    </NavLink>
  );
});

KeplerNavlink.displayName = 'KeplerNavlink';

export default KeplerNavlink;