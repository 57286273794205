import { ThemeColours, colourString } from '@keplerco/core';
import styled, { css } from 'styled-components';

export const LessonCardHeader = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: 130px;
  width: 100%;
  display: flex;
  background-color: var(--primary);

  img,
  svg {
    height: auto;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @supports (aspect-ratio: 16 / 9) {
    padding-top: 0;
    aspect-ratio: 16 / 9;
  }

  &.done {
    opacity: 0.8;
  }
`;

export const LessonCardBody = styled.div`
  height: 100%;
  padding: 15px;

  display: flex;
  flex-direction: column;

  svg.planet-marker {
    width: 20px;
    height: auto;
  }

  .caption1 {
    display: flex;
    align-items: center;

    margin-top: auto;
    padding-top: 15px;

    color: var(--apple);
  }

  .subtitle {
    padding-top: 3px;
  }

  .body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: left;
  }
`;

export const LessonCardFooter = styled.div<{ backgroundColour: ThemeColours; colour: ThemeColours }>`
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;
  padding: 0 25px;

  background: ${props => colourString(props.backgroundColour)};

  svg {
    margin-top: 3px;

    &.lock {
      margin-top: 0;
    }
  }

  .caption1 {
    color: ${props => colourString(props.colour)};
  }

  &.borderTop {
    border-top: 1px solid var(--borders);
  }
`;

export const LessonCardWrapper = css`
  position: relative;
  display: block;
  height: 100%;
  padding-top: 130px;
  border: solid 1px var(--borders);
  border-radius: 8px;
  background: var(--cards);
  box-shadow: 0px 4px 8px var(--outer-shadow-colour);
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;

  .caption1 {
    text-transform: uppercase;
    font-weight: bold;
  }

  &.disabled {
    cursor: not-allowed;

    img {
      filter: grayscale(100%);
    }

    .subtitle,
    .caption2 {
      color: var(--default);
    }

    .caption1 {
      color: var(--text_1);
    }

    svg path {
      fill: var(--text_1);
    }
  }

  &.footer {
    padding-bottom: 50px;
  }
`;

export const LessonCardWrapperAnchor = styled.a`
  ${LessonCardWrapper}
`;

export const LessonCardWrapperBlock = styled.span`
  ${LessonCardWrapper}
`;
