import styled from 'styled-components';
import { LandingPageContent, LandingPageImage, LandingPageWrapper } from '../landing.page.styles';

export const TechnicalLandingPageImage = styled(LandingPageImage)`
  position: relative;
  width: 50%;

  object,
  img {
    position: absolute;
    bottom: 0;
    left: -7vw;

    height: 100%;
    object-fit: cover;
    object-position: right top;

    @media (min-width: 1441px) {
      width: calc(((100vw - 1250px - 150px) / 2) + 100%);
      left: calc(((100vw - 1250px) / 2) * -1);
    }
  }
`;

export const TechnicalLandingPageContent = styled(LandingPageContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

export const TechnicalLandingPageWrapper = styled(LandingPageWrapper)`
  align-items: stretch;
`;
