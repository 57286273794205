import { IconToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function BurgerIcon(props: IconToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.364 13.114C10.364 13.5282 10.6998 13.864 11.114 13.864H29.114C29.5282 13.864 29.864 13.5282 29.864 13.114C29.864 12.6997 29.5282 12.364 29.114 12.364H11.114C10.6998 12.364 10.364 12.6997 10.364 13.114ZM10.364 27C10.364 27.4142 10.6998 27.75 11.114 27.75H29.114C29.5282 27.75 29.864 27.4142 29.864 27C29.864 26.5858 29.5282 26.25 29.114 26.25H11.114C10.6998 26.25 10.364 26.5858 10.364 27ZM11.114 20.75C10.6998 20.75 10.364 20.4142 10.364 20C10.364 19.5858 10.6998 19.25 11.114 19.25H29.114C29.5282 19.25 29.864 19.5858 29.864 20C29.864 20.4142 29.5282 20.75 29.114 20.75H11.114Z"
        fill={tone}
      />
    </svg>
  );
}

