import React from 'react';
import { IQuestionnaireStepHeaderProps } from './create-skill-assessment.models';

export function QuestionnaireStepHeader(props: IQuestionnaireStepHeaderProps): JSX.Element {
  return (
    <React.Fragment>
      <h4 className="heading4">{props.title}</h4>
      <span className="caption1">{props.descriptions}</span>
    </React.Fragment>
  );
}

