import React from 'react';
import { SkeletonLoader } from '../../../../components';
import { ActiveAssessmentInfoContainer } from './active-assessment-focus-panels.styles';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { ListItemLayout, ListLayout } from '@keplerco/core';

export function ActiveAssessmentFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="55px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <div className="dialogBodyLayout">
        <ActiveAssessmentInfoContainer>
          <SkeletonLoaderRow style={{ marginBottom: 10 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="20px" width="250px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="20px" width="250px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        </ActiveAssessmentInfoContainer>

        <ListLayout>
          <ListItemLayout>
            <SkeletonLoader height="90px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="90px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="90px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="90px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="90px" />
          </ListItemLayout>
        </ListLayout>
      </div>

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="25px" width="40px" />
        <SkeletonLoader height="50px" width="300px" />
      </footer>
    </div>
  );
}

