import { search } from '@keplerco/core';
import { SkillsFilters } from './skills.models';
import { LearnerSkillDetail } from '../../../models/view/courses';
import { CompletionStatus } from '../../../enums';

export function filterData(currentData: LearnerSkillDetail[] | undefined, filters: SkillsFilters): LearnerSkillDetail[] {
  if (!currentData) return [];

  let nextData: LearnerSkillDetail[] = structuredClone(currentData);

  if (filters.query !== undefined) nextData = search(nextData, filters.query, 'name');

  if (filters.sortAscending !== undefined) nextData = nextData.sort((a, b) => (filters.sortAscending ? (a.learnerScore?.percentage ?? 0) - (b.learnerScore?.percentage ?? 0) : (b.learnerScore?.percentage ?? 0) - (a.learnerScore?.percentage ?? 0)));

  if (filters.completionStatus !== undefined) {
    switch (filters.completionStatus) {
      case CompletionStatus.Completed: {
        nextData = nextData.filter(skill => skill.totalActivityCount > 0 && skill.completedActivityCount === skill.totalActivityCount);
        break;
      }

      case CompletionStatus.NotStarted: {
        nextData = nextData.filter(skill => skill.totalActivityCount > 0 && skill.completedActivityCount === 0);
        break;
      }

      case CompletionStatus.InProgress: {
        nextData = nextData.filter(skill => skill.totalActivityCount > 0 && skill.completedActivityCount > 0 && skill.completedActivityCount < skill.totalActivityCount);
        break;
      }

      default: {
        break;
      }
    }
  }

  return nextData;
}
