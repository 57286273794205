import React, { useEffect, useState } from 'react';
import { ActiveAssessmentAvatarIconContainer, ActiveAssessmentInfoContainer } from './active-assessment-focus-panels.styles';
import { ActiveAssessmentFocusPanelSkeleton } from './active-assessment-focus-panel.skeleton';
import { Anchor, AvatarIcon, Button, Chip, FocusPanelHeader, ListItemLayout, ListLayout, filteredGraphColours, useMatchScreenWidth } from '@keplerco/core';
import { CompletionStatus, FetchType } from '../../../../enums';
import { FocusPanelLoaderLayer } from '../../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { IActiveAssessmentFocusPanelLayoutProps } from './active-assessment-focus-panels.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useAppActions } from '../../../../overmind';
import { UserAssessmentStatusList } from '../../../../models/view/user-assessment-status-list';
import classNames from 'classnames';

export function ActiveAssessmentFocusPanelLayout(props: IActiveAssessmentFocusPanelLayoutProps) {
  const actions = useAppActions();

  const isTablet = useMatchScreenWidth('tablet');

  const [activeAssessment, setActiveAssessment] = useState<UserAssessmentStatusList>();

  useEffect(() => {
    async function getData() {
      if (!props.activeAssessmentSlug) return;

      actions.startLoader({ path: PagePath.analyticsOverview, type: FetchType.DialogFetching });
      const data = await actions.getUserSkillAssessmentStatusList(props.activeAssessmentSlug);
      setActiveAssessment(data);
      actions.stopLoader(PagePath.analyticsOverview);
    }

    getData();
  }, []);

  return (
    <FocusPanelLoaderLayer path={PagePath.analyticsOverview} skeletonLoader={<ActiveAssessmentFocusPanelSkeleton />}>
      <div className="panelContent">
        <FocusPanelHeader title={activeAssessment?.assessmentName} />

        <div className="dialogBodyLayout" style={{ paddingBottom: 65 }}>
          <ActiveAssessmentInfoContainer>
            <p className="body2">
              {/* TODO: stringify */}
              Includes: {activeAssessment?.assessmentType}
            </p>

            <p className="body2">
              Assigned to:{' '}
              {activeAssessment?.assessmentGroups.map((assessmentGroup, index) => {
                if (index === 0) return assessmentGroup;

                return `, ${assessmentGroup}`;
              })}
            </p>
          </ActiveAssessmentInfoContainer>

          <ListLayout>
            {activeAssessment?.list
              .sort(userAssessmentStatus => (userAssessmentStatus.completionStatus === CompletionStatus.Completed ? 1 : -1))
              .map((userAssessmentStatus, index) => {
                const colour = filteredGraphColours[index % filteredGraphColours.length];

                return (
                  <ListItemLayout key={userAssessmentStatus.firstName + userAssessmentStatus.lastName}>
                    <div className="card glass">
                      <div className="cardListItemContentLayout">
                        <div className={classNames('cardListItemBodyLayout', isTablet ? 'directionColumn' : 'directionRow')}>
                          <ActiveAssessmentAvatarIconContainer>
                            <AvatarIcon name={userAssessmentStatus} backgroundColour={colour} />
                          </ActiveAssessmentAvatarIconContainer>
                          {userAssessmentStatus.firstName} {userAssessmentStatus.lastName}
                        </div>

                        <footer className="cardListItemFooterLayout">
                          {userAssessmentStatus.completionStatus === CompletionStatus.Completed && (
                            <Chip label="Complete" backgroundColour="apple" colour="white" />
                          )}

                          {userAssessmentStatus.completionStatus === CompletionStatus.InProgress && (
                            <Chip label="In Progress" backgroundColour="baby-blue" colour="white" />
                          )}

                          {userAssessmentStatus.completionStatus === CompletionStatus.NotStarted && (
                            <Chip label="Not Started" backgroundColour="grape" colour="white" />
                          )}
                        </footer>
                      </div>
                    </div>
                  </ListItemLayout>
                );
              })}
          </ListLayout>
        </div>

        <footer className="panelFooter">
          <Anchor onClick={props.onClick}>Back</Anchor>

          <Button type="button" filled arrow={false} chubby onClick={props.onClick}>
            Got It
          </Button>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}

