import styled from 'styled-components';

// timeline
export const TimelineWrapper = styled.div`
  display: block;
  width: 100%;
`;

// collapsible
export const CollapsibleContainer = styled.div`
  padding-top: 30px;
`;

export const CollapsibleCardRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

// empty state
export const SkillLayoutEmptyState = styled.div`
  padding: 80px 0;

  @media screen and (max-width: 720px) {
    padding: 50px 0;
  }
`;

