import React from 'react';
import { useAppActions } from '../../../overmind';
import { QueueItem } from '../notification-hub.models';
import { ModalLayoutContainer } from './modal-layout-container';
import { Modal } from '@keplerco/core';
import { suppressModal } from './modal-hub.helpers';

export function ModalHub(props: QueueItem): JSX.Element | null {
  const actions = useAppActions();
  if (suppressModal(props)) {
    return null;
  }
  return (
    <Modal open onClose={() => actions.dismissNotification(props.id)}>
      <ModalLayoutContainer onAction={() => actions.dismissNotification(props.id)} {...props} />
    </Modal>
  );
}
