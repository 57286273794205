export enum SortField {
  Name = 0,
  Department = 1,
  JobTitle = 2,
  TeamsCount = 7,
  PeopleCount = 8,
  DateCreated = 9,
  Team = 11,
  SkillsMapped = 10,
  LessonCount = 12,
  Complete = 13,
  Email = 14,
}
