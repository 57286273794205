import React from 'react';
import { ISyncContentCardBaseProps, ISyncContentCardProps } from './sync-content-card.models';
import {
  SyncContentCardWrapperCompact,
  SyncContentCardWrapperCompactButton,
  SyncContentCardWrapperCompactLabel,
  SyncContentCardWrapperLarge,
  SyncContentCardWrapperLargeButton,
  SyncContentCardWrapperLargeContent,
  SyncContentCardWrapperLargeContentIcon,
  SyncContentCardWrapperLargeContentText,
  SyncContentCardWrapperLargeLabel,
} from './sync-content-card.styles';
import { formatSyncDate } from './sync-content-card.helpers';
import classNames from 'classnames';
import { Button } from '@keplerco/core';

function SyncContentCardLarge(props: ISyncContentCardBaseProps & { hideContent?: boolean }): JSX.Element {
  return (
    <SyncContentCardWrapperLarge>
      <SyncContentCardWrapperLargeContent className={classNames({ hideContent: props.hideContent })}>
        <SyncContentCardWrapperLargeContentIcon>
          <svg fill="none" viewBox="0 0 58 59">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29 20.075c-2.503 0-4.758 1.11-6.315 2.893-.395.446-1.082.5-1.535.098a1.087 1.087 0 0 1-.102-1.534C22.996 19.314 25.834 17.9 29 17.9c5.837 0 10.541 4.774 10.655 10.658l.53-.544a1.088 1.088 0 0 1 1.538-.011c.43.413.437 1.098.017 1.533l-2.392 2.447a1.097 1.097 0 0 1-.778.326c-.292 0-.573-.12-.778-.326L35.4 29.536a1.082 1.082 0 0 1 .017-1.533 1.088 1.088 0 0 1 1.538.01l.524.534c-.115-4.72-3.89-8.472-8.479-8.472Zm-9.57 5.166c.293 0 .573.12.777.326l2.393 2.447c.42.435.412 1.12-.017 1.533a1.088 1.088 0 0 1-1.538-.01l-.524-.534c.115 4.72 3.89 8.472 8.479 8.472 2.503 0 4.758-1.11 6.315-2.893a1.086 1.086 0 0 1 1.535-.098c.45.392.497 1.077.102 1.534-1.948 2.218-4.786 3.632-7.952 3.632-5.837 0-10.541-4.774-10.655-10.657l-.53.543a1.088 1.088 0 0 1-1.538.011 1.082 1.082 0 0 1-.017-1.533l2.392-2.447c.205-.207.485-.326.778-.326Z"
              fill="var(--text)"
            />
          </svg>
        </SyncContentCardWrapperLargeContentIcon>

        <SyncContentCardWrapperLargeContentText>
          <h5 className="heading5">Course sync</h5>
          <p className="body2">Due to some courses being provided by external sources, it may take some time to get the latest data. You may attempt a manual sync to get the latest data.</p>
        </SyncContentCardWrapperLargeContentText>
      </SyncContentCardWrapperLargeContent>

      <SyncContentCardWrapperLargeButton>
        <Button onClick={props.onClick} type="button" theme="dark" square loaderText="Syncing" isLoading={props.isSyncing}>
          Sync
        </Button>
      </SyncContentCardWrapperLargeButton>

      <SyncContentCardWrapperLargeLabel>
        {!!props.lastSyncDate && <span className="caption2">{formatSyncDate(props.lastSyncDate)}</span>}
        {!props.lastSyncDate && <span className="caption2">Not yet synced</span>}
      </SyncContentCardWrapperLargeLabel>
    </SyncContentCardWrapperLarge>
  );
}

function SyncContentCardCompact(props: ISyncContentCardBaseProps & { reverse?: boolean }): JSX.Element {
  return (
    <SyncContentCardWrapperCompact className={classNames({ reverse: props.reverse })}>
      {!!props.lastSyncDate && <SyncContentCardWrapperCompactLabel className={classNames('caption2', { isMapMode: props.isMapMode })}>{formatSyncDate(props.lastSyncDate)}</SyncContentCardWrapperCompactLabel>}
      {!props.lastSyncDate && <SyncContentCardWrapperCompactLabel className={classNames('caption2', { isMapMode: props.isMapMode })}>Not yet synced</SyncContentCardWrapperCompactLabel>}

      <SyncContentCardWrapperCompactButton>
        <Button tiny onClick={props.onClick} type="button" theme="dark" square loaderText="Syncing" isLoading={props.isSyncing}>
          Sync
        </Button>
      </SyncContentCardWrapperCompactButton>
    </SyncContentCardWrapperCompact>
  );
}

export function SyncContentCard(props: ISyncContentCardProps): JSX.Element {
  const { variant = 'large' } = props;

  if (variant === 'compact') return SyncContentCardCompact(props);
  if (variant === 'compact-reverse') return SyncContentCardCompact({ ...props, reverse: true });
  if (variant === 'large-compact') return SyncContentCardLarge({ ...props, hideContent: true });
  return SyncContentCardLarge(props);
}

