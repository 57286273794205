import styled from 'styled-components';

export const CaptureAdditionalLearningCardStrip = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40px;

  background: var(--secondary);

  .caption1 {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--background);
  }
`;

export const CaptureAdditionalLearningCardImage = styled.figure`
  position: relative;

  display: flex;
  justify-content: center;

  svg {
    width: 85px;
    height: auto;
  }
`;

export const CaptureAdditionalLearningCardAnchor = styled.a`
  display: inline;

  font-size: 16px;
  text-transform: uppercase;
  color: var(--link-text);
  -webkit-text-decoration: none;
  text-decoration: none;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    box-shadow: 0 0.05rem var(--cards), 0 0.15rem var(--primary);
  }
`;

export const CaptureAdditionalLearningCardFooter = styled.div`
  text-align: center;
`;

export const CaptureAdditionalLearningCardContent = styled.div`
  padding: 15px 15px;
`;

export const CaptureAdditionalLearningCardInner = styled.span`
  position: relative;

  display: block;

  border: solid 1px var(--borders);
  height: 100%;
  border-radius: 8px;
  padding-top: 40px;

  background: var(--cards);
  box-shadow: 0px 4px 8px var(--outer-shadow-colour);

  overflow: hidden;
`;

export const CaptureAdditionalLearningCardOuter = styled.div`
  cursor: pointer;
`;
