import styled from 'styled-components';

export const StarRatingWrapper = styled.div`
  display: inline-flex;
  gap: 10px;

  &.block {
    display: flex;
    justify-content: flex-start;
  }
`;

export const StarRatingStar = styled.div`
  width: 20px;
  height: auto;
  position: relative;
  z-index: 5;

  svg {
    display: block;
    width: 100%;
    height: auto;

    &.fill {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0);
      opacity: 0;
      will-change: transform, opacity;
      transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms, transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 200ms;
    }
  }
`;

export const StarRatingSegment = styled.div`
  display: block;
  position: relative;

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  &.selected {
    ${StarRatingStar} svg.fill {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
