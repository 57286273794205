import styled from 'styled-components';

export const ColumnConfiguratorWidgetHeader = styled.header`
  padding-bottom: 20px;

  .caption1 {
    display: block;
    margin-bottom: 5px;
  }
`;

export const ColumnConfiguratorWidgetListWrapper = styled.section``;

export const ColumnConfiguratorWidgetListCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ColumnConfiguratorWidgetListCardLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .caption2 {
    white-space: nowrap;
  }
`;