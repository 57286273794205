import React, { useEffect, useState } from 'react';
import { EntityLayout } from './entity.layout';
import { useParams } from 'react-router';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';

export function TeamEntityPage(): JSX.Element {
  const { teamSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [teamName, setTeamName] = useState<string>('Team');

  useEffect(() => {
    async function getData() {
      const temp = await actions.getTeam({ companySlug: companyVariables.slug!, teamSlug: teamSlug! });
      setTeamName(temp?.teamName ?? 'Team');
    }

    getData();
  }, [teamSlug]);

  return <EntityLayout path={PagePath.analyticsTeam} parentPath={PagePath.analyticsTeams} slug={teamSlug!} name={teamName} type="team" />;
}
