import { IconRotatableProps, colourString } from '@keplerco/core';
import React from 'react';

export function ReturnIcon(props: IconRotatableProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M4.68 11.2132L11.68 4.21316C11.8729 4.0256 12.1513 3.9552 12.4102 4.0285C12.6691 4.10179 12.8692 4.30764 12.9352 4.5685C13.0011 4.82936 12.9229 5.1056 12.73 5.29316L7.02 10.9932H20.25C20.6642 10.9932 21 11.3289 21 11.7432C21 12.1574 20.6642 12.4932 20.25 12.4932H7.03L12.73 18.1932C12.8729 18.3324 12.9536 18.5236 12.9536 18.7232C12.9536 18.9227 12.8729 19.1139 12.73 19.2532C12.5922 19.3978 12.3998 19.4777 12.2 19.4732C12.0009 19.4741 11.8099 19.3948 11.67 19.2532L4.67 12.2532C4.37755 11.9603 4.37755 11.486 4.67 11.1932L4.68 11.2132Z"
        fill={tone}
      />
    </svg>
  );
}

