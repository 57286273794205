import styled from 'styled-components';

export const RatingLandingCardsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  width: 100%;
  height: auto;

  a,
  div.card-wrapper {
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  div.card-wrapper {
    cursor: not-allowed;
  }

  a .subtitle,
  div.card-wrapper .subtitle {
    padding-top: 5px;
    padding-bottom: 3px;
    font-weight: bold;
  }

  a .caption1,
  div.card-wrapper .caption1 {
    display: block;
    color: var(--accent-2);
    font-weight: bold;
  }

  a span.link,
  div.card-wrapper span.link {
    display: block;
    text-align: right;
    padding-top: 15px;
    text-transform: uppercase;
    color: var(--link-text);
  }

  a .card,
  div.card-wrapper .card {
    position: relative;
    cursor: pointer;
    height: 100%;
    border: solid var(--borders) 1px;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    .link {
      margin-top: auto;
    }

    &.done {
      border-color: var(--apple);
    }
  }

  div.card-wrapper .card {
    pointer-events: none;
  }

  @media screen and (max-width: 930px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
  }
`;

export const RatingCardTick = styled.div`
  width: 24px;
  height: 24px;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    width: 16px;
    height: auto;
  }
`;
