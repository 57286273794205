import { KeplerPointType } from '../../enums/kepler-point-type';
import { AwardBreakdown } from '../../models/overmind/award-breakdown';

// TODO: Check if there is a better way of naming and organising these layouts
export type IEventType = React.MouseEvent<HTMLDivElement, MouseEvent>;

export interface ReconnectionPoint<T = any> {
  points: T[];
}

export interface Point {
  name: string;
  yOffset: number;
  isDone: boolean;
  children?: ReconnectionPoint<Point>;
}

export interface ISubwayProps {
  segmentWidth?: number;
  margin?: number;
  radius?: number;
  viewHeight?: number;
  points: Point[];
}

export interface FlyOverDefinitions {
  above: string[];
  below: string[];
}

export interface IFlyOverLineProps {
  thisPoint: Point;
  currentPointIndex: number;

  nextPoint: Point;
  nextPointIndex: number;

  children: Point[];

  hostProperties: ISubwayProps;

  flyOvers: FlyOverDefinitions;
}

export enum QueueItemType {
  Activity,
  LoginStreak,
  Success,
  Warn,
  Error,
  MissionBriefing,
  Info,
}

export enum QueueItemPriority {
  Toast = 0,
  Tile = 1,
  Modal = 2,
}

export interface QueueItem {
  id: string;
  active: boolean;
  title?: string;
  message?: string;
  slug?: string;
  amount?: number;
  level?: number;
  awardBreakdowns?: AwardBreakdown[];
  loginStreaks?: Date[];
  keplerPointType?: KeplerPointType;
  type: QueueItemType; // notificationType on BE
  priority?: QueueItemPriority; // notificationPriority on BE

  // FE
  toastHTML?: { __html: string };
  toastIncludeIcon?: boolean;
  toastOnClick?: () => void;
}

export interface IQueueItemLayoutProps extends QueueItem {
  onAction: () => void;
}

export interface IGlobalState {
  queue: QueueItem[];
}
