import React from 'react';
import { ActivationStatus } from '../../../../enums';
import { IconProps, colourString } from '@keplerco/core';

interface IActivationStatusIconProps extends IconProps {
  status: ActivationStatus;
}

export function ActivationStatusIcon(props: IActivationStatusIconProps) {
  const size = props.size ?? 20;

  let fill = 'transparent';
  if (props.status === ActivationStatus.Inactive) fill = colourString('a');
  else if (props.status === ActivationStatus.NotInvited) fill = colourString('r');

  return (
    <svg width={size} height={size} viewBox="0 0 20 20">
      <circle cx={10} cy={10} r={5} fill={fill} />
    </svg>
  );
}

