// The api has some outdated terminology, so what is important here
// is actually the numbers on the enum. The "API Term" refers to 
// what the value is named in the api enum
export enum AssessmentType {
  PeerEndorsement = 0, // API Term: Technical
  Questionnaire = 1, // API Term: Behavioral
  FieldsOfPractice = 2, // API Term: CareerPath
  RoleBased = 3, // API Term: RoleBased
}



