/* eslint-disable @typescript-eslint/no-loss-of-precision */
import { Context } from '../..';
import { NotificationsRequest, INotification } from '../../../models/overmind/notifications';
import { QueueItem, QueueItemPriority, QueueItemType } from '../../../components/notification-hub/notification-hub.models';
import * as base from '../base';

export async function getNotifications(context: Context, requestModel: NotificationsRequest): Promise<INotification[] | undefined> {
  if (!!context.state.skillAssessmentConfig) return;

  const queryParams = new URLSearchParams(requestModel as any).toString();
  const request: base.IRequest = { url: base.url(`notification`, `get-notifications?${queryParams}`), authenticated: true };
  const response: base.IResponse<INotification[]> = await base.request(request);
  response.data
    ?.filter(notification => notification.keplerPointType !== undefined &&
      !context.state.queue.find(item => item.id === notification.id))
    .forEach(notification => {
      addNotification(context, {
        ...notification,
        priority: notification.notificationPriority,
        type: notification.notificationType,
      });
    });
  return response.data;
}

export async function dismissNotificationApi(_: Context, notificationId: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`notification`, `dismiss-notification`, notificationId), authenticated: true, method: 'POST' };
  base.request(request);
}

export function addNotification({ state }: Context, queueItem: QueueItem): void {
  state.queue = [queueItem, ...state.queue];
}

export async function dismissNotification(context: Context, id: string): Promise<void> {
  context.state.queue = [
    ...context.state.queue.map(t => {
      const value = t.id === id.toString() ? false : t.active;

      return {
        ...t,
        active: value,
      };
    }),
  ];

  await dismissNotificationApi(context, id);
}

export function dismissAllNotifications(context: Context): void {
  context.state.queue.forEach(item => {
    if (item.active) dismissNotification(context, item.id);
  });
}

export function dismissAllNotificationsOfPriority(context: Context, priority: QueueItemPriority): void {
  context.state.queue.forEach(item => {
    if (priority === QueueItemPriority.Tile && typeof item.priority === 'undefined') {
      item.priority = QueueItemPriority.Tile;
    }

    if (item.active && item.priority === priority) {
      dismissNotification(context, item.id);
    }
  });
}

export function dismissAllNotificationsOfType(context: Context, type: QueueItemType): void {
  context.state.queue.forEach(item => {
    if (item.active && item.type === type) {
      dismissNotification(context, item.id);
    }
  });
}
