import React, { useRef } from 'react';
import Lottie, { LottieRef } from 'lottie-react';
import classNames from 'classnames';

interface ILottieOutputProps {
  data: any;
  className?: string;
  loop?: boolean;
  autoplay?: boolean;
}

export function LottieOutput(props: ILottieOutputProps): JSX.Element {
  const { data, loop = false, autoplay = true } = props;

  const icon: LottieRef | undefined = useRef() as any;

  return <Lottie className={classNames(`lottie-output`, props.className)} autoplay={autoplay} loop={loop} animationData={data} lottieRef={icon} />;
}

