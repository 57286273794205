import styled from 'styled-components';

export const AdditionalLearningPageLayout = styled.div`
  display: block;
`;

export const AdditionalLearningPageContent = styled.div`
  display: block;
  width: 100%;
`;

export const AdditionalLearningPageCardsList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding: 20px 0 0 0;
  list-style: none;
  gap: 10px;
  width: 100%;
  margin: 0;
`;

export const AdditionalLearningPageCardsListItem = styled.li`
  width: 100%;

  .card {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    grid-template-areas: 'delete content edit';
    grid-template-rows: auto;
    gap: 15px;
    align-items: center;
    padding: 15px;

    @media screen and (max-width: 580px) {
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto;
      grid-template-areas: 'content content content' 'delete . edit';
      gap: 5px 15px;
    }
  }
`;

export const AdditionalLearningCardDeleteButton = styled.div`
  grid-area: delete;
`;

export const AdditionalLearningCardEditButton = styled.div`
  grid-area: edit;
`;

export const AdditionalLearningCardContent = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 140px;
  align-items: center;

  @media screen and (max-width: 580px) {
    display: block;
  }
`;

export const AdditionalLearningCardContentItem = styled.div`
  font-size: 14px;

  @media screen and (max-width: 580px) {
    text-align: left !important;
  }
`;

export const AdditionalLearningCardContentTitle = styled(AdditionalLearningCardContentItem)`
  line-height: 20px;
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;

  @media screen and (max-width: 580px) {
    padding-bottom: 10px;
  }
`;

export const AdditionalLearningCardContentType = styled(AdditionalLearningCardContentItem)`
  text-align: center;
  font-weight: bold;
`;

export const AdditionalLearningCardContentDate = styled(AdditionalLearningCardContentItem)`
  text-align: center;
  color: var(--accent-2);
`;

export const AdditionalLearningCardContentKeplerPoints = styled(AdditionalLearningCardContentItem)`
  text-align: right;

  .caption1 {
    white-space: nowrap;
  }

  @media screen and (max-width: 580px) {
    padding-top: 10px;
  }
`;

export const AdditionalLearningEmptyState = styled.div`
  padding: 30px 0;

  @media screen and (max-width: 720px) {
    padding: 0;
  }
`;

