import styled from 'styled-components';

export const CertificatesPageLayout = styled.div`
  display: block;
`;

export const CertificatesPageCards = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px 0;

  .card {
    border: solid 1px var(--borders);
    padding: 10px 25px;
  }
`;

export const CertificatesEmptyState = styled.div`
  padding: 5vmin 0;
`;

export const CertificatesPageCardInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-areas: 'title date action';
  align-items: center;

  .caption1.title {
    grid-area: title;
  }

  .caption1.date {
    grid-area: date;
  }

  a {
    grid-area: action;
  }

  @media screen and (max-width: 530px) {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    column-gap: 15px;
    grid-template-areas:
      'title action'
      'date action';
  }
`;

