export enum Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum Days {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export class MonthRange {
  // Returns the month that comes next in the order
  // of months. This will allow us to navigate through the
  // UI without having to do too many checks
  public get nextMonth(): Months {
    if (this.currentMonth === Months.December) {
      return Months.January;
    } else {
      const index = (this.currentMonth as number) + 1;
      return index as any;
    }
  }

  // Returns the month that comes previously in the order
  // of months. This will allow us to navigate through the
  // UI without having to do too many checks
  public get previousMonth(): Months {
    if (this.currentMonth === Months.January) {
      return Months.December;
    } else {
      const index = (this.currentMonth as number) - 1;
      return index as any;
    }
  }

  // Returns the name of the current month from the given
  // value passed to the constructor
  public get currentMonthName(): string {
    return Months[this.currentMonth];
  }

  // Returns a chunked array of days which will represent the
  // rows and columns of the datepicker UI. This will allow us
  // to have a huge amount of control over the rendering of
  // the days and months.
  public get dateRows(): Array<Day[]> {
    return this.chunkArray<Day>(this.range, 7);
  }

  public get firstDayOfRange(): Day {
    return this.range[0];
  }

  public get lastDayOfRange(): Day {
    return this.range[this.range.length - 1];
  }

  constructor(public currentMonth: Months, public year: number, private range: Day[], public maxDays: number) {}

  // Converts an array to a chunked array of a certain size.
  // for example, you can chunk [1, 2, 3, 4] into sections of
  // 2 which would produce [[1, 2], [3, 4]]
  private chunkArray<T>(array: T[], size: number): Array<T[]> {
    const chunks = [];
    let i = 0;
    const n = array.length;

    while (i < n) {
      chunks.push(array.slice(i, (i += size)));
    }

    return chunks;
  }
}

// The day class is a simple class that contains the
// definition for a single item on the dropdown picker.
// This will tell us the month and year that is
// associated with the day, as well as the day of
// the month for the day.
export class Day {
  // returns the value of this day with a
  // preceding 0 if the value is only 1 digit
  public get prettyDay(): string {
    return this.day.toString().padStart(2, '0');
  }

  // Checks if the day is equal to the current date
  public get isCurrentDay(): boolean {
    return this.matches(new Date());
  }

  public get printDate(): string {
    return `${this.prettyDay}/${this.month + 1}/${this.year}`;
  }

  constructor(public day: number, public month: number, public year: number) {}

  // Checks if a date or day is equal to this day
  public matches(date: Date | Day): boolean {
    if (date instanceof Day) {
      // If the value is an instance of the Day class,
      // we will check to see if it is this instance,
      // else we will see if it is has the same values
      return this === date || (this.day === date.day && this.month === date.month && this.year === date.year);
    } else {
      // If the value is a Date object, we will check
      // to see if the month, year, and day values of
      // the date are equal to this day
      const month = date.getMonth();
      const day = date.getDate();
      const year = date.getFullYear();

      return this.day === day && this.month === month && this.year === year;
    }
  }

  // Converts this date to a JavaScript Date object
  // for use in other sections of the component
  public toDate(): Date {
    return new Date(this.year, this.month, this.day);
  }

  public toMonthRange(): MonthRange {
    return getDateRangeForMonth(this.month, this.year);
  }

  // Creates a Day object from a JavaScript Date
  public static from(date: Date): Day {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return new Day(day, month, year);
  }
}

export function getDateRangeForMonth(month: Months, year: number): MonthRange {
  if (month > 11) {
    month = 0;
    year = year + 1;
  } else if (month < 0) {
    month = 11;
    year = year - 1;
  }

  // We start by working out the number of days in the month
  // prior to the one we are generating days for. This will
  // allow us to map the previous month's dates to the dates
  // before this days of the current month
  const previousMonth = new Date(year, month, 0);
  const previousNumberOfDays = previousMonth.getDate();

  // Here we get a date value so that we can work out how many
  // days are in the current month. We need to know this so we
  // can generate an array of the month's days.
  const date = new Date(year, month + 1, 0);
  const numberOfDays = date.getDate();

  // Here we get the date for the first day of the given month.
  // This will allow us to figure out how many days from the
  // previous month come before the first day of the current month.
  const startDate = new Date(year, month, 1);
  const dayOfTheWeek = startDate.getDay();

  // We need to fill in the days that come before the days of
  // the current month. We also need to display the correct
  // date for those days in the UI.
  const fillers = createFillerArray(dayOfTheWeek)
    .map((_, i) => {
      return new Day(previousNumberOfDays - i, previousMonth.getMonth(), previousMonth.getFullYear());
    })
    .reverse();

  // We now can create an array that consists the days of the
  // given month. This will have the day of the month as part
  // of the value (inside a Day class)
  const days = createFillerArray(numberOfDays).map((_, i) => {
    return new Day(i + 1, month, year);
  });

  // Creates an array that defines all the days that should
  // display on the UI from the previous month's overflow days
  // to the last day of the current month
  const startDays = [...fillers, ...days];

  // We now need to figure out how many rows will be added
  // to the UI. We can then work out how many days we will
  // need in total to fill each row with 7 days each. We can
  // then create an array of the days the should show for the
  // next month.
  const rowCount = Math.ceil(startDays.length / 7);
  const maxDays = 7 * rowCount;
  const fillEnd = maxDays - startDays.length;
  const endDays = createFillerArray(fillEnd).map((_, i) => {
    const newMonth = month === 11 ? 0 : month + 1;
    return new Day(i + 1, newMonth, year);
  });

  // Finally, we can return a result that contains the current
  // month and the range of the days we generated. This class
  // will contain some helper functions that will make it easier
  // to render and navigate through the picker.
  return new MonthRange(month, year, [...startDays, ...endDays], numberOfDays);
}

export function createYearRange(currentYear: number, startTolerance: number = 10, endTolerance: number = 10): number[] {
  const years = [];

  for (let index = currentYear - startTolerance; index <= currentYear + endTolerance; index++) {
    years.push(index);
  }

  return years;
}

function createFillerArray(size: number): null[] {
  size = size < 0 ? 0 : size;
  return Array.from(Array(size), () => null);
}
