import { FocusPanelViewData } from '../../../lib/focus-panel.models';
import { ReducerAction } from '../../../models/reducer-state';
import { AdditionalLearningSearchRequest, AdditionalLearningListViewModel } from './additional-learning.models';

export interface AdditionalLearningState {
  request: AdditionalLearningSearchRequest;
  data: AdditionalLearningListViewModel[] | undefined;
  additionalLearningToEdit: FocusPanelViewData<string>;
  reflectionId: number | undefined;
  additionalLearningToDelete: AdditionalLearningListViewModel | undefined;
}

export const initialState: AdditionalLearningState = {
  request: {},
  data: undefined,
  additionalLearningToEdit: { show: false },
  reflectionId: undefined,
  additionalLearningToDelete: undefined,
};

export enum AdditionalLearningActionTypes {
  SetRequest = 'SetRequest',
  SetData = 'SetData',
  SetAdditionalLearningToEdit = 'SetAdditionalLearningToEdit',
  SetReflectionId = 'SetReflectionId',
  SetAdditionalLearningToDelete = 'SetAdditionalLearningToDelete',
}

type AdditionalLearningActions =
  | ReducerAction<AdditionalLearningActionTypes.SetRequest, { payload: AdditionalLearningSearchRequest }>
  | ReducerAction<AdditionalLearningActionTypes.SetData, { payload: AdditionalLearningListViewModel[] | undefined }>
  | ReducerAction<AdditionalLearningActionTypes.SetAdditionalLearningToEdit, { payload: FocusPanelViewData<string> }>
  | ReducerAction<AdditionalLearningActionTypes.SetReflectionId, { payload: number | undefined }>
  | ReducerAction<AdditionalLearningActionTypes.SetAdditionalLearningToDelete, { payload: AdditionalLearningListViewModel | undefined }>;

export function reducer(state: AdditionalLearningState, action: AdditionalLearningActions): AdditionalLearningState {
  if (action.type === AdditionalLearningActionTypes.SetRequest) {
    return {
      ...state,
      request: action.payload,
    };
  }

  if (action.type === AdditionalLearningActionTypes.SetData) {
    return { ...state, data: action.payload };
  }

  if (action.type === AdditionalLearningActionTypes.SetAdditionalLearningToEdit) {
    return { ...state, additionalLearningToEdit: action.payload };
  }

  if (action.type === AdditionalLearningActionTypes.SetReflectionId) {
    return { ...state, reflectionId: action.payload };
  }

  if (action.type === AdditionalLearningActionTypes.SetAdditionalLearningToDelete) {
    return { ...state, additionalLearningToDelete: action.payload };
  }

  return state;
}

