import styled from 'styled-components';

// OTHER - START
export const TopNavMenuBurgerIconContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 930px) {
    display: none;
  }
`;

export const TopNavMenuTrigger = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

// export const TopNavMenuHeader = styled.span`
//   ${NavCaptionTypography}

//   padding-left: 10px;
// `;

// const TopNavMenuItem = css`
//   ${NavCaptionTypography}

//   border: none;
//   padding-left: 10px;
//   width: 100%;
//   background-color: var(--cards);
//   display: flex;
//   align-items: center;
//   font-family: var(--body);
//   text-decoration: none;
//   cursor: pointer;

//   &:hover {
//     border-color: var(--accent-2_2);
//     background-color: var(--accent-2_2);
//   }
// `;

// export const TopNavMenuItemAnchor = styled.a`
//   ${TopNavMenuItem}
// `;

// export const TopNavMenuItemButton = styled.button`
//   ${TopNavMenuItem}

//   &.isChecked {
//     background-color: var(--background);
//   }
// `;

// export const TopNavMenuItemNavLink = styled(NavLink)`
//   ${TopNavMenuItem}
// `;

export const TopNavMenuListContainer = styled.div`
  position: fixed;
  top: 70px;
  right: 20px;

  width: 200px;
  border: 1px solid var(--borders_1);
  border-radius: 15px;
  padding: 10px 0;

  background: var(--cards);

  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(5px);
  animation: TopNavMenuListEnter cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms forwards;

  @keyframes TopNavMenuListEnter {
    from {
      opacity: 0;
      transform: translateY(5px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;
// OTHER - END

// AVATAR - START
// export const TopNavMenuAvatarIconContainer = styled(NavAvatarIconContainer)`
//   width: 30px;
//   height: 30px;
// `;
// AVATAR - END
