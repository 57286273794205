import { safeCallback } from '@keplerco/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useWatchLocation(callback: (pathname: string) => void) {
  const { pathname } = useLocation();

  useEffect(() => {
    safeCallback(callback, pathname);
  }, [pathname]);
}
