import { ReducerAction } from '../../../models/reducer-state';
import { SkillsFilters } from './skills.models';
import { LearnerSkillDetail } from '../../../models/view/courses';

interface SkillsState {
  data: LearnerSkillDetail[] | undefined;
  filteredData: LearnerSkillDetail[];
  filters: SkillsFilters;
}

export const initialState: SkillsState = {
  data: undefined,
  filteredData: [],
  filters: {
    query: undefined,
    sortAscending: true,
    completionStatus: undefined,
  },
};

export enum SkillsActionTypes {
  SetData,
  SetFilters,
  SetFilteredData,
}

type SkillsActions = ReducerAction<SkillsActionTypes.SetData, { payload: LearnerSkillDetail[] | undefined }> | ReducerAction<SkillsActionTypes.SetFilteredData, { payload: LearnerSkillDetail[] }> | ReducerAction<SkillsActionTypes.SetFilters, { payload: SkillsFilters }>;

export function reducer(state: SkillsState, action: SkillsActions): SkillsState {
  if (action.type === SkillsActionTypes.SetData) return { ...state, data: action.payload };

  if (action.type === SkillsActionTypes.SetFilteredData) return { ...state, filteredData: action.payload };

  if (action.type === SkillsActionTypes.SetFilters) {
    return { ...state, filters: action.payload };
  }

  return state;
}

