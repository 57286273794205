import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../permissions-entity-dropdown/permissions-entity-dropdown.models';

export interface ISkillLevelOverTimeWidgetProps {
  entity: PermissionsEntity | undefined;
  path?: PagePath.dashboard | PagePath.analyticsPerson | PagePath.analyticsSkills;
}

export enum ValueType {
  RoleBased = 'Role-based',
  Behavioural = 'Behavioural',
  Overall = 'Overall',
}

