import styled from 'styled-components';

export const PeoplePageFilterActionsContainer = styled.div`
  display: grid;
  grid-template-areas: 'search sort filter';
  gap: 15px;

  @media screen and (max-width: 850px) {
    width: 100%;
    grid-template-areas: 'search sort' 'filter filter';
  }

  & > :nth-child(1) {
    grid-area: search;
  }

  & > :nth-child(2) {
    grid-area: sort;
  }

  & > :nth-child(3) {
    grid-area: filter;
  }
`;

export const PeoplePageLayoutActionContainer = styled.div`
  margin-left: auto;
`;

export const PeoplePageActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0px;
`;

export const PeoplePagePagerContainer = styled.div`
  margin-top: 30px;
`;

