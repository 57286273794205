import styled from 'styled-components';

export const InvitesPendingScrollContainer = styled.div`
  max-height: calc(100vh - (100px + 112.25px + 80px + 31.95px));
  overflow-y: auto;
  padding-right: 10px;
`;

export const InvitesPendingSelectToggle = styled.p`
  text-align: flex-end;
  margin-bottom: 10px;
  margin-right: 20px;
  color: var(--link-text);
  cursor: pointer;

  &:hover {
    color: var(--link-text_1);
  }
`;

export const InvitesPendingAvatarIconContainer = styled.div`
  width: 50px;
  height: 50px;
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  overflow: hidden;
`;

