import styled from 'styled-components';

export const RatingPageLayout = styled.div`
  display: block;
`;

export const RatingPageHeader = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 30px;

  .rating-key-anchor a {
    padding-left: 0 !important;
  }
`;

export const RatingPageScaleLayout = styled.div`
  padding-right: calc(375px + 50px);
  position: relative;

  @media screen and (max-width: 930px) {
    padding-right: 0;
  }
`;

export const RatingPageMobileContent = styled.div`
  display: none;
  padding-top: 3px;

  @media screen and (max-width: 930px) {
    display: block;
  }
`;

export const RatingPageScaleInputs = styled.div``;

export const RatingPageScaleFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
`;

export const RatingScaleMarker = styled.div`
  width: calc(375px + 50px);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
`;

export const RatingPageScaleKey = styled(RatingScaleMarker)`
  border-top: solid 1px var(--borders);
  padding-left: 50px;

  @media screen and (max-width: 930px) {
    display: none;
  }
`;

export const RatingPageScaleKeyInner = styled.div`
  border: solid 1px var(--borders);
  border-top: none;
  border-bottom: none;
  position: relative;
  padding: 15px 30px 150px 30px;
  background: linear-gradient(162.05deg, var(--cards_1) 4.1%, transparent 42.5%);
  height: auto;

  .subtitle {
    text-transform: uppercase;
    color: var(--accent-2);
  }

  ul {
    position: relative;
    z-index: 5;

    li {
      .caption1,
      .caption2 {
        display: block;
      }

      .caption1 {
        margin-top: -6px;
        padding-bottom: 3px;
        font-weight: bold;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    bottom: 0;
    width: calc(100% + 2px);
    height: 200px;
    background: linear-gradient(to top, var(--background), var(--background), transparent);
  }
`;

export const RatingLandingCardsWrapper = styled.div`
  padding-bottom: 45px;

  .heading4 {
    padding-bottom: 15px;
  }

  &:last-child {
    padding-bottom: 130px;
  }
`;

export const RatingFocusPanelContentLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  ${RatingPageScaleKeyInner} {
    margin-top: 30px;
    padding: 0;
    background: none;
    border: none;

    .caption1 {
      font-size: 16px;
    }

    .caption2 {
      font-size: 14px;
    }

    &::after {
      display: none;
    }
  }
`;

