import React, { useEffect, useState } from 'react';
import { PersonLayout } from '../analytics/people/person/person.layout';
import { useAppActions, useAppState } from '../../overmind';
import { Lesson } from '../../models';
import { PagePath } from '../../navigation/navigation.enums';

export function DashboardPage(): JSX.Element {
  const actions = useAppActions();
  const { user, keplerPoints } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [lessons, setLessons] = useState<Lesson[] | undefined>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      setLessons(await actions.getContinueLearningLessons());
      setLoading(false);
    }

    getData();
  }, []);

  return (
    <PersonLayout
      loading={loading}
      path={PagePath.dashboard}
      slug={!user ? 'myself' : user.learnerSlug}
      lessons={lessons}
      keplerPoints={keplerPoints}
    />
  );
}
