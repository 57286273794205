import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';

export function CareerPathSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="120px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="255px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="85px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}