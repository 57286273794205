import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { SkillsReportPage } from '../../pages/your-skills/skills-report/skills-report.page';
import { ErrorRedirect } from './routers.helpers';

export function YourSkillsRouter(): JSX.Element {
  return (
    <Routes>
      <Route path={PagePath.root} element={<SkillsReportPage />} />

      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}

