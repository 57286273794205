import React from 'react';
import { QueueItem, QueueItemType } from '../notification-hub.models';
import * as layouts from '../../../widgets/layouts';
import { TileNotificationsItemBody, TileNotificationsItemBodyClose, TileNotificationsItemBodyContent } from './tile-hub.styles';

export function TileLayoutContainer(props: QueueItem & { onAction: () => void }): JSX.Element {
  if (props.type === QueueItemType.Activity) {
    return (<layouts.ActivityTileLayout {...props} />);
  }

  if (props.type === QueueItemType.LoginStreak) {
    return (<layouts.LoginStreakTileLayout {...props} />);
  }

  return (
    <TileNotificationsItemBody>
      <TileNotificationsItemBodyContent>
        <h6>{props.title}</h6>
        <p>{props.message}</p>
      </TileNotificationsItemBodyContent>

      <TileNotificationsItemBodyClose>
        <button onClick={props.onAction} />
      </TileNotificationsItemBodyClose>
    </TileNotificationsItemBody>
  );
}