import React, { PropsWithChildren, useRef, useState } from 'react';
import { DaterangeDateOptionTypes, IDaterangeProps } from './daterange.models';
import * as daterangeStyles from './daterange.styles';
import { DaterangeDialog } from './daterange.dialog';

const localOptions = ['en-UK', { dateStyle: 'long' }];

export function Daterange(props: PropsWithChildren<IDaterangeProps>): JSX.Element {
  const { defaultValue = { type: DaterangeDateOptionTypes.UpToToday } } = props;

  const defaultSetRange = defaultValue.daterangeIfApplicable ?? [];

  const today = useRef<Date>(new Date());

  const [dateLimitStart, setDateLimitStart] = useState<Date | undefined>(defaultSetRange[0] ?? today.current);
  const [dateLimitEnd, setDateLimitEnd] = useState<Date | undefined>(defaultSetRange[1] ?? today.current);
  const [activeItem, setActiveItem] = useState<DaterangeDateOptionTypes>(defaultValue.type);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function customDateRangeValue() {
    const start = !!dateLimitStart ? dateLimitStart.toLocaleDateString(...(localOptions as any)) : `Earliest Date`;
    const end = !!dateLimitEnd ? dateLimitEnd.toLocaleDateString(...(localOptions as any)) : new Date().toLocaleDateString(...(localOptions as any));
    return `${start} - ${end}`;
  }

  function formatRangeString(input: string) {
    const result = input.match(/[A-Z][a-z]+/g);
    if (result) return result.join(' ');
  }

  return (
    <React.Fragment>
      <daterangeStyles.DaterangeTrigger onClick={() => setIsOpen(true)}>
        {!!props.children ? props.children : (
          <daterangeStyles.DaterangeWrapper>
            <daterangeStyles.DaterangeInner>
              <daterangeStyles.DaterangeLabel>Date range</daterangeStyles.DaterangeLabel>
              {activeItem === DaterangeDateOptionTypes.CustomRange ? (
                <daterangeStyles.DaterangeValue>
                  {customDateRangeValue()}
                </daterangeStyles.DaterangeValue>
              ) : (
                <daterangeStyles.DaterangeValue>
                  {formatRangeString(DaterangeDateOptionTypes[activeItem])}
                </daterangeStyles.DaterangeValue>
              )}
            </daterangeStyles.DaterangeInner>

            <daterangeStyles.DaterangeIcon>
              <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.95557 2.8269C5.40328 2.8269 4.95557 3.27462 4.95557 3.8269C4.95557 4.37919 5.40328 4.8269 5.95557 4.8269C6.50785 4.8269 6.95557 4.37919 6.95557 3.8269C6.95557 3.27462 6.50785 2.8269 5.95557 2.8269ZM3.12627 2.8269C3.53811 1.66171 4.64935 0.826904 5.95557 0.826904C7.26179 0.826904 8.37302 1.66171 8.78486 2.8269H15.9556C16.5079 2.8269 16.9556 3.27462 16.9556 3.8269C16.9556 4.37919 16.5079 4.8269 15.9556 4.8269H8.78486C8.37302 5.9921 7.26179 6.8269 5.95557 6.8269C4.64935 6.8269 3.53811 5.9921 3.12627 4.8269H1.95557C1.40328 4.8269 0.955566 4.37919 0.955566 3.8269C0.955566 3.27462 1.40328 2.8269 1.95557 2.8269H3.12627ZM11.9556 8.8269C11.4033 8.8269 10.9556 9.27462 10.9556 9.8269C10.9556 10.3792 11.4033 10.8269 11.9556 10.8269C12.5079 10.8269 12.9556 10.3792 12.9556 9.8269C12.9556 9.27462 12.5079 8.8269 11.9556 8.8269ZM9.12627 8.8269C9.53811 7.66171 10.6493 6.8269 11.9556 6.8269C13.2618 6.8269 14.373 7.66171 14.7849 8.8269H15.9556C16.5079 8.8269 16.9556 9.27462 16.9556 9.8269C16.9556 10.3792 16.5079 10.8269 15.9556 10.8269H14.7849C14.373 11.9921 13.2618 12.8269 11.9556 12.8269C10.6493 12.8269 9.53811 11.9921 9.12627 10.8269H1.95557C1.40328 10.8269 0.955566 10.3792 0.955566 9.8269C0.955566 9.27462 1.40328 8.8269 1.95557 8.8269H9.12627ZM5.95557 14.8269C5.40328 14.8269 4.95557 15.2746 4.95557 15.8269C4.95557 16.3792 5.40328 16.8269 5.95557 16.8269C6.50785 16.8269 6.95557 16.3792 6.95557 15.8269C6.95557 15.2746 6.50785 14.8269 5.95557 14.8269ZM3.12627 14.8269C3.53811 13.6617 4.64935 12.8269 5.95557 12.8269C7.26179 12.8269 8.37302 13.6617 8.78486 14.8269H15.9556C16.5079 14.8269 16.9556 15.2746 16.9556 15.8269C16.9556 16.3792 16.5079 16.8269 15.9556 16.8269H8.78486C8.37302 17.9921 7.26179 18.8269 5.95557 18.8269C4.64935 18.8269 3.53811 17.9921 3.12627 16.8269H1.95557C1.40328 16.8269 0.955566 16.3792 0.955566 15.8269C0.955566 15.2746 1.40328 14.8269 1.95557 14.8269H3.12627Z"
                  fill="var(--primary)"
                />
              </svg>
            </daterangeStyles.DaterangeIcon>
          </daterangeStyles.DaterangeWrapper>
        )}
      </daterangeStyles.DaterangeTrigger>

      <DaterangeDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onClickActiveItem={setActiveItem}
        activeItem={activeItem}
        defaultValue={defaultValue}
        onDaterangeChange={props.onDaterangeChange}
        today={today}
        dateLimitStart={dateLimitStart}
        dateLimitEnd={dateLimitEnd}
        onChangeDateLimitStart={setDateLimitStart}
        onChangeDateLimitEnd={setDateLimitEnd}
        title={props.title}
        applyButtonText={props.applyButtonText}
      />
    </React.Fragment>
  );
}


