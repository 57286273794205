import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import { LottieOutput } from '../../../../components/lottie-icon/lottie-icon';
import confettiLottie from '../../../../assets/confetti.lottie.json';
import { Button, KeplerPoints } from '@keplerco/core';

export function CategoryCompletedModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  return (
    <>
      <div className="dialogBackgroundLayout">
        <LottieOutput loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent">
        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data="/badges/badge_KP.svg" type="image/svg+xml">
              <img src="/badges/badge_KP.svg" alt="" />
            </object>
          </div>

          <h2 className="heading2" style={{ paddingBottom: 30 }}>
            Nice Work!
          </h2>

          <h6 className="subtitle">
            You've earned a total of
            <br />
            <h2 className="heading2">
              <KeplerPoints trimWhiteSpace points={props.amount} />
            </h2>
            <br />
            for completing {props.title ?? 'your course'}
          </h6>
        </div>

        <div className="dialogFooterLayout modalFooterLayout">
          <Button type={'button'} chubby filled arrow={false} onClick={props.onAction}>
            Thanks!
          </Button>
        </div>
      </div>
    </>
  );
}


