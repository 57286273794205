import { ILineChartDataPoint } from '@keplerco/core';

export function lineChartIncrements(data: ILineChartDataPoint[]) {
  const valueMap = data.map(i => i.value.flat()).flat();
  const trueMax = Math.max(...valueMap.map(i => i.value));

  let increment = 0.25;
  if (trueMax >= 1000) increment = 100;
  else if (trueMax > 500) increment = 75;
  else if (trueMax > 250) increment = 50;
  else if (trueMax > 100) increment = 25;
  else if (trueMax > 50) increment = 15;
  else if (trueMax > 20) increment = 10;
  else if (trueMax > 10) increment = 5;
  else if (trueMax > 5) increment = 2;

  return increment;
}

