export const levelNameAndIconMap: Array<{ name: string; iconUrl: string }> = [
  {
    name: 'Stargazer',
    iconUrl: `/badges/badge_Stargazer.svg`,
  },
  {
    name: 'Rocketeer',
    iconUrl: `/badges/badge_Rocketeer.svg`,
  },
  {
    name: 'Trailblazer',
    iconUrl: `/badges/badge_TrailBlazer.svg`,
  },
  {
    name: 'Space Explorer',
    iconUrl: `/badges/badge_SpaceExplorer.svg`,
  },
  {
    name: 'Moon Walker',
    iconUrl: `/badges/badge_MoonWalker.svg`,
  },
];

