import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { AnalysisPageHeader } from '../analysis.styles';

export const QuestionnairesLandingPageHeader = styled(AnalysisPageHeader)`
  padding-bottom: 10px;
  margin-bottom: 0px;
`;

export const QuestionnairesLandingPageCardsExplainer = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  will-change: width;
  transition: width cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms;
  z-index: 5;

  .card {
    width: 100%;
    max-width: 450px;
    margin: auto;
    overflow: hidden;
    border-color: var(--borders);
    background: var(--cards_2);
  }

  .heading3 {
    padding-top: 10px;
    line-height: 1.3;
  }

  .button {
    opacity: 1;
    will-change: opacity;
    transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms;
    transition-delay: 250ms;
    text-align: center;
    padding-top: 5px;

    &.always-visible {
      opacity: 1 !important;

      .body2 {
        color: var(--accent-2);
        padding-bottom: 10px;
      }

      button {
        display: block;
        width: 100%;
      }
    }
  }

  ul {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
    padding: 15px 0;
    margin: 0;
    list-style: none;

    li {
      padding: 0;
      padding-left: 15px;
      line-height: 22px;
      position: relative;

      strong {
        color: var(--text) !important;
      }

      &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: var(--secondary);
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 7px;
      }

      &.no-dot::before {
        display: none;
      }
    }
  }
`;

export const QuestionnairesLandingPageKeplerPointsTally = styled.div`
  height: 160px;
  display: grid;
  place-content: center;
  text-align: center;
  position: relative;

  .lottie-output {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 200px;
  }

  .heading2,
  .heading5 {
    position: relative;
    z-index: 10;
  }

  .heading2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    overflow: hidden;

    button {
      margin-left: -5px;
      margin-top: -20px;

      @media screen and (max-width: 580px) {
        margin-top: -10px;
      }
    }
  }
`;

export const QuestionnairesLandingPageCardsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;
  opacity: 0;
  will-change: opacity;
  transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms;
  transition-delay: 250ms;
  position: relative;
  z-index: 4;
  width: 100%;
  padding: 0;
  margin: 0;
  padding-left: 30px;
  list-style: none;
  min-height: calc(100vh - 250px);

  @media screen and (max-width: 930px) {
    min-height: calc(100vh - 150px);
  }
`;

export const QuestionnairesLandingPageCardsListItem = styled.div`
  display: block;
  cursor: pointer;
`;

export const QuestionnairesLandingPageCardsListItemLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  cursor: pointer;

  &.isComplete {
    pointer-events: none;
  }
`;

export const QuestionnairesLandingPageCardsListLoader = styled.li`
  width: 100%;
  height: 450px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;
`;

export const QuestionnairesLandingPageCards = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-left: 450px;

  &.isCollapsed {
    ${QuestionnairesLandingPageCardsExplainer} {
      width: 450px;
    }

    ${QuestionnairesLandingPageCardsList} {
      opacity: 1;
    }

    .button {
      opacity: 0;
    }
  }

  &.hasCompleted {
    padding-left: 0;
    padding-right: 450px;

    ${QuestionnairesLandingPageCardsExplainer} {
      left: auto;
      right: 0;
    }

    ${QuestionnairesLandingPageCardsList} {
      padding-left: 0;
      padding-right: 30px;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 370px;

    &.hasCompleted {
      padding-left: 0;
      padding-right: 370px;
    }

    &.isCollapsed {
      ${QuestionnairesLandingPageCardsExplainer} {
        width: 370px;

        .card {
          max-width: 370px;
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;

    &.hasCompleted {
      padding-left: 0;
      padding-right: 0;
    }

    ${QuestionnairesLandingPageCardsExplainer}, &.isCollapsed ${QuestionnairesLandingPageCardsExplainer} {
      width: 100%;
      position: static;

      .card {
        max-width: 550px;
      }
    }

    ${QuestionnairesLandingPageCardsList}, &.isCollapsed ${QuestionnairesLandingPageCardsList} {
      opacity: 1;
      max-width: 550px;
      margin: auto;
      width: 100%;
      padding-left: 0;
      padding-top: 30px;
    }

    .button,
    &.isCollapsed .button {
      opacity: 1;
    }
  }
`;

export const QuestionnaireCardDescription = styled.div`
  grid-area: description;
  padding-left: 20px;
  display: grid;
  align-items: center;
`;

export const QuestionnaireCardDescriptionInner = styled.div`
  .heading3 {
    line-height: 1.2;
  }
`;

export const QuestionnaireCardActions = styled.div`
  grid-area: actions;
`;

export const QuestionnaireCardActionsInner = styled.div`
  height: 100%;
  position: relative;

  span {
    border: solid 4px var(--link-text);
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -15px;
    transform: rotate(45deg);
    border-left: none;
    border-bottom: none;
  }

  svg {
    display: block;
    width: 100%;
    position: absolute;
    top: 10px;
    right: 0;
    width: 30px;
    height: auto;
  }
`;

export const QuestionnaireCardProgressBarWrapper = styled.div`
  grid-area: progress;
  background: var(--primary);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
`;

export const QuestionnaireCardProgressBar = styled.progress`
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;
  appearance: none;

  &::-webkit-progress-bar {
    background-color: var(--borders);
  }

  &::-webkit-progress-value {
    background: var(--secondary);
  }
`;

export const QuestionnaireCardWrapper = styled.div`
  border-color: var(--borders);
  background: var(--cards_2);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 50px;
  grid-template-areas: 'description actions';
  padding: 10px 20px 20px 20px;
  min-height: 210px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &.isComplete {
    cursor: default;
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr 50px;
    grid-template-areas: 'description actions';

    ${QuestionnaireCardDescription} {
      padding-left: 0;
    }
  }
`;
