import React, { useState } from 'react';
import { IHoverCardProps } from './hover-card.models';
import { HoverCardAction, HoverCardDescription, HoverCardActionIcon, HoverCardInner, HoverCardTitle, HoverCardWrapper, HoverCardChip, HoverCardFront } from './hover-card.styles';
import classNames from 'classnames';
import { Tooltip, safeCallback, sentenceCase, useMatchScreenWidth, useMediaQuery } from '@keplerco/core';

export function HoverCard(props: IHoverCardProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const isTouch = useMediaQuery('screen and (pointer: coarse)');
  const isMobile = useMatchScreenWidth('mobile');

  const handleMouseEnter = () => {
    if (!isTouch) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouch) {
      setIsHovered(false);
    }
  };

  const handleClick = () => {
    if (isTouch) {
      setIsHovered(prev => !prev);
    }

    safeCallback(props.onClickAction);
  };

  return (
    <React.Fragment>
      <HoverCardWrapper className={classNames({ isHovered }, `card`)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} chip={!!props.chip}>
        {!!props.chip && <HoverCardChip className="subtitle">{props.chip}</HoverCardChip>}

        <HoverCardFront>
          <HoverCardTitle>
            <h4 className="heading4">{sentenceCase(props.title)}</h4>
          </HoverCardTitle>
        </HoverCardFront>

        <HoverCardInner>
          {(!!props.actions || !!props.menu) && (
            <HoverCardAction>
              {props.actions?.map((action, index) => (
                <HoverCardActionIcon key={index} onClick={action.onClick}>
                  <Tooltip key={index} content={action.label}>
                    {typeof action.icon === 'string' ? (
                      <object data={action.icon} type="image/svg+xml">
                        <img src={action.icon} alt="" />
                      </object>
                    ) : (
                      action.icon
                    )}
                  </Tooltip>
                </HoverCardActionIcon>
              ))}
            </HoverCardAction>
          )}

          <HoverCardTitle>
            <h4
              className="heading4"
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '300px',
                textAlign: 'left',
              }}
            >
              {sentenceCase(props.title)}
            </h4>
          </HoverCardTitle>

          {(!!props.description && !isMobile) && <HoverCardDescription>{props.description}</HoverCardDescription>}
        </HoverCardInner>
      </HoverCardWrapper>
    </React.Fragment>
  );
}
