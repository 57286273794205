import { UniqueEntity } from '../../models';

export interface IFormValues {
  firstName: string;
  lastName: string;
  preferredName: string;
  countryId: number | undefined;
  periodOfExperience: number | undefined;
  jobTitle: string;
}

export interface ErrorFieldState {
  firstName: boolean;
  lastName: boolean;
  preferredName: boolean;
  countryId: boolean;
  jobTitle: boolean;
  periodOfExperience: boolean;
}

export interface Country extends UniqueEntity {
  iso?: string;
}

export const defaultFormValues: IFormValues = {
  firstName: '',
  lastName: '',
  preferredName: '',
  countryId: undefined,
  periodOfExperience: undefined,
  jobTitle: '',
};

export const initialErrorFieldState: ErrorFieldState = {
  firstName: false,
  lastName: false,
  preferredName: false,
  countryId: false,
  jobTitle: false,
  periodOfExperience: false,
};

