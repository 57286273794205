import { pipe } from 'overmind';
import { Context } from '../..';
import { AuthenticationStatus } from '../../../enums';
import { DisplayAreas } from '../../../enums/display-areas';
import { identify } from '../../../lib/track';
import { checkAllowCreateAccount, setSubDomain } from './company';
import { getAssessmentYearLearning, getAssignedLearning } from './courses';
import { syncCourseContent } from './data-sync';
import { getTotalLearnerKeplerPoints } from './kepler-points';
import { getNotifications } from './notifications';
import * as base from '../base';
import { fetchUserSettingsProfile, getProfile } from './profile-settings';
import { User } from '../../../models/user';
import { getUserPermissions } from './permissions';
import { getForcedSkillAssessmentConfiguration } from './learner-assessment';

async function logUserLogin({ state }: Context): Promise<boolean> {
  if (state.authenticationStatus !== AuthenticationStatus.Authenticated) return Promise.resolve(false);

  const init = new base.DefaultAuthenticatedParameters();

  return fetch(base.url(`user`, `log-daily-login`), init.toObject())
    .then(response => response.text())
    .then(data => data as any);
}

export async function isAuthenticated(context: Context): Promise<void> {
  if (context.state.isRunningAuthentication || context.state.authenticationStatus === AuthenticationStatus.AuthenticationFailed) return;

  context.state.isRunningAuthentication = true;

  const request: base.IRequest = { url: base.url(`user`, `isauthenticated`), authenticated: true };
  const response: base.IResponse<any> = await base.request(request);

  context.state.authenticationStatus = response.data ? AuthenticationStatus.Authenticated : AuthenticationStatus.UnAuthenticated;

  context.state.isRunningAuthentication = false;

  logUserLogin(context);
}

export async function initialiseAuthenticatedState(context: Context): Promise<any> {
  await isAuthenticated(context);

  if (context.state.authenticationStatus !== AuthenticationStatus.Authenticated) return;

  await Promise.all([setSubDomain(), getProfile(context), fetchUserSettingsProfile(context), getUserPermissions(context)]);

  let settings = context.state.settings;
  if (!settings) settings = await context.actions.fetchUserSettingsProfile();

  const userObject: Partial<User> = JSON.parse(JSON.stringify(context.state.user)) ?? {};
  delete userObject.allowCertificateDownload;
  delete userObject.allowOffPlatformLearning;
  delete userObject.allowReflections;

  if (!!context.state.user?.learnerSlug && !!settings?.email) identify(context.state.user?.learnerSlug, { ...userObject, email: settings.email });
  await getForcedSkillAssessmentConfiguration(context);
  await initialisePostAuthenticatedState(context);
}

export async function getNotificationsRequest(context: Context): Promise<any> {
  await getNotifications(context, {
    size: 20,
    activeOnly: true,
    displayArea: DisplayAreas.EntireApplication,
  });
}

async function initialisePostAuthenticatedState(context: Context): Promise<any> {
  if (context.state.authenticationStatus !== AuthenticationStatus.Authenticated) return;

  syncCourseContent(context).then(() => {
    console.log('Done syncing content');
  });
  await Promise.all([getTotalLearnerKeplerPoints(context), getAssignedLearning(context), getAssessmentYearLearning(context), getNotificationsRequest(context)]);
}

export const initialiseApplication = pipe(
  ({ state }: Context) => {
    state.isInitialising = true;
  },
  initialiseAuthenticatedState,
  checkAllowCreateAccount,
  (context: Context) => {
    context.state.isInitialising = false;
  },
);
