import styled from 'styled-components';
import { AnalysisButtonContainer } from '../analysis/analysis.styles';

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;

  .next {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .invites {
    width: 100%;
    display: flex;
    justify-content: center;
    background: var(--background_2);
    border-top: 1px solid var(--borders);
    border-bottom: 1px solid var(--borders);
    box-shadow: inset 0px 0px 25px var(--inner-shadow-colour);
    backdrop-filter: blur(10px);
    padding: 20px;
    margin-bottom: -20px;
  }
`;

export const PageWrapper = styled.div`
  --offset-width: 350px;
  --gap-width: 100px;

  width: 100%;
  height: auto;
  max-height: calc(100vh - 250px);
  display: flex;
  align-items: stretch;
  overflow: none;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    ${AnalysisButtonContainer} {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
    }
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 150px);
    max-width: 700px;
    width: 90%;
  }
`;

export const ConfirmTeamContent = styled.div`
  width: calc(100% - (var(--offset-width) + var(--gap-width)));
  max-width: calc(100% - (var(--offset-width) + var(--gap-width)));
  position: relative;
  z-index: 1;

  .heading1 {
    padding-bottom: 10px;
    margin-top: 40px;
  }

  .subtitle {
    padding-bottom: 20px;
  }

  object,
  img {
    position: absolute;
    right: calc((var(--gap-width) / 2) * -1);
    top: 0;
    z-index: -1;
  }

  @media screen and (max-width: 930px) {
    max-width: 100%;
    width: 100%;
    border-bottom: solid var(--background-transparent) 1px;
    padding-right: 0;
    padding-bottom: 10px;

    .heading1 {
      margin-top: 10px;
    }

    .subtitle {
      padding-bottom: 10px;
    }
  }
`;

export const ConfirmTeamListWrapper = styled.div`
  width: var(--offset-width);
  max-width: var(--offset-width);
  overflow: hidden;
  padding-left: 20px;

  @media screen and (max-width: 930px) {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
`;

export const PersonalDetailsFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 110px;

  @media screen and (max-width: 580px) {
    padding-bottom: 50px;
  }
`;

export const PersonalDetailsFormHeader = styled.header`
  width: 100%;
  height: auto;
  padding-top: 30px;
`;

export const PersonalDetailsFormSection = styled.form`
  display: block;
  width: 90%;
  margin: auto;
  max-width: 1200px;
`;

export const PersonalDetailsFormSectionDescription = styled.div`
  text-align: center;

  .heading1 {
    margin-bottom: 15px;
  }
`;

export const PersonalDetailsFormOutlet = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 850px;
  margin: auto;
  padding-top: 60px;

  @media screen and (max-width: 580px) {
    padding-top: 30px;
  }
`;

export const PersonalDetailsFormOutletControl = styled.div`
  display: block;
  width: 100%;
  height: auto;
  text-align: left;
`;

export const PersonalDetailsNonTranslationBreakingSpacer = styled.div`
  display: block;
  visibility: hidden;
  width: 100%;
  height: 27px;
`;
