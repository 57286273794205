import React, { useEffect, useState } from 'react';
import { ScheduleAssessmentFooter, ScheduleAssessmentLabel, ScheduleAssessmentSpacer, SkillAssessmentScheduleRadioCardWrapper, SkillAssessmentScheduleWrapper } from './assessment-scheduling.styles';
import { ISkillAssessmentSchedulerWidgetProps, SaveOptions, SkillAssessmentFormValue } from './assessment-scheduling.models';
import { useForm } from 'react-hook-form';
import { Datepicker, SkeletonLoader } from '../../../../components';
import { useAppActions } from '../../../../overmind';
import { ScheduleAction } from '../../../../enums/schedule-action';
import { Schedule } from '../../../../models/view/schedule';
import { FormControl, GridItemLayout, GridLayout, RadioButtonCard, safeCallback } from '@keplerco/core';

export function SkillAssessmentSchedulerWidget(props: ISkillAssessmentSchedulerWidgetProps): JSX.Element {
  const { control, handleSubmit, watch, setValue } = useForm();
  const [formValue, setFormValue] = useState<SkillAssessmentFormValue>();
  const actions = useAppActions();

  const [defaultStartDateValue, setDefaultStartDateValue] = useState<Date>(new Date());
  const [defaultEndDateValue, setDefaultEndDateValue] = useState<Date>(new Date());
  const [currentCreationSchedule, setCurrentCreationSchedule] = useState<Schedule>();
  const [currentCompletionSchedule, setCurrentCompletionSchedule] = useState<Schedule>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function fetchWidgetData() {
    let creationSchedule: Schedule | undefined, completionSchedule: Schedule | undefined;

    try {
      const [existingCreationSchedule, existingCompletionSchedule] = await Promise.all([
        actions.getScheduledAction({ attachedEntity: props.skillAssessmentId, scheduleType: ScheduleAction.AssessmentCreation }),
        actions.getScheduledAction({ attachedEntity: props.skillAssessmentId, scheduleType: ScheduleAction.AssessmentCompletion }),
      ]);

      creationSchedule = existingCreationSchedule;
      completionSchedule = existingCompletionSchedule;
    } catch (error) {
      console.log(error);
    }

    if (!creationSchedule) {
      const schedule = await actions.createScheduleAction({
        entityId: props.skillAssessmentId,
        isActive: false,
        scheduleActionType: ScheduleAction.AssessmentCreation,
      });

      setCurrentCreationSchedule(schedule);
    } else {
      setValue('startDate', creationSchedule.activateImmediately ? 'immediate' : 'future');
      setCurrentCreationSchedule(creationSchedule);
      if (!!creationSchedule.executionDate) setDefaultStartDateValue(new Date(creationSchedule.executionDate));
    }

    if (!completionSchedule) {
      const schedule = await actions.createScheduleAction({
        entityId: props.skillAssessmentId,
        isActive: false,
        scheduleActionType: ScheduleAction.AssessmentCompletion,
      });

      setCurrentCompletionSchedule(schedule);
    } else {
      setValue('endDate', completionSchedule.activateImmediately ? 'manual' : 'future');
      setCurrentCompletionSchedule(completionSchedule);
      if (!!completionSchedule.executionDate) setDefaultEndDateValue(new Date(completionSchedule.executionDate));
    }
  }

  useEffect(() => {
    watch((value: any) => {
      setFormValue(value);
      if (!!currentCreationSchedule && !!currentCompletionSchedule) saveSchedule({ saveEnd: true, saveStart: true }, value);
    });

    fetchWidgetData();
  }, []);

  async function saveSchedule(params: SaveOptions = { saveStart: true, saveEnd: true }, manualFormValues?: SkillAssessmentFormValue) {
    if (!isSaving) {
      setIsSaving(true);

      const savableFormValue = manualFormValues ?? formValue;
      if (!savableFormValue || !savableFormValue.startDate || !savableFormValue.endDate) return false;

      if (params.saveStart) {
        const startSchedule: Schedule = {
          ...currentCreationSchedule!,
        };

        if (savableFormValue.startDate === 'future') {
          startSchedule.activateImmediately = false;
          startSchedule.executionDate = defaultStartDateValue!.toISOString();
        } else {
          startSchedule.activateImmediately = true;
        }

        try {
          await actions.updateScheduleAction(startSchedule);
          if (!params.saveEnd) return true;
        } catch (error) {
          return false;
        }
      }

      if (params.saveEnd) {
        const endSchedule: Schedule = {
          ...currentCompletionSchedule!,
        };

        if (savableFormValue.endDate === 'future') {
          endSchedule.activateImmediately = false;
          endSchedule.executionDate = defaultEndDateValue!.toISOString();
        } else {
          endSchedule.activateImmediately = true;
        }

        try {
          await actions.updateScheduleAction(endSchedule);
          return true;
        } catch (error) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  if (isSaving) {
    return (
      <div>
        <SkeletonLoader height="30px" />
        <div style={{ height: 10 }} />
        <SkeletonLoader height="150px" />
        <div style={{ height: 20 }} />
        <SkeletonLoader height="30px" />
        <div style={{ height: 10 }} />
        <SkeletonLoader height="150px" />
      </div>
    );
  }

  return (
    <SkillAssessmentScheduleWrapper
      onSubmit={handleSubmit(async () => {
        const success = await saveSchedule();
        setIsSaving(false);
        if (success) safeCallback(props.onStepComplete);
      })}
    >
      <ScheduleAssessmentLabel className="heading5">Start Assessment</ScheduleAssessmentLabel>

      <GridLayout columnCount={2}>
        <GridItemLayout>
          <FormControl
            name="startDate"
            control={control}
            rules={{ required: 'Please choose when your assessment will activate' }}
            render={({ field, fieldState }) => (
              <RadioButtonCard haserror={!!fieldState.error} id="immediate" {...field} value="immediate" checked={field.value === 'immediate'} onChange={e => field.onChange(e.target.value)}>
                <div className="card">
                  <SkillAssessmentScheduleRadioCardWrapper>
                    <h6 className="subtitle">Immediately</h6>
                    <p className="body2">Set you assessment to release immediately after creation.</p>
                  </SkillAssessmentScheduleRadioCardWrapper>
                </div>
              </RadioButtonCard>
            )}
          />
        </GridItemLayout>

        <GridItemLayout>
          <FormControl
            name="startDate"
            control={control}
            rules={{ required: 'Please choose when your assessment will activate' }}
            render={({ field, fieldState }) => (
              <RadioButtonCard haserror={!!fieldState.error} id="future" {...field} value="future" checked={field.value === 'future'} onChange={e => field.onChange(e.target.value)}>
                <div className="card">
                  <SkillAssessmentScheduleRadioCardWrapper>
                    <h6 className="subtitle">Specific Day</h6>
                    <p className="body2">Set the release of your assessment at a specific time.</p>
                  </SkillAssessmentScheduleRadioCardWrapper>
                </div>
              </RadioButtonCard>
            )}
          />
        </GridItemLayout>
      </GridLayout>

      {formValue?.startDate === 'future' && (
        <GridLayout columnCount={2}>
          <GridItemLayout>
            <Datepicker
              onDateSelected={date => {
                setDefaultStartDateValue(date);
                saveSchedule({ saveStart: true, saveEnd: false });
              }}
              defaultDate={defaultStartDateValue}
              minDate={new Date()}
              openAbove
            />
          </GridItemLayout>

          <GridItemLayout />
        </GridLayout>
      )}

      <ScheduleAssessmentSpacer />

      <ScheduleAssessmentLabel className="heading5">Close Assessment</ScheduleAssessmentLabel>

      <GridLayout columnCount={2}>
        <GridItemLayout>
          <FormControl
            name="endDate"
            control={control}
            rules={{ required: 'Please choose when your assessment will close' }}
            render={({ field, fieldState }) => (
              <RadioButtonCard haserror={!!fieldState.error} id="manual" {...field} value="manual" checked={field.value === 'manual'} onChange={e => field.onChange(e.target.value)}>
                <div className="card">
                  <SkillAssessmentScheduleRadioCardWrapper>
                    <h6 className="subtitle">Close Assessment Manually</h6>
                    <p className="body2">Leave the assessment open till an admin user closes it.</p>
                  </SkillAssessmentScheduleRadioCardWrapper>
                </div>
              </RadioButtonCard>
            )}
          />
        </GridItemLayout>

        <GridItemLayout>
          <FormControl
            name="endDate"
            control={control}
            rules={{ required: 'Please choose when your assessment will close' }}
            render={({ field, fieldState }) => (
              <RadioButtonCard haserror={!!fieldState.error} id="future" {...field} value="future" checked={field.value === 'future'} onChange={e => field.onChange(e.target.value)}>
                <div className="card">
                  <SkillAssessmentScheduleRadioCardWrapper>
                    <h6 className="subtitle">Specific Day</h6>
                    <p className="body2">Set the closing of your assessment on a specific date.</p>
                  </SkillAssessmentScheduleRadioCardWrapper>
                </div>
              </RadioButtonCard>
            )}
          />
        </GridItemLayout>
      </GridLayout>

      {formValue?.endDate === 'future' && (
        <GridLayout columnCount={2}>
          <GridItemLayout>
            <Datepicker
              onDateSelected={date => {
                setDefaultEndDateValue(date);
                saveSchedule({ saveStart: false, saveEnd: true });
              }}
              defaultDate={defaultEndDateValue}
              minDate={defaultStartDateValue}
              openAbove
            />
          </GridItemLayout>

          <GridItemLayout />
        </GridLayout>
      )}

      {!!currentCompletionSchedule && !!currentCreationSchedule && (
        <ScheduleAssessmentFooter>
          <button>
            Next
            <svg viewBox="0 0 10 14" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.93832 0.483329C1.52354 -0.10302 2.47329 -0.103934 3.05964 0.481289L9.13299 6.54297L3.05964 12.6046C2.47329 13.1899 1.52354 13.189 0.93832 12.6026C0.353097 12.0163 0.354011 11.0665 0.940361 10.4813L4.88626 6.54297L0.940361 2.60465C0.354011 2.01943 0.353097 1.06968 0.93832 0.483329Z"
                fill="var(--primary)"
              />
            </svg>
          </button>
        </ScheduleAssessmentFooter>
      )}
    </SkillAssessmentScheduleWrapper>
  );
}

