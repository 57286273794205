import React from 'react';
import { IEntitySelectionProps } from './entity-selection-list-item.models';
import { KebabMenu, DropdownSelect, Anchor, DropdownMenu, colourString, AvatarIcon, useMatchScreenWidth, Chip } from '@keplerco/core';
import styles from './entity-selection-list-item.module.css';
import classNames from 'classnames';
import { SkeletonLoader } from '../../loading-handling/loaders/skeleton-loader/skeleton-loader';

export function EntitySelectionListItem({
  title,
  menuItems,

  loading,
  selected,
  avatar,
  subtitle,
  levelItems = [],
  selectMultipleLevelItems,
  onTitleClick,
  chips,
}: IEntitySelectionProps) {
  const isMobile = useMatchScreenWidth('mobile');
  return (
    <React.Fragment>
      {loading ? (
        <SkeletonLoader borderRadius="8px" width="100%" height="62px" />
      ) : (
        <div
          className={classNames('card', styles.cardWrapper)}
          style={{
            gridTemplateColumns: `auto ${avatar ? 'auto' : ''} 1fr ${(!!levelItems.length || !!chips?.length) ? `${isMobile ? '150px' : '195px'}` : ''}`,
            borderColor: colourString(selected ? 'secondary' : 'borders'),
          }}
        >
          {!!menuItems.length ? (
            <KebabMenu items={menuItems} dialogContained />
          ) : (
            <div style={{ width: 25, height: 25 }} />
          )}

          {!!avatar && (
            <div className={styles.avatar}>
              <AvatarIcon
                name={{ firstName: title.split('')[0], lastName: title.split('')[1] }}
                backgroundColour="accent-3"
                fontColour="contrast-text"
              />
            </div>
          )}

          <div>
            {!!onTitleClick ? (
              <Anchor textTransform="none" hovertype="opacity" onClick={() => onTitleClick && onTitleClick()}>
                {title}
              </Anchor>
            ) : (
              <div className="body1" style={{ color: colourString('accent-2') }}>{title}</div>
            )}

            {(!!subtitle && !isMobile) && <div className="caption1" style={{ color: 'var(--text_1)' }}>{subtitle}</div>}
          </div>

          {!!levelItems.length && (
            <React.Fragment>
              {selectMultipleLevelItems ? <DropdownMenu multiple label="Expected skill level" items={levelItems} /> : <DropdownSelect label="Expected skill level" items={levelItems} />}
            </React.Fragment>
          )}

          {!!chips?.length && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
              {chips.map(chip => (
                <Chip key={chip.label} {...chip} />
              ))}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}