export type DaterangeChangeFunction = (range: DaterangeOutput) => void;

export interface IDaterangeProps {
  defaultValue?: DaterangeOutput;
  title?: string;
  applyButtonText?: string;
  onDaterangeChange: DaterangeChangeFunction;
}

export interface PeriodRangeSegment {
  start: string;
  end: string;
  endDate: Date;
  startDate: Date;
}

export interface DaterangeGeneratedRanges {
  today: { value: string; date: Date };
  thisWeek: PeriodRangeSegment;
  thisMonth: PeriodRangeSegment;
  thisYear: PeriodRangeSegment;
  yearToDate: PeriodRangeSegment;
}

export enum DaterangeDateOptionTypes {
  UpToToday = 0,
  ThisWeek = 1,
  ThisMonth = 2,
  ThisYear = 3,
  YearToDate = 4,
  CustomRange = 5,
}

export interface DaterangeOutput {
  /**
   * Equates to the enum of today, this week, etc.
   */
  type: DaterangeDateOptionTypes;

  /**
   * Only populated if the type of this object is "DaterangeDateOptionTypes.CustomRange"
   */
  daterangeIfApplicable?: [Date | null, Date | null];
}

export interface IDaterangeDialogProps {
  defaultValue: DaterangeOutput;
  today: React.MutableRefObject<Date>;
  activeItem: DaterangeDateOptionTypes;
  dateLimitStart: Date | undefined;
  dateLimitEnd: Date | undefined;
  isOpen: boolean;

  onClickActiveItem: (item: DaterangeDateOptionTypes) => void;
  onChangeDateLimitStart: (date: Date) => void;
  onChangeDateLimitEnd: (date: Date) => void;
  onDaterangeChange: DaterangeChangeFunction;
  onClose: () => void;

  title?: string;
  applyButtonText?: string;
}
