import React from 'react';
import { IAssessmentCreationStepsProps } from '../create-skill-assessment.models';
import { QuestionnaireStepHeader } from '../create-skill-assessment.helpers';
import { EntityAssignmentWidget } from '../../../../widgets/forms/skill-assessment/entity-selection-widget/entity-assignment-widget';
import { SkillAssessmentTagsWidget } from '../../../../widgets/forms';
import { SkillAssessmentSchedulerWidget } from '../../../../widgets/forms/skill-assessment/assessment-scheduling/assessment-scheduling.widget';
import { FieldsOfPracticeSelector } from '../../../../widgets/forms/skill-assessment/fields-of-practice/fields-of-practice.widget';
import { SkillAssessmentAssignees } from '../../../../models';
import { SmartSelectSection } from '@keplerco/core';
import { PreferencesWidget } from '../../../../widgets/forms/skill-assessment/preferences/preferences.widget';
import { AssessmentType } from '../../../../enums/assessment-type';

interface FieldsOfPracticeStepsProps {
  skillAssessmentSlug: string;
  skillAssessmentId: number;
  isDraft?: boolean;
  stepRefs: React.RefObject<HTMLDivElement>[];
  assessmentAssignees: SkillAssessmentAssignees | null;
}

export function FieldsOfPracticeSteps(props: IAssessmentCreationStepsProps & FieldsOfPracticeStepsProps): JSX.Element[] {
  return [
    <SmartSelectSection key="entitySelection" headerContent={<QuestionnaireStepHeader title="Assign Group:" descriptions="Assign groups of people to the questionnaire assessment." />}>
      <div ref={props.stepRefs[1]} key="entitySelection">
        <EntityAssignmentWidget assessmentAssignees={props.assessmentAssignees} onStepComplete={() => props.handleStepCompletion(1)} assessmentSlug={props.skillAssessmentSlug} />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="careerPathSelection" headerContent={<QuestionnaireStepHeader title="Fields of Practice" descriptions="Assign Fields of practice to the people/s you have selected." />}>
      <div ref={props.stepRefs[1]} key="entitySelection">
        <FieldsOfPracticeSelector onStepComplete={() => props.handleStepCompletion(2)} careerPathSlugs={[]} assessmentSlug={props.skillAssessmentSlug} />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="scheduleAssessment" headerContent={<QuestionnaireStepHeader title="Schedule assessment:" descriptions="Schedule when to start and close the assessment" />}>
      <div ref={props.stepRefs[3]} key="scheduleAssessment">
        <SkillAssessmentSchedulerWidget skillAssessmentId={props.skillAssessmentId} onStepComplete={() => props.handleStepCompletion(3)} skillAssessmentSlug={props.skillAssessmentSlug} />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="preferences" headerContent={<QuestionnaireStepHeader title="Preferences:" descriptions="Choose the preferences for this assessment" />}>
      <div ref={props.stepRefs[4]} key="preferences">
        <PreferencesWidget assessmentSlug={props.skillAssessmentSlug} onNext={() => props.handleStepCompletion(4)} assessmentType={AssessmentType.FieldsOfPractice} />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="skillTags" headerContent={<QuestionnaireStepHeader title="Tag:" descriptions="Assign a tag to an assessment for analytics." />}>
      <div ref={props.stepRefs[5]} key="skillTags">
        <SkillAssessmentTagsWidget
          onComplete={() => {
            props.onFinalStepCompletion();
          }}
          assessmentSlug={props.skillAssessmentSlug}
        />
      </div>
    </SmartSelectSection>,
  ].filter((_, index) => index + 1 <= props.index);
}

