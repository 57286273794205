import React from 'react';
import { IExplainerFocusPanelProps } from './explainer-focus-panel.models';
import { Button, FocusPanelHeader } from '@keplerco/core';
import classNames from 'classnames';
import styles from './explainer-focus-panel.module.css';

export function ExplainerFocusPanelLayout(props: IExplainerFocusPanelProps): JSX.Element {
  return (
    <React.Fragment>
      <div className="dialogContentLayout focusPanelContentLayout">
        <FocusPanelHeader supertitle={props.supertitle} title={props.title} divider />

        <div className="dialogBodyLayout" style={{ marginTop: 30 }}>
          <pre className={classNames('body2', styles.textWrap)}>{props.description}</pre>
        </div>

        <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'flex-end' }}>
          <Button type="button" filled arrow={false} onClick={props.onClose}>
            Close
          </Button>
        </footer>
      </div>
    </React.Fragment>
  );
}