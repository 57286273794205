import { useEffect, useMemo, useState } from 'react';

export function useInViewport(ref: React.RefObject<HTMLElement>) {
  const [inViewport, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, observer]);

  return inViewport;
}
