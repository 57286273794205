import styled from 'styled-components';

export const CompanyAverages = styled.div`
  display: grid;

  @media screen and (min-width: 581px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px 10px 10px 10px;
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
    margin-top: 15px;
  }
`;

export const CompanyAverage = styled.div`
  @media screen and (min-width: 581px) {
    border-left: 1px solid var(--borders);
    padding: 10px 30px;
  }

  @media screen and (max-width: 580px) {
    &:not(:last-child) {
      border-bottom: 1px solid var(--borders);
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }
`;

