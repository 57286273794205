import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 2px;
  padding-right: 10px;
  border-radius: 50px;
  border: 1.5px solid var(--borders_1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  cursor: pointer;
`;

export const LanguageOptionElement = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
`;

export const Container = styled.div<{ position: string }>`
  display: inline-block;
  z-index: 9999;

  ${props =>
    props.position === 'bottomLeft' &&
    `
    position: fixed;
    bottom: 10px;
    left: 40px;
  `}

  ${props =>
    props.position === 'topNavigation' &&
    `
    position: relative; 
    padding-right: 10px;
  `}
`;

export const Modal = styled.div<{ position: string }>`
  position: absolute;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  margin-bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  overflow-y: auto;
  max-height: 50vh;

  @media (max-width: 600px) {
    max-height: 70vh;
  }

  ${props =>
    props.position === 'bottomLeft' &&
    `
    bottom: 100%;
  `}

  ${props =>
    props.position === 'topNavigation' &&
    `
    top: 100%;
    margin-top: 10px;
  `}
`;

export const ModalHeading = styled.h5`
  color: black;
  font-weight: 400;
`;

