import styled from 'styled-components';

export const FuzzySearchWrapper = styled.div`
  display: block;
  width: 100%;
  height: auto;
`;

export const FuzzySearchHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin: 10px 0 0 0;
  border-top: solid 1px var(--borders_1);
  border-bottom: solid 1px var(--borders_1);

  &.boxedOutput,
  &.noBorders {
    padding: 0;
    margin: 0;
    border: none;
  }
`;

export const FuzzySearchHeaderColumn = styled.div`
  .chart {
    margin-top: 0 !important;
  }
`;

export const FuzzySearchResultsContainer = styled.div`
  padding: 30px 0;
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  border-bottom: solid 1px var(--borders_1);
  width: 100%;

  &.noBorders {
    border-bottom: none;
  }

  &.boxedOutput {
    padding: 20px;
    padding-top: 26px;
    border: solid 1px var(--borders_1);
    border-top: none;
    margin-top: -6px;
    background: var(--background);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.hasError {
    border-bottom-color: var(--validation);
  }
`;

export const FuzzySearchEmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: solid 1px var(--borders_1);

  &.noBorders {
    border-bottom: none;
  }
`;
