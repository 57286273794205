import styled from 'styled-components';
import { CMSRow } from '../../../theme/layout.styles';

export const SkillAssessmentAssigneePageHeader = styled.header`
  .heading5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const SkillAssessmentAssigneePageContent = styled.section`
  display: block;
  padding-top: 25px;
`;

export const SkillAssessmentEmptyMark = styled.span`
  color: var(--text_1);
  display: block;
  text-align: center;
`;

export const SkillAssessmentAssigneesHeader = styled(CMSRow)`
  display: grid;
  grid-template-columns: 350px 1fr auto;
  gap: 15px;
  padding-top: 10px;
`;

