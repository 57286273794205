import { Employee, UniqueEntity } from '../../../../../models';
import { Permission, Permissions } from '../../../../../models/overmind/permissions';
import { TagList } from '../../../../../models/tag';

export enum ManagePersonCascadingPanelIds {
  Sections = 'sections',
  Permissions = 'permissions',
  Tags = 'tags',
}

export interface IManagePersonCascadingPanelsProps {
  personId: string | undefined;
  onCancel: () => void;
}

export interface IManagePersonFocusPanelProps {
  person: Employee | undefined;
  permissions: Permissions | undefined;
  tags: TagList[] | undefined;
  onClickSection: (section: ManagePersonCascadingPanelIds) => void;
  onCancel: () => void;
  onSave: (personSlug: string) => void;
}

export interface IManagePersonDetailsLayoutProps {
  defaultValues: Employee | undefined;
  onSave: (personSlug: string) => void;
}

// permissions
export enum ManagePersonPermissionsCascadingPanelIds {
  Sections = 'sections',
  Analytics = 'analytics',
  Administration = 'administration',
  Assessments = 'assessments',
  Tags = 'tags',
  LearningManagement = 'learningManagement',
  Skills = 'skills',
  Roles = 'roleManagement',
}

export interface IManagePersonPermissionsCascadingPanelsProps {
  person: Employee | undefined;
  permissions: Permissions | undefined;
  onSave: () => void;
  onCancel: () => void;
}

export interface IManagePersonPermissionsFocusPanelProps {
  person: Employee | undefined;
  permissions: Permissions | undefined;
  onClickSection: (section: ManagePersonPermissionsCascadingPanelIds) => void;
  onCancel: () => void;
}

export interface IManagePersonPermissionFocusPanelProps {
  name: string;
  company: UniqueEntity | undefined;
  person: Employee | undefined;
  permission: Permission | undefined;
  onCancel: () => void;
  onSave: () => void;
}

// tags
export interface IManagePersonTagsFocusPanelProps {
  personSlug: string | undefined;
  onCancel: () => void;
}
