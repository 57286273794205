import React, { useEffect, useState } from 'react';
import * as theme from '../../../theme';
import { PasswordStrength } from '../../../components';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { SetPasswordAccountPageCheckbox, AccountPageContainer, AccountPageLineBreak, AccountPageForm, AccountPageNavigationToggler } from '../account.styles';
import { AccountPageButton } from '../account.components';
import { ResetPassword } from '../../../models/view/reset-password';
import { AccountPageLoaderLayer } from '../../../components/loading-handling/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { getSubdomain } from '../../../lib/get-subdomain';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, Checkbox, ConfirmationPasswordValidator, EmailValidator, FormControl, RequiredValidator, Textfield } from '@keplerco/core';

export function SetPasswordPage(): JSX.Element {
  const actions = useAppActions();
  const { passwordErrors } = useAppState<KeplerState>();
  const params = new URLSearchParams(window.location.search);
  const [formValue, setFormValue] = useState<{ [key: string]: any }>();
  const { control, handleSubmit, getValues, watch, setError } = useForm<any>({ reValidateMode: 'onChange' });
  const [samlUrl, setSamlUrl] = useState<string | undefined>();
  const navigate = useNavigate();

  watch(value => setFormValue(value));

  useEffect(() => {
    actions.getSamlUrl(getSubdomain()).then(response => {
      setSamlUrl(response);
    });
  }, []);

  return (
    <theme.ShapesBackgroundWrapper>
      <AccountPageContainer>
        <div className="card glass">
          <AccountPageLoaderLayer path={PagePath.accountSetPassword}>
            <AccountPageNavigationToggler className="showDesktop">
              <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                Already have an account? <u>Sign In</u>
              </span>
            </AccountPageNavigationToggler>

            <h1 className="heading1" id="set-password-heading">
              Welcome to Kepler
            </h1>
            <h6 className="subtitle">Fill in your company email address and set your password</h6>

            <AccountPageForm
              id="setPassword"
              autoComplete="off"
              onSubmit={handleSubmit(async (value: ResetPassword) => {
                actions.startLoader({ path: PagePath.accountSetPassword, type: FetchType.PageFetching });
                value.code = params.get('code')?.toString();
                await actions.setPasswordAndSync(value);
                actions.stopLoader(PagePath.accountSetPassword);
              })}
            >
              <FormControl paddingBottom={0} control={control} name="email" rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

              <FormControl
                paddingBottom={0}
                control={control}
                name="password"
                rules={new RequiredValidator('Enter your password')}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <Textfield {...field} haserror={!!fieldState.error} label="Password" type="password" responsive />
                    {!!formValue?.password && <PasswordStrength password={formValue.password} />}
                  </React.Fragment>
                )}
              />

              <FormControl
                paddingBottom={0}
                control={control}
                name="confirmPassword"
                rules={new ConfirmationPasswordValidator('Enter your confirmation password', getValues('password'))}
                render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Confirm Password" type="password" responsive />}
              />

              <FormControl
                paddingBottom={0}
                control={control}
                name="terms"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <SetPasswordAccountPageCheckbox>
                      <Checkbox {...field} id="termsAndConditions" checked={getValues('terms')} clickablearea="checkbox">
                        <div style={{ textAlign: 'left', fontSize: 14 }}>
                          By signing up, you agree to our{' '}
                          <a target="_blank" rel="noreferrer" href="https://legal.keplerco.io/documents/terms-of-use/">
                            terms of service
                          </a>{' '}
                          and{' '}
                          <a target="_blank" rel="noreferrer" href="https://legal.keplerco.io/documents/privacy-policy/">
                            privacy policy
                          </a>
                        </div>
                      </Checkbox>
                    </SetPasswordAccountPageCheckbox>

                    {!!fieldState.error && <div className="formErrorMessage">Please agree to our terms of service and privacy policy before continuing</div>}
                  </React.Fragment>
                )}
              />

              <Button type="button">Activate your account</Button>

              {passwordErrors !== undefined &&
                passwordErrors?.length > 0 &&
                passwordErrors.map((error: string, index: number) => {
                  return (
                    <React.Fragment key={'error_' + index}>
                      <div className="formErrorMessage">{error}</div>
                    </React.Fragment>
                  );
                })}
            </AccountPageForm>

            <AccountPageLineBreak>Or activate using</AccountPageLineBreak>

            {!!samlUrl && (
              <AccountPageButton
                buttonType="saml"
                onClick={async () => {
                  if (!!getValues('terms')) {
                    window.location.href = `${samlUrl}?returnUrl=${params.get('kt')},,,${params.get('code')}`;
                  } else {
                    setError('terms', { type: 'focus' }, { shouldFocus: true });
                  }
                }}
              />
            )}

            <AccountPageButton
              buttonType="google"
              onClick={() => {
                if (!!getValues('terms')) {
                  actions.externalLogin({ provider: 'Google', kt: params.get('kt'), code: params.get('code') });
                } else {
                  setError('terms', { type: 'focus' }, { shouldFocus: true });
                }
              }}
            />

            <AccountPageButton
              buttonType="microsoft"
              onClick={() => {
                if (!!getValues('terms')) {
                  actions.externalLogin({ provider: 'Microsoft', kt: params.get('kt'), code: params.get('code') });
                } else {
                  setError('terms', { type: 'focus' }, { shouldFocus: true });
                }
              }}
            />

            <AccountPageButton
              buttonType="linkedin"
              onClick={() => {
                if (!!getValues('terms')) {
                  actions.externalLogin({ provider: 'LinkedIn', kt: params.get('kt'), code: params.get('code') });
                } else {
                  setError('terms', { type: 'focus' }, { shouldFocus: true });
                }
              }}
            />
          </AccountPageLoaderLayer>
        </div>
      </AccountPageContainer>
    </theme.ShapesBackgroundWrapper>
  );
}

