import React from 'react';
import { Days, LoginStreak } from '../../../components/login-streak/login-streak';
import { QueueItem } from '../../../components';
import { TileNotificationsItemBody, TileNotificationsItemBodyClose, TileNotificationsItemBodyContent, TileNotificationsItemBodyIcon } from '../../../components/notification-hub/tile-hub/tile-hub.styles';

export function LoginStreakTileLayout(props: QueueItem & { onAction: () => void }): JSX.Element {
  if (!props.loginStreaks) return <React.Fragment />;

  const dates = props.loginStreaks.map(date => new Date(date));
  const days: Days[] = ['MON', 'TUE', 'WED', 'THU', 'FRI'];

  return (
    <TileNotificationsItemBody>
      <TileNotificationsItemBodyIcon>
        <object data="/icons/ico_flame.svg" type="image/svg+xml">
          <img src="/icons/ico_flame.svg" alt="Streak" />
        </object>
      </TileNotificationsItemBodyIcon>

      <TileNotificationsItemBodyContent>
        <p className="body1">
          {dates?.length ?? 1} Day{dates?.length !== 1 ? 's' : ''} in a Row
        </p>

        <LoginStreak dates={dates} days={days} />
      </TileNotificationsItemBodyContent>

      <TileNotificationsItemBodyClose>
        <button onClick={props.onAction} />
      </TileNotificationsItemBodyClose>
    </TileNotificationsItemBody>
  );
}

