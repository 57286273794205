import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import confettiLottie from '../../../../assets/confetti.lottie.json';
import { LottieOutput } from '../../../../components/lottie-icon/lottie-icon';
import { SkillGapReportBreakdownList, SkillGapReportBreakdownListItem } from './skills-gap-report-modal.styles';
import { Button, KeplerPoints } from '@keplerco/core';

export function SkillsGapReportModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  return (
    <>
      <div className="dialogBackgroundLayout">
        <LottieOutput loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent">
        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data="/badges/badge_Astronaut.svg" type="image/svg+xml">
              <img src="/badges/badge_Astronaut.svg" alt="Astronaut" />
            </object>
          </div>

          <h2 className="heading2">Stellar Job!</h2>

          <h6 className="subtitle">
            You've earned a total of
            <br />
            <h3 className="heading3">
              <KeplerPoints trimWhiteSpace points={Math.round(props.amount ?? 0)} />
            </h3>
            <br />
            for completing your skills assessment.
          </h6>

          {props.awardBreakdowns && (
            <SkillGapReportBreakdownList className="card-list">
              {props.awardBreakdowns?.map(award => {
                return (
                  <SkillGapReportBreakdownListItem key={award.description}>
                    <div className="card">
                      <small>{award.description}</small>

                      <h4 className="heading4">
                        +<KeplerPoints points={Math.round(award.amount)} />
                      </h4>
                    </div>
                  </SkillGapReportBreakdownListItem>
                );
              })}
            </SkillGapReportBreakdownList>
          )}
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            Great, Thanks!
          </Button>
        </footer>
      </div>
    </>
  );
}

