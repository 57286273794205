import classNames from 'classnames';
import React from 'react';
import { LoginStreakDay, LoginStreakDays } from './login-streak.styles';

export type Days = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';

export function LoginStreak(props: { dates?: Date[]; days?: Days[] }): JSX.Element {
  const abbreviations: Days[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  const days: Days[] = props.days ?? abbreviations;

  let indices = days.map((day, index) => index);
  if (!!props.dates) {
    indices = props.dates.map(date => {
      const abbreviation = abbreviations[date.getDay()];
      return days.findIndex(day => day === abbreviation);
    });
  }

  return (
    <LoginStreakDays>
      {days.map((day, index) => {
        return (
          <LoginStreakDay key={day} className={classNames({ active: indices.includes(index) })}>
            <span className="caption2" style={{ marginBottom: '5px', textAlign: 'center' }}>
              {day}
            </span>
            <div />
          </LoginStreakDay>
        );
      })}
    </LoginStreakDays>
  );
}

