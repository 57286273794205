import React from 'react';

export function ReflectIcon(): JSX.Element {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
      <path
        d="M32.1653 25.7977C31.5276 25.7977 31.011 26.3163 31.011 26.9541V67.6063C31.011 68.2443 31.5276 68.7638 32.1653 68.7638H62.9456C63.5834 68.7638 64.1029 68.2443 64.1029 67.6063V26.9541C64.1029 26.3163 63.5834 25.7977 62.9456 25.7977H32.1653ZM62.9456 70.4165H32.1653C30.6185 70.4165 29.3574 69.1566 29.3574 67.6063V26.9541C29.3574 25.4047 30.6185 24.1442 32.1653 24.1442H62.9456C64.4953 24.1442 65.7565 25.4047 65.7565 26.9541V67.6063C65.7565 69.1566 64.4953 70.4165 62.9456 70.4165"
        fill="var(--primary)"
      />
      <path
        d="M30.1821 66.6899H30.0534C27.4101 66.6899 25.2617 64.5393 25.2617 61.8951V47.496C25.2617 47.0397 25.6308 46.6695 26.0856 46.6695C26.5433 46.6695 26.9124 47.0397 26.9124 47.496V61.8951C26.9124 63.3875 27.9594 64.6402 29.3554 64.9578V26.9544C29.3554 25.405 30.617 24.1443 32.1675 24.1443H59.8162C59.3786 22.9302 58.2171 22.0697 56.8669 22.0697H30.0534C28.3227 22.0697 26.9124 23.4789 26.9124 25.2108V42.0938C26.9124 42.551 26.5433 42.9212 26.0856 42.9212C25.6308 42.9212 25.2617 42.551 25.2617 42.0938V25.2108C25.2617 22.5672 27.4101 20.4165 30.0534 20.4165H56.8669C59.3957 20.4165 61.4955 22.3953 61.6471 24.9216C61.6614 25.149 61.5784 25.3716 61.424 25.5375C61.2666 25.7034 61.0492 25.7978 60.8232 25.7978H32.1675C31.5295 25.7978 31.0117 26.3165 31.0117 26.9544V65.8626C31.0117 66.3198 30.6399 66.6899 30.1821 66.6899Z"
        fill="var(--primary)"
      />
      <path
        d="M38.8578 30.6038C37.0198 30.6038 35.5258 32.0981 35.5258 33.9352C35.5258 35.7723 37.0198 37.2666 38.8578 37.2666C40.6957 37.2666 42.1897 35.7723 42.1897 33.9352C42.1897 32.0981 40.6957 30.6038 38.8578 30.6038ZM38.8578 38.9204C36.1091 38.9204 33.875 36.6838 33.875 33.9352C33.875 31.1868 36.1091 28.9502 38.8578 28.9502C41.6064 28.9502 43.8433 31.1868 43.8433 33.9352C43.8433 36.6838 41.6064 38.9204 38.8578 38.9204Z"
        fill="var(--primary)"
      />
      <path
        d="M38.8578 52.2651C38.401 52.2651 38.0323 51.8959 38.0323 51.4389C38.0323 50.9827 38.401 50.6124 38.8578 50.6124C40.6957 50.6124 42.1897 49.1181 42.1897 47.281C42.1897 45.4431 40.6957 43.9496 38.8578 43.9496C37.0198 43.9496 35.5258 45.4431 35.5258 47.281C35.5258 48.0239 35.7652 48.7268 36.2192 49.314C36.4971 49.675 36.431 50.1939 36.0706 50.4732C35.7074 50.7524 35.1902 50.6858 34.9095 50.3238C34.2327 49.4458 33.875 48.3934 33.875 47.281C33.875 44.5323 36.1091 42.296 38.8578 42.296C41.6064 42.296 43.8433 44.5323 43.8433 47.281C43.8433 50.0296 41.6064 52.2651 38.8578 52.2651Z"
        fill="var(--secondary)"
      />
      <path
        d="M38.8578 57.2944C37.0198 57.2944 35.5258 58.7887 35.5258 60.6267C35.5258 62.4638 37.0198 63.9583 38.8578 63.9583C40.6957 63.9583 42.1897 62.4638 42.1897 60.6267C42.1897 58.7887 40.6957 57.2944 38.8578 57.2944ZM38.8578 65.6111C36.1091 65.6111 33.875 63.3753 33.875 60.6267C33.875 57.8783 36.1091 55.6417 38.8578 55.6417C41.6064 55.6417 43.8433 57.8783 43.8433 60.6267C43.8433 63.3753 41.6064 65.6111 38.8578 65.6111Z"
        fill="var(--primary)"
      />
      <path d="M60.4086 38.9203H56.5759C56.1186 38.9203 55.7471 38.5499 55.7471 38.0937C55.7471 37.637 56.1186 37.2666 56.5759 37.2666H60.4086C60.863 37.2666 61.2346 37.637 61.2346 38.0937C61.2346 38.5499 60.863 38.9203 60.4086 38.9203Z" fill="#E5145F" />
      <path d="M52.2021 38.9199H46.2581C45.8004 38.9199 45.4326 38.5496 45.4326 38.0936C45.4326 37.6365 45.8004 37.2665 46.2581 37.2665H52.2021C52.6569 37.2665 53.0276 37.6365 53.0276 38.0936C53.0276 38.5496 52.6569 38.9199 52.2021 38.9199Z" fill="#E5145F" />
      <path d="M60.4082 34.7615H49.4217C48.9644 34.7615 48.5957 34.3922 48.5957 33.9352C48.5957 33.4791 48.9644 33.1086 49.4217 33.1086H60.4082C60.8626 33.1086 61.2342 33.4791 61.2342 33.9352C61.2342 34.3922 60.8626 34.7615 60.4082 34.7615Z" fill="#E5145F" />
      <path d="M60.4089 52.2653H46.2585C45.8012 52.2653 45.4297 51.8957 45.4297 51.4387C45.4297 50.9825 45.8012 50.6124 46.2585 50.6124H60.4089C60.8634 50.6124 61.2349 50.9825 61.2349 51.4387C61.2349 51.8957 60.8634 52.2653 60.4089 52.2653Z" fill="#E5145F" />
      <path d="M60.4082 48.1073H49.4217C48.9644 48.1073 48.5957 47.7372 48.5957 47.281C48.5957 46.824 48.9644 46.4536 49.4217 46.4536H60.4082C60.8626 46.4536 61.2342 46.824 61.2342 47.281C61.2342 47.7372 60.8626 48.1073 60.4082 48.1073Z" fill="#E5145F" />
      <path d="M60.4089 65.6112H46.2585C45.8012 65.6112 45.4297 65.2416 45.4297 64.7846C45.4297 64.3285 45.8012 63.9584 46.2585 63.9584H60.4089C60.8634 63.9584 61.2349 64.3285 61.2349 64.7846C61.2349 65.2416 60.8634 65.6112 60.4089 65.6112Z" fill="#E5145F" />
      <path d="M60.4082 61.4532H49.4217C48.9644 61.4532 48.5957 61.0831 48.5957 60.627C48.5957 60.17 48.9644 59.7996 49.4217 59.7996H60.4082C60.8626 59.7996 61.2342 60.17 61.2342 60.627C61.2342 61.0831 60.8626 61.4532 60.4082 61.4532Z" fill="#E5145F" />
    </svg>
  );
}
