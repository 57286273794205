import styled from 'styled-components';

export const ReportCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--borders);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0;
  background: var(--cards);
`;

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
`;

export const ReportName = styled.h6`
  font-size: 1.25rem;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 930px) {
    width: 100%;
    max-width: 100%;
    min-width: unset;
  }
`;

export const ReportDescription = styled.p`
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
`;

export const ReportToggle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  margin-right: 55px;
  min-height: 50px;

  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;

export const ReportChip = styled.div`
  margin-bottom: 5px;
`;

export const ReportAction = styled.div`
  margin: 10px 0 0 auto;
`;

export const ReportSearch = styled.div`
  padding: 10px 0;
`;

export const HorizontalDivider = styled.div`
  margin-top: 10px;
  padding: 0;
  border-top: 1px solid var(--borders);
`;

export const ReportFocusPanelHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid var(--borders);
`;

export const ReportFocusPanelToggle = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  background: var(--cards);
  border-radius: 0.5rem;
  border: 1px solid var(--borders);
  padding: 0 0.5rem;
  margin: 10px 0 25px;
`;

export const ToggleConnector = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid var(--default);
`;

