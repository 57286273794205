import React, { Dispatch, SetStateAction } from 'react';
import { IAssessmentCreationStepsProps } from '../create-skill-assessment.models';
import { SkillAssessmentAssignees } from '../../../../models';
import { SkillAssessmentTagsWidget } from '../../../../widgets/forms';
import { QuestionnaireStepHeader } from '../create-skill-assessment.helpers';
import { SmartSelectSection } from '@keplerco/core';
import { PreferencesWidget } from '../../../../widgets/forms/skill-assessment/preferences/preferences.widget';
import { AssessmentType } from '../../../../enums/assessment-type';
import { PeopleAndSkillsWidget } from '../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.widget';
import { AssessmentPreferencesResponse } from '../../../../models/overmind/assessment-preferences';

interface PeerEndorsementStepsProps {
  handleEndorsementSelectionChange: (updatedAssessmentAssignees: SkillAssessmentAssignees) => void;
  assessmentAssignees: SkillAssessmentAssignees | null;
  skillAssessmentSlug: string;
  isDraft?: boolean;
  setHasUserWithNoSkills: Dispatch<SetStateAction<boolean>>;
  stepRefs: React.RefObject<HTMLDivElement>[];
  preferences: AssessmentPreferencesResponse;
  setPreferences: React.Dispatch<React.SetStateAction<AssessmentPreferencesResponse>>;
}

export function PeerEndorsementSteps(props: IAssessmentCreationStepsProps & PeerEndorsementStepsProps): JSX.Element[] {
  return [
    <SmartSelectSection key="preferences" headerContent={<QuestionnaireStepHeader title="Preferences:" descriptions="Choose the preferences for this assessment" />}>
      <div ref={props.stepRefs[1]} key="preferences">
        <PreferencesWidget
          assessmentSlug={props.skillAssessmentSlug}
          onNext={() => props.handleStepCompletion(1)}
          assessmentType={AssessmentType.PeerEndorsement}
        />
      </div>
    </SmartSelectSection>,
    // TODO: delete Components
    // <SmartSelectSection key="endorsementSelection" headerContent={<QuestionnaireStepHeader title="Select who to endorse:" descriptions="Choose which team members you would like to take part in the endorsement, and their manager." />}>
    //   <div ref={props.stepRefs[2]} key="endorsementSelection">
    //     <EndorsementSelectionWidget onEndorsementSelectionChange={props.handleEndorsementSelectionChange} selectedAssignees={props.assessmentAssignees} assessmentSlug={props.skillAssessmentSlug} onStepComplete={() => props.handleStepCompletion(2)} />
    //   </div>
    // </SmartSelectSection>,
    // <SmartSelectSection key="selectSkills" headerContent={<QuestionnaireStepHeader title="Select skills:" descriptions="Select the skills you would like the team to endorse each other on." />}>
    //   <div ref={props.stepRefs[3]} key="selectSkills">
    //     <SelectSkillsWidget setHasUserWithNoSkills={props.setHasUserWithNoSkills} isDraft={!!props.isDraft} selectedAssigneeSkills={props.assessmentAssignees} onStepComplete={() => props.handleStepCompletion(3)} assessmentId={props.skillAssessmentSlug} />
    //   </div>
    // </SmartSelectSection>,
    <SmartSelectSection key="peopleAndSkills" headerContent={<QuestionnaireStepHeader title="People and Skills:" descriptions="Select individuals and assign skills for the assessment" />}>
      <div ref={props.stepRefs[2]} key="peopleAndSkills">
        <PeopleAndSkillsWidget
          assessmentSlug={props.skillAssessmentSlug}
          preferences={props.preferences}
          onNext={() => props.handleStepCompletion(2)}
        />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="skillTags" headerContent={<QuestionnaireStepHeader title="Tag:" descriptions="Assign a tag to an assessment for analytics." />}>
      <div ref={props.stepRefs[3]} key="skillTags">
        <SkillAssessmentTagsWidget
          onComplete={() => {
            props.onFinalStepCompletion();
          }}
          assessmentSlug={props.skillAssessmentSlug}
        />
      </div>
    </SmartSelectSection>,
  ].filter((_, index) => index + 1 <= props.index);
}
