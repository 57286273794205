import { UniqueEntity } from '../../../../models';

export function uniqueEntityArraysUnchanged(currentA: UniqueEntity[] | undefined, currentB: UniqueEntity[] | undefined) {
  if (!currentA && !currentB) return true;
  if (!currentA && !!currentB) return false;
  if (!!currentA && !currentB) return false;
  if (currentA!.length !== currentB!.length) return false;

  const nextA: UniqueEntity[] = structuredClone(currentA!);
  nextA.sort((a, b) => a.name.localeCompare(b.name));
  const nextB: UniqueEntity[] = structuredClone(currentB!);
  nextB.sort((a, b) => a.name.localeCompare(b.name));

  for (let i = 0; i < nextA.length; i++) {
    if (nextA[i].slug !== nextB[i].slug) return false;
  }

  return true;
}

export function numberArraysUnchanged(currentA: number[] | undefined, currentB: number[] | undefined) {
  if (!currentA && !currentB) return true;
  if (!currentA && !!currentB) return false;
  if (!!currentA && !currentB) return false;
  if (currentA!.length !== currentB!.length) return false;

  const nextA: number[] = structuredClone(currentA!);
  nextA.sort((a, b) => a - b);
  const nextB: number[] = structuredClone(currentB!);
  nextB.sort((a, b) => a - b);

  for (let i = 0; i < nextA.length; i++) {
    if (nextA[i] !== nextB[i]) return false;
  }

  return true;
}