import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { ReducerAction } from '../../../models/reducer-state';
import { TeamSearchResponse } from '../../../models/view/team-search-response';
import { TeamsCMSLayoutHeader } from './teams.cms.models';
import { SortField } from '../../../enums';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { PagePath } from '../../../navigation/navigation.enums';
import { TeamListItem } from '../../../models';
import { FocusPanelViewData } from '../../../lib/focus-panel.models';
import { Breadcrumb } from '@keplerco/core';

interface TeamsCMSState {
  pageTitle: string;
  crumbs: Breadcrumb[];
  request: BaseSearchRequest;

  data?: TeamSearchResponse;
  teamToManage?: FocusPanelViewData<string>;
  archiveTeam?: TeamListItem | undefined;

  managingColumns?: boolean;
  columnConfiguration: ColumnConfiguration[];
}

export const initialState: TeamsCMSState = {
  pageTitle: 'Teams',
  crumbs: [],
  request: { sortAscending: true, sortField: SortField.Name, pageSize: 15 },

  columnConfiguration: [
    { label: 'Team', key: 'team', required: true },
    { label: 'Department', key: 'department', required: false, selected: true },
    { label: 'Date Created', key: 'dateCreated', required: false, selected: true },
    { label: 'People', key: 'people', required: false, selected: true },
  ],
};

export enum TeamsCMSActionTypes {
  SetHeader,
  SetRequest,
  SetData,
  SetTeamToManage,
  SetArchiveTeam,
  ManageColumns,
  EditColumnConfiguration,
}

type TeamsCMSActions =
  | ReducerAction<TeamsCMSActionTypes.SetHeader, { payload: TeamsCMSLayoutHeader }>
  | ReducerAction<TeamsCMSActionTypes.SetRequest, { payload: BaseSearchRequest }>
  | ReducerAction<TeamsCMSActionTypes.SetData, { payload: TeamSearchResponse | undefined }>
  | ReducerAction<TeamsCMSActionTypes.SetTeamToManage, { payload: FocusPanelViewData<string> }>
  | ReducerAction<TeamsCMSActionTypes.SetArchiveTeam, { payload: TeamListItem | undefined }>
  | ReducerAction<TeamsCMSActionTypes.EditColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<TeamsCMSActionTypes.ManageColumns, { payload: { open: boolean; reset?: boolean } }>;

export function reducer(state: TeamsCMSState, action: TeamsCMSActions): TeamsCMSState {
  if (action.type === TeamsCMSActionTypes.SetHeader) {
    let crumbs: Breadcrumb[] = [
      { name: 'Administration', url: PagePath.administrationBase },
      { name: 'Teams', url: window.location.pathname },
    ];

    if (!!action.payload.departmentSlug) {
      crumbs = [
        { name: 'Administration', url: PagePath.administrationBase },
        { name: 'Departments', url: `${PagePath.administrationBase}${PagePath.administrationDepartments.replace(':companySlug', action.payload.companySlug)}` },
        { name: 'Teams', url: window.location.pathname },
      ];
    }

    return {
      ...state,
      pageTitle: 'Teams',
      crumbs: crumbs,
    };
  }

  if (action.type === TeamsCMSActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  if (action.type === TeamsCMSActionTypes.SetData) {
    return { ...state, data: action.payload };
  }

  if (action.type === TeamsCMSActionTypes.SetTeamToManage) {
    return { ...state, teamToManage: action.payload };
  }

  if (action.type === TeamsCMSActionTypes.SetArchiveTeam) {
    return { ...state, archiveTeam: action.payload };
  }

  if (action.type === TeamsCMSActionTypes.ManageColumns) {
    if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
    else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
  }

  if (action.type === TeamsCMSActionTypes.EditColumnConfiguration) {
    return { ...state, columnConfiguration: action.payload };
  }

  return state;
}
