import React from 'react';
import { useNavigate } from 'react-router-dom';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { LandingPageContent, LandingPageContentCard, LandingPageContentCardsWrapper, LandingPageImage, LandingPageWrapper } from '../landing.page.styles';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Chip } from '@keplerco/core';

export function CompletedPage(): JSX.Element {
  const navigate = useNavigate();

  return (
    <LandingPageWrapper>
      <LandingPageImage>
        <object data={themedAssetUrl('graphics/pie-graph.graphic.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('graphics/pie-graph.graphic.svg')} alt="graph" />
        </object>
      </LandingPageImage>

      <LandingPageContent>
        <Chip label="Team setup" backgroundColour="primary" colour="contrast-text" />

        <h1 className="heading1">You're all done</h1>
        <h6 className="subtitle">Your peer endorsement has been set up and your team members can now endorse each other.</h6>

        <LandingPageContentCardsWrapper>
          <LandingPageContentCard to={PagePath.yourSkillsBase} borderColour={'lime'} onClick={() => navigate(PagePath.dashboard)}>
            <h3 className="heading3">Go to my learning dashboard</h3>
            <span className="caption2">Start you own learning journey and close your skills gaps</span>
          </LandingPageContentCard>
        </LandingPageContentCardsWrapper>
      </LandingPageContent>
    </LandingPageWrapper>
  );
}

