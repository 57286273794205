import { Anchor } from '@keplerco/core';
import styled from 'styled-components';

export const SelectSkillsWidgetWrapper = styled.div`
  display: block;
`;

export const SelectSkillsWidgetHeader = styled.header`
  display: block;
  margin-bottom: 15px;

  .heading5 {
    margin-bottom: 15px;
    color: var(--accent-3);
  }
`;

export const SelectSkillsWidgetBody = styled.section`
  display: block;
  width: 100%;
`;

export const SelectSkillsRadioCardLayout = styled.div`
  .subtitle {
    font-weight: 700;
    font-family: var(--headings);
    color: var(--accent-3);
    font-size: 14px;
  }
`;

export const SelectSkillDualSelectWrapper = styled.div`
  max-width: 100%;
`;

export const SelectSkillWidgetSubmitButton = styled(Anchor)`
  align-self: flex-end;
  margin: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const SelectSkillWidgetSubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectSkillErrorMessageWrapper = styled.div`
  padding-bottom: 10px;
`;

