import styled from 'styled-components';
import { themedAssetUrl } from '../lib/theme-asset-url';
import { NavLink } from 'react-router-dom';

export const ShapesBackgroundWrapper = styled.div`
  width: 100%;
  background-image: url(${themedAssetUrl('backgrounds/pattern.background.svg')});
  width: 100%;
  background-repeat: repeat-x;
  background-position: bottom;
  min-height: calc(100vh - 92px);

  @media screen and (max-width: 930px) {
    min-height: calc(100vh - 75px);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  z-index: 10;
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  border: 1px solid var(--borders);
  background: var(--cards);
  backdrop-filter: blur(10px);
  will-change: width;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  min-height: 110px;

  @media screen and (max-width: 930px) {
    width: 100% !important;
    min-height: 0;
    padding: 15px 20px;
  }
`;

export const BottomButtonWrapper = styled(ButtonWrapper)`
  bottom: 0 !important;
`;

export const ButtonWrapperInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;

  .center {
    margin: auto;
  }
`;

export const ButtonTag = styled.label`
  color: var(--accent-3);
  font-size: 14px;

  @media screen and (max-width: 580px) {
    display: none;
  }
`;

export const ButtonTagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const AppPageWrapper = styled.div`
  display: block;

  &.full {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  &.padBottom {
    padding-bottom: 120px;

    @media (max-width: 580px) {
      padding-bottom: 80px;
    }
  }
`;

export const AppPageHeader = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 30px;
`;

export const PageFilterHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;

  @media screen and (max-width: 530px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    gap: 15px;
  }
`;

export const PageFilterHeaderColumn = styled.div``;

export const CMSColumn = styled.div`
  &.fill {
    flex: 1;
  }

  &.disabled {
    opacity: 0.65;
    pointer-events: none;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 800;
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`;

export const CMSRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const CMSHeaderRow = styled(CMSRow)`
  display: grid;
  grid-template-columns: 1fr 300px auto auto;
  gap: 20px;
`;

export const CMSTableLink = styled(NavLink)`
  color: var(--link-text);
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
`;

