import React from 'react';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { additionalLearningTypeIcons, AdditionalLearningListViewModel } from '../../../pages/learning-journey/additional-learning/additional-learning.models';
import { AdditionalLearningCardWrapper, AdditionalLearningCardHeader, AdditionalLearningCardBody, AdditionalLearningCardFooter } from './additional-learning-card.styles';
import { DeleteIcon, EditIcon, IconButton, KeplerPoints } from '@keplerco/core';

export function AdditionalLearningCard(props: { additionalLearning: AdditionalLearningListViewModel; onClickDelete: (additionalLearning: UniqueEntity) => void; onClickEdit: (additionalLearning: UniqueEntity) => void }): JSX.Element {
  const iconUrl = additionalLearningTypeIcons.find(item => item.type === props.additionalLearning.type)?.iconUrl;

  return (
    <AdditionalLearningCardWrapper>
      <AdditionalLearningCardHeader>
        <object data={iconUrl} type="image/svg+xml">
          <img src={iconUrl} alt="" />
        </object>
      </AdditionalLearningCardHeader>

      <AdditionalLearningCardBody>
        <span className="caption2">
          <KeplerPoints trimWhiteSpace points={props.additionalLearning.keplerPoints} /> earned
        </span>

        <h6 className="subtitle">{props.additionalLearning.name}</h6>

        {!!props.additionalLearning.completedOn && (
          <span className="caption1" style={{ color: 'var(--default)' }}>
            Added: {new Date(props.additionalLearning.completedOn).toLocaleDateString('en-ZA', { day: '2-digit', month: 'long', year: 'numeric' })}
          </span>
        )}
      </AdditionalLearningCardBody>

      <AdditionalLearningCardFooter>
        <IconButton iconType="fill" onClick={() => props.onClickDelete({ slug: props.additionalLearning.slug, name: props.additionalLearning.name })}>
          <DeleteIcon />
        </IconButton>

        <IconButton iconType="stroke" hoverTone="none" disableHoverEffect onClick={() => props.onClickEdit({ slug: props.additionalLearning.slug, name: props.additionalLearning.name })}>
          <EditIcon size={30} />
        </IconButton>
      </AdditionalLearningCardFooter>
    </AdditionalLearningCardWrapper>
  );
}

