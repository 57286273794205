export enum FetchStatus {
  Inactive = 'Inactive',
  Active = 'Active',
}

export enum FetchType {
  PageFetching = 'Page Fetching',
  DialogFetching = 'Dialog Fetching',
  Sending = 'Sending',
  Syncing = 'Syncing',
  Custom = 'Custom',
}
