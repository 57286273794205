import React from 'react';
import { AccountPageLoaderLayer } from '../../../components/loading-handling/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { VerifyEmailAccountPageImage, AccountPageContainer, VerifyEmailAccountPageCopy } from '../account.styles';
import { PagePath } from '../../../navigation/navigation.enums';

export function VerifyEmailPage(): JSX.Element {
  const params = new URLSearchParams(window.location.search);

  return (
    <AccountPageContainer>
      <div className="card glass">
        {/* TODO: test */}
        {/* <AccountPageLoaderLayer path={PagePath.verifyEmail}> */}
        <AccountPageLoaderLayer path={PagePath.accountVerifyEmail}>
          <VerifyEmailAccountPageImage data="/graphics/verify-email.graphic.svg" type="image/svg+xml">
            <img src="/graphics/verify-email.graphic.svg" alt="Plane" />
          </VerifyEmailAccountPageImage>

          <h1 className="heading1">Verify your email</h1>

          <VerifyEmailAccountPageCopy>
            <h6 className="subtitle">We've sent a confirmation email to {!!params.get('email') ? <strong>{params.get('email')}</strong> : 'your email'} with a link to activate your account.</h6>
            <h6 className="subtitle">Didn't receive the email? Please check your Spam folder.</h6>
            {/* TODO: Add in the following line when we have services to resend email: "If you still don't see it, you can <span>resend the confirmation email</span>." KPLR-1217 */}
          </VerifyEmailAccountPageCopy>
        </AccountPageLoaderLayer>
      </div>
    </AccountPageContainer>
  );
}

