import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { UniqueEntity } from '../../../models';
import { OrganizationLevelType } from '../../../enums';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';
import { Mode } from '../../../navigation/navigation.enums';
import { IPermissionsEntityDropdownWidgetProps, PermissionsEntity } from './permissions-entity-dropdown.models';
import { FilterByMenu, MenuItem } from '@keplerco/core';

export function PermissionsEntityDropdownWidget(props: IPermissionsEntityDropdownWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { mode, permissions, companyVariables } = useAppState();

  const [menuItems, setMenuItems] = useState<MenuItem[]>();

  async function generateEntities(companySlug: string | undefined): Promise<UniqueEntity[] | undefined> {
    if (!companySlug) return undefined;
    const departments = await actions.getAnalyticsDepartments(companySlug);
    const entities: UniqueEntity[] | undefined = departments?.map(department => ({ name: department.name, slug: department.slug }));
    return entities;
  }

  function generateMenuItems(entities: UniqueEntity[], organizationLevel: OrganizationLevelType, slug: string | undefined) {
    if (entities.length < 2 && mode !== Mode.PlatformManagement && extractHighestOrganizationLevel(permissions?.analytics.organizationLevels)?.organizationLevel !== OrganizationLevelType.Company) {
      setMenuItems([]);
      props.onClick({
        organizationLevel: !!slug ? OrganizationLevelType.Company : organizationLevel,
        entity: entities[0],
      });
      return;
    }

    const allEntity: PermissionsEntity = {
      organizationLevel: !!slug ? OrganizationLevelType.Company : organizationLevel,
      entity: { name: 'All', slug },
    };
    const allMenuItem: MenuItem = {
      onClick: () => props.onClick(allEntity),
      label: 'All',
    };

    const menuItems: MenuItem[] = entities.map(entity => ({ onClick: () => props.onClick({ organizationLevel, entity }), label: entity.name }));

    setMenuItems([allMenuItem].concat(menuItems));
    props.onClick(allEntity);
  }

  useEffect(() => {
    async function getData() {
      // role based
      if (mode === Mode.PlatformManagement) {
        const entities = await generateEntities(companyVariables.slug);
        if (!entities) return;

        generateMenuItems(entities, OrganizationLevelType.Department, companyVariables.slug);
        return;
      }

      // permissions based
      if (!permissions) return;
      const organizationLevel = extractHighestOrganizationLevel(permissions?.analytics.organizationLevels);
      if (!organizationLevel) return;

      // company
      if (organizationLevel.organizationLevel === OrganizationLevelType.Company) {
        const entities = await generateEntities(organizationLevel.entities[0].slug);
        if (!entities) return;

        generateMenuItems(entities, OrganizationLevelType.Department, organizationLevel?.entities[0].slug);
        return;
      }

      // department
      if (organizationLevel.organizationLevel === OrganizationLevelType.Department) {
        generateMenuItems(organizationLevel.entities, OrganizationLevelType.Department, undefined);
        return;
      }

      // team
      if (organizationLevel.organizationLevel === OrganizationLevelType.Team) {
        generateMenuItems(organizationLevel.entities, OrganizationLevelType.Team, undefined);
        return;
      }
    }

    getData();
  }, [mode, companyVariables.slug, permissions]);

  return <React.Fragment>{!!menuItems && menuItems.length > 0 && <FilterByMenu defaultItem={props.entity?.entity?.name} items={menuItems} />}</React.Fragment>;
}

