import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../../../components';

export function ManagePersonTagsFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="25px" width="150px" />
        <SkeletonLoader height="50px" width="150px" />
      </div>
    </div>
  );
}

