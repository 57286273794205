import styled from 'styled-components';

export const SkeletonLoaderBones = styled.div<{ height: string; width: string }>`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export const SkeletonLoaderInnards = styled.div<{ borderRadius: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius};
  background: var(--borders_2);
  overflow: hidden;
  z-index: 45;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 200%;
    height: 100%;
    background: linear-gradient(90deg, transparent 0%, transparent 35%, var(--text_2) 50%, transparent 75%, transparent 100%);
    opacity: 0.2;
    animation: Shine linear 1500ms infinite;
  }

  @keyframes Shine {
    from {
      left: -150%;
    }

    to {
      left: 150%;
    }
  }
`;

export const SkeletonLoaderContainer = styled.div`
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const SkeletonLoaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SkeletonLoaderColumn = styled.div<{ flex?: number }>`
  flex: ${props => props.flex ?? 1};
`;

