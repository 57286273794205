import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import * as daterangeStyles from './daterange.styles';
import { DaterangeDateOptionTypes, DaterangeOutput, IDaterangeDialogProps } from './daterange.models';
import classNames from 'classnames';
import { Datepicker } from '../datepicker/datepicker';
import { computeDateRanges } from './daterange.data';

export function DaterangeDialog(props: IDaterangeDialogProps): JSX.Element {
  const { defaultValue, today, activeItem, dateLimitStart, dateLimitEnd, isOpen, onClickActiveItem, onChangeDateLimitStart, onChangeDateLimitEnd, onDaterangeChange, onClose, title, applyButtonText } = props;

  const ranges = useRef(computeDateRanges(today.current));

  function apply(): void {
    const range = [dateLimitStart, dateLimitEnd] as any;

    const data: DaterangeOutput = {
      type: activeItem,
      daterangeIfApplicable: activeItem === DaterangeDateOptionTypes.CustomRange ? range : void 0,
    };

    onDaterangeChange(data);
    onClose();
  }

  return ReactDOM.createPortal(
    <daterangeStyles.DaterangeModal open={isOpen}>
      <daterangeStyles.DaterangeModalInner>
        <daterangeStyles.DaterangeModalHeader>
          <daterangeStyles.DaterangeCloseButton onClick={onClose}>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6213 2.2999L15.2213 0.899902L8.62128 7.4999L2.02128 0.899902L0.621277 2.2999L7.22128 8.8999L0.621277 15.4999L2.02128 16.8999L8.62128 10.2999L15.2213 16.8999L16.6213 15.4999L10.0213 8.8999L16.6213 2.2999Z" fill="var(--accent-3)" />
            </svg>
          </daterangeStyles.DaterangeCloseButton>

          <h6 className="subtitle">{title ?? 'Sort by date'}</h6>
        </daterangeStyles.DaterangeModalHeader>

        <daterangeStyles.DaterangeModalContentListWrapper>
          <daterangeStyles.DaterangeModalContentList>
            {/* Date Range Option for "Up to Today" */}
            <daterangeStyles.DaterangeModalContentListItem onClick={() => onClickActiveItem(DaterangeDateOptionTypes.UpToToday)} className={classNames({ checked: activeItem === DaterangeDateOptionTypes.UpToToday })}>
              <daterangeStyles.DaterangeModalContentListRadio className={classNames({ checked: activeItem === DaterangeDateOptionTypes.UpToToday })} />
              <daterangeStyles.DaterangeModalContentListRadioContentPlain>
                <daterangeStyles.DaterangeModalRadioCaption>Up to Today</daterangeStyles.DaterangeModalRadioCaption>
                <daterangeStyles.DaterangeModalRadioTag>Everything up to {ranges.current.today.value}</daterangeStyles.DaterangeModalRadioTag>
              </daterangeStyles.DaterangeModalContentListRadioContentPlain>
            </daterangeStyles.DaterangeModalContentListItem>

            {/* Date Range Option for "This Week" */}
            <daterangeStyles.DaterangeModalContentListItem onClick={() => onClickActiveItem(DaterangeDateOptionTypes.ThisWeek)} className={classNames({ checked: activeItem === DaterangeDateOptionTypes.ThisWeek })}>
              <daterangeStyles.DaterangeModalContentListRadio className={classNames({ checked: activeItem === DaterangeDateOptionTypes.ThisWeek })} />
              <daterangeStyles.DaterangeModalContentListRadioContentPlain>
                <daterangeStyles.DaterangeModalRadioCaption>This Week</daterangeStyles.DaterangeModalRadioCaption>
                <daterangeStyles.DaterangeModalRadioTag>
                  {ranges.current.thisWeek.start} - {ranges.current.thisWeek.end}
                </daterangeStyles.DaterangeModalRadioTag>
              </daterangeStyles.DaterangeModalContentListRadioContentPlain>
            </daterangeStyles.DaterangeModalContentListItem>

            {/* Date Range Option for "This Month" */}
            <daterangeStyles.DaterangeModalContentListItem onClick={() => onClickActiveItem(DaterangeDateOptionTypes.ThisMonth)} className={classNames({ checked: activeItem === DaterangeDateOptionTypes.ThisMonth })}>
              <daterangeStyles.DaterangeModalContentListRadio className={classNames({ checked: activeItem === DaterangeDateOptionTypes.ThisMonth })} />
              <daterangeStyles.DaterangeModalContentListRadioContentPlain>
                <daterangeStyles.DaterangeModalRadioCaption>This Month</daterangeStyles.DaterangeModalRadioCaption>
                <daterangeStyles.DaterangeModalRadioTag>
                  {ranges.current.thisMonth.start} - {ranges.current.thisMonth.end}
                </daterangeStyles.DaterangeModalRadioTag>
              </daterangeStyles.DaterangeModalContentListRadioContentPlain>
            </daterangeStyles.DaterangeModalContentListItem>

            {/* Date Range Option for "This Year" */}
            <daterangeStyles.DaterangeModalContentListItem onClick={() => onClickActiveItem(DaterangeDateOptionTypes.ThisYear)} className={classNames({ checked: activeItem === DaterangeDateOptionTypes.ThisYear })}>
              <daterangeStyles.DaterangeModalContentListRadio className={classNames({ checked: activeItem === DaterangeDateOptionTypes.ThisYear })} />
              <daterangeStyles.DaterangeModalContentListRadioContentPlain>
                <daterangeStyles.DaterangeModalRadioCaption>This Year</daterangeStyles.DaterangeModalRadioCaption>
                <daterangeStyles.DaterangeModalRadioTag>
                  {ranges.current.thisYear.start} - {ranges.current.thisYear.end}
                </daterangeStyles.DaterangeModalRadioTag>
              </daterangeStyles.DaterangeModalContentListRadioContentPlain>
            </daterangeStyles.DaterangeModalContentListItem>

            {/* Date Range Option for "Year to Date" */}
            <daterangeStyles.DaterangeModalContentListItem onClick={() => onClickActiveItem(DaterangeDateOptionTypes.YearToDate)} className={classNames({ checked: activeItem === DaterangeDateOptionTypes.YearToDate })}>
              <daterangeStyles.DaterangeModalContentListRadio className={classNames({ checked: activeItem === DaterangeDateOptionTypes.YearToDate })} />
              <daterangeStyles.DaterangeModalContentListRadioContentPlain>
                <daterangeStyles.DaterangeModalRadioCaption>Year to Date</daterangeStyles.DaterangeModalRadioCaption>
                <daterangeStyles.DaterangeModalRadioTag>
                  {ranges.current.yearToDate.start} - {ranges.current.yearToDate.end}
                </daterangeStyles.DaterangeModalRadioTag>
              </daterangeStyles.DaterangeModalContentListRadioContentPlain>
            </daterangeStyles.DaterangeModalContentListItem>

            {/* Date Range Option for "Custom Range" */}
            <daterangeStyles.DaterangeModalContentListCustomItem onClick={() => onClickActiveItem(DaterangeDateOptionTypes.CustomRange)} className={classNames({ checked: activeItem === DaterangeDateOptionTypes.CustomRange })}>
              <daterangeStyles.DaterangeModalContentListRadio className={classNames({ checked: activeItem === DaterangeDateOptionTypes.CustomRange })} />
              <daterangeStyles.DaterangeModalContentListRadioContent>
                <daterangeStyles.DaterangeModalRadioCaption>Custom Range</daterangeStyles.DaterangeModalRadioCaption>
                <daterangeStyles.DaterangeModalPickerOutput>
                  <Datepicker fixed label="Date from" defaultDate={dateLimitStart} onDateSelected={onChangeDateLimitStart} maxDate={dateLimitEnd} />
                  <Datepicker fixed label="Date to" defaultDate={dateLimitEnd} onDateSelected={onChangeDateLimitEnd} minDate={dateLimitStart} maxDate={today.current} />
                </daterangeStyles.DaterangeModalPickerOutput>
              </daterangeStyles.DaterangeModalContentListRadioContent>
            </daterangeStyles.DaterangeModalContentListCustomItem>
          </daterangeStyles.DaterangeModalContentList>
        </daterangeStyles.DaterangeModalContentListWrapper>

        <daterangeStyles.DaterangeModalFooter>
          <span
            onClick={() => {
              onClickActiveItem(defaultValue.type ?? DaterangeDateOptionTypes.UpToToday);
              onClose();
            }}
          >
            Cancel
          </span>

          <button onClick={() => apply()}>{applyButtonText ?? 'Apply'}</button>
        </daterangeStyles.DaterangeModalFooter>
      </daterangeStyles.DaterangeModalInner>

      <daterangeStyles.DaterangeModalBackground onClick={onClose} />
    </daterangeStyles.DaterangeModal>,
    window.document.body
  );
}
