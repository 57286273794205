import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { SkillAssessmentsCMSPage } from '../../pages/assessments/skill-assessments.cms.page';
import { CreateSkillAssessmentsCMSPage } from '../../pages/assessments/create-skills-assessment/create-skill-assessment.cms.layout';
import { SkillAssessmentAssigneesPage } from '../../pages/assessments/skill-assessment-assignees/skill-assessment-assignees.page';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { useCompanySlugParamChange } from '../../lib/use-company-slug-param-change';

export function AssessmentsRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.assessmentsBase);

  return (
    <Routes>
      <Route path={PagePath.assessments} element={<SkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentsFilter} element={<SkillAssessmentsCMSPage />} />

      <Route path={PagePath.assessmentsCreate} element={<CreateSkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentsUpdate} element={<CreateSkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentAssignees} element={<SkillAssessmentAssigneesPage />} />
      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.assessmentsBase}${PagePath.assessments}`} />} />
    </Routes>
  );
}

