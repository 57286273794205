import styled from 'styled-components';

export const ActiveAssessmentInfoContainer = styled.div`
  margin: 30px 0px;
  border-top: solid 1px var(--borders);
  border-bottom: solid 1px var(--borders);
  padding: 15px;
`;

export const ActiveAssessmentAvatarIconContainer = styled.div`
  width: 50px;
  height: 50px;
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  overflow: hidden;
`;
