/* eslint-disable indent */
import React, { useEffect, useReducer, useState } from 'react';
import { FetchType } from '../../../../../../enums';
import { IManagePersonDetailsLayoutProps } from '../manage-person-panels.models';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { Team, UniqueEntity, Department, SavableEmployee } from '../../../../../../models';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { useForm } from 'react-hook-form';
import * as store from '../manage-person-panels.store';
import { Button, DropdownSearch, DropdownSelect, DropdownSelectItem, EmailValidator, FieldController, FormControl, LettersValidator, ListItemLayout, ListLayout, RequiredValidator, Textfield } from '@keplerco/core';
import { PeriodOfExperience, PeriodOfExperienceDescription } from '../../../../../../enums/period-of-experience';
import { MAJOR_LANGUAGES } from '../../../../../../lib/languages';

export function ManagePersonDetailsLayout(props: IManagePersonDetailsLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [, dispatch] = useReducer(store.reducer, store.initialState);

  const { control, handleSubmit, reset } = useForm<any>({ mode: 'onBlur', defaultValues: props.defaultValues });

  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [countries, setCountries] = useState<Array<UniqueEntity>>();
  const [teams, setTeams] = useState<Array<Team>>();
  const [departments, setDepartments] = useState<Array<Department>>();

  const [selectedCountry, setSelectedCountry] = useState<UniqueEntity>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();

  const [periodOfExperienceItems, setPeriodOfExperienceItems] = useState<DropdownSelectItem[]>([
    { label: PeriodOfExperienceDescription.ZeroToTwo, value: PeriodOfExperience.ZeroToTwo.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.ZeroToTwo) },
    { label: PeriodOfExperienceDescription.TwoToFour, value: PeriodOfExperience.TwoToFour.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.TwoToFour) },
    { label: PeriodOfExperienceDescription.FourToSeven, value: PeriodOfExperience.FourToSeven.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.FourToSeven) },
    { label: PeriodOfExperienceDescription.SevenPlus, value: PeriodOfExperience.SevenPlus.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.SevenPlus) },
  ]);

  const [languagesItems, setLanguagesItems] = useState<DropdownSelectItem[]>(
    MAJOR_LANGUAGES
      .map(language => ({
        value: language,
        onClick: () => onClickLanguage(language),
      }))
  );

  function onClickPeriodOfExperience(periodOfExperience: PeriodOfExperience) {
    setPeriodOfExperienceItems(currentState => currentState.map(item => ({ ...item, selected: parseInt(item.value) === periodOfExperience })));
  }

  function onClickLanguage(language: string) {
    setLanguagesItems(currentState => currentState.map(item => ({ ...item, selected: item.value === language ? !item.selected : item.selected })));
  }

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });

      const [countriesData, teamsData, departmentsData] = await Promise.all([actions.getCountries(String()), actions.getAdministrationTeams(companyVariables.slug!), actions.getAdministrationDepartments(companyVariables.slug!)]);

      setCountries(countriesData);
      setTeams(teamsData);
      setDepartments(departmentsData);

      if (!!props.defaultValues) {
        setSelectedCountry(countriesData?.find(country => country.id === props.defaultValues?.countryId));

        const tempSelectedDepartment = departmentsData?.find(department => department.slug === props.defaultValues?.departmentSlug);
        setSelectedDepartment(tempSelectedDepartment);

        const tempSelectedTeam = teamsData?.find(team => team.slug === props.defaultValues?.teamSlug);
        setSelectedTeam(tempSelectedTeam);

        setPeriodOfExperienceItems(currentState => currentState.map(item => ({ ...item, selected: parseInt(item.value) === props.defaultValues?.periodOfExperience })));

        setLanguagesItems(currentState => currentState.map(item => ({ ...item, selected: props.defaultValues?.languages?.some(language => language === item.value) })));

        reset(props.defaultValues);
      }

      actions.stopLoader(PagePath.administrationPeople);
    }

    getData();
  }, [props.defaultValues]);

  async function onSubmitHandler(values: any) {
    if (!selectedCountry?.id || !selectedDepartment || !selectedTeam) return;

    actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.Sending });

    const model: SavableEmployee = {
      id: props.defaultValues?.id,

      companySlug: companyVariables.slug!,

      countryId: selectedCountry.id,
      departmentId: !!selectedDepartment?.id ? parseInt(selectedDepartment.id) : undefined,
      teamId: selectedTeam?.id,

      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      jobTitle: values.jobTitle,
      periodOfExperience: parseInt(values.periodOfExperience),
      isDepartmentManager: values.isDepartmentManager,
      isTeamChampion: values.isTeamChampion,
      languages: languagesItems.filter(item => item.selected).map(item => item.value),
    };

    const person = await actions.savePerson(model);
    if (!!person) {
      dispatch({ type: store.ManagePersonPermissionsActionTypes.SetPerson, payload: person });
      props.onSave(person.id!);
    }

    actions.stopLoader(PagePath.administrationPeople);
  }

  return (
    <div className="card">
      <h5 className="heading5">User Details</h5>

      <form id="savePerson" onSubmit={handleSubmit(onSubmitHandler)}>
        <ListLayout>
          <ListItemLayout>
            <div className="dialogBodyLayout">
              <div className="row">
                <div className="column">
                  <FormControl paddingBottom={15} control={control} rules={new LettersValidator('Enter a first name')} name="firstName" render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="First name" type="text" responsive />} />
                </div>

                <div className="column">
                  <FormControl paddingBottom={15} control={control} rules={new LettersValidator('Enter a last name')} name="lastName" render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Last name" type="text" responsive />} />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <FormControl paddingBottom={15} control={control} rules={new EmailValidator()} name="email" render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" type="email" responsive />} />
                </div>

                <div className="column">
                  <FormControl paddingBottom={15} control={control} rules={new RequiredValidator('Enter a job title')} name="jobTitle" render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Job title" type="text" responsive />} />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <FieldController
                    name="periodOfExperience"
                    control={control}
                    rules={new RequiredValidator('Select years of experience')}
                    render={({ field, fieldState }) => (
                      <DropdownSelect
                        {...field}
                        label="Years of experience"
                        items={periodOfExperienceItems}
                        responsive
                        validation={{
                          dirty: fieldState.isDirty || !!fieldState.error,
                          invalid: fieldState.invalid,
                          message: fieldState.error?.message ?? 'Select years of experience'
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {!!countries && (
                <DropdownSearch
                  label="Country"
                  items={countries
                    .map(country => ({
                      value: country.id!.toString(),
                      label: country.name,
                      onClick: () => setSelectedCountry(country),
                      selected: selectedCountry?.id === country.id,
                    }))
                  }
                  validation={{
                    dirty: selectedCountry?.id !== props.defaultValues?.countryId || isDirty,
                    invalid: !selectedCountry,
                    message: 'Please select a country',
                  }}
                  responsive
                  dialogContained
                />
              )}

              {!!departments && (
                <DropdownSelect
                  label="Department"
                  items={departments
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(department => ({
                      value: department.slug,
                      label: department.name,
                      onClick: () => {
                        setSelectedDepartment(department);
                        if (!!selectedTeam && !department.teamIds?.includes(selectedTeam.id)) {
                          setSelectedTeam(undefined);
                        }
                      },
                      selected: selectedDepartment?.slug === department.slug,
                    }))
                  }
                  validation={{
                    dirty: selectedDepartment?.slug !== props.defaultValues?.departmentSlug || isDirty,
                    invalid: !selectedDepartment,
                    message: 'Please select a department',
                  }}
                  responsive
                />
              )}

              {!!teams && (
                <DropdownSelect
                  label="Team"
                  items={teams
                    .filter(team => !selectedDepartment ? true : selectedDepartment.slug === team.departmentSlug)
                    .sort((a, b) => a.teamName.localeCompare(b.teamName))
                    .map(team => ({
                      value: team.slug!,
                      label: team.teamName,
                      onClick: () => setSelectedTeam(team),
                      selected: selectedTeam?.slug === team.slug,
                    }))
                  }
                  validation={{
                    dirty: selectedTeam?.slug !== props.defaultValues?.teamSlug || isDirty,
                    invalid: !selectedTeam,
                    message: 'Please select a team',
                  }}
                  responsive
                />
              )}

              <DropdownSearch
                label="Languages"
                items={languagesItems}
                responsive
                dialogContained
                multiple
              />
            </div>
          </ListItemLayout>
        </ListLayout>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="button" onClick={() => setIsDirty(true)}>
            Submit
          </Button>
        </div>
      </form>
    </div >
  );
}

