import React from 'react';

export function ThumbnailGraphic(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="225" viewBox="0 0 400 225" fill="none">
      <g clipPath="url(#clip0_129_389)">
        <circle cx="200" cy="112.5" r="35.4167" stroke="var(--contrast-text)" strokeWidth="2.08333" />
        <path d="M190.651 101.792C190.651 100.213 192.34 99.2076 193.729 99.9612L213.455 110.669C214.908 111.458 214.908 113.543 213.455 114.331L193.729 125.039C192.34 125.793 190.651 124.788 190.651 123.208V101.792Z" fill="var(--contrast-text)" />
      </g>
    </svg>
  );
}