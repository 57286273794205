import { Context } from '../..';
import * as base from '../base';
import { TeamMemberReviewResource } from '../../../models/team-member-review-resource';
import { handleErrorToErrorPage } from '../functions';
import { ErrorSignalTypes } from '../../../enums';
import { LearnerSkillResource } from '../../../models/learner-skill-resource';

export async function getTeamReviews(context: Context): Promise<TeamMemberReviewResource[] | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`review`, `get-team-reviews`, context.state.skillAssessmentConfig?.assessmentSlug), authenticated: true };
  const response: base.IResponse<TeamMemberReviewResource[]> = await base.request(request);

  return response.data;
}

export async function submitTeamReviews(context: Context): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`review`, `complete-team-reviews`, context.state.skillAssessmentConfig?.assessmentSlug), authenticated: true, method: 'POST' };
  await base.request(request);
}

export async function submitTeamMemberReview(context: Context, payload: TeamMemberReviewResource): Promise<TeamMemberReviewResource | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`review`, `submit-team-member-review`, context.state.skillAssessmentConfig?.assessmentSlug), authenticated: true, method: 'POST', body: JSON.stringify([payload]) };
  const response: base.IResponse<TeamMemberReviewResource> = await base.request(request);

  return response.data;
}

export async function completeSelfReview(context: Context): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`review`, `complete-self-review`, context.state.skillAssessmentConfig.assessmentSlug), authenticated: true, method: 'POST' };
  await base.request(request);
}

export async function getSelfReviews(context: Context): Promise<LearnerSkillResource[] | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`review`, `get-self-reviews`, context.state.skillAssessmentConfig?.assessmentSlug), authenticated: true };
  const response: base.IResponse<LearnerSkillResource[]> = await base.request(request);

  return response.data?.filter((item, index, data) => index === data.findIndex(tempIndex => item.companySkillId === tempIndex.companySkillId));
}

export async function submitSelfReviews(context: Context, payload: Array<LearnerSkillResource>): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.url(`review`, `submit-self-reviews`, context.state.skillAssessmentConfig?.assessmentSlug), authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}