import React from 'react';
import { AccountPageButtonContainer } from './account.styles';

import linkedin from './icon_login_linkedIn.svg';
import microsoft from './icon_login_microsoft.svg';
import saml from './icon_login_SSO.svg';
import google from './icon_login_google.svg';

interface IAccountPageButtonProps {
  buttonType: 'linkedin' | 'microsoft' | 'google' | 'saml';
  onClick: () => {} | void;
}

function getIcon(type: string) {
  if (type === 'linkedin') return linkedin;
  else if (type === 'google') return google;
  else if (type === 'saml') return saml;
  else return microsoft;
}

export function AccountPageButton(props: IAccountPageButtonProps): JSX.Element {
  const type = props.buttonType;

  return (
    <AccountPageButtonContainer className={props.buttonType} onClick={props.onClick}>
      <img src={getIcon(type)} alt={type} />
    </AccountPageButtonContainer>
  );
}



