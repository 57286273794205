import { ThemeColours } from '@keplerco/core';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const LandingPageContent = styled.div`
  padding-left: 80px;
  padding-right: 50px;
  flex: 1;

  @media screen and (max-width: 930px) {
    padding: 0 30px;
    padding-top: 45px;
  }

  @media screen and (max-width: 580px) {
    .heading1 {
      padding: 5px 0;
    }
  }
`;

export const LandingPageContentCardsWrapper = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  gap: 30px;

  margin: 0;
  padding: 30px 0;
`;

const contentCardStyles = css`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 14px 60px 14px 30px;

  text-decoration: none;
  background: var(--cards);
  box-shadow: 0px 4px 20px var(--outer-shadow-colour_1);

  .heading3 {
    padding: 10px 0 5 0;
    line-height: 1;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }

  &::before {
    width: 20px;
    height: 20px;
    border: 4px solid var(--primary);
    right: 30px;
    top: 50%;
    margin-top: -10px;
    transform: rotate(45deg);
    border-bottom: none;
    border-left: none;
  }

  &.done {
    pointer-events: none;

    &::before {
      background: var(--primary);
      border-radius: 50%;
      transform: none;
      border: none;
      height: 30px;
      width: 30px;
      margin-top: -15px;
      right: 20px;
    }

    &::after {
      width: 16px;
      height: 10px;
      border: solid 3px var(--contrast-text);
      border-top: none;
      border-right: none;
      right: 25px;
      top: 50%;
      margin-top: -5px;
      transform: rotate(-45deg) translate(0px, -3px);
    }
  }
`;

export const LandingPageContentCard = styled(NavLink)<{ borderColour?: ThemeColours }>`
  ${contentCardStyles}

  border-left: solid 4px ${props => `var(--${props.borderColour})`};
`;

export const LandingPageContentCardTile = styled.div<{ borderColour?: ThemeColours }>`
  ${contentCardStyles}

  border-left: solid 4px ${props => `var(--${props.borderColour})`};
  cursor: pointer;
`;

export const LandingPageImage = styled.div`
  width: 40%;
  max-width: 580px;
  height: auto;

  object,
  img {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    display: none;
  }
`;

export const LandingPageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 93px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;
  margin: auto;

  @media screen and (max-width: 930px) {
    display: block;
  }
`;
