import React from 'react';
import { SkeletonLoaderInnards, SkeletonLoaderBones } from './skeleton-loader.styles';

export function SkeletonLoader(props: { height?: string; width?: string, borderRadius?: string }): JSX.Element {
  const { height = '50px', width = '100%', borderRadius = '20px' } = props;

  function validateSize(size: string) {
    return /^[0-9]*$/.test(size) ? `${size}px` : size;
  }

  return (
    <SkeletonLoaderBones height={validateSize(height)} width={validateSize(width)}>
      <SkeletonLoaderInnards borderRadius={borderRadius} />
    </SkeletonLoaderBones>
  );
}
