import styled from 'styled-components';
import { RatingPageHeader } from '../../rating.styles';

export const RatingTechnicalPeerEndorsementPageHeader = styled(RatingPageHeader)`
  margin-bottom: 30px;
  border-bottom: 1px solid var(--borders);
`;

export const EndorsementsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const RatingTechnicalPeerEndorsementPageAvatar = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  margin: 15px auto 15px auto;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0px 4px 8px var(--outer-shadow-colour_1);
  overflow: hidden;
  border: 2px solid var(--accent-2);

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }
`;

export const RatingTechnicalPeerEndorsementCard = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  box-shadow: 0px 4px 8px var(--outer-shadow-colour_1);
  cursor: pointer;

  .ratingChipWrapper {
    justify-content: center;
  }

  .subtitle {
    font-weight: bold;
  }

  .caption1 {
    font-weight: bold;
    color: var(--accent-2);
    margin-bottom: 30px;
  }

  a {
    margin-top: auto;
  }
`;
