import { useEffect, useState } from 'react';
import { FetchStatus, SyncStatus } from '../enums';
import { useAppActions, useAppState } from '../overmind';

// 1000ms (1s) * 120 = 2 minutes
const time = 1000 * 120;

export function useDataSyncTimeout(): void {
  const actions = useAppActions();

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.Inactive);

  useEffect(() => {
    async function syncData() {
      setFetchStatus(FetchStatus.Active);
      await actions.syncCourseContent();
      setTimeout(() => setFetchStatus(FetchStatus.Inactive), time);
    }

    if (fetchStatus === FetchStatus.Inactive) syncData();
  }, [fetchStatus]);
}

// learner
// general
// await actions.getNotificationsRequest();
// await actions.getTotalLearnerKeplerPoints();

// dashboard
// await actions.getContinueLearningLessons();

// journeys
// await actions.getLearningMap();
// await actions.getQuestionSetSkillQuestionnaire({ skillSlug: props.slug, questionSetType: QuestionSetType.PreCourseAssessment });

// journey plan
// location.pathname.includes('assigned-plan') ? await actions.getAssignedLearningJourney(slug) : await actions.getLearningJourney(skillSlug)
// await actions.getReflectionList(skillSlug);
// await actions.getQuestionSetSkillQuestionnaire({ skillSlug, questionSetType: QuestionSetType.PreCourseAssessment });
// await actions.getQuestionSetSkillQuestionnaire({ skillSlug, questionSetType: QuestionSetType.PostCourseAssessment });

// report
// await actions.getKeplerPoints();
// await actions.getCoursesInProgress();
// await actions.getCoursesCompleted();
// await actions.getCourseCompletionRate();
// await actions.getCertificateCount();

// learning manager/company admin
// report
// await actions.getKeplerPoints();
// await actions.getCoursesInProgress();
// await actions.getCoursesCompleted();
// await actions.getCourseCompletionRate();
// await actions.getCertificateCount();
// await actions.getKeplerPointsLeaderBoard();

export function useDataSyncReaction(dataSyncCallback: () => void): void {
  const { courseSyncStatus, lastSynced } = useAppState();

  useEffect(() => {
    async function getData() {
      await dataSyncCallback();
    }

    if (!!lastSynced && courseSyncStatus === SyncStatus.Synced) getData();
  }, [lastSynced]);
}

