import React from 'react';
import { useParams } from 'react-router';
import { TeamsCMSLayout } from './teams.cms.layout';
import { PagePath } from '../../../navigation/navigation.enums';

export function DepartmentTeamsCMSPage(): JSX.Element {
  const params = useParams();

  return <TeamsCMSLayout path={PagePath.administrationDepartmentTeams} slugs={{ companySlug: params.companySlug!, departmentSlug: params.departmentSlug! }} type="department" />;
}

