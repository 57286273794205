import React from 'react';
import styled from 'styled-components';
import { themedAssetUrl } from '../lib/theme-asset-url';
import { useLocation } from 'react-router-dom';
import { PagePath } from '../navigation/navigation.enums';
import { useMatchScreenWidth } from '@keplerco/core';

const DotsWrapper = styled.div`
  background-size: cover;
  position: absolute;
  bottom: 0px;
  width: 270px;
  height: 270px;
  right: 0px;
  background-repeat: no-repeat;
  z-index: 0;

  object,
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 580px) {
    bottom: 50px;
    width: 200px;
    height: 200px;
  }
`;

export function Dots(props: { show?: boolean }): JSX.Element | null {
  const hideOn = [PagePath.analysisBase, PagePath.analyticsBase, PagePath.learningJourneyBase, '/error/'];
  const isMatch = !!hideOn.find(path => window.location.pathname.includes(path));
  const isTablet = useMatchScreenWidth('tablet');
  const location = useLocation();

  if (!isMatch && !isTablet && props.show) {
    return (
      <DotsWrapper tabIndex={-1} data-label={location.pathname}>
        <object tabIndex={-1} data={themedAssetUrl('graphics/dots.graphic.svg')} type="image/svg+xml">
          <img tabIndex={-1} src={themedAssetUrl('graphics/dots.graphic.svg')} alt="dots" />
        </object>
      </DotsWrapper>
    );
  } else return null;
}

