import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Mode, PagePath } from '../navigation.enums';
import { useAppState } from '../../overmind';

export function ActiveCompanySlugRedirect(props: { landingRedirectTo: string }) {
  const { companyVariables } = useAppState();

  return (
    <Routes>
      {!!companyVariables.slug && (
        <Route path={PagePath.root} element={<Navigate to={`${props.landingRedirectTo.replace(':companySlug', companyVariables.slug)}`} />} />
      )}
      <Route path={PagePath.rootWildcard} element={<ModeRedirect />} />
    </Routes>
  );
}

export function ModeRedirect() {
  const { user, mode } = useAppState();

  return (
    <Routes>
      <Route path={PagePath.root} element={<Navigate to={user?.isSystemAdmin && mode === Mode.PlatformManagement ? PagePath.companies : PagePath.dashboard} />} />
      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}

export function ErrorRedirect(): JSX.Element {
  return (
    <Routes>
      <Route path={PagePath.rootWildcard} element={<Navigate to={PagePath.errorRouting404} replace />} />
    </Routes>
  );
}