import React from 'react';
import { Button, FocusPanelHeader, ListLayout, ListItemLayout } from '@keplerco/core';
import { getSkillLevelExplanations } from '../../../../lib/skill-level-explanations.helpers';
import { useAppState } from '../../../../overmind';

export function SkillLevelsExplainerFocusPanelLayout({ onClose }: { onClose: () => void }): JSX.Element {
  const { companyVariables } = useAppState();
  const skillLevelExplanations = getSkillLevelExplanations(companyVariables.maxLevel);

  return (
    <div className="dialogContentLayout focusPanelContentLayout" style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      <FocusPanelHeader
        supertitle="Company skill levels"
        title="What does the skill level mean?"
        subtitle="Each level has set responsibilities that determine how effective you are at that skill."
        divider
      />

      <ListLayout>
        {skillLevelExplanations.map(skillLevelExplanation => (
          <ListItemLayout key={skillLevelExplanation.title}>
            <div className="card">
              <h5 className="heading5">{skillLevelExplanation.title}</h5>
              <p className="body2">{skillLevelExplanation.description}</p>
            </div>
          </ListItemLayout>
        ))}
      </ListLayout>

      <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="button" onClick={onClose}>Okay, Got It</Button>
      </footer>
    </div>
  );
}
