import { ThemeColours, colourString } from '@keplerco/core';
import styled from 'styled-components';

export const SkillLevelOverTimeWidgetLegendElement = styled.div<{ colour: ThemeColours }>`
  position: relative;
  padding-left: 20px;
  color: var(--text);
  font-size: 0.78rem;
  opacity: 0.3;
  cursor: pointer;

  &.active {
    opacity: 1;
  }

  &.active.forceActive {
    cursor: no-drop;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    display: block;
    margin-top: -5px;
    border-radius: 1px;
    width: 10px;
    height: 10px;
    background-color: ${({ colour }) => colourString(colour)};
  }
`;

export const SkillLevelOverTimeWidgetLegend = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

