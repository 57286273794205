import React from 'react';
import { Employee } from '../../../models/view/employee';
import { PagePath } from '../../../navigation/navigation.enums';
import { FlagIcon } from '@keplerco/core';

export function EmployeeSeniorityFlag(props: Employee) {
  if (props.isDepartmentManager) {
    return <FlagIcon tooltipContent="This person is a department manager" size={30} theme="green" />;
  } else if (props.isTeamChampion) {
    return <FlagIcon tooltipContent="This person is a team champion" size={30} theme="blue" />;
  }

  return <FlagIcon size={20} theme="none" />;
}

export function createDepartmentLink(companySlug: string, departmentId: number | string) {
  const slugged = PagePath.administrationDepartmentPeople.replace(':companySlug', companySlug);
  return `${PagePath.administrationBase}${slugged.replace(':departmentSlug', departmentId?.toString())}`;
}

export function createTeamLink(companySlug: string, teamId: number | string) {
  const slugged = PagePath.administrationTeamPeople.replace(':companySlug', companySlug);
  return `${PagePath.administrationBase}${slugged.replace(':teamSlug', teamId?.toString())}`;
}

