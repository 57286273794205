import { Context } from '../..';
import { SyncStatus } from '../../../enums';
import { AuthenticationStatus } from '../../../enums/authentication-status';
import * as base from '../base';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { DeveloperConsole } from '@keplerco/core';

const devConsole = new DeveloperConsole('Data Sync', ['#E5145F', '#FFFFFF']);

export async function syncCourseContent(context: Context): Promise<void> {
  if (context.state.authenticationStatus !== AuthenticationStatus.Authenticated || context.state.courseSyncStatus === SyncStatus.Syncing) return;

  context.state.courseSyncStatus = SyncStatus.Syncing;
  devConsole.log('Started');

  const request: base.IRequest = { url: base.url(`datasync`, `course-content`), authenticated: true };
  const response: base.IResponse<any> = await base.request(request);

  const currentNotifications = context.state.queue.filter(item => item.title?.includes(`We couldn't sync with the server.`));
  for (const notification of currentNotifications) context.actions.dismissNotification(notification.id);

  if (response.error) {
    context.state.courseSyncStatus = SyncStatus.Error;

    if (currentNotifications.length > 2) {
      context.actions.addNotification({
        id: Date.now().toString(),
        title: `We couldn't sync with the server. Please contact support`,
        message: response.error.type,
        priority: QueueItemPriority.Toast,
        type: QueueItemType.Error,
        active: true,
      });
    } else {
      context.actions.addNotification({
        id: Date.now().toString(),
        title: `We couldn't sync with the server.`,
        message: response.error.type,
        priority: QueueItemPriority.Toast,
        type: QueueItemType.Error,
        active: true,
      });
    }

    return;
  }

  context.actions.setLastSyncedAndSyncStatus();
}

export function setLastSyncedAndSyncStatus(context: Context): void {
  context.state.lastSynced = new Date();
  context.state.courseSyncStatus = SyncStatus.Synced;
  devConsole.log('Completed');
}
