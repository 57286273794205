import styled from 'styled-components';

export const TagManagementScrollContainer = styled.div`
  max-height: calc(100vh - (100px + 112.25px + 80px + 31.95px));
  overflow-y: auto;
  padding-right: 10px;
`;

export const TagHeaderColumn = styled.div`
  width: 50%;
  text-align: left;
`;
