import React from 'react';
import classNames from 'classnames';
import { EmailBlockDeleteButtonContainer, EmailVerificationStatusIndicator, PrimaryEmailChip, PrimaryEmailChipWrapper, SettingsPageEmailBlock } from './profile.styles';
import { IProfileEmailBlockProps } from './profile.models';
import { DeleteIcon, IconButton, safeCallback } from '@keplerco/core';

export function ProfileEmailBlock(props: IProfileEmailBlockProps): JSX.Element {
  return (
    <SettingsPageEmailBlock className={classNames({ noBorder: props.hideChip })}>
      <p className="body1">{props.email}</p>

      {!props.hideChip && (
        <PrimaryEmailChipWrapper>
          <PrimaryEmailChip
            onClick={() => {
              if (!props.isPrimary && !!props.verificationDate) safeCallback(props.onMakePrimary, props.email);
              else console.log('Error State', `Cannot make email primary`);
            }}
            className={classNames({ active: props.isPrimary, unverified: !props.verificationDate })}
          >
            {props.isPrimary ? `Primary Email` : `Make Primary Email`}
          </PrimaryEmailChip>
        </PrimaryEmailChipWrapper>
      )}

      <EmailVerificationStatusIndicator>
        {!!props.verificationDate ? (
          <span>
            Verified
            <div className="indicator">
              <svg fill="none" viewBox="0 0 15 15">
                <path fill="#57A8F2" fillRule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Zm7.072 3.21 4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827v-.001Z" clipRule="evenodd" />
              </svg>
            </div>
          </span>
        ) : (
          <>
            <span>
              Not yet verified
              <div className="indicator">
                <svg fill="none" viewBox="0 0 15 15">
                  <path
                    fill="#F25757"
                    d="M12.843 2.188C9.92-.729 5.12-.729 2.193 2.188c-2.924 2.918-2.924 7.706 0 10.624a7.482 7.482 0 0 0 10.575 0c2.925-2.918 3-7.706.075-10.624ZM9.62 10.642l-2.1-2.095-2.1 2.095-1.05-1.047 2.1-2.095-2.1-2.095 1.05-1.047 2.1 2.095 2.1-2.095 1.05 1.047-2.1 2.095 2.1 2.095-1.05 1.047Z"
                  />
                </svg>
              </div>
            </span>
            <button
              onClick={() => {
                safeCallback(props.onResendVerification, props.email);
              }}
            >
              Resend verification
            </button>
          </>
        )}
      </EmailVerificationStatusIndicator>

      {!props.isPrimary && (
        <EmailBlockDeleteButtonContainer>
          <IconButton
            iconType="fill"
            onClick={() => {
              if (!props.isPrimary) safeCallback(props.onDelete, props.email);
              else console.log('Error State', `Cannot delete email`);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </EmailBlockDeleteButtonContainer>
      )}
    </SettingsPageEmailBlock>
  );
}

