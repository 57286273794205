import styled from 'styled-components';
import { getBorderColorByCompletionStatus } from './learner-assessments.helpers';
import { CompletionStatus } from '../../../enums';

export const PageWrapper = styled.div`
  margin: 20px;
`;

export const AssessmentCard = styled.div<{ status: CompletionStatus }>`
  position: relative;
  display: flex;
  min-height: 63px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 15px 25px;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    width: 4.5px;
    background-color: ${props => getBorderColorByCompletionStatus(props.status)};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;
export const AssessmentCardSubtitle = styled.div`
  font-size: 0.7em;
  color: var(--accent-3);
`;

export const AssessmentTitleWrapper = styled.div`
  flex-direction: column;
  display: flex;
`;

export const SkillGradingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;
