import { Context } from '../..';
import * as base from '../base';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { AdditionalLearningViewModel, AdditionalLearningSearchRequest, AdditionalLearningListViewModel } from '../../../pages/learning-journey/additional-learning/additional-learning.models';

export async function deleteAdditionalLearning(context: Context, slug: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`offplatformlearning`, `remove-off-platform-learning`, slug), authenticated: true, method: 'DELETE' };
  await base.request(request);
  await context.actions.getTotalLearnerKeplerPoints();
}

export async function saveAdditionalLearning(context: Context, offPlatformLearningDetailViewModel: AdditionalLearningViewModel): Promise<AdditionalLearningViewModel | undefined> {
  const request: base.IRequest = { url: base.url(`offplatformlearning`, `save-off-platform-learning`), authenticated: true, method: 'POST', body: JSON.stringify(offPlatformLearningDetailViewModel) };
  const response: base.IResponse<AdditionalLearningViewModel> = await base.request(request);

  return response.data;
}

export async function getAdditionalLearning(context: Context, slug: string): Promise<AdditionalLearningViewModel | undefined> {
  const request: base.IRequest = { url: base.url(`offplatformlearning`, `get-off-platform-learning`, slug), authenticated: true };
  const response: base.IResponse<AdditionalLearningViewModel> = await base.request(request);

  return response.data;
}

export async function getAdditionalLearningList(context: Context, params: AdditionalLearningSearchRequest): Promise<AdditionalLearningListViewModel[] | undefined> {
  const queryEntries = Object.entries(params);
  const search = new URLSearchParams(queryEntries);

  const request: base.IRequest = { url: base.url(`offplatformlearning`, `get-off-platform-learning-list?${search.toString()}`), authenticated: true };
  const response: base.IResponse<AdditionalLearningListViewModel[]> = await base.request(request);

  return response.data;
}

export async function searchLearnerSkills(context: Context, searchTerm: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.url(`learnerskill`, `search-learner-skills?searchTerm=${searchTerm}`), authenticated: true };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);

  return response.data;
}
