import { FocusPanel, ListIcon, MapIcon, PageHeader, ToggleButtonGroup } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { SyncContentCard } from '../../../components';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { FetchType, SyncStatus } from '../../../enums';
import { useDataSyncReaction } from '../../../lib/data-sync.hooks';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../overmind';
import { CoreSkillsMapSkeleton } from './core-skills-map.skeleton';
import { CoreSkillsMapPageContainer, CoreSkillsMapPageHeaderContainer, CoreSkillsMapSyncContainer } from './core-skills-map.styles';
import { LearnerSkillDetail } from '../../../models/view/courses';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { CoreSkillsMap } from './core-skills-map';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { LearningJourneyViewType } from '../../../enums/learning-journey-view-type';
import { SkillLevelsExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/skill-levels-explainer-focus-panel/skill-levels-explainer-focus-panel.layout';

export function CoreSkillsMapPage(): JSX.Element {
  const actions = useAppActions();
  const { lastSynced, courseSyncStatus, companyVariables } = useAppState();

  const navigate = useKeplerNavigate();

  const [skills, setSkills] = useState<LearnerSkillDetail[]>();
  const [open, setOpen] = useState<boolean>(false);

  async function getData(loader = false) {
    if (loader) actions.startLoader({ path: PagePath.learningJourneyCoreSkillsMap, type: FetchType.PageFetching });
    const response = await actions.getCoursesLearnerSkills();
    setSkills(response);
    if (loader) actions.stopLoader(PagePath.learningJourneyCoreSkillsMap);
  }

  useEffect(() => {
    getData(true);
  }, []);

  useDataSyncReaction(async () => {
    getData();
  });

  async function onClickViewType(viewType: LearningJourneyViewType) {
    actions.startLoader({ path: PagePath.learningJourneyCoreSkillsMap, type: FetchType.PageFetching });
    actions.setLearningJourneyView(viewType);
    navigate(viewType === LearningJourneyViewType.Map ? `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}` : PagePath.learningJourneyBase);
    actions.stopLoader(PagePath.learningJourneyCoreSkillsMap);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningJourneyCoreSkillsMap} skeletonLoader={<CoreSkillsMapSkeleton />} background={'hsla(224, 52%, 10%, 1)'}>
        <CoreSkillsMapPageContainer>
          <CoreSkillsMapPageHeaderContainer style={{ position: `${!skills?.length ? 'relative' : 'absolute'}` }}>
            <PageHeader
              breadcrumbs={[
                {
                  name: 'Learning Journey',
                  url: PagePath.yourSkillsBase,
                },
                {
                  name: 'Core Skills Map',
                  url: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}`,
                },
              ]}
              title="Core Skills Map"
              forceDark
              onClickHelp={companyVariables.useLevels ? () => setOpen(true) : undefined}
            >
              <CoreSkillsMapSyncContainer>
                <SyncContentCard
                  isSyncing={courseSyncStatus === SyncStatus.Syncing}
                  variant="compact"
                  lastSyncDate={lastSynced}
                  isMapMode
                  onClick={() => {
                    actions.syncCourseContent().then(() => {
                      actions.getTotalLearnerKeplerPoints();
                    });
                  }}
                />

                <ToggleButtonGroup
                  buttons={[
                    { label: LearningJourneyViewType.Map, icon: <MapIcon /> },
                    { label: LearningJourneyViewType.List, icon: <ListIcon /> },
                  ]}
                  defaultButtonLabel={LearningJourneyViewType.Map}
                  onClick={buttonLabel => onClickViewType(buttonLabel as LearningJourneyViewType)}
                />
              </CoreSkillsMapSyncContainer>
            </PageHeader>
          </CoreSkillsMapPageHeaderContainer>

          {!skills?.length ? <EmptyState title="No skills yet" badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} color="contrast-text" /> : <CoreSkillsMap skills={skills} />}
        </CoreSkillsMapPageContainer>
      </PageLoaderLayer>

      {companyVariables.useLevels && (
        <FocusPanel open={open} onClose={() => setOpen(false)}>
          <SkillLevelsExplainerFocusPanelLayout onClose={() => setOpen(false)} />
        </FocusPanel>
      )}
    </React.Fragment>
  );
}

