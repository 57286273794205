import styled from 'styled-components';

export const AssessmentQuestionnairesWrapper = styled.div`
  display: block;
`;

export const AssessmentQuestionnairesCardsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
`;

export const AssessmentQuestionnairesSearchHeader = styled.header`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  gap: 15px;
  padding-bottom: 15px;
`;

export const AssessmentQuestionnairesSearchHeaderColumn = styled.div``;

export const AssessmentQuestionnairesCardsListItem = styled.div`
  .caption1,
  .caption2 {
    display: block;
  }

  .caption1 {
    color: var(--accent-3);
    font-weight: 500;
    font-family: var(--heading);
  }

  .caption2 {
  }
`;

export const AssessmentQuestionnairesFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 25px;
`;

