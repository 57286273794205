import styled from 'styled-components';

export const RegisterPasswordWrapper = styled.div`
  position: relative;
`;

export const RegisterPasswordErrorFlyouts = styled.div`
  position: absolute;
  display: flex;
  gap: 15px;
  flex-flow: column nowrap;
  top: -20px;
  right: calc(100% + 15px);
  width: 100%;
  border-color: var(--validation);

  ul {
    display: block;
    color: var(--validation);
    text-align: left;
    padding: 0 0 0 22px;
    margin: 0;

    li {
      font-size: 14px;
      padding-bottom: 5px;

      span {
        color: var(--text);
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background-color: var(--validation);
    position: absolute;
    z-index: -1;
    right: -9px;
    transform: rotate(45deg);
    top: 32px;
  }

  @media screen and (max-width: 1055px) {
    position: static;
    margin-top: 15px;
    padding: 10px;

    &::after {
      display: none;
    }
  }
`;

