import React, { useState } from 'react';
import { TopNav } from './top-nav/top-nav';
import { SideNav } from './side-nav/side-nav';

export function NavigationOutlet(props: { isMobile: boolean; isTablet: boolean }): JSX.Element {
  const { isMobile, isTablet } = props;

  const [showSideNavDialog, setShowSideNavDialog] = useState<boolean>(false);

  return (
    <React.Fragment>
      <TopNav isMobile={isMobile} isTablet={isTablet} onOpenSideNavDialog={() => setShowSideNavDialog(currentState => !currentState)} />

      <SideNav isMobile={isMobile} isTablet={isTablet} showSideNavDialog={showSideNavDialog} onCloseSideNavDialog={() => setShowSideNavDialog(false)} />
    </React.Fragment>
  );
}
