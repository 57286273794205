import React from 'react';
import { SkeletonLoader } from '../../../components';
import { SkeletonLoaderRow, SkeletonLoaderColumn } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

export function ManageCompanyFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SkeletonLoader height="45px" width="315px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoader height="calc(100vh - 125px)" />

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="25px" width="150px" />
        <SkeletonLoader height="50px" width="150px" />
      </footer>
    </div>
  );
}

